import React from "react";
// Customizable Area Start
import "./style.css";
import {
  Box, Typography,Popover,
   Grid,
   TableContainer,
   Table,
   TableHead,
   TableRow,
   TableCell,
   TableBody,
   Button,
   Dialog,
   DialogContent,
   DialogContentText,
} from "@material-ui/core";

import {ThemeProvider, createTheme } from '@material-ui/core/styles';
import ClientServiceController, { Props } from "./ClientServiceController.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import UserProfile from "../../../components/src/UserProfile.web";
import { arrowDown, image_Imagenav_backicon } from "./assets";


let theme = createTheme({
   
  });
  theme = createTheme(theme, {
   
    overrides: {
      MuiTableContainer: {
        root: {
          boxShadow: "none",
          borderCollapse: "separate",
          borderSpacing: "0px 6px",
          fontFamily:"Poppins !important",
          
          borderRadius: 0,
          "&.normal-grid": {
            "& .MuiTable-root": {
              "& .MuiTableHead-root": {
                "& .MuiTableCell-root": {
                  padding: "14px 22px",
                  fontWeight: 500,
                  paddingLeft:"12px",
                  fontSize: 16,
                  lineHeight: "20px",
                  letterSpacing: "0.25px",
                  color: "#8f99a3",
                  border: "none",
                  fontFamily:"Poppins !important",
                  whiteSpace: "nowrap",
                },
              },
              "& .MuiTableBody-root": {
                "& .MuiTableRow-root.MuiTableRow-hover:hover": {
                  backgroundColor: "rgba(9,72,73,0.05)",
                  cursor: "pointer",
                  paddingTop: 6,
                  paddingBottom: 6,
                  fontFamily:"Poppins !important",
                },
                "& .MuiTableRow-root.disabled": {
                    "& .MuiTableCell-root": {
                        opacity:0.60
                    }
                  },
                "& .MuiTableCell-root": {
                  padding: "6px 12px",
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: "20px",
                  letterSpacing: "0.25px",
                  color: "#fff",
                  borderColor: "#808d9e",
                  borderTop:"1px solid #808d9e",
                  borderBottom:"1px solid #808d9e",
                  fontFamily:"Poppins !important",
                },
              },
            },
          },
        },
      },

    
   

      MuiPopover: {
        root: {
          zIndex: 999,
          borderRadius: "14px",
          "&.simple-tooltip .MuiPaper-root": {
            padding: 14,
            boxShadow: "none",
            fontSize: 12,
            letterSpacing: "0.4px",
            backgroundColor: "#395d6b !important",
            borderRadius: "14px",
          },
          "& .MuiPaper-rounded": {
            borderRadius: "0px",
            boxShadow: "none",
          },
        },
      },
    },
  });
// Customizable Area End


export default class ClientService extends ClientServiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start


  // Customizable Area End

  render() {

    // Customizable Area Start
    
      return (
          <ThemeProvider theme={theme}>
              <div className="pagedashboard-wrapper2">
             
                  <Grid container style={{
                      flexWrap: "wrap",
                      background: "#2a2a39"
                  }} >
                      <Grid item xs={12} style={{
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between"
                      }}>
                      <Box style={webStyle.profileNameNew}>
                        <Box onClick={()=>this.goToBackPage()} data-test-id="backButton" ><img src={image_Imagenav_backicon} style={webStyle.marginImg1} alt="back" /></Box>
                        <Box style={webStyle.planning}> Clients</Box>
                    </Box>
                          <UserProfile username={this.state.plannerInfo.name} email={this.state.plannerInfo.email}  profilePicture={this.state.plannerInfo.image} />
                      </Grid>
                      <Grid item md={12} className="tabsContainer-service">
                          <Grid container className="clientTabsSpacing" >
                              <Grid item >
                                  <Typography style={webStyle.inActiveTab} data-test-id="service-tab" onClick={() => this.navigateRoute("ClientDetails")}> Details </Typography>
                              </Grid>
                              <Grid item >
                                  <Typography style={webStyle.inActiveTab} data-test-id="service-tab" onClick={() => this.navigateRoute("ClientAssets")}> Assets </Typography>
                              </Grid>
                              <Grid item >
                                  <Typography style={webStyle.activeTab} data-test-id="service-tab"> Services </Typography>
                              </Grid>
                              <Grid item >
                                  <Typography style={webStyle.inActiveTab} onClick={()=>{
                                    this.handleGoToVault()
                                  }} data-test-id="vault-tab"> Vault </Typography>
                              </Grid>

                          </Grid>
                      </Grid>


                  </Grid>
                  {
                      this.state.services.length === 0 && <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                          <Box>
                              <Typography align="center" style={webStyle.noServiceTxt} data-test-id="noTemplate">
                                  No services available
                              </Typography>
                          </Box>
                      </Box>
                  }
                  <Box style={{
                    marginTop:"18px"
                  }}>
                  {
                      this.state.services.map((service, serviceIndex) => {
                          return (
                              <Box style={webStyle.boxContainer} className="client-service-box" >
                                <Box style={{
                                    display:"flex",
                                    alignItems:"center"
                                }}>
                                    {
                                        service.attributes.image && 
                                        <div style={{
                                            marginRight:"12px"
                                        }}>
                                             <img src={service.attributes.image} alt="" style={{
                                                height: "28px",
                                                width: "28px",
                                                marginTop: "16px"
                                                
                                             }} />
                                        </div>
                                    }
                                   

                                  <Typography style={webStyle.titleText}>
                                      {service.attributes.service_name}

                                  </Typography>
                                  </Box>

                                  <Typography style={webStyle.heading}>
                                      Service Status

                                  </Typography>
                                  <Box>
                                      <TableContainer className="normal-grid">
                                          <Table style={{
                                              minWidth: 750, borderCollapse: "separate",
                                              borderSpacing: "0px 14px"
                                          }}>
                                              <TableHead>
                                                  <TableRow>
                                                      <TableCell align="left">Service</TableCell>
                                                      <TableCell align="left">Expected Date</TableCell>

                                                      <TableCell align="left">Completion Date</TableCell>
                                                      <TableCell align="left">Status</TableCell>

                                                  </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                  {
                                                      service.attributes.plan_inclusions.map((planInclusion) => (
                                                          <TableRow className={this.isPlanInclusionCompleted(planInclusion.status,planInclusion.allowEdit)? "disabled":""} key={planInclusion.id}>
                                                              <TableCell align="left">
                                                                  {planInclusion.name}
                                                              </TableCell>
                                                              <TableCell align="left">
                                                                  <Box style={{
                                                                      display: "flex",
                                                                      alignItems: "center"
                                                                  }}>
                                                                      <div>
                                                                      {planInclusion.expected_date ? planInclusion.expected_date : "DD/MM/YYYY"}
                                                                        </div>
                                                                      <CustomDatePicker type="expected" isReadonly={this.isPlanInclusionCompleted(planInclusion.status, planInclusion.allowEdit)} currentDate={planInclusion.expected_date} onDateChange={this.handleDateChange} planId={planInclusion.id} serviceIndex={serviceIndex} />
                                                                  </Box>

                                                              </TableCell>
                                                              <TableCell align="left">
                                                                  <Box style={{
                                                                      display: "flex",
                                                                      alignItems: "center"
                                                                  }}>
                                                                    <div>
                                                                    {planInclusion.completion_date ? planInclusion.completion_date : "DD/MM/YYYY"}

                                                                    </div>

                                                                      <CustomDatePicker type="compeletion" isReadonly={this.isPlanInclusionCompleted(planInclusion.status, planInclusion.allowEdit)} currentDate={planInclusion.completion_date} onDateChange={this.handleDateChange} planId={planInclusion.id} serviceIndex={serviceIndex} />
                                                                  </Box>
                                                              </TableCell>
                                                              <TableCell align="left">
                                                                  <div style={{ display: "flex", alignItems: "center", textTransform: "capitalize" }}>
                                                                      {planInclusion.status}
                                                                      <div
                                                                          data-test-id="change-status-service"
                                                                          onClick={(event) => {
                                                                              if (!this.isPlanInclusionCompleted(planInclusion.status, planInclusion.allowEdit)) {
                                                                                  this.handleSetStatusTooltip(event.currentTarget, serviceIndex, planInclusion.id);
                                                                              }
                                                                          }}
                                                                          style={{ cursor: "pointer" }}
                                                                      >
                                                                          <img src={arrowDown} alt="arrowDown" style={{ width: "14px", height: "8px", marginLeft: "12px" }} />
                                                                      </div>
                                                                  </div>
                                                              </TableCell>
                                                          </TableRow>))
                                                  }
                                              </TableBody>
                                          </Table>
                                      </TableContainer>
                                  </Box>
                              </Box>
                          )
                      })
                  }
                  {
                     this.state.services.length > 0 && !this.state.allCompleted &&   <div style={webStyle.btnContainer}>

                     <Button style={webStyle.cancelBtn}  onClick={() => this.handleCancelService()} data-test-id="cancel-service" >
                         Cancel
                     </Button>
                     <Button style={webStyle.saveBtn} data-test-id="save-service" onClick={() => this.handleSaveServices()} >
                         Save
                     </Button>
                 </div>
                  }


                  </Box>

              
                 
                  <Dialog

                      open={this.state.openConfirmationPopup}
                      keepMounted
                      data-test-id="dialogModel"
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                      PaperProps={{ style: webStyle.dialogStyle }}
                  >
                      <DialogContent style={{ padding: '8px 20px' }}>
                          <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
                              textAlign: "center"
                          }}>
                              <Typography style={webStyle.successMessage}>
                                  Are you sure you want to change the status of service?
                              </Typography >
                              <Typography style={webStyle.successMessage}>
                                  Once changed, it can't be undone.
                              </Typography >

                              <div style={{...webStyle.btnContainer,justifyContent:"space-between"}}>

                                  <Button style={webStyle.cancelBtnRed} data-test-id="cancel-submit-service" onClick={() => this.handleCloseConfirmationPopup()}>
                                      Cancel
                                  </Button>
                                  <Button style={webStyle.cancelBtn} data-test-id="save-submit-service" onClick={() => this.handleSubmitServices()} >
                                      Save
                                  </Button>
                              </div>
                          </DialogContentText>
                      </DialogContent>
                  </Dialog>
                  <Popover
                      open={Boolean(this.state.changeStatusTooltip)}
                      className="simple-tooltip"
                      elevation={0}
                      anchorEl={this.state.changeStatusTooltip}
                      onClose={this.handleCloseStatusTooltip}
                      anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                      }}
                      transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                      }}
                      PaperProps={{
                          style: {
                              backgroundColor: "transparent",
                              marginTop: "10px",
                          },
                      }}
                  >
                      <Box style={{
                        padding:"12px"
                      }}>
                          <Typography
                              style={{
                                  fontSize: "14px",
                                  color: "#fff",
                                  marginBottom:"12px",
                                  cursor:"pointer"
                              }}
                              data-test-id="pending-service"
                              onClick={() => this.handleStatusChange("pending")}
                          >
                              Pending
                          </Typography>
                          <Typography
                              style={{
                                  fontSize: "14px",
                                  color: "#fff",
                                  cursor:"pointer"
                              }}
                              data-test-id="completed-service"
                              onClick={() => this.handleStatusChange("completed")}

                          >
                              Completed
                          </Typography>
                      </Box>
                  </Popover>

              </div>
          </ThemeProvider>
      );
    // Customizable Area End
  }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle= {
    boxContainer:{
        backgroundColor: "#3c3c50",
        marginBottom:"50px",
        marginTop:"18px",
    },
    noServiceTxt: { fontSize: "14px", color: "#c4c4c4", fontFamily: 'Poppins', whiteSpace: "break-spaces" },
    titleText: {fontSize: "24px",color: "#fff",fontFamily: "Poppins",marginTop:"18px"},
    heading:{fontSize: "18px",color: "#fff",fontFamily: "Poppins",fontWeight:500,marginTop:"12px"},
    successMessage: {fontSize: "20px",color: "#fff",fontFamily: "Poppins"},
    dialogStyle: {width: 'max-content',backgroundColor: '#3b3c50', borderRadius: '8px' ,boxShadow: "none", border:"1px solid #57575d",paddingTop:"16px",paddingBottom:"16px"},
    saveBtn: { marginLeft:"24px",color: "#3c3c50", background: "#c9f9f6", width:"180px", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px",padding:"10px" },
    btnContainer: {display:"flex",justifyContent:"end", textAlign: "center", marginTop: "18px",fontFamily: "Poppins",gap:'10px' },
    cancelBtnRed: { color: "#ee5353", width:"180px", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #ee5353", borderRadius: "8px",padding:"10px" } ,
    profileNameNew: { display: 'flex', alignItems: 'center' },
    marginImg1: { margin: '0px', cursor: "pointer" },
    planning: { fontSize: "22px", color: "fff", fontFamily: 'Poppins', fontWeight: 500 ,marginLeft:"18px"},
    inActiveTab: { fontSize: "22px", color: "2de2d5", cursor: "pointer",fontFamily:"Poppins"},
    activeTab: { fontSize: "22px", fontWeight: 600, color: "2de2d5", cursor: "pointer", borderBottom: "1px solid" ,fontFamily:"Poppins"},
    cancelBtn: { color: "#c9f9f6", width:"180px", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px",padding:"10px" } ,
}
// Customizable Area End


