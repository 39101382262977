import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

interface CustomerPlannerDataObject {
    id: string,
    type: string,
    attributes: {
        planner_id: number,
        name: string,
        location: string,
        jurisdiction_advice: string,
        disclosure: string,
        firm: string,
        employment_history: string,
        qualification: string,
        experience: number,
        certificate: string,
        email: string,
        terms_and_condition: boolean,
        url: string,
        dob: string,
        mobile: string,
        allow_access: boolean,
        access_permission_after_service: boolean | null
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  logoImgsetting?:string
  plannerList: CustomerPlannerDataObject[],
  language:string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class EstatePlanningPreferencesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomerPlannerListDataCallId: string = "";
  postPermissionApiCallId: string = "";


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    
    // Customizable Area End


    this.state = {
      // Customizable Area Start
      logoImgsetting: "",
      plannerList: [],
      language:'English'
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleCustomerPlannerListDataResponse(apiRequestCallId,responseJson);
    this.handlePermissionResponse(apiRequestCallId,responseJson);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getStorageDataLanguage();
    this.getCustomerPlannerList();
  }


  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const { contentType, method, endPoint, body } = data;
    const clientDetailsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    clientDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token: localStorage.getItem("authToken")
      })
    );

    clientDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    clientDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && clientDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(clientDetailsRequestMessage.id, clientDetailsRequestMessage);
    return clientDetailsRequestMessage.messageId;
  };
  getStringCrypto = (keyvalue: string) => {
    return configJSON.languageListForecasting[this.state.language][keyvalue]
  }

  getCustomerPlannerList = async () => {
    this.getCustomerPlannerListDataCallId = await this.apiCall({
      method: "GET" + '',
      endPoint: `/bx_block_formapprovalworkflow/wealth_planners/list_customers_planners`,
      body: null,
      contentType:"application/json"
    });
  }

  handleCustomerPlannerListDataResponse = (apiCallId:string, responseJson: { WealthPlanner: { data: CustomerPlannerDataObject[] }}) => {
    if(apiCallId === this.getCustomerPlannerListDataCallId && responseJson?.WealthPlanner?.data){
        this.setState({plannerList: responseJson.WealthPlanner.data})
    }
  }

  async getStorageDataLanguage (){
    let language = await getStorageData('language');
    const logoImg1Setting = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ logoImgsetting: logoImg1Setting ,language: language});

   
  }

  handlePlannerPermission = async (checked: boolean, plannerId: number) => {
    const payload = {
        "permission": checked,
        "wealth_planner_id": plannerId,
    }
    this.postPermissionApiCallId = await this.apiCall({
        method: "POST" + '',
        endPoint: `/account_block/accounts/allow_access_permission`,
        body: JSON.stringify(payload),
        contentType:"application/json"
    });
  }

  handlePermissionResponse = (apiCallId:string, responseJson: { message: string }) => {
    if(apiCallId === this.postPermissionApiCallId && responseJson?.message){
        this.getCustomerPlannerList();
    }
  }

  navigateRoute = (route: string, navigateData: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), navigateData)
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message)
  }

  // Customizable Area End 
}