import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { AssetItem } from "./utils";
import { sidearrow, subarrowicon } from "../../../blocks/dashboard/src/assets";
import { NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import TimelineList from "./TimelineList";

interface TimeLineListData {
    name: string;
    items: Array<{ [key: string]: unknown }>;
    href: string
}
interface SortableItemProps {
    item: AssetItem;
    languageDirection: string;
    getLanguageString: any;
    isExpanded: boolean;
    onToggleExpand: () => void;
    timelineListData: TimeLineListData;
    timeLineListLoading: boolean
}

const SortableItem: React.FC<SortableItemProps> = ({ item, languageDirection, getLanguageString, isExpanded, onToggleExpand, timelineListData, timeLineListLoading }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id });

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: "20px",
        marginBottom: "25px",
        backgroundColor: "#3c3c50",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        boxShadow: "0 4px 10px rgba(0,0,0,.15)"
    };

    const toggleExpand = () => {
        if (item.count > 0) {
            onToggleExpand();
            !isExpanded && item.handlerFunction();
        } else {
            toast.error(getLanguageString('NoAsset'));
        }
    };

    const getRTLname = (defaultName: string, arabicName: string) => languageDirection === "rtl" ? arabicName : defaultName;

    return (
        <div style={style}>
            <div
                className="sortable-item-container"
            >
                <div
                    className="sortable-item-dragable-part"
                    ref={setNodeRef}
                    {...attributes}
                    {...listeners}
                >
                    <NavLink to={item.addInfoHref}>
                        <img src={item.icon} className="sortable-item-image" alt={item.name} />
                    </NavLink>

                    <div>
                        <p className="item-title">
                            {getRTLname(item.name, item.arabic_name)}
                        </p>
                        <p className="item-subtitle">
                            {item.count} {getLanguageString("Assets")}
                        </p>
                    </div>
                </div>

                <div style={{ textAlign: "right" }}>
                    <p className="item-subtitle">{getLanguageString("Balance")}</p>
                    <p
                        className="item-title"
                        style={{
                            color: item.name === 'Liability' && item.balance !== "0" ? 'rgb(226,81,83)' : "#FFFFFF",
                        }}
                    >
                        {item.name === 'Liability' && item.balance !== "0" ? '-' : ""} {item.symbol}{item.balance}
                    </p>
                </div>

                <div
                    onClick={toggleExpand}
                    style={{ cursor: "pointer", padding: "0.5rem" }}
                >
                    <img src={isExpanded ? subarrowicon : sidearrow} alt="Toggle" />
                </div>
            </div>

            {isExpanded && (
                timeLineListLoading ? <p style={{ color: '#2ce2d5', fontSize: '16px' }}>{getLanguageString("loading")}</p> : <TimelineList isRTL={languageDirection === "rtl"} timelineListData={timelineListData} />
            )}
        </div>
    );
};

export default SortableItem;
