export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const profileImage = require("../assets/profileimage.png");
export const varrow = require("../assets/varrow.png");
export const resetIcon = require("../assets/resetPasswordEnabled.png");
export const deleteIcon = require("../assets/smallDeleteAccountEnabled.png");
export const deleteLargeIcon = require("../assets/Deleteaccount.png");
export const CopyIcon = require("../assets/Copy.png");
export const EsyncWebIcon = require("../assets/esyncWeb.png");
export const SyncIcon = require("../assets/SyncIcon.png");
export const DefaultProfileImage = require("../assets/defaultProfile.png");
export const Trash = require("../assets/Trash.png");
export const YellowcyncIcon = require("../assets/YellowSync.png");
export const editIcon = require("../assets/edit.png");
export const closeIcon = require("../assets/close.png");
export const red_delete_icon = require("../assets/red_delete_icon.svg");
export const gray_delete_icon = require("../assets/gray_delete_icon.svg");
export const pest_sync = require("../assets/pest_sync.svg");
export const gray_sync = require("../assets/gray_sync.svg");
export const bankIcon = require("../assets/bankIcon.svg");

