import React from "react";
// Customizable Area Start
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    Typography,
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { arrow, arrowDown, arrowUp, checkedIcon, uncheckedIcon } from "./assets"
import ArrowBack from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import toast from "react-hot-toast";
import EngagementServiceProposalController, {
    Props
} from "./EngagementServiceProposalController.web";

// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class EngagementServiceProposal extends EngagementServiceProposalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    handlePlanInclusve = (plan: any) => {
        return (
            <>
                {
                    plan?.map((data: any, index: any) => (
                        <Accordion
                            onChange={this.handleChange(`${data.name}${index}`)}
                            expanded={this.state.expanded === `${data.name}${index}`}
                            style={styles.accordion}
                            key={index}
                        >
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={styles.accordionSummary as React.CSSProperties}>
                                <div style={{ padding: '0px 10px', justifyContent: 'space-between', width: '100%', display: 'flex', }}>
                                    <div style={{ justifyContent: 'space-between', display: 'flex', }}>
                                        <img src={arrow} height={18} style={{ margin: '4px 10px 0px 0px', fontFamily: "Poppins" }} width={18} />
                                        <Typography>{data.name}</Typography>
                                    </div>
                                    {
                                        this.state.expanded !== `${data.name}${index}` ?
                                            <>
                                                <img src={arrowDown} width={12} style={{ margin: '9px 0px 0px 0px' }} height={7} />
                                            </> :
                                            <>
                                                <img src={arrowUp} width={12} height={7} style={{ margin: '9px 0px 0px 0px' }} />

                                            </>
                                    }

                                </div>
                            </AccordionSummary>
                            <AccordionDetails style={styles.accordionDetails}>
                                <Typography
                                    style={{
                                        padding: '0px 10px',
                                        fontFamily: "Poppins",
                                        width: '100%',
                                        wordWrap:'break-word'
                                    }}>
                                        {data.explanation}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </>
        )
    }

    handlePlanSelect = (selecetPlan: any) => {        
        return (
            <>
                {selecetPlan?.map((data: any, index: any) => (
                    <Accordion
                        key={index}
                        expanded={this.state.expanded === `${data.name}${index}`}
                        onChange={this.handleChange(`${data.name}${index}`)}
                        style={styles.accordion}
                    >
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={styles.accordionSummary as React.CSSProperties}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0px 10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <img src={arrow} height={18} width={18} style={{ margin: '4px 10px 0px 0px' }} />
                                    <Typography style={{ fontFamily: "Poppins" }}>{data.name}</Typography>
                                </div>
                                {
                                    this.state.expanded === `${data.name}${index}` ?
                                        <>
                                            <img src={arrowUp} height={7} width={12} style={{ margin: '9px 0px 0px 0px' }} />
                                        </> :
                                        <>
                                            <img src={arrowDown} height={7} width={12} style={{ margin: '9px 0px 0px 0px' }} />

                                        </>
                                }
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={styles.accordionDetails}>
                            <Typography style={{ fontFamily: "Poppins", padding: '0px 10px',width: '100%',
                                        wordWrap:'break-word' }}>
                                {data.explanation}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </>
        )
    }

    render() {

        const { selectedServices } = this.state;
        const { serviceData, isSeleted } = this.state;
        return (
            // Customizable Area Start
            <>
                <BackgroundBoxSatyle dir={this.state.direction}>
                    <CnvestmentBox>
                        <Box style={webStyle1.profileNameNewIcon}>
                            <Box style={webStyle1.profileNameNew}>
                                {
                                    isSeleted ?
                                        <>
                                            <ArrowBack style={{ color: '#fff', paddingRight: '5px', marginRight: '10px', cursor: 'pointer' }} data-test-id="handleSeleteBack" onClick={() => this.handleSeleteBack()} />
                                        </> :
                                        <>
                                            <ArrowBack style={{ color: '#fff', paddingRight: '5px', marginRight: '10px', cursor: 'pointer' }} data-test-id="handleClickBack" onClick={() => this.handleClickBack()} />
                                        </>
                                }

                                <Box style={webStyle1.planning}>{isSeleted ? this.getTranslationsLanguage('confirmOrder') : this.getTranslationsLanguage('serviceProposal')}</Box>
                            </Box>

                        </Box>
                        <Box style={webStyle1.listLine}>
                            {isSeleted ? this.getTranslationsLanguage('selectedServices') : this.getTranslationsLanguage('selectServices')}
                        </Box>
                        {
                            isSeleted ?
                                <>
                                    <Grid container spacing={4}>
                                        {selectedServices?.map((item: any, index: any) => (
                                            <Grid item lg={6} key={index}>
                                                <div>

                                                    <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #fff', borderRadius: '8px', minHeight: '400px', height:"100%" }}>
                                                        <Grid>
                                                            <Grid item xs={12}>
                                                                <div style={{ padding: '25px 20px 10px 20px', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <div style={{ display: 'flex' }}>

                                                                        <img src={item.image} style={{ margin: '4px 10px 0px 0px' }} height={18} width={18} />
                                                                        <Textlabel_LosAngeles_1>{item.name}</Textlabel_LosAngeles_1>
                                                                    </div>
                                                                    <div>

                                                                        <Textlabel_LosAngeles_2>{`${item.currency} ${item.price}`}</Textlabel_LosAngeles_2>
                                                                        {/* Add radio button here */}
                                                                        <div style={{ display: 'flex', justifyContent: 'end', }}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <div style={{ justifyContent: 'center', padding: '0px 25px', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                                                    <div style={{ flex: 1, height: 1, backgroundColor: 'gray' }} />
                                                                    <Typography style={{ textAlign: 'center', color: '#fff', margin: '0 13px', fontFamily: "Poppins" }}>{this.getTranslationsLanguage('planInclusions')}</Typography>
                                                                    <div style={{ backgroundColor: 'gray', flex: 1, height: 1, }} />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {
                                                                    this.handlePlanInclusve(item.plan_inclusions)
                                                                }

                                                            </Grid>
                                                        </Grid>
                                                        <Box
                                                            // style={webStyle1.buttonBox}
                                                            style={{ margin: 'auto 25 0 10' }}
                                                        >

                                                            <ButtonRoemoveStyle className="nameButton" data-test-id={"handleRemoveItems"+ index} onClick={() => this.handleRemoveSelectedItem(item)
                                                            }>{this.getTranslationsLanguage('remove')}</ButtonRoemoveStyle>
                                                        </Box>
                                                    </div>
                                                </div>
                                            </Grid>

                                        ))}
                                    </Grid>
                                    <Box style={webStyle1.buttonBox}>

                                        <ButtonStyle className="nameButton" disabled={!selectedServices?.length} data-test-id="handleSetService" onClick={() =>
                                            this.handleSetService()
                                        }>{this.getTranslationsLanguage('continue')}</ButtonStyle>
                                    </Box>
                                </>
                                :

                                <>
                                    <Grid container spacing={4}>
                                        {serviceData?.map((item: any, index: any) => (
                                            <Grid item lg={6} key={index}>
                                                <div style={{ border: selectedServices.some((service: any) => service.service_id === item.service_id) ? '1px solid #2DE2D5' : '1px solid #fff', borderRadius: '8px', minHeight: '400px', height:'100%' }}>
                                                    <Grid> 
                                                        <Grid item xs={12}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '25px 20px 10px 20px' }}>
                                                                <div style={{ display: 'flex' }}>

                                                                    <img src={item.image} height={18} width={18} style={{ margin: '4px 10px 0px 0px' }} />
                                                                    <Textlabel_LosAngeles_1>{item.name}</Textlabel_LosAngeles_1>
                                                                </div>
                                                                <div>

                                                                    <Textlabel_LosAngeles_2>{`${item.currency} ${item.price}`}</Textlabel_LosAngeles_2>
                                                                    {/* Add radio button here */}
                                                                    <div style={{ justifyContent: 'end', display: 'flex', marginTop: '20px' }}>
                                            <IconButton onClick={this.handleServiceSelectionItem(item)} data-test-id="iconButtonSelect">
                                                {selectedServices.some((service: { service_id: string }) => service.service_id === item.service_id) ?
                                                    <img src={checkedIcon} alt="select" style={{ height: '20px', width: '20px' }} />
                                                    : <img src={uncheckedIcon} alt="select" style={{ height: '20px', width: '20px' }} />
                                                }
                                            </IconButton>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '0px 25px' }}>
                                                                <div style={{ flex: 1, height: 1, backgroundColor: 'gray' }} />
                                                                <Typography style={{ margin: '0 13px', textAlign: 'center', color: '#fff', fontFamily: "Poppins" }}>{this.getTranslationsLanguage('planInclusions')}</Typography>
                                                                <div style={{ flex: 1, height: 1, backgroundColor: 'gray' }} />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>

                                                            {

                                                                this.handlePlanSelect(item?.plan_inclusions)
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Box style={webStyle1.buttonBox}>

                                        <ButtonStyle className="nameButton" data-test-id="handleSeleteItems" onClick={() => {
                                            if (selectedServices.length) {
                                                this.handleSeleteItem()
                                            }else{
                                                toast.dismiss()
                                                toast.error(this.getTranslationsLanguage('pleaseSelect'))
                                            }

                                        }}>{this.getTranslationsLanguage('continue')}</ButtonStyle>
                                    </Box>
                                </>
                        }


                    </CnvestmentBox>
                </BackgroundBoxSatyle >
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle1 = {
    dividerLine: { color: '#607d8b' },
    expandMoreRounded: { color: '#3cd7c6' },
    displayBox: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
    fontSizeText: { fontSize: '12px', color: 'white' },
    openModel: { padding: '20px 25px' },
    average_rating_fontSize: { color: '#fff', fontSize: '12px' },
    handleClickMargin: { margin: '0px 15px' },
    emailLable: { display: 'flex' },
    modelRequest: { fontSize: "20px", color: "white" },
    minBoxDisplay1: { display: 'flex', justifyContent: 'center' },
    marginImg: { margin: '0px 10px', cursor: "pointer" },
    clicked: { boxShadow: "#1bf5e3 0px 0px 10px", border: "1px solid #1bf5e3", borderRadius: "12px", background: "#395d6b", marginBottom: '30px' },
    consecteturText: { color: '#fff', fontSize: '14px', fontWeight: 400, margin: '20px 0px' },
    stareColor: { color: '#ceccc2' },
    allReviewBtn: { borderRadius: '6px', border: '1px solid #C9F9F6', padding: '5px 20px', color: "#C9F9F6", cursor: 'pointer' },
    marginTopBox: { marginTop: '20px' },
    textlabel_NameOfCorporation: { color: "#928F98", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 400, display: "flex" },
    focusLableNew: { color: "#FFF", fontFamily: "Poppins", fontSize: "16px", fontWeight: 600 },
    tdAnswers: { color: '#2CE2D5', fontSize: '14px', fontWeight: 600, paddingLeft: '15px' },
    personalDetails: { padding: '10px' },
    tdQuestions: { color: '#fff', fontSize: '14px', fontWeight: 400 },
    textlabel_LosAngeles: { color: "#2DE2D5", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500, cursor: "pointer" },
    reviewerName: { color: '#fff', fontWeight: 400 },
    webStyleMenBox: { marginTop: "2rem", borderRadius: "12px", border: "1px solid #57575D", background: "#395d6b", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", marginBottom: '30px' },
    boxMargin: { margin: '0px 25px' },
    focusLable: { color: "#FFF", fontFamily: "Poppins", fontSize: "16px", fontWeight: 600, margin: "10px 0px" },
    reviewerNameText: { color: '#fff', opacity: '0.5', fontWeight: 500 },
    profileName: { display: 'flex', alignItems: 'center', gap: '10px' },
    profileNameNew: { display: 'flex', alignItems: 'center' },
    boxMarginEnd: { margin: '0px 25px', display: "flex", justifyContent: "end", width: "100%" },
    profileNameNewIcon: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    planningBackground: { borderRadius: "12px", border: "1px solid #57575D", background: "#3C3C51", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", padding: "10px 10px 25px 10px" },
    buttonBox: { display: 'flex', justifyContent: 'center', marginTop: '20px' },
    reviewerDP: { borderRadius: '50px', width: '50px', height: '50px' },
    minBoxDisplay: { display: 'flex', justifyContent: 'space-between' },
    planning: { fontSize: "25px", fontWeight: 600, color: "white" },
    succsecrequest: { backgroundColor: "#c9e6b0", margin: "25px 0px", color: '#2fc163', borderRadius: "10px", padding: '10px' },
    rejectedrequest: { backgroundColor: "#f4beba", margin: "25px 0px", color: '#d9545f', borderRadius: "10px", padding: '10px' },
    ScheduleCallBtn: { marginLeft: '15px', color: '#3C3C51', border: '1px solid #C9F9F6', background: '#C9F9F6', padding: '9px 48px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer' },
    listLine: { color: "#94929f", margin: "25px 0px" },
    chatBtn: { color: '#C9F9F6', border: '1px solid #C9F9F6', padding: '9px 70px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer' },
};

const BackgroundBoxSatyle = styled(Box)({ width: '100%', height: '100vh', overflowY: 'scroll', backgroundColor: '#2b2a37' })
const CnvestmentBox = styled(Box)({
    paddingLeft: "80px !important", paddingRight: "80px !important", paddingTop: "40px !important",
    '@media (min-width: 320px)': { paddingLeft: "15px !important", paddingRight: "15px !important" },
    '@media (min-width: 1200px)': { paddingLeft: "80px !important", paddingRight: "80px !important" },
})
const ButtonStyle = styled(Button)({
    borderRadius: "8px", background: "#C8F9F6", color: "#000", textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500, width: '300px', padding: "5px 20px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {
        backgroundColor: "#395D6B",
    },
})

const ButtonRoemoveStyle = styled(Button)({
    textAlign: "center", fontFamily: "Poppins", border: '1px solid #7E828F', fontSize: "16px", borderRadius: "6px", color: "#7E828F", fontStyle: "normal", fontWeight: 500, width: '100%', padding: "5px 20px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {

    },
})
const Textlabel_LosAngeles_1 = styled(Box)({ color: "#fff", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500, cursor: "pointer" })
const Textlabel_LosAngeles_2 = styled(Box)({ color: "#2DE2D5", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500, cursor: "pointer", textTransform: "capitalize" })
const styles = {
    accordion: {
        // border: `1px solid `,
        borderRadius: '8px',
        color: '#ffff',
        backgroundColor: '#2b2a37',
        margin: '0px 0px',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&::before': {
            display: 'none',
            borderBottom: 0,
        },
    },
    accordionSummary: {
        width: '100%',
        border: 'none',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {

        },
    },
    accordionDetails: {
        color: 'gray',
        borderBottom: 'none',
        // borderTop: '1px solid rgba(0, 0, 0, .125)',
    },



};


// Customizable Area End