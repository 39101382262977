Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "goalmanagement";
exports.labelBodyText = "goalmanagement Body";
exports.bankOptionApiEndpoint = "bx_block_dashboard/dashboards/connected_banks";

exports.btnExampleTitle = "CLICK ME";
exports.languageListForecasting = {
  English: {
    CreateGoal: "Create/Update your monthly budget goal",
    CreateSpending: "Create your other spending goals",
    Goals: "You haven't set any goals yet...",
    Spending: "Spending",
    Remaining: "Remaining",
    BudgetGoals: "Budget Goals",
    CreateBudget: "Create/Update your monthly budget goal",
    StartDate: "What is your start date?",
    Amount: "Amount",
    Amountplaceholder: "Enter amount",
    Currency: "Currency",
    SpendingGoals: "Create other spending goals",
    SaveFor: "Name your spending goal",
    SaveForPlaceholder: "Name your goal",
    TargetDate: "What is your target date?",
    AssignCategory: "Select a category",
    AssignCategoryPlaceholder: "Select a category",
    CreateButton: "Create other spending goals",
    Successfully: "Your goal is added successfully!",
    achieved: "achieved",
    Delete: "Delete",
    MonthlyBtn: "Create/Update your monthly budget goal",
    GoalName: "Goal name can't be blank.",
    AmountNumber: "Amount can't be blank.",
    CurrencyBank: "Currency can't be blank.",
    StartData: "Start date can't be blank.",
    TargetBank: "Target date can't be blank.",
    CategoryBank: "Category can't be blank.",
    StartNaneBank: "Start Date can't be blank.",
    deleteGoal: "Are you sure you want to delete this goal ?",
    Cancel: "Cancel",
    goalNameRequired: "Goal name is required",
    amountTypeError: "Amount must be a number",
    amountDecimal: "Amount must have two decimal places or fewer",
    amountValidation: "Amount is too large",
    amountRequired: "Amount is required",
    currencyRequired: "Currency is required",
    categoryRequired: "Category is required",
    startDateTypeError: "Start Date must be a valid date",
    startDateRequired: "Start Date is required",
    targetDateTypeError: "Target Date must be a valid date",
    targetDateRequired: "Target Date is required",
    targetDateAfterStart: "Target Date can't be before Start Date",
    selectYourBanks:"Select Banks",
    selectAtLeastOneBank: "Please select at least one bank.",
    invalidBankID: "Invalid bank account ID.",
    bankSelectionRequired: "Bank selection is required.",
    priceNumber: 'Amount must be a number',
    priceGreaterZero: 'Amount must be greater than zero',
  },
  Arabic: {
    priceNumber: 'يجب أن يكون المبلغ رقمًا',
    priceGreaterZero: 'يجب أن يكون المبلغ أكبر من الصفر',
    Cancel: "يلغي",
    deleteGoal: "هل أنت متأكد أنكتريد حذف هذا الهدف ؟",
    StartNaneBank: "لا يمكن أن يكون تاريخ البدء فارغًا.",
    CategoryBank: "لا يمكن ترك الفئة فارغة.",
    TargetBank: "لا يمكن أن يكون التاريخ المستهدف فارغًا.",
    StartData: "لا يمكن أن يكون تاريخ البدء فارغًا.",
    CurrencyBank: "لا يمكن أن تكون العملة فارغة.",
    AmountNumber: "لا يمكن أن يكون المبلغ فارغًا.",
    GoalName: "لا يمكن أن يكون فارغًا في اسم مستدير.",
    Save: "حفظ",
    Delete: "يمسح",
    achieved: "حقق",
    MonthlyBtn: "إنشاء/تحديث هدف الميزانية الشهرية الخاصة بك",
    Successfully: "تمت إضافة هدفك بنجاح!",
    CreateButton: "إنشاء أهداف الإنفاق الأخرى",
    AssignCategoryPlaceholder: "حدد فئة",
    AssignCategory: "حدد فئة",
    TargetDate: "ما هو التاريخ المستهدف؟",
    SaveForPlaceholder: "قم بتسمية هدفك",
    SaveFor: "ما الذي تريد الادخار من أجله؟",
    SpendingGoals: "إنشاء أهداف الإنفاق الأخرى",
    Currency: "عملة",
    Amountplaceholder: "أدخل المبلغ",
    Amount: "كمية",
    StartDate: "ما هو تاريخ البدء؟",
    CreateBudget: "إنشاء هدف الميزانية الشهرية",
    BudgetGoals: "أهداف الميزانية",
    Remaining: "متبقي",
    Spending: "الإنفاق",
    Goals: "لم تحدد أهدافك بعد..",
    CreateSpending: "قم بإنشاء أهداف الإنفاق الأخرى الخاصة بك",
    CreateGoal: "قم بإنشاء هدف ميزانيتك الشهرية",
    goalNameRequired: "اسم الهدف مطلوب",
    amountTypeError: "يجب أن يكون المبلغ رقما",
    amountDecimal: "يجب ألا يزيد المبلغ عن خانتين عشريتين",
    amountValidation: "المبلغ كبير جدًا",
    amountRequired: "المبلغ مطلوب",
    currencyRequired: "العملة مطلوبة",
    categoryRequired: "الفئة مطلوبة",
    startDateTypeError: "يجب أن يكون تاريخ البدء تاريخًا صالحًا",
    startDateRequired: "تاريخ البدء مطلوب",
    targetDateTypeError: "يجب أن يكون التاريخ المستهدف تاريخًا صالحًا",
    targetDateRequired: "التاريخ المستهدف مطلوب",
    targetDateAfterStart: "لا يمكن أن يكون تاريخ الهدف قبل تاريخ البدء",
    selectYourBanks:"اختر البنوك",
    selectAtLeastOneBank: "يرجى اختيار بنك واحد على الأقل.",
    invalidBankID: "رقم حساب البنك غير صالح.",
    bankSelectionRequired: "اختيار البنك مطلوب."
  }
}
// Customizable Area End