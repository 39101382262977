import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    OutlinedInput,
    Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import DateFnsUtils from '@date-io/date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Formik } from "formik";
import "../assets/PortableAsset.css"
import PortableAssetController, {
    Props
} from "./PortableAssetController.web";
import { formatNumberWithCommas, sanitizeAndRemoveCommas } from "../../../components/src/commonFn";
import CommonDropdown from "../../../components/src/Dropdown/CommonDropdown";
// Customizable Area End

// Customizable Area Start
let portableTheme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
});

portableTheme = createTheme(portableTheme, {
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
    overrides: {
        MuiDialog: {
            paper: {
                boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
                borderRadius: '8px',
                backgroundColor: '#395D6B',
                width: 'max-content',
            },
        },
        MuiOutlinedInput: {
            root: {
                "& .MuiOutlinedInput-input": {
                    color: "white",
                    borderBottom: '0px',
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiAutocomplete-endAdornment": {
                    position: 'unset'
                }
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)"

            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)",
                "& button": {
                    backgroundColor: "rgb(57, 93, 107)",
                    color: "rgb(181, 176, 176)",
                }
            },
            dayheader: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#3C3C51",
            },
        },
        MuiPickersDay: {
            day: {
                color: "rgb(181, 176, 176)",
            },
            current: {
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            },
            daySelected: {
                backgroundColor: "#2CE2D5",
            },
            dayDisabled: {
                color: "#ccc",
            },
        },
        MuiPickersModal: {
            dialogAction: {
                backgroundColor: "#3C3C51",
                color: "#33abb6",
            },
        },
    },
})

const portableCustomStyles = {
    control: (defaultStyles: any) => ({
        ...defaultStyles,
        borderRadius: '8px',
        padding: "8px",
        backgroundColor: "#2a2a38",
        color: 'rgba(255, 255, 255, 0.60)',
        textAlign: "left",
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Poppins',
    }),
};
// Customizable Area End

export default class PortableAssetweb extends PortableAssetController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    InitValue = (
        condition: boolean, truthyValue: string | number | null | { label: string, value: string }, falsyValue: string | null | { label: string, value: string },
        shouldFormat: boolean = false
      ) => {
        if (!condition && shouldFormat && falsyValue != null) {
          return formatNumberWithCommas(falsyValue);
        }
      
        return condition ? truthyValue : falsyValue;
    }
    CheckRtlClsName = () => {
        return this.state.logoAddPortableAsset === "rtl" ? "assetsPriceNew" : "assetsPrice"
    }

    render() {
        const totalStepsPortable = 3;
        const currentStepPortable = this.state.activeStep;
        return (
            // Customizable Area Start
            <Box style={{ height: '100vh', width: '100%', overflowY: 'auto' }} className='cash_sideBorder box_bg_color' dir={this.state.direction === 'rtl' ? 'rtl' : 'ltr'}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', }} className="headerMain">
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={this.state.imagenav_BackIcon} style={{ width: '24px', height: '13px', cursor: "pointer" }} data-test-id="backButton" onClick={this.portableAsset} />
                        <a style={{ color: 'white', padding: '0px 20px', fontSize: '24px', fontFamily: 'Poppins', fontWeight: 600 }}>
                            {this.editPortableAssetTitle()} {this.getStringAddPortable('PortableAsset')}</a>
                    </Box>
                    <Box>
                        <Box style={{ display: 'flex', alignItems: 'center', }}>
                            <CommonDropdown
                                options={this.state.hader_dropdown}
                                value={this.state.selectCurrency}
                                onChange={(val: any) => { this.setCurrency(val)}}
                                placeholder="Select a currency"
                            />
                            <Box>
                                <img src={this.state.image_Vuesax} style={{ width: '41px', height: '41px', cursor: "pointer" }} onClick={this.settingRoute} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <ThemeProvider theme={portableTheme}>
                            <CssBaseline />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box className="stepperBox">
                        {this.ProgressBar(totalStepsPortable, currentStepPortable)}
                        </Box>

                        <Formik
                                        enableReinitialize
                                        initialValues={{
                                            Assetname: this.InitValue(!this.state.editPortableData, "", this.state.editPortableData?.name),
                                            Price: this.InitValue(!this.state.editPortableData, "", this.state.editPortableData?.price,true),
                                            PriceDate: this.InitValue(!this.state.editPortableData, null, this.state.editPortableData?.purchase_date),
                                            MarketPrice: this.InitValue(!this.state.editPortableData, "", this.state.editPortableData?.market_value,true),
                                            MarketDate: this.InitValue(!this.state.editPortableData, null, this.state.editPortableData?.market_date),
                                            Currencys: this.InitValue(!this.state.editPortableData, this.state?.selectCashCurrency, { value: this.state.editPortableData?.price_currency, label: this.state.editPortableData?.price_currency }),
                                            model: this.InitValue(!this.state.editPortableData, "", this.state.editPortableData?.model),
                                            loanSelect:this.InitValue(!this.state.editPortableData || !this.state.editPortableData?.loan_status, {label: "No", value: "no"} , {label: "Yes", value: "yes"}),
                                            loanAmount: this.InitValue(!this.state.editPortableData, "", this.state.editPortableData?.loan_detail?.amount,true),
                                            loanTerm: this.InitValue(!this.state.editPortableData, "", this.state.editPortableData?.loan_detail?.term_years),
                                            loanMonths: this.InitValue(!this.state.editPortableData, "", this.state.editPortableData?.loan_detail?.term_months),
                                            loanInterest: this.InitValue(!this.state.editPortableData, "", this.state.editPortableData?.loan_detail?.annual_interest_rate),
                                            loanPayment: this.InitValue(!this.state.editPortableData, "", this.state.editPortableData?.loan_detail?.loan_monthly_payments),
                                            loanStartDate: this.InitValue(!this.state.editPortableData?.loan_detail, null, this.state.editPortableData?.loan_detail?.start_date),
                                            loanFirstPayment: this.InitValue(!this.state.editPortableData?.liability, null, this.state.editPortableData?.liability?.first_payments),
                                            country: this.InitValue(!this.state.editPortableData, null, this.state.initialCountryValue)
                                        }}
                                        onSubmit={(values: any) => {
                                            const cleanValues = {
                                                ...values,
                                                Price: sanitizeAndRemoveCommas(values?.Price || ""),
                                                MarketPrice: sanitizeAndRemoveCommas(values?.MarketPrice || ""),
                                                loanAmount: sanitizeAndRemoveCommas(values?.loanAmount || ""),
                                                loanPayment: sanitizeAndRemoveCommas(values?.loanPayment || ""),
                                            };
                                            this.HandleSubmit(cleanValues);
                                        }}
                                        validationSchema={this.codeSchema[this.state.activeStep]}
                                        data-test-id="accountLogin"
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                    >
                                        {({ errors, values, handleSubmit, handleChange, setFieldValue, handleBlur }: any) => (
                                            <form onSubmit={handleSubmit} onKeyDown={this.onKeyDownAddAsset} data-test-id='submitForm'>
                                                <Box>
                                                    <Typography>
                                                        {this.state.activeStep === 0 &&
                                                            <Box>
                                                                <Box>
                                                                    <Typography className="title">{this.getStringAddPortable('PortableAssetType')}</Typography>

                                                                    <Box>
                                                                        {(this.multiBox())}
                                                                        <Box>
                                                                            <Box>
                                                                                <Typography className="title">{this.getStringAddPortable('AssetName')}</Typography>
                                                                                <Box>
                                                                                    {this.OutlinedInputAddAsset({name:"Assetname",placeholder:"placeholderAssetName", readOnly: false }, webStyle, values.Assetname, handleChange, "text", this.state.assetname)}
                                                                                </Box>
                                                                                <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                    {(this.errorMessageOne(errors))}
                                                                                </Box>
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography className="title">{this.getStringAddPortable('portableAssetModel')}</Typography>
                                                                                <Box >
                                                                                    {this.OutlinedInputAddAsset({name:"model",placeholder:"placeholderportableAssetModel", readOnly: false }, webStyle, values.model, handleChange, "text", this.state.Model)}
                                                                                </Box>
                                                                            </Box>
                                                                            <Box>
                                                                                <Box>
                                                                                    <Typography className="title">{this.getStringAddPortable('locationCountry')}</Typography>
                                                                                    {(this.propertyLocation(webStyle, values, setFieldValue))}
                                                                                    <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                        {this.finalErrorMessage(errors?.country)}
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>}
                                                        {this.state.activeStep === 1 &&
                                                            <Box>
                                                                <Box>
                                                                    <Box>
                                                                        <Typography className="title">{this.getStringAddPortable('portableAssetCurrency')}</Typography>
                                                                        {(this.selectMenu("Currencys", setFieldValue, portableCustomStyles, values, values.Currencys))}
                                                                        <Box sx={webStyle.fieldError} data-test-id="error">
                                                                            {this.finalErrorMessage(errors.Currencys)}
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className="assetsInputSecond">
                                                                        <Box className={this.CheckRtlClsName()}>
                                                                            <Typography className="title">{this.getStringAddPortable('purchasePrice')}</Typography>
                                                                            {this.OutlinedInputAddAsset( {name:"Price",placeholder:"placeholderportableAssetPrice", readOnly: !!this.state.editPortableData }, webStyle, values.Price, handleChange, "text", this.state.loan,setFieldValue)}
                                                                            <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                {this.finalErrorMessage(errors.Price)}
                                                                            </Box>
                                                                        </Box>
                                                                        <Box className="assetsCurrency ">
                                                                            <Typography className="title">{this.getStringAddPortable('date')}</Typography>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <KeyboardDatePicker
                                                                                    KeyboardButtonProps={{
                                                                                        style: {
                                                                                            color: 'white',
                                                                                            width: 'auto'
                                                                                        }
                                                                                    }}
                                                                                    value={values.PriceDate && new Date(values.PriceDate)}
                                                                                    className=" portableAssetDatepickerNew"
                                                                                    variant="inline"
                                                                                    inputVariant="outlined"
                                                                                    format="dd/MM/yy"
                                                                                    name="PriceDate"
                                                                                    data-test-id="PriceDate"
                                                                                    autoOk
                                                                                    disabled={!!this.state.editPortableData}
                                                                                    placeholder="DD/MM/YY"
                                                                                    onChange={(selectedDate) => setFieldValue("PriceDate", selectedDate)}
                                                                                    InputProps={{
                                                                                        style: {
                                                                                            borderRadius: '8px',
                                                                                            padding: '10px 0px',
                                                                                            color: 'white'
                                                                                        }
                                                                                    }}
                                                                                    style={{ width: "100%" }}
                                                                                    keyboardIcon={<img src={this.state.image_Calendar} alt="" style={{
                                                                                        marginTop: "-4px",
                                                                                        marginRight: "12px",
                                                                                        height: "18px",
                                                                                        width: "18px",
                                                                                        borderRadius: "0",
                                                                                    }} />}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                            <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                {this.finalErrorMessage(errors.PriceDate)}
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className="assetsInputSecond">
                                                                        <Box className={this.CheckRtlClsName()}>
                                                                            <Typography className="title">{this.getStringAddPortable('marketValue')}</Typography>
                                                                            {this.OutlinedInputAddAsset({name:"MarketPrice",placeholder:"placeholderportableAssetPrice", readOnly: false }, webStyle, values.MarketPrice, handleChange, "text", this.state.loan,setFieldValue)}
                                                                            <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                {this.finalErrorMessage(errors.MarketPrice)}
                                                                            </Box>
                                                                        </Box>
                                                                        <Box className="assetsCurrency ">
                                                                            <Typography className="title">{this.getStringAddPortable('date')}</Typography>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <KeyboardDatePicker
                                                                                    KeyboardButtonProps={{
                                                                                        style: {
                                                                                            color: 'white',
                                                                                            width: 'auto'
                                                                                        }
                                                                                    }}
                                                                                    value={values.MarketDate && new Date(values.MarketDate)}
                                                                                    className=" portableAssetDatepickerNew"
                                                                                    variant="inline"
                                                                                    inputVariant="outlined"
                                                                                    format="dd/MM/yy"
                                                                                    placeholder="DD/MM/YY"
                                                                                    autoOk
                                                                                    name="MarketDate"
                                                                                    data-test-id="MarketDate"
                                                                                    onChange={(DateSelected) => setFieldValue("MarketDate", DateSelected)}
                                                                                    InputProps={{
                                                                                        style: {
                                                                                            padding: '10px 0px',
                                                                                            borderRadius: '8px',
                                                                                            color: 'white'
                                                                                        }
                                                                                    }}
                                                                                    style={{ width: "100%" }}
                                                                                    keyboardIcon={<img src={this.state.image_Calendar} alt="" style={{
                                                                                        marginRight: "12px",
                                                                                        borderRadius: "0",
                                                                                        marginTop: "-4px",
                                                                                        height: "18px",
                                                                                        width: "18px",
                                                                                    }} />}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                            <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                {this.finalErrorMessage(errors.MarketDate)}
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography className="title">{this.getStringAddPortable('loan')}</Typography>
                                                                        <Box>
                                                                            {(this.loanSelectMenu("loanSelect", setFieldValue, customStylesProperty, values, values.loanSelect))}
                                                                        </Box>
                                                                    </Box>
                                                                    {values.loanSelect.value === 'yes' && <Box>
                                                                        <Typography className="title">{this.getStringAddPortable('loanDetails')}</Typography>
                                                                        <Box>
                                                                            <Box>
                                                                                {this.OutlinedInputAddAsset({name:"loanAmount",placeholder:"loanAmount", readOnly: false }, webStyle, values.loanAmount, handleChange, "text", this.state.Model,setFieldValue)}
                                                                            </Box>
                                                                            <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                {this.finalErrorMessage(errors.loanAmount)}
                                                                            </Box>
                                                                        </Box>
                                                                        <Box className=" custom-mortgage-details-field">
                                                                            <Box>
                                                                                {this.OutlinedInputAddAsset({name:"loanTerm",placeholder:"loanTerm", readOnly: false }, webStyle, values.loanTerm, handleChange, "number", this.state.Model)}
                                                                            </Box>
                                                                            <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                {this.finalErrorMessage(errors.loanTerm)}
                                                                            </Box>
                                                                        </Box>
                                                                        <Box className=" custom-mortgage-details-field">
                                                                            <Box>
                                                                                {this.OutlinedInputAddAsset({name:"loanMonths",placeholder:"loanMonths", readOnly: false }, webStyle, values.loanMonths, handleChange, "number", this.state.Model)}
                                                                            </Box>
                                                                            <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                {this.finalErrorMessage(errors.loanMonths)}
                                                                            </Box>
                                                                        </Box>

                                                                        <Box className=" custom-mortgage-details-field">
                                                                            <Box>
                                                                                {this.OutlinedInputAddAsset({name:"loanInterest",placeholder:"loanAnnualInterestRate", readOnly: false }, webStyle, values.loanInterest, handleChange, "number", this.state.Model)}
                                                                            </Box>
                                                                            <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                {this.finalErrorMessage(errors.loanInterest)}
                                                                            </Box>
                                                                        </Box>

                                                                        <Box className=" custom-mortgage-details-field">
                                                                            <OutlinedInput
                                                                                style={webStyle.inputRoot}
                                                                                data-test-id="txtInputAssetname"
                                                                                placeholder={this.getStringAddPortable("loanMonthlyPayments")}
                                                                                fullWidth={true}
                                                                                name="loanPayment"
                                                                                type="text"
                                                                                value={this.state.loanPaymentCalculation}
                                                                                readOnly
                                                                            />
                                                                        </Box>
                                                                        <Box className=" custom-mortgage-details-field">
                                                                            <Box className="cash_assetsCurrency addNewAddCashMainCurrency inputWrapper">
                                                                                <Box>
                                                                                    <Box style={webStyle.fullWidth} className="cash_" data-test-id="addCashCurrenNewcyDropdown">
                                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                            <KeyboardDatePicker
                                                                                                variant="inline"
                                                                                                keyboardIcon={<img src={this.state.image_Calendar} alt="" style={{
                                                                                                    marginRight: "12px",
                                                                                                    borderRadius: "0",
                                                                                                    width: "18px",
                                                                                                    marginTop: "-4px",
                                                                                                    height: "18px",
                                                                                                }} />}
                                                                                                inputVariant="outlined"
                                                                                                format="dd/MM/yy"
                                                                                                placeholder={this.getStringAddPortable("loanStartDate")}
                                                                                                name="loanStartDate"
                                                                                                autoOk
                                                                                                data-test-id="loanStartDate"
                                                                                                value={values.loanStartDate}
                                                                                                onChange={(value) => { setFieldValue("loanStartDate", value) }}
                                                                                                InputProps={{
                                                                                                    style: {
                                                                                                        padding: '10px 0px',
                                                                                                        borderRadius: '8px',
                                                                                                        color: 'white',
                                                                                                    }
                                                                                                }}
                                                                                                KeyboardButtonProps={{
                                                                                                    style: {
                                                                                                        color: 'white'
                                                                                                    }
                                                                                                }}
                                                                                                style={webStyle.fullWidth}
                                                                                                className=" newBoxContant12Pension"
                                                                                            />
                                                                                        </MuiPickersUtilsProvider>
                                                                                    </Box>
                                                                                    <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                        {this.finalErrorMessage(errors.loanStartDate)}
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box className=" custom-mortgage-details-field">
                                                                            <Box className="cash_assetsCurrency addNewAddCashMainCurrency inputWrapper">
                                                                                <Box>
                                                                                    <Box style={webStyle.fullWidth} className="cash_" data-test-id="addCashCurrenNewcyDropdown">
                                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                            <KeyboardDatePicker
                                                                                                variant="inline"
                                                                                                keyboardIcon={<img src={this.state.image_Calendar} alt="" style={{
                                                                                                    marginRight: "12px",
                                                                                                    borderRadius: "0",
                                                                                                    marginTop: "-4px",
                                                                                                    width: "18px",
                                                                                                    height: "18px",
                                                                                                }} />}
                                                                                                inputVariant="outlined"
                                                                                                autoOk
                                                                                                format="dd/MM/yy"
                                                                                                placeholder={this.getStringAddPortable("loanFirstPaymentDate")}
                                                                                                name="loanFirstPayment"
                                                                                                data-test-id="loanFirstPayment"
                                                                                                value={values.loanFirstPayment}
                                                                                                onChange={(LoanValue) => { setFieldValue("loanFirstPayment", LoanValue) }}
                                                                                                InputProps={{
                                                                                                    style: {
                                                                                                        padding: '10px 0px',
                                                                                                        borderRadius: '8px',
                                                                                                        color: 'white',
                                                                                                    }
                                                                                                }}
                                                                                                KeyboardButtonProps={{
                                                                                                    style: {
                                                                                                        color: 'white'
                                                                                                    }
                                                                                                }}
                                                                                                style={webStyle.fullWidth}
                                                                                                className=" newBoxContant12Pension"
                                                                                            />
                                                                                        </MuiPickersUtilsProvider>
                                                                                    </Box>
                                                                                    <Box sx={webStyle.fieldError} data-test-id="error">
                                                                                        {this.finalErrorMessage(errors.loanFirstPayment)}
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>}
                                                                </Box>
                                                            </Box>}
                                                        {this.state.activeStep === 2 && (
                                                            <Box>
                                                                <Box>
                                                                    <Typography className="title">{this.getStringAddPortable('portableAssetModel')}</Typography>
                                                                    <Box>
                                                                        <Box >
                                                                            {(this.uploadPicture())}
                                                                            {(this.uploadOriginalReceipt())}
                                                                            {(this.uploadWarrantyOrCertificate())}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Typography>
                                                    <Box style={{ textAlign: "end", paddingTop: '48px' }}>
                                                        <Button
                                                            data-test-id="submitButton"
                                                            className="nextButton"
                                                            style={{
                                                                color: '#3C3C50',
                                                                textAlign: 'center',
                                                                fontSize: '17px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 700,
                                                                lineHeight: '24px',
                                                                textTransform: 'none',
                                                                width: "auto",
                                                            }}
                                                            variant="contained"
                                                            type="submit"
                                                        >
                                                            {(this.staperButton())}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </form>
                                        )}
                                    </Formik>
                       
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>

                </Grid>
                </ThemeProvider>

                <Box>
                    <Dialog
                        open={this.state.openModel}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={this.state.modelSuccess} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent'>
                                {this.getStringAddPortable("successfullyAddData")}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    selectCountry: {
        width: "100%",
        "& .MuiAutocomplete-inputRoot": {
            padding: '10px',
        },
        "& .MuiAutocomplete-listbox": {
            backgroundColor: 'red',
            color: 'black!important',
        },
        "& .MuiAutocomplete-option": {
            padding: '10px',
        },
    },
    fullWidth: {
        width: "100%"
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    datePicker: {
        border: '1px solid black',
        borderRadius: '4px',
        padding: '5px',
    },
    inputRoot: {
        borderRadius: '8px',
        height: '56px',
        outline: 'none',
        fontFamily: 'Poppins',
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        '& input::placeholder': {
            fontWeight: 500,
            fontSize: '14px',
            color: '#f44336 ',
            opacity: 0.5,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#94989c !important',
        },
        '& .MuiInputBase-input': {
            borderColor: 'white !important'
        },
    },
    logoandimg: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        top: '162px',
        paddingTop: '20px',
        paddingRight: '49px'
    },
    fieldError: {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    },
    inputStyle: {
        border: "1px solid white",
        padding: "15px",
    },
    buttons: {
        backgroundColor: "#c7f9f6",
        borderRadius: '10px',
        margin: '0px',
        Color: '#3C3C50',
        height: "50px",
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'none',
    },
};

const customStylesProperty = {
    control: (defaultStyles: any) => ({
        ...defaultStyles,
        backgroundColor: "#2a2a38",
        borderRadius: '8px',
        padding: "8px",
        color: 'rgba(255, 255, 255, 0.60)',
        fontSize: '16px',
        fontFamily: 'Poppins',
        textAlign: "left",
        fontWeight: '500',
    }),
    singleValue: (defaultStyles: any) => ({
        ...defaultStyles,
        color: 'white'
    })
};
// Customizable Area End
