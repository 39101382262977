import React, { ChangeEvent } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Box, Typography ,OutlinedInput, Grid } from "@material-ui/core";
import Select from 'react-select';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import FormControl from '@material-ui/core/FormControl';
import { apiCall } from "../../../components/src/Common";
import { imagenav_BackIcon, image_Vuesax, image_Calendar, image_Upload, image_Group4, view_Rectangle, modelSuccess ,imagenav_Bitmap } from "./assets";
import { Message } from "../../../framework/src/Message";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import * as Yup from "yup";
import { getStorageData } from 'framework/src/Utilities';
import { FormikValues } from "formik";
import { formatNumberWithCommas, processInputValue, sanitizeAndRemoveCommas } from "../../../components/src/commonFn";

export const propertyConfigJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    category: string;
    image_Vuesax: string;
    dropdownCategoryStatus: boolean;
    selectedPdf: any;
    selectCurrency: { value: string, label: string },
    selectCashCurrency: any,
    activeStep: number;
    skipped: any;
    image_Group4: string;
    selectAssets: number;
    image_Calendar: string;
    image_Upload: string;
    view_Rectangle: string;
    modelSuccess: string;
    currencyList: any,
    selectedFile: any;
    selectedPdf2: any;
    currency_name: any;
    activeModalType: string;
    selectedCategoryID: any;
    subCategory: string;
    isVisible: boolean;
    openPictureUploadError: boolean,
    openPictureUploadNewError: boolean,
    Currencys: string;
    imgtitel: string;
    categoriesArray: any;
    allStatus: any;
    Price: number;
    imagenav_BackIcon: string;
    PortableAssettype: string;
    Assetname: string;
    Currency: string;
    Loan: string;
    Date: Date;
    hader_dropdown: any;
    openPdfUploadError2: boolean,
    model: string;
    bedroomds: number,
    selectedPdfError: string,
    id: string;
    openPdfUploadError: boolean,
    open: false;
    setOpens: false;
    openPropertyModel: boolean;
    selectedFileError: string;
    bathrooms: number,
    activeStepTwo: boolean;
    propertyNameError: string;
    propertyAssetsTypes: any;
    combineImg: any;
    imgBoxFinal: any;
    editPropertyData: any;
    countryList: any;
    allCountries: string,
    countryPage: number,
    apiCallingStage: boolean,
    allCountryListData: any;
    focusedInput: string;
    location: any;
    towerName:any;
    apartment:any;
    floor:any;
    area:any;
    price:any;
    marketPrice:any;
    mortgageAmount:any;
    mortgageTerm:any;
    mortgageMonths:any;
    mortgageInterest:any;
    mortgage:any;
    language:string,
    direction: string,
    propertyName:any;
    setMortgageError:string;
    logoImg1AddProperty:string;
    propertyId: string;
    monthlyPayment: string;
    mortgageAmountCal: number | null;
    mortgageTermCal: number | null;
    mortgageMonthsCal: number | null;
    mortgageInterestsCal: number | null;
    initialCountryValue: string | null;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AddPropertyController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postUserPDFDataCallId: any;
    getCategoriesApiCallId: any;
    postUserSelectDataCallId: any;
    deleteCategoriesApiCallId: any;
    deleteSubCategoriesApiCallId: any;
    addCategoryApiCallId: any;
    addSubCategoryApiCallId: any;
    validationApiCallId: string = "";
    postUserStatusDataCallId: any;
    portableTypesApiCallId: any;
    getPropertyCountriesCallId: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseData),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            setMortgageError:"",
            logoImg1AddProperty: "",
            propertyName:React.createRef(),
            mortgage:React.createRef(),
            price:React.createRef(),
            marketPrice:React.createRef(),
            mortgageAmount:React.createRef(),
            mortgageInterest:React.createRef(),
            mortgageMonths:React.createRef(),
            mortgageTerm:React.createRef(),
            area:React.createRef(),
            floor:React.createRef(),
            apartment:React.createRef(),
            towerName:React.createRef(),
            location: React.createRef(),
            focusedInput: "propertyName",
            token: "",
            category: "",
            subCategory: "",
            image_Vuesax: image_Vuesax,
            direction:'ltr',
            isVisible: false,
            dropdownCategoryStatus: false,
            activeModalType: "",
            selectedCategoryID: [],
            selectCurrency: { value: '', label: '' },
            selectCashCurrency: '',
            activeStep: 0, skipped: new Set(),
            selectAssets: 0,
            modelSuccess: modelSuccess,
            imagenav_BackIcon: "",
            image_Calendar: image_Calendar,
            currencyList: [],
            selectedFile: [],
            selectedPdf: "",
            selectedPdf2: "",
            currency_name: [],
            allStatus: [],
            imgtitel: "",
            PortableAssettype: "",
            language:"English",
            Assetname: "",
            image_Upload: image_Upload,
            image_Group4: image_Group4,
            view_Rectangle: view_Rectangle,
            Price: 1263,
            Currency: "",
            Loan: "",
            Currencys: "",
            model: "",
            id: "",
            Date: new Date(),
            hader_dropdown: [],
            categoriesArray: [],
            openPdfUploadError: false,
            openPdfUploadError2: false,
            openPictureUploadError: false,
            openPictureUploadNewError: false,
            open: false,
            setOpens: false,
            openPropertyModel: false,
            bedroomds: 0,
            bathrooms: 0,
            selectedFileError: '',
            selectedPdfError: '',
            activeStepTwo: false,
            propertyNameError: '',
            propertyAssetsTypes: [],
            combineImg: [],
            imgBoxFinal: '',
            editPropertyData: null,
            countryList: [],
            allCountries: '',
            countryPage: 0,
            apiCallingStage: true,
            allCountryListData: [],
            propertyId: "",
            monthlyPayment: "",
            mortgageAmountCal: null,
            mortgageTermCal: null,
            mortgageMonthsCal: null,
            mortgageInterestsCal: null,
            initialCountryValue: null
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    async componentWillMount() {
        let language = await getStorageData('language');
        const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
        this.setState({ logoImg1AddProperty: logoImg1AddCash });
        const limagenav_BackIcon = language === 'Arabic' ? imagenav_Bitmap : imagenav_BackIcon;
        this.setState({ imagenav_BackIcon: limagenav_BackIcon  });

    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ direction: currentLanguage ,language: language});
    }
    isStepSkipped(step: any) {
        return this.state.skipped.has(step);
    }

    dialogPropertyPaperStyle = {
        width: 'max-content',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    };

    portableAsset = () => {
        if (this.state.activeStep == 0) {
            !this.state.editPropertyData ? this.props.navigation.navigate("CategoriessubcategoriesWeb") : this.props.navigation.navigate("PropertyAssetsOverview")
        } else if (this.state.activeStep == 1 || this.state.activeStep == 2) {
            this.setState((prevState) => ({
                activeStep: this.state.activeStep - 1
            }));
        }
    }

    settingRoute = () => {
        
        const msg: Message = new Message(
            getName(MessageEnum.NavigationSettingPage)
          );
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(msg);
          

    }

    handleNext = () => {
        let newSkipped = this.state.skipped;
        if (this.isStepSkipped(this.state.activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(this.state.activeStep);
        }
        if (this.state.activeStep < 2) {
            this.setState(() => ({
                activeStep: this.state.activeStep + 1
            }));
        } else {
            this.setState({ activeStepTwo: true })
        }
    };

    convertBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    openPictureUpload = () => {
        const inputAddPensionOne = document.createElement('input');
        inputAddPensionOne.type = 'file';
        inputAddPensionOne.accept = "image/png, image/jpeg, iamge/jpg";
        inputAddPensionOne.multiple = true
        inputAddPensionOne.click();
        inputAddPensionOne.onchange = (event: any) => {
            this.openPictureUploadInSideMain(event);
        }
    }

    openPictureUploadInSideMain = async (event: any) => {
        const filesArray = Array.from(event.target.files);
        filesArray.forEach(async (file: any) => {
            if (file.size < 2000000) {
                const base64 = await this.convertBase64(file)
                this.setState({
                    selectedFile: [...this.state.selectedFile, file],
                    combineImg: [...this.state.combineImg, base64]
                })
            } else {
                this.setState({ openPictureUploadError: true })
            }
        })
    }

    formikField = (mainCondation: any, returnValue: any) => {
        if (mainCondation == 'currency') {
            return !this.state.editPropertyData ? this.state?.selectCashCurrency : { value: this.state.editPropertyData?.price_currency, label: this.state.editPropertyData?.price_currency }
        } 
        return !this.state.editPropertyData ? null : { value: this.state.editPropertyData.status.id, label: this.state.editPropertyData.status.name }        
    }

    handleScroll = () => {
        if (this.state.apiCallingStage) {
            this.setState({ apiCallingStage: false }, () => {
                this.getPropertyCountriesValue();
            })
        }
    };

    inputRender = (params: any) => {
        return (
            <TextField
                {...params} placeholder={this.getStringAddProperty('placeholderPropertyCountry')} variant="outlined" />
        )
    }

    propertyLocation = (addPropertywebStyle: any, values: any, setFieldValue: any) => {
        return (<Autocomplete
            id="combo-box-demo"
            data-test-id="country-dropdown"
            options={this.state.countryList}
            getOptionLabel={(option: any) => option}
            style={{...addPropertywebStyle.selectCountry,  WebkitAppearance: 'none', display: 'block',width: '100%',}}
            classes={{ popper: 'selectMenu'}}
            value={values.country  || null}
            onChange={(event: ChangeEvent<{}>, newValue: any) => {
                if (newValue) {
                    setFieldValue("country", newValue);
                }
            }}
            ListboxProps={{ onScroll: this.handleScroll() }}
            renderInput={(params: any) => this.inputRender({
                ...params,
                inputProps: {
                    ...params.inputProps,
                    style: {
                        WebkitAppearance: 'none', 
                        
                    },
                },
            })}            defaultValue={this.state.countryList[0] || ''}
        />)
    }

    handleRemove = () => {
        this.setState({ selectedFile: ''});
    };
    mainHandleRemove = (ind: number) => {
        const newDoument = [...this.state.selectedFile]
        const newBlobMani = [...this.state.combineImg]
        newDoument.splice(ind, 1)
        newBlobMani.splice(ind, 1)
        this.setState({ selectedFile: newDoument, combineImg: newBlobMani })
    };
    openPdfUpload = () => {
        const inputPensionTwo = document.createElement('input');
        inputPensionTwo.type = 'file';
        inputPensionTwo.accept = "image/png, image/jpeg, iamge/jpg, application/pdf";
        inputPensionTwo.click();
        inputPensionTwo.onchange = (event: any) => {
            this.openPictureUploadInSideLast(event);
        }
    }
    openPictureUploadInSideLast = async (event: any) => {
        const uploadFile = event.target.files[0] || null;
        if (uploadFile.size < 2000000) {
            const base64Last = await this.convertBase64(uploadFile)
            this.setState({ selectedPdf: uploadFile, openPictureUploadNewError: false, selectedPdfError: '', imgBoxFinal: base64Last })
        } else {
            this.setState({ openPdfUploadError: true })
        }
    }
    handleRemovePdf = () => {
        this.setState({ selectedPdf: '', imgBoxFinal: ''});
    }
    dialogPropertyModel = () => {
        this.setState({ openPropertyModel: !this.state.openPropertyModel })
        this.props.navigation.navigate('CategoriessubcategoriesWeb')
    }

    responseErrorMessage = (addPensionResponseJson: any) => {
        if (addPensionResponseJson.errors.name) {
            this.setState({ propertyNameError: addPensionResponseJson.errors.name[0] })
        } else {
            this.setState({ propertyNameError: '' })
        }
        if (addPensionResponseJson.errors.mortgage) {
            this.setState({ setMortgageError: addPensionResponseJson.errors.mortgage[0] })
        } else {
            this.setState({ setMortgageError: ''  })
        }
    }
    firstApiResponse = (from: string, message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserPDFDataCallId !== null &&
            this.postUserPDFDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonAddProperty = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJsonAddProperty.list) {
                const userSelectedCurrncy = responseJsonAddProperty.list?.find((value: any) => {
                    return value?.id == responseJsonAddProperty?.selection
                })
                const currency = responseJsonAddProperty.list?.map((value: any) => (
                    { value: value.currency_type, label: value.currency_type }
                ));
                this.setState({
                    currency_name: currency, currencyList: responseJsonAddProperty,
                    selectCurrency: { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` },
                    selectCashCurrency: { value: userSelectedCurrncy.currency_type, label: userSelectedCurrncy.currency_type }
                });
                this.setState({
                    hader_dropdown: responseJsonAddProperty?.list?.map((value: any) => (
                        { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
                    ))
                });
            }
            if (responseJsonAddProperty?.meta?.message.includes("successfully")) {
                this.setState({ openPropertyModel: !this.state.openPropertyModel })
            }
            if (responseJsonAddProperty?.meta?.message.includes("updated")) {
                this.props.navigation.navigate("PropertyAssetsOverview")
            }
            if (responseJsonAddProperty?.errors) {
                this.responseErrorMessage(responseJsonAddProperty)
            }
        }
    }
    secondApiResponse = (from: string, message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserStatusDataCallId !== null &&
            this.postUserStatusDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const getStatus = responseJson?.data?.map((value: any) => (
                { value: value.attributes.id, label: value.attributes.name.charAt(0).toUpperCase() + value.attributes.name.slice(1) }
            ))
            this.setState({ allStatus: getStatus })
        }
    }
    thirdApiResponse = (from: string, message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.portableTypesApiCallId !== null &&
            this.portableTypesApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    propertyAssetsTypes: responseJson?.data,
                    id: !this.state.editPropertyData ? responseJson?.data[0].attributes.id : this.state.editPropertyData.sub_category.id,
                    imgtitel: !this.state.editPropertyData ? responseJson?.data[0].attributes.name : this.state.editPropertyData.sub_category.name
                })
            }
        }
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleNavigationMessage(message)
        this.firstApiResponse(from, message)
        this.secondApiResponse(from, message)
        this.thirdApiResponse(from, message)
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserSelectDataCallId !== null &&
            this.postUserSelectDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let cashResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (cashResponseJson.meta.currency) {
                this.getCurrencyList()
            }
        }

        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getPropertyCountriesCallId !== null &&
            this.getPropertyCountriesCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let cashResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.handleCashResponse(cashResponseJson)
        }
        // Customizable Area End
    }
    handleCashResponse = (cashResponseJson: any) => {
        if (cashResponseJson.length > 1) {
            this.setState((prevState) => ({
                countryList: [...prevState.countryList, ...cashResponseJson.map((value: any) => {
                    this.state.allCountryListData.push(value)
                    return (this.returnFlagCode(value.attributes.emoji_flag)+ " "+  (this.state.direction == 'rtl' ?  value.attributes.arabic_name : value.attributes.name) )
                })],
            }), () => {
                this.setState({ apiCallingStage: true })
            });
        } else {
            this.setCountryData();
        }
    }

    handleNavigationMessage(message: Message) {
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
          const data1 = message.getData(
            getName(MessageEnum.NavigationPayLoadMessage) 
          );
          if (data1) {
              this.setState({
                editPropertyData: data1.attributes,
                monthlyPayment: formatNumberWithCommas(data1?.attributes?.property_mortgage?.mortgage_monthly_payments?.toString()),
                propertyId: data1.id,
                bedroomds: data1.attributes.bed_rooms,
                bathrooms: data1.attributes.bath_rooms,
                mortgageAmountCal: parseInt(data1?.attributes?.property_mortgage?.amount),
                mortgageTermCal: parseInt(data1?.attributes?.property_mortgage?.term_years),
                mortgageMonthsCal: parseInt(data1?.attributes?.property_mortgage?.term_months), 
                mortgageInterestsCal: parseInt(data1?.attributes?.property_mortgage?.annual_interest_rate),
              }, () =>  this.setEditImages())
          }
        }
    }

    statusAPI = async () => {
        const data = localStorage.getItem("token")
        const header = { "token": data };
        let userDetailurl = propertyConfigJSON.StatusApi;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: propertyConfigJSON.exampleAPiNewMethodtoken,
        });
        this.postUserStatusDataCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }
    getPortableTypes() {
        const data = localStorage.getItem("token")
        const header = { "token": data };
        let userDetailurl = propertyConfigJSON.PropertyTypes;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: propertyConfigJSON.exampleAPiNewMethodtoken,
        });
        this.portableTypesApiCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    getCurrencyList = () => {
        const data = localStorage.getItem("token")
        const header = { "token": data };
        let userDetailurl = propertyConfigJSON.Loginsubmittoken;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: propertyConfigJSON.exampleAPiMethodtoken,
        });
        this.postUserPDFDataCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
        this.statusAPI()
        this.getPortableTypes()
    }
    firstImage = async (value: any) => {
        try {
            const response = await fetch(value.url);
            const blob = await response.blob();
            const newFile = new File([blob], value.filename, { type: value.content_type });
            this.setState({ selectedFile: [...this.state.selectedFile, newFile] })
        } catch (error) {
            return null;
        }
    }

    secondImage = async (value: any) => {
        try {
            const response = await fetch(value.url);
            const blob = await response.blob();
            const newFile = new File([blob], value.filename, { type: value.content_type });
            this.setState({ selectedPdf: newFile })
        } catch (error) {
            return null;
        }
    }

    roomManager = () => {
        if (this.state.editPropertyData?.bath_rooms) {
            this.setState({ bathrooms: this.state.editPropertyData.bath_rooms })
        }
        if (this.state.editPropertyData?.bed_rooms) {
            this.setState({ bedroomds: this.state.editPropertyData.bed_rooms })
        }
    }

    setEditImages = () => {
        if (this.state.editPropertyData?.images && this.state.editPropertyData?.images.length > 0) {
            const imageUploadOne = this.state.editPropertyData?.images?.map((value: any) => value.url)
            this.setState({ combineImg: imageUploadOne })
            this.state.editPropertyData?.images?.map(async (value: any) => {
                this.firstImage(value)
            })
        }
        if (this.state.editPropertyData?.title_deeds && this.state.editPropertyData?.title_deeds.length > 0) {
            this.setState({ imgBoxFinal: this.state.editPropertyData?.title_deeds[0].url })
            this.state.editPropertyData?.title_deeds?.map(async (value: any) => {
                this.secondImage(value)
            })
        }
        this.roomManager()
    }

    getPropertyCountriesValue = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPropertyCountriesCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/get_countries?page=${this.state.countryPage + 1}`
        );
        this.setState({ countryPage: this.state.countryPage + 1 })
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    async componentDidMount() {
        let language = await getStorageData('language');
        const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
        this.setState({ direction: currentLanguage });

        this.getCurrencyList();
        this.getPropertyCountriesValue();
        this.setEditImages();
    }

    propertyImageUpload = (formData: any) => {
        if (this.state.selectedFile?.length > 0) {
            this.state.selectedFile?.forEach((it: any, index: number) => {
                it && formData?.append("data[attributes][images][]", it);
            })
        } else {
            formData.append("data[attributes][delete_all_images]", "true");
        }
        if ([this.state.selectedPdf]?.length > 0) {
            [this.state.selectedPdf].forEach((it: any, index: number) => {
                if (it) formData?.append("data[attributes][title_deeds][]", it);
                else formData?.append("data[attributes][delete_all_deeds]", 'true');
            })
        }
    }

    submitPropertyApi = (requestMessageProperty: any) => {
        if (this.state.editPropertyData) {
            requestMessageProperty.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
                'PUT'
            );
        } else {
            requestMessageProperty.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
                'POST'
            );
        }
    }
    handleNestedLoop=(values:any,formData:any)=>{
        if (Boolean(values?.mortgageSelect?.value === 'yes')) {
            formData.append("data[attributes][property_mortgage_attributes][amount]",values.mortgageAmount);
            formData.append("data[attributes][property_mortgage_attributes][term_years]",values.mortgageTerm);
            formData.append("data[attributes][property_mortgage_attributes][term_months]",values.mortgageMonths);
            formData.append("data[attributes][property_mortgage_attributes][annual_interest_rate]",values.mortgageInterest);
            formData.append("data[attributes][property_mortgage_attributes][mortgage_monthly_payments]", sanitizeAndRemoveCommas(this.state.monthlyPayment));
            formData.append("data[attributes][property_mortgage_attributes][start_date]", this.formateDate(values.mortgageStartDate));
            formData.append("data[attributes][property_mortgage_attributes][first_loan_payment_date]", this.formateDate(values.mortgageFirstPayment));
        } else {
            formData?.append("data[attributes][property_mortgage_attributes]", "null");
        }
    }

    getCountryData = (values: any) => {
        const getData = this.state.allCountryListData.filter((value: {
            id: string;
            type: string;
            attributes: {
              name: string;
              emoji_flag: string;
              country_code: string;
              arabic_name: string;
            }
        }) => { return value.attributes?.emoji_flag+" "+( this.state.direction == 'rtl' ? value.attributes.arabic_name :value.attributes.name) == values.country })[0]
        return getData?.id
    }

    setCountryData = () => {
        const getData = this.state.allCountryListData.find((valueData: any) => valueData.id === this.state.editPropertyData?.country);
        this.setState({
            initialCountryValue: getData?.attributes?.emoji_flag + " " +( this.state.direction == 'rtl' ?   getData?.attributes?.arabic_name : getData?.attributes?.name)
        })
    }

    formateDate = (dateString: string) => {
        const newDate = new Date(dateString);
        return `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`;
    }

    HandleSubmit = async (values: FormikValues) => {
        this.handleNext()
        if (this.state.activeStep === 2 && this.state.activeStepTwo) {
            const data = localStorage.getItem("token")
            const header = { "token": data };
            let userDetailurl = !this.state.editPropertyData ? propertyConfigJSON.PropertyApi : `account_block/properties/${this.state.propertyId}`;
            const formData = new FormData();
            formData.append("data[attributes][sub_category_id]", this.state.id);
            formData.append("data[attributes][name]",values.propertyName);
            formData.append("data[attributes][location]",values.location);
            formData.append("data[attributes][country]",values.country ? this.getCountryData(values) : null);
            formData.append("data[attributes][price_currency]",values.currency?.value ? values.currency?.value : this.state.selectCashCurrency.value);
            formData.append("data[attributes][price]",values.price);
            formData.append("data[attributes][purchase_date]",this.formateDate(values.propertyPriceDate));
            const marketPrice = values.marketPrice ?? 0;
            formData.append("data[attributes][market_value]", marketPrice.toString());
            const marketPriceDate = values.marketPriceDate ? this.formateDate(values.marketPriceDate) : 0;
            formData.append("data[attributes][market_date]", marketPriceDate.toString());
            formData.append("data[attributes][martagage_loan]",Boolean(values.mortgageSelect?.value === 'yes').toString());
            formData.append("data[attributes][apartment_number]",values.apartment);
            formData.append("data[attributes][floor_number]",values.floor);
            formData.append("data[attributes][area_size]",values.area);
            formData.append("data[attributes][bed_rooms]",this.state.bedroomds.toString());
            formData.append("data[attributes][bath_rooms]",this.state.bathrooms.toString());
            formData.append("data[attributes][status_id]",!values.status?.value ? values?.status : values.status?.value);
           
            this.handleNestedLoop(values,formData)
            this.propertyImageUpload(formData)
            const requestMessageProperty = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.postUserPDFDataCallId = requestMessageProperty.messageId;
            requestMessageProperty.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
                userDetailurl
            );
            requestMessageProperty.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessageProperty.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );
            this.submitPropertyApi(requestMessageProperty)
            runEngine.sendMessage(requestMessageProperty.id, requestMessageProperty);
        }
    }

    potableSelect = () => {
        return (
            <FormControl>
                <Select options={this.state.hader_dropdown}
                    value={this.state?.selectCurrency}
                    onChange={(val: any) => { this.setPropertyCurrency(val) }}
                    classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="selectMenu" 
                />
            </FormControl>
        )
    }

    setCurrencyPutapi = (currencyId: string) => {
        const dataCash = localStorage.getItem("token")
        const header = { "token": dataCash };
        let userDetailurl = propertyConfigJSON.SetCurrencyPutapi;
        const propertyFormData = new FormData();
        propertyFormData.append("currency_id", currencyId);
        const newPropertyRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postUserSelectDataCallId = newPropertyRequestMessage.messageId;
        newPropertyRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            userDetailurl
        );
        newPropertyRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        newPropertyRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            propertyFormData
        );
        newPropertyRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            'PUT'
        );
        runEngine.sendMessage(newPropertyRequestMessage.id, newPropertyRequestMessage);
    }

    setPropertyCurrency = (value: { value: string, label: string }) => {
        const propertyCurrencyId = this.state.currencyList?.list?.find((newValue: any) => {
            return newValue?.currency_type == value?.value
        }).id
        this.setCurrencyPutapi(propertyCurrencyId)
    }

    getStringAddProperty = (key: string) => {
        let languageProperty = localStorage.getItem("language") || "English"
        return propertyConfigJSON.languageListLogin[languageProperty][key]
    }

    validDecimals = (value: string) => {
        if (!value) return true;
        return /^\d+(\.\d{1,2})?$/.test(value);
    }

    propertySchema = [
        Yup.object().shape({
            propertyName: Yup.string()
            .required(this.getStringAddProperty('propertyNameError')),
            country: Yup.string().nullable()
                .required(this.getStringAddProperty('locationCountryRequired'))
        }),
        Yup.object().shape({
            propertyPriceDate: Yup.date().nullable().required(this.getStringAddProperty("propertyDateReqError")),
            marketPriceDate: Yup.date().min(
                Yup.ref('propertyPriceDate'),
                this.getStringAddProperty("PropertyMarketPriceError")
            ).nullable(),
            price: Yup.number()
            .transform((value, originalValue) => {
              if (typeof originalValue === 'string') {
                return Number(sanitizeAndRemoveCommas(originalValue));
              }
              return value;
            })
            .nullable()
            .typeError(this.getStringAddProperty('priceTypeErrors'))
            .required(this.getStringAddProperty('priceErrors')),
          
            mortgageSelect: Yup.object().shape({
                label: Yup.string(),
                value: Yup.string()
            }).required(),
            mortgageAmount: Yup.number()
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                return Number(sanitizeAndRemoveCommas(originalValue));
                }
                return value;
            })
            .when('mortgageSelect.value', {
                is: "yes",
                then: Yup.number()
                .nullable()
                .required(this.getStringAddProperty('mortgageAmountError'))
            })
            .nullable(),

            mortgageTerm: Yup.number().when('mortgageSelect.value', {
                is: "yes",
                then: Yup.number().nullable().typeError(this.getStringAddProperty('mortgageTermDecimalError'))
                    .integer(this.getStringAddProperty("mortgageTermDecimalError"))
                    .required(this.getStringAddProperty('mortgageTermError'))
            }).nullable(),
            mortgageMonths: Yup.number().when('mortgageSelect.value', {
                is: "yes",
                then: Yup.number().nullable().typeError(this.getStringAddProperty('mortgageMonthsTypeError'))
                    .integer(this.getStringAddProperty("mortgageMonthsDecimalError"))
                    .min(0, this.getStringAddProperty("mortageMonthsMinError"))
                    .max(12, this.getStringAddProperty("mortgageMonthsMaxError"))
                    .required(this.getStringAddProperty('mortgageMonthsError'))
            }).nullable(),
            mortgageInterest: Yup.number().when('mortgageSelect.value', {
                is: "yes",
                then: Yup.number().nullable().typeError(this.getStringAddProperty('mortgageInterestTypeError'))
                    .required(this.getStringAddProperty('mortgageInterestError'))
            }).nullable(),
            mortgageStartDate: Yup.string().when('mortgageSelect.value', {
                is: "yes",
                then: Yup.string().required(this.getStringAddProperty('mortgageStartDateError'))
            }).nullable(),
            mortgageFirstPayment: Yup.string().when('mortgageSelect.value', {
                is: "yes",
                then: Yup.string().required(this.getStringAddProperty('mortgageFirstPaymentError'))
            }).nullable(),
        }),
        Yup.object().shape({
            status: Yup.object().shape({
                label: Yup.string(),
                value: Yup.string()
            })
                .nullable()
                .required(this.getStringAddProperty('statusError')),
        }),
    ]

    finalErrorMessage(error: any) {
        if (error) {
            return error
        } else {
            return;
        }
    }

    staperButton = () => {
        if (this.state.activeStep === 0) {
            return this.getStringAddProperty('NextButton')
        } else if (this.state.activeStep === 1) {
            return this.getStringAddProperty('NextButton')
        } else if (this.state.activeStep === 2) {
            return this.getStringAddProperty('SaveButton')
        }
    }

    stepperArea = () => {
        return (
            <Stepper nonLinear style={{ padding: '24px 0px' }} className={"dottedLine"} activeStep={this.state.activeStep}>
                <Step className={this.state.activeStep >= 0 ? 'dottedLine2' : 'dottedLine'}>
                    <StepLabel></StepLabel>
                </Step>
                <Step className={this.state.activeStep >= 1 ? 'dottedLine2' : 'dottedLine'}>
                    <StepLabel></StepLabel>
                </Step>
                <Step className={this.state.activeStep >= 2 ? 'dottedLine2' : 'dottedLine'}>
                    <StepLabel></StepLabel>
                </Step>
            </Stepper>)
    }
    ProgressBar = (totalSteps:any, currentStep:any) => {
        return (
          <div style={{ display: 'flex', gap: '5px' }}>
            {Array.from({ length: totalSteps }).map((_, index) => (
              <div
                key={index}
                style={{
                  width: '100%',
                  height: '5px',
                  backgroundColor: index === currentStep ? '#2ce2d5' : 'grey',
                  borderRadius: '50px',
                  transition: 'background-color 0.3s ease'
                }}
              ></div>
            ))}
          </div>
        );
      };

    multiBox() {
        return (
                <Grid container spacing={3}>
                    {this.state.propertyAssetsTypes?.map((value: any, ind: number) => {
                        return (
                            <Grid item xs={6} sm={4} key={ind} data-test-id="setBoxNew">
                                <Box data-test-id="setBoxNew">
                                <Box 
                                data-test-id="setBox0"
                                style={{
                                    backgroundColor: this.state?.id == value.attributes?.id ? '#2CE2D5' : "#36364D",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxShadow: this.state?.id !== value.attributes?.id ? '0px 0px 4px 0px rgba(6, 141, 132, 0.45)' : 'rgb(255 255 255 / 0%) 0px 0px 4px 0px',
                                    alignItems: 'center',
                                    borderRadius: '12px',
                                    padding: '24px',
                                    cursor: 'pointer',
                                }}
                                
                                onClick={() => {
                                    this.setState({ id: value.attributes.id, imgtitel: value.attributes.name })
                                }}
                                >

                                <img width='96px' height="96px" src={value.attributes.image} data-test-id="addPropertySecondImage" />
                                        <Typography style={{
                                            paddingTop: '9px',
                                            textAlign: 'center',
                                            fontSize: '16px',
                                            fontFamily: 'Poppins',
                                            color: this.state.id == value.attributes.id ? '#3C3C50' : '#FFF',
                                            fontWeight: 500,
                                        }}>
                                            {value.attributes.name == "more" ? 'Others' : value.attributes.name.charAt(0).toUpperCase() + value.attributes.name.slice(1)}
                                        </Typography>
                                </Box>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
        )
    }
    uploadPicture = () => {
        return (<Box style={{ paddingTop: '23px' }}>
            <Typography className="fieldTitle">{this.getStringAddProperty('Uploadpicture')}</Typography>
            <Box className="fieldArea" data-test-id="openPictureUpload" onClick={() => { this.openPictureUpload() }} style={{ borderColor: this.state.selectedFile?.name ? '#2CE2D5' : '#FFF' }}>
                <Typography className="fieldAreaLabel">{this.getStringAddProperty('UploadaReferenceImage')}</Typography>
                <img className="fieldImg" src={this.state.image_Upload} />
            </Box>
            {(this.errorMessage())}
            {this.state.selectedFile.map((value: any, index: number) => {
                return (<Box className="fieldAreaShow addPropertyNewimageOne" key={index} style={{ display: value?.name ? 'flex' : 'none' }}>
                    <Typography className="fieldAreaFileName" data-test-id="handleRemove" >
                        <img className="uploadBoximg addPropertyRemoveOne" src={this.state.combineImg[index]} />{value?.name}</Typography>
                    <img className="fieldImg" src={this.state.image_Group4} onClick={() => this.mainHandleRemove(index)} />
                </Box>)
            })}
        </Box>)
    }
    uploadOriginalReceipt = () => {
        return (<Box style={{ paddingTop: '23px' }} className="newPropertyUploadOrigintitle">
            <Typography className="fieldTitle "> {this.getStringAddProperty('UploadTitleDeed')}</Typography>
            <Box className="fieldArea" data-test-id="openPictureUploadTwo" onClick={() => this.state.selectedPdf == '' && this.openPdfUpload()} style={{ borderColor: this.state.selectedPdf?.name ? '#2CE2D5' : "#fff" }}>
                <Typography className="fieldAreaLabel"> {this.getStringAddProperty('UploadTheTitleDeed')}</Typography>
                <img className="fieldImg" src={this.state.image_Upload} />
            </Box>
            {(this.errorMessageNew())}
            <Box className="fieldAreaShow" style={{ display: this.state.selectedPdf?.name ? 'flex' : 'none' }} >
                <Typography className="fieldAreaFileName" data-test-id="handleRemove" >
                    <img src={this.state.imgBoxFinal} className="uploadBoximg" />{this.state.selectedPdf?.name} </Typography>
                <img className="fieldImg" src={this.state.image_Group4} onClick={this.handleRemovePdf} />
            </Box>
        </Box>)
    }

    errorMessage = () => {
        return (
            <Typography className="fieldValidate" style={{
                fontFamily: this.state.selectedFileError ? 'Poppins' : '',
                color: this.state.openPictureUploadError || this.state.selectedFileError ? 'rgb(238, 83, 83)' : 'rgba(255, 255, 255, 0.6)',
                fontSize: this.state.selectedFileError ? '12px' : '',
                fontStyle: this.state.selectedFileError ? 'normal' : '',
                lineHeight: this.state.selectedFileError ? '18px' : '',
                fontWeight: this.state.selectedFileError ? 400 : 0,
            }}>  {this.getStringAddProperty('UploadpictureError')}</Typography>
        )
    }

    propertySelectMenu(name: string, setFieldValue: any, customStyles: any, values: any, fieldValues: any) {
        return (
            <Select
                defaultValue={fieldValues}
                name={name}
                styles={customStyles}
                classNamePrefix="an-simple-select"
                onChange={(val: any) => { setFieldValue(name, val) }}
                options={this.state.currency_name}
                className="selectMenu selectedMenuDropdown"
                value={name === "mortgageCurrency" ? this.mortgageCurrencyFild(fieldValues) : this.fieldValuesFild(fieldValues)}
                data-test-id="selectMenu"
            />
        )
    }
    mortgageSelectMenu(name: string, setFieldValue: any, customStyles: any, values: any, fieldValues: any) {
        return (
            <Select
                defaultValue={fieldValues}
                name={name}
                styles={customStyles}
                classNamePrefix="an-simple-select"
                onChange={(val: any) => { setFieldValue(name, val) }}
                options={[{label: "Yes", value: "yes"}, {label: "No", value: "no"}]}
                className="selectMenu selectedMenuDropdown"
                value={fieldValues}
                data-test-id="mortgageSelectMenu"
            />
        )
    }
    fieldValuesFild = (fieldValues: any) => {
        return fieldValues.value ? fieldValues : this.state.selectCashCurrency
    }
    mortgageCurrencyFild = (fieldValues: any) => {
        return fieldValues.value ? fieldValues : ""
    }

    errorMessageNew = () => {
        return (
            <Typography className="fieldValidate" style={{
                color: this.state.openPictureUploadError || this.state.selectedPdfError ? 'rgb(238, 83, 83)' : 'rgba(255, 255, 255, 0.6)',
                lineHeight: this.state.selectedPdfError ? '18px' : '',
                fontFamily: this.state.selectedPdfError ? 'Poppins' : '',
                fontWeight: this.state.selectedPdfError ? 400 : 0,
                fontSize: this.state.selectedPdfError ? '12px' : '',
                fontStyle: this.state.selectedPdfError ? 'normal' : '',
            }}> 
                {this.state.selectedPdfError ? this.state.selectedPdfError : this.getStringAddProperty('UploadpictureError')}
            </Typography>
        )
    }

    
    handleBathroomsValue = (type:string)=>{
        type === 'increment' ? this.setState({ bathrooms: this.state.bathrooms + 1 }) : this.setState({ bathrooms: this.state.bathrooms - 1 })
    }
    handleBedroomsValue = (type:string)=>{
        type === 'increment' ? this.setState({ bedroomds: this.state.bedroomds + 1 }) : this.setState({ bedroomds: this.state.bedroomds - 1 })
        
    }

    handleMonthlyPaymentCalculate = (name: string, value: string) => {
        switch (name) {
            case "mortgageAmount":
                this.setState({ mortgageAmountCal: parseInt(sanitizeAndRemoveCommas(value)) }, () => this.calculateMortgageInstallments())
                break;
            case "mortgageTerm":
                this.setState({ mortgageTermCal: parseInt(value) }, () => this.calculateMortgageInstallments())
                break;
            case "mortgageMonths":
                this.setState({ mortgageMonthsCal: parseInt(value) }, () => this.calculateMortgageInstallments())
                break;
            case "mortgageInterest":
                this.setState({ mortgageInterestsCal: parseInt(value) }, () => this.calculateMortgageInstallments())
                break;
            default:
                break;
        }
    }

    OutlinedInputAddProperty = (name: string, addPropertywebStyle: any, values: any, handleChange: any, placeholder: string, type: string, nameInputField: { current: null }, readOnly: boolean = false,setFieldValue?: any) => {
        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let inputValue = e.target.value;

            if ((name === "price" || name==="marketPrice" || name==="mortgageAmount") && setFieldValue) { 
                const result = processInputValue(inputValue,15);

                if (result) {
                    const { actualValue, formattedValue } = result;
                    setFieldValue(name, actualValue);
                    handleChange(name)(formattedValue);
                  }
            } else {
            handleChange(name)(inputValue || '');
            }

            if (["mortgageAmount", "mortgageTerm", "mortgageMonths", "mortgageInterest"].includes(name)) {
                this.handleMonthlyPaymentCalculate(name, inputValue)
            }
        };
        return (
            <>
                <OutlinedInput
                    style={addPropertywebStyle.inputRoot}
                    data-test-id="txtInputAssetname"
                    placeholder={this.getStringAddProperty(placeholder)}
                    fullWidth={true}
                    name={name}
                    readOnly={readOnly}
                    type={type}
                    value={values}
                    onChange={handleInputChange}
                    inputRef={nameInputField} onKeyDown={this.handleEnterKeyAddProerty} onClick={() => this.handleNameInputClickAddProerty(name)}
                />
            </>
        )
    }

    calculateMortgageInstallments = () => {
        const { mortgageAmountCal, mortgageInterestsCal, mortgageMonthsCal, mortgageTermCal } = this.state
        if (mortgageAmountCal?.toString() && mortgageTermCal?.toString() && mortgageMonthsCal?.toString() && mortgageInterestsCal?.toString()) {
            const monthlyInterest = mortgageInterestsCal / (12 * 100);
            const totalPayment = mortgageTermCal * 12 + mortgageMonthsCal;
            const monthlyPayment = mortgageAmountCal * (monthlyInterest * Math.pow(1 + monthlyInterest, totalPayment)) / (Math.pow(1 + monthlyInterest, totalPayment) - 1);
            this.setState({ monthlyPayment: monthlyPayment ? formatNumberWithCommas((Math.round(monthlyPayment * 100) / 100).toString()): ""  })
        } else {
            this.setState({ monthlyPayment: "" })
        }
    }

    handleEnterKeyAddProerty = (e: any) => {
        if (e.key === "Enter") {
            if (this.state.focusedInput === "propertyName") {
                this.state.location.current?.focus();
                this.setState({ focusedInput: "location" })
            }else if (this.state.focusedInput === "location") {
                this.state.towerName.current?.focus();
                this.setState({ focusedInput: "towerName" })
            }else if (this.state.focusedInput === "towerName") {
                this.state.apartment.current?.focus();
                this.setState({ focusedInput: "apartment" })
            }else if (this.state.focusedInput === "apartment") {
                this.state.floor.current?.focus();
                this.setState({ focusedInput: "floor" })
            }else if (this.state.focusedInput === "floor") {
                this.state.area.current?.focus();
                this.setState({ focusedInput: "area" })
            }else if (this.state.focusedInput === "area") {
                this.state.price.current?.focus();
                this.setState({ focusedInput: "price" })
            }else if (this.state.focusedInput === "price") {
                this.state.mortgage.current?.focus();
                this.setState({ focusedInput: "mortgage" })
            }
        }
    };
    editPropertyName = () => {
        return this.state.logoImg1AddProperty === "rtl" ? "يحرر" : "Edit"
    }
    addPropertyName = () => {
        return this.state.logoImg1AddProperty === "rtl" ? "يضيف" : "Add"
    }
    onKeyDownAddProerty = (keyEvent: any) => { if ((keyEvent.charCode || keyEvent.keyCode) === 13) { keyEvent.preventDefault() } }

    handleNameInputClickAddProerty = (name: string) => { this.setState({ focusedInput: name }) };

    editPropertyTitle = () => {
        return !this.state.editPropertyData ? this.addPropertyName() : this.editPropertyName()
    }
    returnFlagCode = (value : string) => {
        return value
    }

    // Customizable Area End
}