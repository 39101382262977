import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from "yup";
import storage from "../../../framework/src/StorageProvider.web";
import React , { ChangeEvent } from "react";
import moment from "moment";
import { InputProps } from "@material-ui/core";
import toast from "react-hot-toast";
import { Regex } from "../../../components/src/commonFn";


export interface IPlannerInfo {
  id: string
  type: string
  attributes: {
    planner_id: number
    name: string
    location: string
    jurisdiction_advice: string
    disclosure: string
    firm: string
    employment_history: string
    qualification: string
    experience: number
    certificate: string
    email: string
    terms_and_condition: boolean
    url: string
    dob: string
    mobile: string
    firm_website: string
    focus: {
    name: string,
    id:string
    }[]
    image: string
  }
}

export interface IPlannerEditDetails{
  name: string,
  firm: string,
  jurisdiction_advice: string,
  disclosure: string,
  employment_history: string,
  qualification: string,
  experience: number | null,
  certificate:string,
  mobile: string,
  firm_website : string,
  dob: Date,
  location:{
    value:string,
    label: string
  },
  focus:{
    value:string,
    label: string
  }[]
}

interface IFile {
  fileCopyUri: string;
  name: string;
  size: number;
  type: string;
  uri: string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  plannerInfo: IPlannerInfo | null,
  isEdit: boolean,
  initialValues: IPlannerEditDetails,
  openPhotoDialog: boolean,
  confirmDialog: boolean,
  isUpdatingPhoto: boolean,
  errorMessage: string,
  locationDropdown: {value:string,label:string}[]
  focusDropdown: {value:string,label:string}[],
  isOldResetPasswordVisible: boolean;
  isNewesetPasswordVisible: boolean;
  isConfirmNewResetPasswordVisible: boolean;
  isResettingPassoword: boolean;
  openDeleteDialog: boolean;
  openSnackbar:boolean,
  errorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class WealthPlannerSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    // Customizable Area End


    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      plannerInfo: null,
      isEdit: false,
      initialValues: {
        name: "",
        firm:"",
        jurisdiction_advice: "",
        disclosure: "",
        employment_history: "",
        qualification: "",
        experience: null,
        certificate:"",
        mobile: "",
        firm_website : "",
        dob: new Date(),
        location: {
          value: "",
          label: ""
        },
        focus:[]
      },
      openPhotoDialog: false,
      isUpdatingPhoto: false,
      errorMessage:"",
      locationDropdown: [],
      focusDropdown : [],
      confirmDialog:false,
      isOldResetPasswordVisible: false,
      isNewesetPasswordVisible: false,
      isConfirmNewResetPasswordVisible:false,
      isResettingPassoword: false,
      openDeleteDialog: false,
      openSnackbar:false,
      errorMsg: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileInputRef = React.createRef();

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleUserProfileResponse(apiRequestCallId,responseJson);
    this.handleUserProfileUpdate(apiRequestCallId);
    this.handleUserProfilePhotoUpdate(apiRequestCallId);
    this.handleLocationResponse(apiRequestCallId,responseJson)
    this.handleFocusListResponse(apiRequestCallId,responseJson)
    this.handleResetPasswordResponse(apiRequestCallId,responseJson);
    this.handleDeleteAccountResponse(apiRequestCallId,responseJson);

    

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getUserProfile();
    this.getLocation();
    this.getFocusList()
  }


  getUserProfileAPICallId: string = "";
  updateUserProfileAPICallId: string = "";
  getLocationAPICallId: string = "";
  getFocusAPICallId: string = "";
  updateUserProfilePhotoAPICallId:string = ""
  fileInputRef: React.RefObject<InputProps & { click: Function }>;
  updatePasswordAPIcallId: string = ""
  deleteAccountAPIcallId:string = ""

  getLocation = async () => {
    this.getLocationAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: "bx_block_content_management/investments/filter_items",
      body: null
    });
  }

  getUserProfile = async () => {
    this.getUserProfileAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/show_profile",
      body: null
    });
  }

  getFocusList = async () => {
    this.getFocusAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/focus_list",
      body: null
    });
  }

  apiCall = async (data: {
    method: string, endPoint: string, body: string | null |FormData,contentType ?: string
  }) => {
    const {  method, endPoint, body,contentType } = data;
    const token = await storage.get("auhtToken")

    const settingRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    settingRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token,
        
      })
    );
    if(contentType){
      settingRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": contentType,
          token,
        })
      );
    }

    settingRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    settingRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && settingRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(settingRequestMessage.id, settingRequestMessage);
    return settingRequestMessage.messageId;
  };

  handleUserProfileResponse(apiCallId:string,responseJson: {
    data: IPlannerInfo
  }){
    if(apiCallId === this.getUserProfileAPICallId){
      this.setState({
        plannerInfo: responseJson.data
      })
    }
  }

  handleFocusListResponse(apiCallId:string,responseJson: {
    focus: {
      data :{
        attributes : {
          name: string
        },
        id: string
      }[]
    }
  }){
    if(apiCallId === this.getFocusAPICallId){
      this.setState({
        focusDropdown: responseJson.focus.data.map((item)=>{
          return {
            label: item.attributes.name,
            value: item.id
          }
        })
      })
    }
  }

  handleEditDetails = () => {
    this.setState({
     isEdit: true,
    })
    if(this.state.plannerInfo){
      this.setState({
        initialValues: {
          name: this.state.plannerInfo.attributes.name,
          firm: this.state.plannerInfo.attributes.firm,
          mobile: this.state.plannerInfo.attributes.mobile,
          jurisdiction_advice: this.state.plannerInfo.attributes.jurisdiction_advice,
          disclosure: this.state.plannerInfo.attributes.disclosure,
          employment_history: this.state.plannerInfo.attributes.employment_history,
          qualification: this.state.plannerInfo.attributes.qualification,
          experience: this.state.plannerInfo.attributes.experience,
          certificate: this.state.plannerInfo.attributes.certificate,
          firm_website: this.state.plannerInfo.attributes.firm_website,
          dob: moment(this.state.plannerInfo.attributes.dob,"DD/MM/YYYY").toDate(),
          location: {
            value: this.state.plannerInfo.attributes.location,
            label: this.state.plannerInfo.attributes.location
          },
          focus: this.state.plannerInfo.attributes.focus.map((focus)=>{
            return {
              value: this.getFocusId(focus.name),
              label : focus.name
            }
          })
         }
      })
    }
  }

  handleOpenConfirmDialog(values:IPlannerEditDetails){
    this.setState({
      initialValues: values,
      confirmDialog: true
    })
  }

  getFocusId = (name:string) => {
    let focus  = this.state.focusDropdown.find((focus)=>focus.label === name) 
    return focus ? focus.value : "0"
  }


  handleSubmit = async () => {
    let experience = "0";
    if(this.state.initialValues.experience){
      experience = this.state.initialValues.experience.toString()
    }
    const focus = this.state.initialValues.focus.map((focus) => focus.value);
    const formdata = new FormData();
    formdata.append("data[name]", this.state.initialValues.name);
    formdata.append("data[location]", this.state.initialValues.location.value);
    formdata.append("data[jurisdiction_advice]", this.state.initialValues.jurisdiction_advice);
    formdata.append("data[focus]", JSON.stringify(focus));
    formdata.append("data[certificate]", this.state.initialValues.certificate);
    formdata.append("data[experience]",experience);
    formdata.append("data[qualification]", this.state.initialValues.qualification);
    formdata.append("data[employment_history]", this.state.initialValues.employment_history);
    formdata.append("data[firm]", this.state.initialValues.firm);
    formdata.append("data[disclosure]", this.state.initialValues.disclosure);
    formdata.append("data[firm_website]", this.state.initialValues.firm_website);
    formdata.append("data[mobile]", this.state.initialValues.mobile);
    formdata.append("data[dob]", moment(this.state.initialValues.dob).format("DD-MM-YYYY"));
   
    this.updateUserProfileAPICallId = await this.apiCall({
      method: "PATCH",
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/update",
      body: formdata
    });
  }
  handleCloseConfirmationPopup(){
    this.setState({
      confirmDialog: false,
      isEdit:false
    })
  }

  handleClosePhotoDialog(){
    this.setState({
      openPhotoDialog: false,
    })
  }

  handleUserProfileUpdate(apiCallId:string){
    if(apiCallId === this.updateUserProfileAPICallId){
      this.getUserProfile();
      this.setState({
        isEdit: false,
        confirmDialog: false
      })
    }
  }

  handleEditPhoto=()=>{
    this.setState({
      openPhotoDialog : true
    })
  }

  openPictureUpload = () => {
    const refrence = this.fileInputRef.current;
    if (refrence) {
      refrence.click();
    }
  }
  

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.files) {
      const file = event.target.files[0];
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
        if (!allowedTypes.includes(file.type)) {
        this.setState({
                  errorMessage: "Only JPEG, PNG, or GIF formats are allowed",
                  isUpdatingPhoto: false
                })
        return;
      }
        if (file.size > 1048576) { 
        this.setState({
          errorMessage: "File must be 1MB or smaller",
          isUpdatingPhoto: false
        });
        return;
      }
          let reader = new FileReader();
        reader.onload = async () => {
          this.setState({ isUpdatingPhoto: true, errorMessage: "" });
          const formdata = new FormData();
          formdata.append("data[image]", file);
          this.updateUserProfilePhotoAPICallId = await this.apiCall({
            method: "PATCH",
            endPoint: "bx_block_formapprovalworkflow/wealth_planners/update",
            body: formdata
          });
        };
        reader.readAsDataURL(file);
    }
  };

  handleUserProfilePhotoUpdate(apiCallId:string){
    if(apiCallId === this.updateUserProfilePhotoAPICallId){
      this.getUserProfile();
      this.setState({
        isUpdatingPhoto: false,
      })
    }
  }

  

  validationSchema = yup.object().shape({
    name: yup.string().nullable().required('Name is required'),
    firm: yup.string().nullable().required('Firm is required'),
    jurisdiction_advice: yup.string().nullable().required('Jurisdiction advice is required'),
    disclosure: yup.string().nullable().required('Disclosure is required'),
    employment_history: yup.string().nullable().required('Employment history is required'),
    qualification: yup.string().nullable().required('Qualification is required'),
    experience: yup.number()
      .nullable()
      .min(0, 'Experience must be a positive number')
      .required('Experience is required'),
    certificate: yup.string().nullable().required('Certificate is required'),
    mobile: yup.string()
      .matches(
        /^\+?\d{6,15}$/,
        'Must be a valid mobile number'
       )
      .nullable()
      .required('Mobile number is required'),
    firm_website: yup.string().nullable()
    .matches(
      Regex.urlRegex,
      'Must be a valid URL'
     )
      .required('Firm website is required'),
    dob: yup.date().nullable()
      .max(new Date(), 'Date of birth cannot be in the future')
      .required('Date of birth is required'),
    location: yup.object().nullable().shape({
        value: yup.string().nullable().required("Location is required"),
      }),
    focus: yup.array().nullable().of(
        yup.object().shape({
          value: yup.string(),
          label: yup.string()
        })
      ).min(1, 'Focus is required')
      .required('Focus is required')
  });

  handleLocationResponse(apiCallId:string,responseJson: {
    locations :string[]
  }){
    if(apiCallId === this.getLocationAPICallId){
      this.setState({
        locationDropdown: responseJson.locations.map((item)=> {
          return {
            value: item,
            label: item
          }
        }),
      })
    }
  }

  handleToggleCurrentPassword = () => {
    this.setState({
      isOldResetPasswordVisible : !this.state.isOldResetPasswordVisible
    })
  }

  handleToggleNewPassword = () => {
    this.setState({
      isNewesetPasswordVisible : !this.state.isNewesetPasswordVisible
    })
  }

  handleToggleConfirmPassword = () => {
    this.setState({
      isConfirmNewResetPasswordVisible : !this.state.isConfirmNewResetPasswordVisible
    })
  }

  passwordSchema = yup.string()
  .required('Password is required')
  .min(8, 'Password must be at least 8 characters long')
  .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
  .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
  .matches(/\d/, 'Password must contain at least one number');
 validationResetPasswordSchema = yup.object().shape({
  currentPassword: yup.string().required('Current Password is required'),
  newPassword: this.passwordSchema,
  confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  .required('Confirm Password is required'),
  })

  handleResetPassword = () => {
    this.setState({
      isResettingPassoword: true
    })
  }

  cancelResetPassword = () => {
    this.setState({
      isResettingPassoword: false
    })
  }

  handleSubmitResetPassword =async (payload :{
    new_password:string,
    old_password: string
  })=> {
    this.updatePasswordAPIcallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/reset_password",
      body: JSON.stringify(payload),
      contentType:"application/json"   
     });
  }

  handleResetPasswordResponse(apiCallId:string,responseJson: {
    message :string
  }){
    if(apiCallId === this.updatePasswordAPIcallId){
      if(responseJson.message === "Password changed successfully"){
        toast.success(responseJson.message);
        this.setState({
          isResettingPassoword: false
        })
      }else{
        toast.error(responseJson.message)
      }
    }
  }

  hadleDeleteAccountDialog = () => {
    this.setState({
      openDeleteDialog: true
    })
  }

  hadleDeleteAccount = async () => {
    this.deleteAccountAPIcallId = await this.apiCall({
      method: "DELETE",
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/delete_account",
      body: null,
     });
  }

  cancelDeleteAccount = () => {
    this.setState({
      openDeleteDialog: false
    })
  }

 async handleDeleteAccountResponse(apiCallId:string,responseJson: {
    message :string
  }){
    if(apiCallId === this.deleteAccountAPIcallId){
      if(responseJson.message === "Cannot delete account due to upcoming or ongoing services. Please contact admin for support."){
        this.setState({
          openDeleteDialog: false,
          errorMsg: responseJson.message,
            openSnackbar: true
        })
      }else{
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Loginplanner");
        message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(message);
        await storage.remove("auhtToken")
        this.setState({
          openDeleteDialog: false,
          errorMsg: responseJson.message,
            openSnackbar: true
        })
        toast.success(responseJson.message);
      }
    }
  }

  handleCloseSnackbar = () => {
    this.setState({
      openSnackbar: false
    })
  }
  
  handleForgotPassword = async () =>{
    await storage.set("role","planner");
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotPassword");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);

  }
  // Customizable Area End 
}