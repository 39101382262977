import React from "react";

// Customizable Area Start
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import '../assets/css/myneSettingsBankWallet.css';
import { Link } from 'react-router-dom';
import DeleteDialogModal from "../../../components/src/DeleteDialogModal";
import BankWalletCard from "../../../components/src/BankWallet/BankWalletCard";
// Customizable Area End

import MyneSettingsController, {
  Props,
} from "./MyneSettingsController.web";

export default class MyneBankWalletLoginScreen extends MyneSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  fetchKeys(data: any) {
    let obj = {
      bank_name: '',
      bank_logo_url: '',
      bank_account_number: '',
      sort_code: ''
    }
    if (data.attributes) {
      const d = data.attributes;
      obj.bank_name = d.bank_name || d.walletName;
      obj.bank_logo_url = d.bank_logo_url;
      obj.bank_account_number = d.bank_account_number;
      obj.sort_code = d.sort_code;
    }
    return obj
  }
  roundedNewBank = () => {
    return this.state.logoImgsetting === "rtl" ? "bnk-close-rounded bnk-close-rounded-new" : "bnk-close-rounded"
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-bank-wallet-container" dir={this.state.logoImgsetting}>
        <div className="setting-profile-bank-wallet-container">
          <div className="bnk-small-text">
            {this.getStringGoalmanagement("Settings")}
            <Link to={'/Dashboard'} className={this.roundedNewBank()}>
              <svg xmlns="http://www.w3.org/2000/svg" width={34} height="34" fill="#ffffff" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
            </Link>
          </div>
          <div className="bnk-bold-text">{this.getStringGoalmanagement("bankLogins")}</div>

          {
            this.state.isLoadeBankData ? <p style={{ color: '#2ce2d5', fontSize: '16px' }}>{this.getStringGoalmanagement("loading")}</p> : <>
              <div className="bank-container">
                {this.filterBankData().length > 0 && (
                  this.filterBankData().map((bank: any, index: any) => (
                    <div key={index} className="entity_container">
                      <div className="entityHeader">
                        <div>
                          <p className="title-value">{bank.bank_name}</p>
                          <p className="wallet-count-small-text">
                            {`${this.getStringGoalmanagement('AccountsLinked')} ${bank.accounts ? bank.accounts.length : 0}`}
                          </p>
                        </div>
                        {
                          bank.entity_id && <button type="button" className="remove_all_entity" onClick={() => this.openConfirmationModal(bank.entity_id, 'entity')}>
                            {this.getStringGoalmanagement('deleteAllEntity')}
                          </button>
                        }

                      </div>


                      <div className="bank-card-container">
                        {bank.accounts && bank?.accounts.length > 0 && bank?.accounts?.map((account: any, index: any) => (
                          <BankWalletCard
                            key={index}
                            bankAccountData={{
                              ...account,
                              bank_name: bank.bank_name || "Default Bank Name",
                              bank_logo_url: bank.bank_logo_url || "default-logo-url.png",
                              isSynced:bank.isSynced
                            }}
                            onRemove={this.openConfirmationModal}
                            onSync={this.handleRefreshBank}
                            getString={this.getStringGoalmanagement}
                          />
                        ))}
                      </div>
                    </div>
                  ))
                )}

                <div>
                  <div className="bank-card-container">
                    {this.filterPortfolioData().length > 0 && (
                      this.filterPortfolioData().map((bank: any, index: any) => (
                        <BankWalletCard
                          key={index}
                          bankAccountData={{
                            id: bank.id!,
                            bank_logo_url: bank.bank_logo_url,
                            bank_name: bank.bank_name,
                            bank_account_number: bank.bank_account_number?.toString(),
                            sort_code: bank.sort_code,
                            connected_on: bank.connected_on || '',
                            last_synced: bank.last_synced || '',
                            type: bank.type!,
                            isSynced:bank.isSynced
                          }}
                          onRemove={this.openConfirmationModal}
                          onSync={this.handleRefreshBank}
                          getString={this.getStringGoalmanagement}
                        />
                      ))
                    )}
                    <div className="bank-card-container dashed-card">
                      <Link to='/AddBankAccounts'>
                        <AddRoundedIcon className="add-card-logo" />
                      </Link>
                      <span className="add-new-text">{this.getStringGoalmanagement("AddNewBank")}</span>
                    </div>
                  </div>
                </div>
              </div>

            </>
          }


        </div>

        <DeleteDialogModal
          open={this.state.openDeleteConfirmationModal}
          onClose={this.closeConfirmationModal}
          onConfirm={this.handleDeleteBank}
          dialogTitle={this.getStringGoalmanagement( this.state.selectedBankForDelete.bankType ==='entity' ? "deleteModalMessageforAllEntity": this.state.selectedBankForDelete.bankType ==="portfolio"? "deleteModalMessageforProtfolioEntity" : "deleteModalMessageforSingleEntity")}
          cancelText={this.getStringGoalmanagement("Cancel")}
          confirmText={this.getStringGoalmanagement("Delete")}
        />
      </div>
      // Customizable Area End
    );
  }
}