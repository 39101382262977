Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "multipageforms2";
exports.labelBodyText = "multipageforms2 Body";
exports.btnExampleTitle = "CLICK ME";

exports.Loginsubmittoken = "bx_block_profile/profiles/currency_list";
exports.SetCurrencyPutapi = "bx_block_profile/profiles/add_currency_to_account";
exports.exampleAPiMethodtoken = "GET";
exports.exampleAPiNewMethodtoken = "GET";
exports.ApiContentType = "account_block/portable_assets";
exports.ShareApi = "account_block/shares";
exports.CashApi = "account_block/cashes";
exports.PensionApi = "account_block/pensions"
exports.StatusApi = "account_block/statuses"
exports.exampleAPi = "POST"
exports.PropertyApi = "account_block/properties";
exports.PropertyTypes = "/bx_block_categories/sub_categories/?name=property";
exports.PortableTypes = "/bx_block_categories/sub_categories/?name=luxury";
exports.languageListLogin = {
  English: {
    placeholderpensionAmount: 'Enter your pension value',
    pensionCurrency: 'Currency',
    StartDate: 'Start Date',
    pensionCurrencyNew: 'Country',
    Companyname: 'Company name',
    placeholderCompanyname: 'Enter share company',
    Numberofshares: 'Number of shares',
    placeholderNumberofshares: 'Enter the no. of shares you hold',
    Price: 'Price',
    placeholderPrice: 'Enter your share value',
    Shares: 'Shares',
    numberOfSharesError: 'Number Of Shares is required',
    priceError: 'Price is required',
    companyNameError: 'Company Name is required',
    newPriceError: 'New Price is required',
    Pensionname: 'Pension name',
    placeholderPensionname: 'Enter pension name',
    placeholderPensionCurrencyNew: 'Enter your property location',
    Location: 'Location',
    Name: 'Name',
    nameError: 'Name is required',
    amountError: 'Amount is required',
    amountDecimalError: 'Required maximum 2 digit after decimal . Not more than that',
    pensionAmount: 'Amount',
    placeholderLocation: 'Enter pension location',
    PensionHader: 'Pension',
    amountAddPension: 'Amount is required',
    pensionNameError: "Pension Name is required",
    enterValidDate: "Please enter a valid date",
    amountRequired:"Amount is a required field",
    amountMustNumber:'Amount must be a number',
    placeholderName: "Give a name for your cash",
    Amount: 'Amount',
    placeholderAmount: 'Enter the amount',
    amountMaxLengthError: "Amount should be less than 11 digit",
    Currency: 'Currency',
    Cash: 'Cash',
    SaveButton: 'Save',
    placeholderPropertyName: 'Give a name to your asset',
    propertyCountry: 'Location Country',
    placeholderPropertyCountry: 'Enter your property location',
    assetCountry: "Select your asset country",
    propertyLocation: 'Location Address',
    placeholderPropertyLocation: 'Enter your property location',
    Communityname: 'Tower/Community name',
    placeholderCommunityname: 'Enter your asset tower/community name',
    ApartmentNumber: 'Apartment Number',
    placeholderApartmentNumber: 'Enter your apartment number',
    FloorNumber: 'Floor Number',
    placeholderFloorNumber: 'Enter floor number',
    AreaSize: 'Area/Size (sq m)',
    placeholderAreaSize: 'Enter your asset area or size',
    propertyNameError: "Property Name is required",
    locationCountryRequired: "Location Country is required",
    Bedrooms: 'Bedrooms',
    Bathrooms: 'Bathrooms',
    CurrencyNew: 'Currency',
    NewPrice: 'New Price',
    placeholderNewPrice: 'Enter your share value (Optional)',
    NewCurrencyOne: 'Currency',
    SaveAddShares: 'Save',
    propertyPrice: 'Purchase Price',
    marketPrice: "Market Value",
    placeholderPropertyPrice: 'Enter purchase value',
    placeholderMarketPrice: "Enter market value (Optional)",
    propertyCurrency: 'Currency',
    propertyMortgage: 'Mortgage',
    propertyMortgageDetails: "Mortgage Details",
    placeholderPropertyMortgage: 'Enter your asset value',
    placeholderMortgageAmount: "Mortgage Amount *",
    placeholderMortgageTerm: "Mortgage term (years) *",
    placeholderMortgageMonth: "Months *",
    placeholderMortgageInterest: "Annual interest rate (%) *",
    placeholderMortgageMonthlyPayment: "Mortgage monthly payments",
    placeholderMortgageStartDate: "Mortgage start date *",
    placeholderMortgageFirstPaymentDate: "First mortgage payment date *",
    date: "Date",
    Status: 'Status',
    UploadaReferenceImage: 'Upload one or multiple property image',
    UploadpictureError: 'Max file size limit 2MB',
    UploadTitleDeed: 'Upload title deed',
    UploadTheTitleDeed: 'Upload the title deed',
    propertyName: 'Property Name is required',
    statusError: 'Status is required',
    priceErrors: 'Price is required',
    priceTypeErrors: 'Purchase Price must be a number',
    priceDecimalErrors: 'Purchase Price Must have two decimal places or fewer',
    marketPriceErrors: 'Market Price is required',
    marketPriceTypeErrors: 'Market Price must be a number',
    marketPriceDecimalErrors: 'Market Price Must have two decimal places or fewer',
    mortgageMonthsDecimalError: 'Mortgage Months must be an integer',
    mortgageMonthsTypeError: 'Mortgage Months must be a number',
    mortageMonthsMinError: "Mortgage Months must be greater than or equal to 0",
    mortgageMonthsMaxError: "Mortgage Months must be less than or equal to 12",
    mortgageInterestDecimalError: 'Mortgage Interest Must have two decimal places or fewer',
    mortgageInterestTypeError: 'Mortgage Interest must be a number',
    mortgageAmountDecimalError: 'Mortgage Amount Must have two decimal places or fewer',
    mortgageAmountTypeError: 'Mortgage Amount must be a number',
    mortgageTermDecimalError: 'Mortgage Term must be an integer',
    mortgageTermTypeError: 'Mortgage Term must be a number',
    loanMonthsDecimalError: 'Loan Months must be an integer',
    loanMonthsTypeError: 'Loan Months must be a number',
    loanInterestDecimalError: 'Loan Interest Must have two decimal places or fewer',
    loanInterestTypeError: 'Loan Interest must be a number',
    loanAmountDecimalError: 'Loan Amount Must have two decimal places or fewer',
    loanAmountTypeError: 'Loan Amount must be a number',
    loanTermDecimalError: 'Loan Term must be an integer',
    loanTermTypeError: 'Loan Term must be a number',
    loanAmountError: 'Loan Amount is required',
    loanTermError: 'Loan Term is required',
    loanMonthsError: 'Months is required',
    loanMonthsMinError: "Loan Months must be greater than or equal to 0",
    loanMonthsMaxError: "Loan Months must be less than or equal to 12",
    loanInterestError: 'Interest is required',
    loanStartDateError: 'Start Date is required',
    loanFirstPaymentError: 'First Payment is required',
    startDateError: "Start date is required",
    NextButton: 'Next',
    purchasePrice: "Purchase Price",
    marketValue: "Market Value",
    locationCountry: "Location Country",
    loan: "Loan",
    loanDetails: "Loan Details",
    loanAmount: "Loan Amount *",
    loanTerm: "Loan term (years) *",
    loanMonths: "Months *",
    loanAnnualInterestRate: "Annual interest rate (%) *",
    loanMonthlyPayments: "Loan monthly payments",
    loanStartDate: "Loan start date *",
    loanFirstPaymentDate: "First loan payment date *",
    PortableAsset: 'Luxury Asset',
    PortableAssetType: 'Luxury Asset type',
    AssetName: 'Asset name',
    placeholderAssetName: 'Give a name to your asset',
    portableAssetPrice: 'Price',
    placeholderportableAssetPrice: 'Enter your asset value',
    portableAssetCurrency: 'Currency',
    portableAssetLoan: 'Loan',
    placeholderportableAssetLoan: 'Enter your loan value',
    portableAssetModel: 'Model',
    placeholderportableAssetModel: 'Enter your asset model name or number',
    DateOfPurchase: 'Date of purchase',
    portableAssetUploadpicture: 'Upload picture',
    placeholderStatus: 'Enter property status',
    Uploadpicture: 'Upload picture(s)',
    Property: 'Property',
    PropertyType: "Property type",
    PropertyName: 'Property name',
    portableAssetUploadPortableAsset: 'Upload a portable asset image',
    portableAssetUploadError: 'Max file size limit 2MB',
    UploadOriginalReceipt: 'Upload original receipt (optional)',
    UploadReceiptDocuments: 'Upload original receipt or other documents',
    UploadWarrantyCertificate: 'Upload warranty or certificate (optional)',
    AssetnameError: 'Asset name is required',
    locationCountryRequired: "Location Country is required",
    portablePriceError: 'Price is required',
    propertyDateReqError: "Purchase Date is required",
    MarketPriceError: "Market Date can't be before Price Date",
    PropertyMarketPriceError: "Market Date can't be before Purchase Date",
    MarketDateReqError: "Market Date is required",
    successfullyAddData: "Your asset is added successfully",
    mortgageAmountError: "Amount is required",
    mortgageTermError: 'Term is required',
    mortgageMonthsError: 'Months is required',
    mortgageInterestError: 'Interest is required',
    mortgageStartDateError: 'Start Date is required',
    mortgageFirstPaymentError: 'First payment date is required'
  },
  Arabic: {
    successfullyAddData: "تمت إضافة الأصول الخاصةبك بنجاح!",
    locationCountryRequired:'مطلوب تحديد البلد',
    Cash: 'نقدي',
    Companyname: 'اسم الشركة',
    placeholderCompanyname: 'أدخل شركة المساهمة',
    Numberofshares:'عدد الحصص',
    placeholderNumberofshares: 'أدخل عدد الحصص التي تمتلكها',
    Price: 'سعر',
    placeholderPrice: 'أدخل قيمة الحصص',
    CurrencyNew: 'عملة',
    NewPrice: 'سعر جديد',
    placeholderNewPrice: 'أدخل قيمة الحصص',
    NewCurrencyOne: 'عملة',
    SaveAddShares: 'يحفظ',
    Shares: 'الاسهم',
    placeholderPropertyPrice: 'أدخل قيمة الشراء',
    placeholderMarketPrice: 'أدخل القيمة السوقية',
    propertyCurrency: 'عملة',
    propertyMortgage: 'القرض العقاري',
    propertyMortgageDetails: 'تفاصيل الرهن العقاري',
    placeholderPropertyMortgage: 'أدخل قيمة الأصول الخاصة بك',
    placeholderMortgageAmount: 'Mortgage Amount *',
    placeholderMortgageTerm: 'مدة الرهن العقاري (سنوات) *',
    placeholderMortgageMonth: 'شهور *',
    placeholderMortgageInterest: 'معدل الفائدة السنوي (٪) *',
    placeholderMortgageMonthlyPayment: "أقساط الرهن العقاري الشهرية",
    placeholderMortgageStartDate: 'تاريخ بدء الرهن العقاري *',
    placeholderMortgageFirstPaymentDate: 'تاريخ سداد الرهن العقاري الأول *',
    enterValidDate: "الرجاء إدخال تاريخ صالح",
    date: 'تاريخ',
    Status: 'حالة',
    pensionAmount: 'كمية',
    placeholderpensionAmount: 'أدخل قيمة سهمك',
    pensionCurrency: 'عملة',
    StartDate: 'تاريخ البدء',
    pensionCurrencyNew: 'دولة',
    Name: 'اسم',
    placeholderName: "إعطاء اسم لأموالك",
    Amount: 'كمية',
    placeholderAmount: 'أدخل المبلغ',
    Currency: 'عملة',
    CashNew: 'نقدي',
    SaveButton: 'حفظ',
    nameError: 'مطلوب اسم',
    amountError: 'المبلغ مطلوب',
    placeholderPensionCurrencyNew: 'أدخل موقع العقار الخاص بك',
    Location: 'موقع',
    placeholderLocation: 'إعطاء اسم لموقعك',
    PensionHader: 'مَعاش',
    amountAddPensionError: 'المبلغ مطلوب',
    amountMustNumber:'يجب أن يكون المبلغ رقمًا',
    amountRequired:"المبلغ هو حقل مطلوب",
    pensionNameError: 'اسم المعاش مطلوب',
    Property: 'ملكية',
    placeholderportableAssetModel: 'أدخل اسم نموذج الأصل الخاص بك أو رقمه',
    DateOfPurchase: 'تاريخ الشراء',
    portableAssetUploadpicture: 'تحميل صورة',
    portableAssetUploadPortableAsset: 'قم بتحميل صورة الأصول المحمولة',
    portableAssetUploadError: 'الحد الأقصى لحجم الملف 5 ميجابايت',
    PropertyType: 'نوع الملكية',
    UploadaReferenceImage: 'قم بتحميل صورة ملكية واحدة أو عدة صور',
    UploadpictureError: 'الحد الأقصى لحجم الملف 5 ميجابايت',
    UploadTitleDeed: 'تحميل سند الملكية',
    UploadTheTitleDeed: 'قم بتحميل سند الملكية',
    PropertyName:  'اسم العقار',
    placeholderPropertyName: 'اختر اسم لأصولك',
    propertyCountry: 'البلد الموقع',
    placeholderPropertyCountry: 'أدخل موقع العقار الخاص بك',
    assetCountry: "حدد بلد الأصول الخاصة بك",
    propertyLocation: 'عنوان المنطقة',
    placeholderPropertyLocation: 'أدخل موقع العقار الخاص بك',
    Communityname: 'اسم البرج/المجتمع',
    numberOfSharesError: 'عدد الأسهم مطلوب',
    priceError: 'السعر مطلوب',
    companyNameError: 'اسم الشركة مطلوب',
    newPriceError: 'مطلوب سعر جديد',
    Pensionname: 'اسم المعاش',
    placeholderPensionname: 'أدخل اسم المعاش',
    placeholderCommunityname: 'أدخل اسم برج الأصول/المجتمع الخاص بك',
    ApartmentNumber: "رقم العقار",
    placeholderApartmentNumber: 'أدخل رقم عقارك',
    Bedrooms: 'غرف نوم',
    Bathrooms: 'الحمامات',
    propertyPrice: 'سعر الشراء',
    marketPrice: 'القيمة السوقية',
    placeholderStatus: 'أدخل حالة العقار',
    Uploadpicture: 'تحميل الصور)',
    FloorNumber: 'رقم الدور',
    placeholderFloorNumber: 'أدخل رقم الطابق',
    AreaSize: 'المساحة/الحجم (متر مربع)',
    placeholderAreaSize: 'أدخل منطقة الأصول الخاصة بك أو حجمها',
    propertyNameError: 'اسم العقار مطلوب',
    locationCountryRequired:'مطلوب تحديد البلد',
    statusError: 'الحالة مطلوبة',
    priceErrors: 'السعر مطلوب',
    priceTypeErrors: 'يجب أن يكون سعر الشراء رقمًا',
    priceDecimalErrors: 'يجب أن يكون سعر الشراء يحتوي على رقمين عشريين أو أقل',
    marketPriceErrors: 'السعر السوقي مطلوب',
    marketPriceTypeErrors: 'يجب أن يكون السعر السوقي رقمًا',
    marketPriceDecimalErrors: 'يجب أن يكون السعر السوقي يحتوي على رقمين عشريين أو أقل',
    mortgageAmountTypeError: 'يجب أن يكون مبلغ الرهن العقاري رقمًا',
    mortgageAmountDecimalError: 'يجب أن يكون مبلغ الرهن العقاري يحتوي على رقمين عشريين أو أقل',
    mortgageTermTypeError: 'يجب أن تكون مدة الرهن العقاري رقمًا',
    mortgageTermDecimalError: 'يجب أن يكون مصطلح الرهن العقاري عددا صحيحا',
    mortgageMonthsTypeError: 'يجب أن تكون الشهور الخاصة بالرهن العقاري رقمًا',
    mortageMonthsMinError: "الأشهر المتبقية للقرض يجب أن تكون أكبر من أو تساوي 0",
    mortgageMonthsMaxError: "الأشهر المتبقية للقرض يجب أن تكون أقل من أو تساوي 12",
    mortgageMonthsDecimalError: 'يجب أن تكون أشهر الرهن العقاري عددًا صحيحًا',
    mortgageInterestTypeError: 'يجب أن يكون معدل الفائدة السنوي للرهن العقاري رقمًا',
    mortgageInterestDecimalError: 'يجب أن يكون معدل الفائدة السنوي للرهن العقاري يحتوي على رقمين عشريين أو أقل',
    loanAmountTypeError: 'يجب أن يكون مبلغ القرض رقمًا',
    loanAmountDecimalError: 'يجب أن يكون مبلغ القرض يحتوي على رقمين عشريين أو أقل',
    loanTermTypeError: 'يجب أن تكون مدة القرض رقمًا',
    loanAmountError: 'مبلغ القرض مطلوب',
    loanTermError: 'مدة القرض مطلوبة',
    loanMonthsError: 'عدد الشهور مطلوب',
    loanMonthsMinError: "الأشهر المتبقية للقرض يجب أن تكون أكبر من أو تساوي 0",
    loanMonthsMaxError: "الأشهر المتبقية للقرض يجب أن تكون أقل من أو تساوي 12",
    loanInterestError: 'معدل الفائدة مطلوب',
    loanStartDateError: 'تاريخ البدء مطلوب',
    loanFirstPaymentError: 'الدفعة الأولى مطلوبة',
    loanTermDecimalError: 'يجب أن تكون مدة القرض عددًا صحيحًا',
    loanMonthsTypeError: 'يجب أن تكون الشهور الخاصة بالقرض رقمًا',
    loanMonthsDecimalError: 'يجب أن تكون أشهر القرض عددًا صحيحًا',
    loanInterestTypeError: 'يجب أن يكون معدل الفائدة السنوي للقرض رقمًا',
    loanInterestDecimalError: 'يجب أن يكون معدل الفائدة السنوي للقرض يحتوي على رقمين عشريين أو أقل',
    NextButton: 'التالي',
    purchasePrice: "سعر الشراء",
    marketValue: "قيمة السوق",
    locationCountry: "بلد الموقع",
    loan: "قرض",
    loanDetails: "تفاصيل القرض",
    loanAmount: "مبلغ القرض *",
    loanTerm: "مدة القرض (سنوات) *",
    loanMonths: "الشهور *",
    loanAnnualInterestRate: "معدل الفائدة السنوي للقرض (%) *",
    loanMonthlyPayments: "الأقساط الشهرية للقرض",
    loanStartDate: "تاريخ بدء القرض *",
    loanFirstPaymentDate: "تاريخ أول دفعة للقرض *",
    PortableAsset: 'الأصول المحمولة',
    AssetName: 'اسم الأصل',
    placeholderAssetName: 'اختر اسم لأصولك',
    portableAssetPrice: 'سعر',
    placeholderportableAssetPrice: 'أدخل قيمة الأصول الخاصة بك',
    portableAssetCurrency: 'عملة',
    portableAssetLoan: 'يُقرض',
    PortableAssetType: 'نوع الأصول المحمولة',
    placeholderportableAssetLoan: 'أدخل قيمة القرض الخاص بك',
    portableAssetModel: 'نموذج',
    UploadOriginalReceipt: 'تحميل الإيصال الأصلي (اختياري)',
    UploadReceiptDocuments: 'قم بتحميل الإيصال الأصلي أو المستندات الأخرى',
    UploadWarrantyCertificate: 'تحميل الضمان أو الشهادة (اختياري)',
    AssetnameError: 'اسم الأصل مطلوب',
    portablePriceError: 'السعر مطلوب',
    propertyDateReqError: "تاريخ الشراء مطلوب",
    MarketDateReqError: "تاريخ السوق مطلوب",
    MarketPriceError: 'لا يمكن أن تكون بيانات السوق قبل تاريخ السعر',
    PropertyMarketPriceError: 'لا يمكن أن يكون تاريخ السوق قبل تاريخ الشراء',
    mortgageAmountError: 'المبلغ مطلوب',
    mortgageTermError: 'المصطلح مطلوب',
    mortgageMonthsError: 'الأشهر مطلوبة',
    mortgageInterestError: 'الفائدة مطلوبة',
    mortgageStartDateError: 'تاريخ البدء مطلوب',
    startDateError: "تاريخ البدء مطلوب",
    mortgageFirstPaymentError: 'مطلوب تاريخ الدفع الأول'
  }
}
// Customizable Area End