import React from "react";

// Customizable Area Start
import { Link } from 'react-router-dom'
import '../assets/css/myneSettingsResetPassword.css'
// Customizable Area End

import MyneSettingsController, {
  Props,
} from "./MyneSettingsController.web";

export default class MyneSettingsResetPassword extends MyneSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  roundedResetPassword = () => {
    return this.state.logoImgsetting === "rtl" ? "rs-close-rounded rs-close-rounded-new" : "rs-close-rounded"
  }
  passwordVisible1 = () => {
    return this.state.logoImgsetting === "ltr" ? "passwordVisible1" : "passwordVisible1-new"
  }
  resetpassword = () => {
    return this.state.logoImgsetting === "ltr" ? "reset-password-code reset-input-top-margin reset-password-code-new" : "reset-password-code reset-input-top-margin"
  }
  resetpasswordcode = () => {
    return this.state.logoImgsetting === "ltr" ? "reset-password-code reset-forget-password-text" : "reset-password-code reset-forget-password-text-new"
  }
  // Customizable Area End 

  render() {
    return (
      // Customizable Area Start
      <div className="myne-reset-password-container" dir={this.state.logoImgsetting}>
        <div className="setting-reset-password-container">
          <div className="rs-small-text">{this.getStringGoalmanagement("AccountDetails")}
            <Link to={'/Dashboard'} className={this.roundedResetPassword()}>
              <svg xmlns="http://www.w3.org/2000/svg" height="100%" fill="#ffffff" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
            </Link>
          </div>
          <div className="rs-small-text rs-bold-text">{this.getStringGoalmanagement("ResetPassword")}</div>
          <div className="rs-password-container">
            {this.state.resetSuccessMessage && <span className="rs-detail-small-text reset-text-color">{this.state.resetSuccessMessage}</span>}
            {(this.state.resetCurrentErrorMessage || this.state.resetNewErrorMessage || this.state.resetConfirmPasswordErrorMessage) && <div className="rs-detail-small-text errorMsgs">{this.state.resetCurrentErrorMessage || this.state.resetNewErrorMessage || this.state.resetConfirmPasswordErrorMessage}</div>}
            <div className="reset-password-code" style={{ marginTop: 7 }}>
              <input
                type={this.state.isOldResetPasswordVisible ? "text" : "password"}
                className={`reset-input-box ${this.state.resetCurrentErrorMessage ? 'error-border' : ''}`}
                placeholder={this.getStringGoalmanagement("CurrentPasswordplaceholder")}
                data-test-id='current-password-reset'
                onChange={(e) => this.handleInputChange(e.target.value, 'resetCurrentPassword')} value={this.state.resetCurrentPassword}
              />
              {!this.state.isOldResetPasswordVisible ? (
                <svg data-test-id='current-password-visible' className={this.passwordVisible1()} onClick={() => this.handleInputChange(!this.state.isOldResetPasswordVisible, 'isOldResetPasswordVisible')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              ) : (
                <svg data-test-id='current-password-visible1' className={this.passwordVisible1()} onClick={() => this.handleInputChange(!this.state.isOldResetPasswordVisible, 'isOldResetPasswordVisible')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              )}
              <br />
            </div>

            <div className="reset-password-code reset-input-top-margin ">
              <input type={this.state.isNewesetPasswordVisible ? "text" : "password"}
                className={`reset-input-box ${this.state.resetNewErrorMessage ? 'error-border' : ''}`}
                placeholder={this.getStringGoalmanagement("NewPasswordplaceholder")}
                data-test-id='new-password-reset'
                onChange={(e) => this.handleInputChange(e.target.value, 'resetNewPassword')} value={this.state.resetNewPassword} />
              {!this.state.isNewesetPasswordVisible ? (
                <svg data-test-id='new-password-visible' className={this.passwordVisible1()} onClick={() => this.handleInputChange(!this.state.isNewesetPasswordVisible, 'isNewesetPasswordVisible')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              ) : (
                <svg data-test-id='new-password-visible1' className={this.passwordVisible1()} onClick={() => this.handleInputChange(!this.state.isNewesetPasswordVisible, 'isNewesetPasswordVisible')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              )}
            </div>

            <div className={this.resetpassword()}>
              <input type={this.state.isConfirmNewResetPasswordVisible ? "text" : "password"}
                className={`reset-input-box ${this.state.resetConfirmPasswordErrorMessage ? 'error-border' : ''}`}
                placeholder={this.getStringGoalmanagement("ConfirmNewPasswordplaceholder")}
                data-test-id='confirm-new-password-reset'
                onChange={(e) => this.handleInputChange(e.target.value, 'resetConfirmPassword')} value={this.state.resetConfirmPassword}

              />
              {!this.state.isConfirmNewResetPasswordVisible ? (
                <svg data-test-id='confirm-password-visible' className={this.passwordVisible1()} xmlns="http://www.w3.org/2000/svg" onClick={() => this.handleInputChange(!this.state.isConfirmNewResetPasswordVisible, 'isConfirmNewResetPasswordVisible')} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              ) : (
                <svg data-test-id='confirm-password-visible1' className={this.passwordVisible1()} xmlns="http://www.w3.org/2000/svg" onClick={() => this.handleInputChange(!this.state.isConfirmNewResetPasswordVisible, 'isConfirmNewResetPasswordVisible')} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              )}
            </div>

            <span 
              onClick={this.handleGotoForgotPassword}
              data-test-id="forgotPasswordBtn"
              className={this.resetpasswordcode()}>{this.getStringGoalmanagement("ForgotPassword")}</span>

            <div data-test-id="reset-password-btn" onClick={() => this.onResetPasswordFormSubmit()}
              className={`reset-confirm-password ${!this.onValidateResetForm() ? 'btn-disable' : ''}`}>
              <div className="btn-reset-confirm">{this.getStringGoalmanagement("ConfirmPassword")}</div>
            </div>

          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}