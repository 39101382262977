Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "questionbank";
exports.labelBodyText = "questionbank Body";

exports.btnExampleTitle = "CLICK ME";


exports.languageListQuestion = {
  English: {
    EstatePlanning:'Estate Planning',
    BeginQue:'Begin Questionnaire ?',
    BeginQuestionsNote:'Begin with answering set of questions to help us find the right wealth planner for you',
    FinancialAdvisor:'Financial Advisor Questionnaire',
    Next:"Next",
    Submit:'Submit',
    Description:'Description',
    SelectAnswer:'Please select answer',
    Curating:"We're curating the",
    BestResult:'best results for you now!',
    OneMoment:'just one moment.',
  },
  Arabic:{
    OneMoment:'انتظر قليلا',
    Curating:"نحن نقوم برعاية",
    BestResult:'أفضل النتائج بالنسبة لك الآن!',
    SelectAnswer:'الرجاء تحديد الإجابة',
    FinancialAdvisor:'استبيان المستشار المالي',
    Next:"التالي",
    Description:'وصف',
    Submit:'يُقدِّم',
    BeginQue:'ابدأ الاستبيان؟',
    EstatePlanning:'التخطيط العقاري',
    BeginQuestionsNote:'ابدأ بالإجابة على مجموعة من الأسئلة لمساعدتنا في العثور على مخطط الثروة المناسب لك',

  }
}
// Customizable Area End