import React from "react";
// Customizable Area Start
import "./style.css";
import {

  sidearrow1

} from "./assets";
import { NavLink } from "react-router-dom";
import { Box, styled } from "@material-ui/core";

// Customizable Area End
import QuestionbankController, {
  Props,
} from "./QuestionbankController";
export default class Questionbank extends QuestionbankController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDir =()=>{
    return this.state.direction === 'rtl' ? 'rtl' : 'ltr'
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div className="questionnaire-wrapper" style={{ height: "100vh" }} dir={this.renderDir()}>
        <div className="page1-title">
          <h4>{this.getStringQuestion('EstatePlanning')}</h4>
        </div>
        <br/>
        <ContentWrapper className="content-wrapper">
          <div className="page1-title">
            <p>{this.getStringQuestion('BeginQuestionsNote')}
            </p>
          
          </div>
          <br/>
          <NavLink to="/FinancialQuestion">
          <div style={{display:"flex",paddingInline:"20px",  background: '#395D6D',color:'white', borderRadius: 10, justifyContent: 'space-between', marginTop: 10, alignItems: 'center', padding:'1rem'}}>
            <p><b>  {this.getStringQuestion('BeginQue')}</b></p>
            <div className="down-arrow">
              
              <img
                src={
                  sidearrow1
                }
                style={{transform: this.state.direction === 'rtl' ? 'scaleX(-1)':''}}
              />
             
            </div>
          </div>
          </NavLink>
        
        </ContentWrapper>
      </div>


    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ContentWrapper = styled(Box)({
  width: "43%",
  "@media (max-width: 767px)": {
      width: "100%"
  }
})
// Customizable Area End
