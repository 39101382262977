export default function getPerformanceAssetDistribution(asset: string) {

    let assetName = '';
    switch (asset.toLocaleLowerCase()) {
        case "bank":
            assetName = "Bank";
            break;
        case "bank accounts":
            assetName = "Bank";
            break;
        case "share":
            assetName = "Shares%20%26%20Funds";
            break;
        case "stock":
            assetName = "Stocks";
            break;
        case "stocks":
            assetName = "Stocks";
            break;
        case "crypto currency":
            assetName = "Crypto";
            break;
        case "crypto":
            assetName = "Crypto";
            break;
        case "portable":
            assetName = "Luxury";
            break;
        case "cash":
            assetName = "Cash";
            break;
        case "property":
            assetName = "Property";
            break;
        case "pension":
            assetName = "Pension";
            break;
        case "liability":
            assetName = "Liabilities";
            break;
        case "commodity":
            assetName = "Commodities";
            break;
        case "shares & funds":
            assetName = "Shares%20%26%20Funds";
            break;
        default:
            assetName = asset;
    }
    return assetName;
}