import React, { Component } from 'react';
const pendingIcon = require("./image_hourglass.png");



interface PendingBudgetPageProps {
  direction?: string;
}

class PendingBudgetPage extends Component<PendingBudgetPageProps> {
  render() {
    const { direction = "ltr"  } = this.props;

    return (
        <div dir={direction} style={{ width: '100%', height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

            <img width={100} src={pendingIcon} alt="" />
            {
                direction == 'rtl'
                    ? <p style={{ color: '#fff', textAlign: "center" }}>
                    <strong> جاري جلب المعاملات الخاصة بك. </strong><br />
                    وفي الوقت نفسه، يرجى استكشاف التطبيق</p>
                    :
                    <p style={{ color: '#fff', textAlign: "center" }}>
                       <strong> Your transactions are being fetched. </strong><br />
                       Meanwhile, Please explore the app</p>
            }
        </div>
    );
  }
}

export default PendingBudgetPage;
