// Customizable Area Start
export const History = require("../assets/History.png");
export const Close = require("../assets/Close.png");
export const location = require("../assets/location.png");
export const imgLocation = require("../assets/location.png");
export const imgLogo = require("../assets/logo.png");
export const imgsplash = require("../assets/splash.png");
export const imgambarsar = require("../assets/ambarsar.png");
export const imgsurat = require("../assets/surat.png");
export const Maplogo = require("../assets/Map.png");
export const Plus = require("../assets/Plus.png");
export const startpin = require("../assets/Start_pin.png");
export const destinationPin = require("../assets/destination_pin.png");
export const Flag = require("../assets/Flag.png");
export const PlusIcon = require("../assets/plus_icon.png");
export const ViewDp = require("../assets/view_Dp.png");
export const eye = require("../assets/eye.png");
export const openIcon = require("../assets/openIcon.png");
export const UpArrow = require("../assets/upArrow.png");
export const image_Imagenav_backicon = require("../assets/image_Imagenav_backicon.png");
export const groupArrow = require("../assets/group_.svg");
export const close = require("../assets/close1.png");
export const SuccessCard = require("../assets/cr copy.png");
export const apple = require("../assets/apple.png");
export const plus1 = require("../assets/plus1.png");
export const card = require("../assets/card.png");
export const modelSuccess = require("../assets/cr.png");
export const groupArrow11 = require("../assets/group_11.svg");
export const groupArrowOrange = require("../assets/group_orange.svg");
export const info = require("../assets/info.png");

export const arrow = require("../assets/arrow.png")
export const arrowDown = require("../assets/arrow_down.png")


export const warning1 = require("../assets/warning(1).png");
export const arrowRight = require("../assets/arrow-right.png");
export const arrowUp = require("../assets/arrow-up.png");
export const selectedRound = require("../assets/selected-round.png");
export const unSelectedRound = require("../assets/un-selected-round.png");

export const line = require("../assets/line.png");
export const clientImage = require("../assets/client-image.png");
export const startSelect = require("../assets/star-selected.png");
export const starUnSelect = require("../assets/star-unselected.png");

export const notAvailable = require("../assets/notAvailableFinal.png");

export const checkedIcon = require("../assets/checkedIcon.svg");
export const uncheckedIcon = require("../assets/uncheckedIcon.svg");
export const threeDots = require("../assets/threedots.svg");
export const settingicon = require("../assets/settingicon.png");


// Customizable Area End
