import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  arrowDown,
  arrowUp,
  selectedRound,
  unSelectedRound,
  line,
} from "../../blocks/formapprovalworkflow/src/assets";
import { ISerivce } from "../../blocks/formapprovalworkflow/src/FormApprovalWorkflowController.web";

const ServiceDetails: React.FC<{
  service: ISerivce;
  direction:String
}> = ({ service, direction='ltr' }) => {
  const [canViewStatus, setCanViewStatus] = useState<boolean>(true);
  return (
    <Box >
      <div style={{ display: 'flex', alignItems: 'center', gap:'16px' }}>
        <div style={{ alignSelf: 'center' }}>
          <img style={webstyles.serviceImg} src={service.image} alt="" />
        </div>
        <div>
          <Typography style={webstyles.title}>{service.name}</Typography>
          <Typography style={webstyles.name}>{service.wealth_planner_name}</Typography>
        </div>
      </div>
      <Box
        style={{ display: "flex", justifyContent: "space-between", marginTop: "26px", }}
      >
        <Typography style={webstyles.serviceStatusTitle}>
          {direction==='ltr'?'Service Status':'حالة الخدمة'}
        </Typography>
        <div onClick={() => setCanViewStatus(!canViewStatus)}>
          {!canViewStatus ? (
            <img style={webstyles.arrowRightStyle} src={arrowDown} alt="" />
          ) : (
            <img style={webstyles.arrowUpStyle} src={arrowUp} alt="" />
          )}
        </div>
      </Box>
      {canViewStatus && (
        <Box
          style={{
            marginTop: "26px",
            marginBottom: "12px",
          }}
        >
          {service.plan_inclusions.map((planInclusion, index) => {
            return (
              <>
                <Grid container wrap="nowrap">
                  <Grid item>
                    <div style={{height:'26px', width:'26px'}}>
                      <img
                        src={
                          planInclusion.status ? selectedRound : unSelectedRound
                        }
                        style={{width:'26px', height:'26px'}}
                        alt=""
                      />
                    </div>
                  </Grid>
                  <Grid item style={{...webstyles.serviceStatusContainer, flexGrow:1, width:'calc(100% - 152px)'}}>
                  <Tooltip title={planInclusion.name} placement="top-end">
                    <Typography
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        ...(planInclusion.status
                          ? webstyles.serviceCompletedStyle
                          : webstyles.serviceInCompletedStyle)
                      }}
                    >
                      {planInclusion.name}
                    </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{ marginLeft: "12px" }}>
                    <Typography noWrap
                      style={
                        planInclusion.status
                          ? webstyles.serviceCompletedStyle
                          : webstyles.serviceInCompletedStyle
                      }
                    >
                      {planInclusion.date}
                    </Typography>
                  </Grid>
                </Grid>
                {index + 1 !== service.plan_inclusions.length && (
                  <Grid container>
                    <Grid item>
                      <div>
                        <img style={{...webstyles.lineStyle,margin:`${direction==='ltr'?'0 0 0 12px':'0 12px 0 0'}`}} src={line} alt="" />
                      </div>
                    </Grid>
                  </Grid>
                )}
              </>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

type IStyle = { [key: string]: React.CSSProperties };

const webstyles: IStyle = {
  container: {
    border: "1px solid #8f99a3",
    padding: "16px",
    fontFamily: "Poppins",
    borderRadius: "12px",
  },
  title: {
    fontSize: "18px",
    color: "#fff",
    fontFamily: "Poppins",
  },
  name: {
    color: "#8f99a3",
    fontSize: "16px",
    borderBottom: "1px solid #8f99a3",
    width:"max-content",
    fontFamily: "Poppins",

  },
  serviceStatusTitle: {
    fontSize: "17px",
    color: "#fff",
    fontFamily: "Poppins",
  },
  serviceCompletedStyle: {
    fontSize: "18px",
    color: "#fff",
    fontFamily: "Poppins",
  },
  serviceInCompletedStyle: {
    fontSize: "18px",
    color: "#8f99a3",
    fontFamily: "Poppins",
  },
  serviceStatusContainer: {
    marginLeft: "12px",
  },
  arrowRightStyle: {
    paddingTop: "8px",
    marginLeft: "12px",
  },
  arrowUpStyle: {
    paddingTop: "8px",
    marginLeft: "12px",
  },
  lineStyle: {
    width: "2px",
    height: "16px",
  },
  serviceImg: {
    height: "24px",
    width: "24px",
  },
};

export default ServiceDetails;
