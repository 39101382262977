import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { arro, setting, bank, money, netverk, img1, img2, img3, img4, img5, img6, imagenavBitmap ,commodityImg,liablityImg} from "./assets";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  selectCurrency: { value: string, label: string },
  currencyList: any,
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  arro: string;
  setting: string;
  assetType: { title: string, img: string, Content: string } [];
  currency_name: any
  logoImg1Login: string,
  addAssets: string,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUserSelectDataCallId: any;
  postUserPDFDataCallId: any;
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  validationApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      logoImg1Login: localStorage.getItem('language') == "Arabic" ? "rtl" : "ltr",
      token: "",
      categoriesArray: [],
      selectCurrency: localStorage.getItem('language') == "Arabic" ? { label: '', value: '' } : { value: '', label: '' },
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      currencyList: [],
      arro: localStorage.getItem('language') !== "Arabic" ? arro : imagenavBitmap,
      setting: setting,
      assetType: localStorage.getItem('language') !== "Arabic" ?
      [{ title: "Bank Accounts", img: bank, Content: "Add your bank accounts for all your debit and credit cards and track your net worth" },
      { title: "Cash", img: money, Content: "Add your cash and accounts not in MYNE to track your investments" },
      { title: "Stocks", img: netverk, Content: "Add your stock tickers or link your stock wallet to track your investment in one place" },
      { title: "Shares & Funds", img: img1, Content: "Add your shares to track your investment in one place" },
      { title: "Crypto Currency", img: img2, Content: "Add your crypto tickers or link your crypto wallet to track your investment in one place" },
      { title: "Property", img: img4, Content: "Add your bank accounts for all your debit and credit cards and track your net worth" },
      { title: "Luxury", img: img5, Content: "Add all your luxury assets and track its value to know your net worth" },
      { title: "Pension", img: img6, Content: "Link your pension aaccounts and track your investments in one place" },
       this.getLiability(),
      { title: "Commodities", img: commodityImg, Content: "Add all your commodities and track its value to know your net worth" },
      ]: [{ title : "حسابات مصرفية", img : bank, Content : "أضف حساباتك المصرفية لجميع بطاقات الخصم وبطاقات الائتمان الخاصة بك وتتبع صافي ثروتك" },
      { title : "نقد", img : money, Content: "أضف أموالك وحساباتك غير الموجودة في MYNE لتتبع استثماراتك" },
      { title : "أسهم", img : netverk, Content: "أضف مؤشرات الأسهم الخاصة بك أو اربط محفظة الأسهم الخاصة بك لتتبع استثمارك في مكان واحد" },
      { title : "حصص", img : img1, Content: "أضف أسهمك لتتبع استثمارك في مكان واحد" },
      { title : "عملة مشفرة", img: img2, Content: "أضف مؤشرات العملات المشفرة الخاصة بك أو اربط محفظتك المشفرة لتتبع استثمارك في مكان واحد" },
      { title : "عقار", img: img4, Content: "أضـــِف عقـــَارَاتِكَ وتَتَبَّع قيمَتَهَا لمعرفة صافي ثروتك" },
      { title : "نقول", img:img5, Content: "قم بإضافة جميع الأصول الفاخرة الخاصة بك وتتبع قيمتها لمعرفة صافي ثروتك" },
      { title : "معاش تقاعدي", img:img6, Content: "اربط حسابات المعاش التعاقدي الخاص بك وتتبع استثماراتك في مكان واحد" },
      { title : "مسئولية قانونية", img:liablityImg, Content: "قم بإضافة جميع التزاماتك وتتبع قيمتها لمعرفة صافي ثروتك" },
      this.getCommodity()
      ],
      currency_name: "",
      addAssets: localStorage.getItem('language') !== "Arabic" ? "Add Assets" : "إضافة أصول"
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  validation = (value: any) => {
    if (['Bank Accounts', 'حسابات مصرفية'].includes(value.title)) {
      this.props.navigation.navigate('Cradditionalformsformschangerequests');
    } else if (['Cash', 'نقد'].includes(value.title)) {
      this.props.navigation.navigate('AddCashWeb');
    } else if (['Stocks', "أسهم"].includes(value.title)) {
      this.props.navigation.navigate('StockSelection');
    } else if (['Shares & Funds', "حصص"].includes(value.title)) {
      const navMessage = new Message(getName(MessageEnum.NavigationMessage));
      navMessage.addData(getName(MessageEnum.NavigationTargetMessage), "AddSharesWeb");
      navMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
      );
      this.send(navMessage) 
    } 
    else if (['Commodities', "السلع"].includes(value.title)) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "AddCommodity");
      message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
      );
      this.send(message) 
    } 
    else if (['Liabilities', "مسئولية قانونية"].includes(value.title)) {
      const navMessage = new Message(getName(MessageEnum.NavigationMessage));
      navMessage.addData(getName(MessageEnum.NavigationTargetMessage), "AddLiabilityWeb");
      navMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
      );
      this.send(navMessage) 
    } 
    else {
      this.cryptoCurrency(value)
    }
  };

  getLiability(){
    return  ({ title: "Liabilities", img: liablityImg, Content: "Add all your liabilities and track its value to know your net worth" })
  }

  getCommodity(){
    return({ title : "السلع", img: commodityImg, Content: "أضف جميع سلعك وتتبع قيمتها لتعرف صافي ثروتك" })
  }

  cryptoCurrency = (value:any) => {
    if (['Crypto Currency', 'عملة مشفرة'].includes(value.title)) {
      this.props.navigation.navigate('CryptoSelection');
    } else if (['NFT', "رمز غير قابل للاستبدال"].includes(value.title)) {
      this.props.navigation.navigate('');
    } else if (['Property', "عقار"].includes(value.title)) {
      this.props.navigation.navigate('AddPropertyweb');
    } else if (['Portable',"Luxury", "نقول"].includes(value.title)) {
      this.props.navigation.navigate('PortableAssetweb');
    } else if (['Pension', "معاش تقاعدي"].includes(value.title)) {
      this.props.navigation.navigate('AddPensionweb');
    }
  }

  addCategorySelect = () => {
    return (<FormControl>
      <Select options={this.state.currency_name} value={this.state?.selectCurrency} onChange={(val: any) => { this.csasSetCurrency(val) }} classNamePrefix="an-simple-selectUSDNew" className="selectUSDNew" data-test-id="selectMenu" />
    </FormControl>)
  }

  dashboardNavigation = () => {
    return this.props.navigation.navigate('Dashboard')
  }

  setCurrencyPutapi = (currencyId: string) => {
    const casHeader = { "token": this.state.token };
    let casUserDetailurl = configJSON.SetCategoriessPutapi;
    const formData = new FormData();
    formData.append("currency_id", currencyId);
    const casRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postUserSelectDataCallId = casRequestMessage.messageId;
    casRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      casUserDetailurl
    );
    casRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(casHeader)
    );
    casRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    casRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(casRequestMessage.id, casRequestMessage);
  }

  csasSetCurrency = (value: { value: string, label: string }) => {
    const currencyId = this.state.currencyList.list.find((newValue: any) => {
      return newValue.currency_type == value.value
    })
    this.setCurrencyPutapi(currencyId?.id)
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserPDFDataCallId !== null &&
      this.postUserPDFDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.list) {
        const userSelectedCurrncy = responseJson.list.find((value: any) => {
          return value.id == responseJson.selection
        })

        const currency = responseJson.list.map((value: any) => (
          { value: value.currency_type, label: value.currency_type }
        ));
        this.setState({
          currency_name: currency, currencyList: responseJson,
          selectCurrency: { value: userSelectedCurrncy.currency_type, label: localStorage.getItem('language') == "Arabic" ? `${userSelectedCurrncy.currency_type}${userSelectedCurrncy.symbol}` : `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` },
        });

      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserSelectDataCallId !== null &&
      this.postUserSelectDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let cashResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (cashResponseJson.meta.currency) {
        this.getPensionList()
      }
    }
    // Customizable Area End
  }

  getPensionList = () => {
    const headerToken: any = localStorage.getItem("token")
    const header = { "token": headerToken };
    let userDetailurl = configJSON.Loginsubmittokennew;
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethodtokenNew,
    });
    this.setState({ token: headerToken })
    this.postUserPDFDataCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }
  async componentDidMount() {
    this.getPensionList();
    
 
  }

  // Customizable Area End

}
