import React from "react";

// Customizable Area Start
import "./style.css";
import {
  Box, Typography,
  Grid,
  Button,
} from "@material-ui/core";

import UserProfile from "../../../components/src/UserProfile.web";
import { formatNumberWithCommas } from "../../../components/src/commonFn";
import { image_Imagenav_backicon } from "./assets";
import { styled } from "@material-ui/styles";

interface EstatePlannerQuestionAnswers { 
  id: string; type: string; answer: string[]; text: string; user_input: boolean; options: string[] 
}
// Customizable Area End

import ClientDetailsController, {
  Props,
  configJSON,
} from "./ClientDetailsController.web";

export default class ClientDetails extends ClientDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  
  getValueFN = (condition: string | boolean | null, truthyValue: string | null | undefined) => {
    return condition ? truthyValue : "N/A"
  }
  renderPaymentDetails = () => {
    if (this.state.clientDetails && this.state.clientDetails.attributes.payment !== "0 AED") {
      return (
        <>
          <Grid item xs={12} sm={3} className="formLabel">Payment:</Grid>
          <Grid item xs={12} sm={9} className="formValue">{this.state.clientDetails?.attributes.payment}</Grid>
          <Grid item xs={12} sm={3} className="formLabel">Payment Date:</Grid>
          <Grid item xs={12} sm={9} className="formValue">{this.state.clientDetails.attributes.payment_date}</Grid>
        </>
      )
    }
  }
  renderServices = () => {
    if (this.state.clientDetails && this.state.clientDetails.attributes.services.length > 0) {
      return (
        <>
          <Grid item xs={12} sm={3} className="formLabel">Services:</Grid>
          <Grid item xs={12} sm={9} className="formValue">
            {this.state.clientDetails.attributes.services.map(values => (
              <Box>{values}</Box>
            ))}
          </Grid>
        </>
      )
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="pagedashboard-wrapper2">
        <ThemeWrapper>
          {configJSON.userName}
          <Grid container className="headerContainer">
            <Grid item xs={12} className="headerItem">
              <Box className="profileNameNew">
                <Box onClick={() => this.navigateRoute("Clients")} data-test-id="backButton" >
                  <img src={image_Imagenav_backicon} className="marginImg1" alt="back" />
                </Box>
                <Box className="planning"> Clients</Box>
              </Box>
              <UserProfile username={this.state.plannerInfo.name} email={this.state.plannerInfo.email} profilePicture={this.state.plannerInfo.image} />
            </Grid>
            <Grid item md={12} className="tabsContainer">
              <Grid container className="clientTabsSpacing">
                <Grid item>
                  <Typography className="activeTab" data-test-id="details-tab"> Details </Typography>
                </Grid>
                <Grid item>
                  <Typography className="inActiveTab" data-test-id="assets-tab" onClick={() => {
                    this.navigateRoute("ClientAssets")
                  }}> Assets </Typography>
                </Grid>
                <Grid item>
                  <Typography className="inActiveTab" data-test-id="service-tab" onClick={() => {
                    this.navigateRoute("ClientService")
                  }}> Services </Typography>
                </Grid>
                <Grid item>
                  <Typography className="inActiveTab" onClick={() => {
                    this.navigateRoute("UploadThing")
                  }} data-test-id="vault-tab"> Vault </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box className="mainContainer">
            <Box className="boxContainer">
              <Box className="boxContent">
                <Grid container className="formContainer">
                  <Grid item xs={12} sm={3} className="formLabel">Name:</Grid>
                  <Grid item xs={12} sm={9} className="formValue">{this.getValueFN(this.state.clientDetails && this.state.clientDetails?.attributes.full_name, this.state.clientDetails?.attributes.full_name)}</Grid>
                  <Grid item xs={12} sm={3} className="formLabel">Email Address:</Grid>
                  <Grid item xs={12} sm={9} className="formValue">{this.getValueFN(this.state.clientDetails && this.state.clientDetails?.attributes.email, this.state.clientDetails?.attributes.email)}</Grid>
                  <Grid item xs={12} sm={3} className="formLabel">Location:</Grid>
                  <Grid item xs={12} sm={9} className="formValue">{this.getValueFN(this.state.clientDetails && this.state.clientDetails?.attributes.location, this.state.clientDetails?.attributes.location)}</Grid>
                  <Grid item xs={12} sm={3} className="formLabel">Consultation Call Plan:</Grid>
                  <Grid item xs={12} sm={9} className="formValue">
                    {this.state.clientDetails && this.state.clientDetails.attributes.consultation_call_plan ? (
                      <>
                        <Box>{this.state.clientDetails.attributes.consultation_call_plan}</Box>
                        <Box className={`formDetails ${this.state.clientDetails.attributes.call_status ? "active" : ""}`}>
                          <Typography className="formText">{this.getScheduleCallString(this.state.clientDetails.attributes.booking_date, this.state.clientDetails.attributes.start_time, this.state.clientDetails.attributes.end_time)}</Typography>
                          <Button className="formCallBtn" disabled={!this.state.clientDetails.attributes.call_status} data-test-id="join-call" onClick={this.handleJoinCall}>Join Call</Button>
                        </Box>
                      </>
                    ) : (
                      <Box>N/A</Box>
                    )}
                  </Grid>
                  {this.renderServices()}
                  {this.renderPaymentDetails()}
                </Grid>

              </Box>
            </Box>
          </Box>
          <Box className="mainContainer">
            <Box className="boxContainer">
              <Box className="boxContent" style={{flexDirection:'column'}}>
              <Typography className="formValue" style={{textAlign:'left', width:"100%", fontSize:'20px', margin:'20px 0'}} data-test-id=""> Estate Planning Questionnaires </Typography>
                <Grid container className="" style={{flexDirection:'column'}}>
                  {this.state.estatePlanningQuestions && this.state?.estatePlanningQuestions?.map((item: EstatePlannerQuestionAnswers, index:number) => <Box key={index} data-test-id="questionContainer">
                    <Grid item xs={12} className="formLabel" style={{marginTop:"25px"}}> Q {index+1}. {item.text}</Grid>
                    
                    
                    {item.type == "Checkbox" ?
                      <Grid item xs={12} style={{ marginBottom: "0px" }} className="formValue">{item?.answer?.length !== 0 && item?.answer.join(" ").split(" ")[0].split(",").join(", ")}</Grid>
                      :
                      <Grid item xs={12} style={{ marginBottom: "0px" }} className="formValue">{item?.answer?.length !== 0 && item?.answer[0]}</Grid>
                    }
                    {
                      item.user_input && <Grid item xs={12} style={{ marginBottom: "0px" }} className="formValue"> <span style={{ fontWeight: "normal" }}> Description:</span> {item.answer[1]}</Grid>
                    }

                  </Box>)
                  }
                </Grid>
              </Box>
            </Box>
          </Box>

        </ThemeWrapper>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ThemeWrapper = styled(Box)({
  '& .clientTabsSpacing':{
    gap:'30px',
    flexWrap:'wrap',
    '@media (max-width: 500px)': {
      gap:'10px'
    }

  },
  '& .headerContainer': {
    flexWrap: "wrap",
    background: "#2a2a39"
  },
  '& .headerItem': {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  '& .profileNameNew': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .marginImg1': {
    margin: '0px',
    cursor: "pointer"
  },
  '& .planning': {
    fontSize: "22px",
    color: "#fff",
    fontFamily: 'Poppins',
    fontWeight: 500,
    marginLeft: "18px"
  },
  '& .tabsContainer': {
    marginBottom: "24px",
    '@media (max-width: 600px)': {
      marginTop: '20px',
    }
  },
  '& .inActiveTab': {
    fontSize: "22px",
    color: "#2de2d5",
    cursor: "pointer",
    fontFamily: "Poppins"
  },
  '& .activeTab': {
    fontSize: "22px",
    fontWeight: 600,
    color: "#2de2d5",
    cursor: "pointer",
    borderBottom: "1px solid",
    fontFamily: "Poppins"
  },
  '& .mainContainer': {
    marginTop: "18px"
  },
  '& .boxContainer': {
    backgroundColor: "#3c3c50",
    marginBottom: "50px",
    padding: "32px",
    marginTop: "18px",
    paddingTop: "4px",
    borderRadius: "5px",
  },
  '& .boxContent': {
    display: "flex",
    alignItems: "center"
  },
  '& .formContainer': {
    marginTop: "18px"
  },
  '& .formLabel': {
    color: "#fff",
    fontFamily: "Poppins",
    opacity: "70%",
    marginBottom: "5px"
  },
  '& .formValue': {
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: 600
  },
  '& .formDetails': {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    marginBottom: "15px"
  },
  '& .formDetails .formText': {
    color: "#fff",
    fontFamily: "Poppins",
    opacity: "70%",
    fontSize: "14px"
  },
  '& .formDetails.active .formText': {
    color: "#2F92FC",
    opacity: "100%",
  },
  '& .formDetails .formCallBtn': {
    marginLeft: "24px",
    height: "35px",
    color: "#3c3c50",
    background: "#c9f9f6",
    width: "200px",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: 600,
    textTransform: "none",
    border: "1px solid #c9f9f6",
    borderRadius: "8px",
    opacity: "50%"
  },
  '& .formDetails.active .formCallBtn': {
    color: "#c9f9f6",
    background: "transparent",
    textTransform: "none",
    border: "1px solid #c9f9f6",
    borderRadius: "8px",
    opacity: "100%"
  }
});
// Customizable Area End