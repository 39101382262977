import React from 'react';
import Select from 'react-select';
import { FormControl } from '@material-ui/core';

interface DropdownProps {
    options: any[];
    value: any;
    onChange: (value: any) => void;
    placeholder?: string;
    classNamePrefix?: string;
    dropdownWidth?: string;
    menuWidth?: string;
    optionPadding?: string;
}

const CommonDropdown: React.FC<DropdownProps> = ({
    options,
    value,
    onChange,
    placeholder = "Select an option",
    classNamePrefix = "an-simple-selectUSD",
    dropdownWidth = "150px",
    menuWidth = "150px",
    optionPadding = "18px 15px",
}) => {
    return (
        <FormControl>
            <Select
                options={options}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                classNamePrefix={classNamePrefix}
                className="selectUSD"
                styles={{
                    option: (base, state) => ({
                        ...base,
                        fontWeight: 'normal', 
                        fontSize: '14px', 
                        padding: optionPadding, 
                        backgroundColor: state.isFocused ? '#3C3C50' : 'transparent', 
                        color: state.isFocused ? '#fff' : '#000', 
                        ':active': {
                            backgroundColor: '#3C3C50', 
                            color: '#fff', 
                        },
                    }),
                    singleValue: (base) => ({
                        ...base,
                        fontWeight: 500, 
                        fontSize: '18px', 
                    }),
                    menu: (base) => ({
                        ...base,
                        width: menuWidth, 
                    }),
                    container: (base) => ({
                        ...base,
                        width: dropdownWidth, 
                    }),
                }}
            />
        </FormControl>
    );
};

export default CommonDropdown;
