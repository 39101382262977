import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast";
import SelectedBank from "./selectedBank.web";
import storage from "../../../framework/src/StorageProvider.web";
// import Lean from "../../../web/src/Lean.js";
// Customizable Area End

export const configJSON = require("./config");

interface IPlannerServiceDetails {
  "total_client": number,
  "client_percent": number,
  "client_operand": string,
  "active_services": number,
  "service_percent": number,
  "service_operand": string,
  "client_satisfaction": number,
  "rating_percent": number,
  "rating_operand": string,
  "errors"?: object,
  "flanks_credentials_token"?: string
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  componentPage: string;
  countries: any;
  banks: any;
  banksParams: string;
  banksParamsName: string;
  dropdownVisible: boolean;
  selectedBank: string;
  selectedBankName: string;
  selectedBankLogo: string;
  accountsConfirmationForLean: boolean;
  transactionsConfirmationForLean: boolean;
  Lean: any;
  token: any;
  customerId: any;
  bankIdentifier: any;
  countryPage: any;
  apiCallingStage: boolean;
  allCountriesLocation: any;
  searchLocation: any;
  isArabic: any;
  storage_provider: any;
  flankId: string;
  hasResponse: boolean;
  loading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApiintegrationbankaccountsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCountryCallId: string = "";
  getBanksCallId: string = "";
  getCustomerIdCallId: string = "";
  setUserBankCallId: string = "";
  getClientsAPICallID: string = "";
  getFlankRegistrationCallID: string = "";
  postFlankRegistrationCallID: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      componentPage: "home",
      countries: "",
      banks: "",
      banksParams: "",
      storage_provider: "",
      banksParamsName: '',
      dropdownVisible: false,
      selectedBank: "",
      selectedBankName: "",
      selectedBankLogo: "",
      accountsConfirmationForLean: false,
      transactionsConfirmationForLean: false,
      Lean: '',
      token: localStorage.getItem('token'),
      customerId: "",
      bankIdentifier: '',
      countryPage: 0,
      apiCallingStage: true,
      allCountriesLocation: [],
      searchLocation: '',
      isArabic: false,
      flankId: '',
      hasResponse: false,
      loading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    const script = document.createElement("script");
    script.src = configJSON.leanConnection;
    script.async = true;
    script.onload = () => {
      if (typeof window != `undefined` && (window as any).Lean) {
        this.setState({
          Lean: (window as any).Lean
        })
      }
    };

    document.body.appendChild(script);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCountryCallId.length > 0 &&
      apiRequestCallId === this.getCountryCallId
    ) {
      if (responseJson.length > 1) {
        this.setState((prevState) => ({
          countries: [...prevState.countries, ...responseJson],
          allCountriesLocation: [...prevState.allCountriesLocation, ...responseJson]
        }), () => {
          this.setState({ apiCallingStage: true })
        });
        this.callCountry()
      }
    }

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBanksCallId.length > 0 &&
      apiRequestCallId === this.getBanksCallId
    ) {
      this.setState({ banks: responseJson })
    }

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCustomerIdCallId.length > 0 &&
      apiRequestCallId === this.getCustomerIdCallId
    ) {
      this.handleSaltedgeRedirect(responseJson);
      this.setState({ customerId: responseJson.customer_id })
    }

    if (
      apiRequestCallId === this.setUserBankCallId
    ) {
      if (responseJson && responseJson.confirmation) {
        setTimeout(() => {
          this.setState({ componentPage: "success" });
        }, 125000);
        this.setState({ hasResponse: true}, ()=>{
          setTimeout(() => {
            this.setState({ componentPage: "success" })
          }, 1000);
        })
        window.localStorage.setItem('authToken', this.state.token);
      } else {
        toast.error('Something went wrong. Please try again later!')
        this.setState({ componentPage: "banks" });
      }
    }

    this.createRegistrationWithFlank(responseJson, apiRequestCallId);
    this.handleUserRegistrationWithFlank(responseJson, apiRequestCallId)
    // Customizable Area End
  }

  getFlankRegistrationCallIdMethod(searchString: string) {
    const urlParams = new URLSearchParams(searchString);
    const code = urlParams.get('code');
    if (code) {
      this.setState({
        componentPage: 'inProgress'
      })
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getFlankRegistrationCallID = requestMessage.messageId;
      const header = {
        "Content-Type": "application/json",
        token: this.state.token
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      let httpBody = {
        link_code: code
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_apiintegrationbankaccounts/generate_credentials_token"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  async postFlankRegistration(flankToken: string) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postFlankRegistrationCallID = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let bank_ids = await storage.get("bank_id")

    let httpBody = {
      data: {
        bank_id: bank_ids,
        flank_credentials_token: flankToken,
      }
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/userbank"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  createRegistrationWithFlank(responseData: IPlannerServiceDetails, apiRequestCallId: string) {
    if (apiRequestCallId === this.getFlankRegistrationCallID) {
      let flankToken = responseData.flanks_credentials_token as string
      if (!responseData.errors) {
        this.postFlankRegistration(flankToken)
      }
    }
  }

  handleUserRegistrationWithFlank(responseData: IPlannerServiceDetails, apiRequestCallId: string) {
    if (apiRequestCallId === this.postFlankRegistrationCallID) {
      setTimeout(() => {
        this.setState({ componentPage: "success" });
      }, 125000);
      this.setState({ hasResponse: true }, ()=>{
        setTimeout(() => {
          this.setState({ componentPage: "success" })
        }, 1000);
      })
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleDropdownVisible = async () => {
    if (this.state.dropdownVisible) {
      this.setState({ dropdownVisible: false })
    } else {
      this.setState({ dropdownVisible: true })
    }
  }

  skipBankConnection = () => {
    if (!window.localStorage.getItem('authToken')) {
      window.localStorage.setItem('authToken', this.state.token);
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: true }));
      }, 200)
    }else{
      this.goToDashboardScreen()

}
  }
  goToDashboardScreen() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationToDashboardScreen)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: true }));
    }, 200)
  }
  setBankState=()=>{
    this.setState({componentPage:"banks"})
  }

  

  callCountry = async () => {
    if (this.state.apiCallingStage) {
      this.setState({ apiCallingStage: false }, () => {
        this.getCountries();
      })
    }
  }

  getCountries = async () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_countries?page=${this.state.countryPage + 1}`
    );
    this.setState({ countryPage: this.state.countryPage + 1 })

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  isJson(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  handleMessage = (event: any) => {
    if (this.isJson(event.data)) {
      let a = JSON.parse(event.data);
      let connectionId = a.data.connection_id
      if (a.data.stage == 'success') {
        this.setState({ componentPage: "inProgress" })
        this.userBank({ status: "SUCCESS", connectionID: connectionId, saltEdge: true })
      }
    }
  };
  async componentDidMount() {
    window.addEventListener('message', this.handleMessage);

    super.componentDidMount();
    this.getCountries();
    this.getBanks();
    this.getCustomerId('');
    this.checkLanguage();
    this.getFlankRegistrationCallIdMethod(window.location.search);
  }
 
   async componentDidUpdate(prevProps: {}, prevState:S) {
    
    const { componentPage } = this.state;
   
    if ((componentPage && componentPage !== prevState.componentPage && componentPage == 'selectedBank')) {
      this.setState({transactionsConfirmationForLean:false,accountsConfirmationForLean:false});
    }

  }
  
  checkLanguage = () => {
    let language = localStorage.getItem('language');
    if (language == 'Arabic') {
      this.setState({ isArabic: true });
    }
    else {
      this.setState({ isArabic: false });
    }
  }
  getBanks = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBanksCallId = requestMessage.messageId;
    if (this.state.banksParams) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getBanksApiEndPoints + '?country_code=' + this.state.banksParams
      );
    }
    else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getBanksApiEndPoints
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCustomerId = async (provider_code: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerIdCallId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      provider_code ? configJSON.getCustomerIDApi + '?provider_code=' + provider_code : configJSON.getCustomerIDApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onCountryChange = async (value: any, name: any) => {
    this.setState({ banksParams: value })
    this.setState({ banksParamsName: name })
    this.setState({ dropdownVisible: false })
    setTimeout(() => {
      this.getBanks();
    }, 500);
  }

  inputLocation = (value: any) => {
    const searchData = this.state.allCountriesLocation.filter((val: any) => val.attributes.name.toLowerCase().includes(value.toLowerCase()))
    this.setState({ searchLocation: value, countries: searchData })
  }

  onCountryReset = async () => {
    this.setState({ banksParams: "" })
    this.setState({ banksParamsName: "" })
    this.setState({ dropdownVisible: false })
    setTimeout(() => {
      this.getBanks();
    }, 500);
  }

  handleArabic = () => {
    return this.state.isArabic ? "rtl" : "ltr"
  }
  handleBankParams = () => {
    return this.state.banksParams ? this.state.banksParamsName : "Select Country"

  }
  handlbanksParamsName = () => {
    return this.state.banksParams ? this.state.banksParamsName : "حدد الدولة"
  }
  onAddAccount = async () => {
    if (this.state.selectedBank.length < 1) {
      toast.error('No bank selected')
    }
    else if (this.state.bankIdentifier === "saltedge") {
      storage.set("authToken",this.state.token);
      this.getCustomerId(this.state.storage_provider)
    }
    else if (this.state.bankIdentifier === 'flanks') {
      storage.set("authToken",this.state.token);
      this.handleFlankRedirect()
    }

    else {
      this.setState({ componentPage: "selectedBank" })
    }
  }
  leanConnection = async () => {
    if (this.state.transactionsConfirmationForLean && this.state.accountsConfirmationForLean) {
      this.state.Lean.connect({
        app_token: "2b90d112-f53f-4e9c-af1d-123a520d31c2",
        permissions: ["identity", "accounts", "transactions", "balance"],
        customer_id: this.state.customerId,
        sandbox: "false",
        bank_identifier: this.state.bankIdentifier,
        callback: this.leanCallBack,
      });
    } else {
      toast.error('please give all permissions')
    }
  }
  userBank = async (response: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setUserBankCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let httpBody: any = {
      "data": {
        "bank_id": this.state.selectedBank
      }
    };

    if (response.saltEdge) {
      httpBody = {
        "data": {
          "bank_id": this.state.selectedBank,
          "connection_id": response.connectionID
        }
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/userbank"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  leanCallBack = async (responseObject: any) => {
    if (responseObject.status == 'SUCCESS') {
      this.setState({ componentPage: "inProgress" })
      this.userBank('')
    }
    else {
      this.setState({transactionsConfirmationForLean:false,accountsConfirmationForLean:false});
      toast.error('Something went wrong');
    }
    // insert actions you want to perform on SDK close
  }

  handleTransactionPermission = () =>{
    this.setState({
      transactionsConfirmationForLean: !this.state.transactionsConfirmationForLean
    })
  }

  handleAccountPermission = () =>{
    this.setState({
      accountsConfirmationForLean: !this.state.accountsConfirmationForLean
    })
  }
  
  handleSaltedgeRedirect(responseJson: any) {
    if (this.state.bankIdentifier === "saltedge") {
      window.open(responseJson?.connection_lead?.data?.redirect_url);
    }
  }

  handleFlankRedirect() {
    const environments = {
      uat: "https://myneleap2023-321315-react.b321315.uat.eastus.az.svc.builder.ai",
      stage: "https://myneleap2023-321315-react.b321315.stage.eastus.az.svc.builder.ai",
      dev: "https://myneleap2023-321315-react.b321315.dev.eastus.az.svc.builder.cafe",
      prod: "https://planner.joinmyne.com"
  };
    let currentUrl  =  window.location.href;
    if(currentUrl.includes("localhost")){
      window.open(`https://platform.flanks.io/link?token=d41070ef-0244-41c7-90b2-ab2ab8a83c02&bank=${this.state.flankId}&language=en`);
    }else if(currentUrl.startsWith(environments.uat)){
      window.open(`https://platform.flanks.io/link?token=8af57fb5-9fb2-4f98-8698-f24f6bce84df&bank=${this.state.flankId}&language=en`);
    }else if(currentUrl.startsWith(environments.stage)){
      window.open(`https://platform.flanks.io/link?token=fd52f1a9-1888-434b-b869-58cb6635e437&bank=${this.state.flankId}&language=en`);
    }else if(currentUrl.startsWith(environments.dev)){
      window.open(`https://platform.flanks.io/link?token=f17a7580-8e9c-43cf-8f8b-a9b005a8a73f&bank=${this.state.flankId}&language=en`);
    }else if(currentUrl.startsWith(environments.prod)){
      window.open(`https://platform.flanks.io/link?token=841462c4-808e-4a84-b6cf-17d719f97fcb&bank=${this.state.flankId}&language=en`);
    }
    else {
      window.open(`https://platform.flanks.io/link?token=4645af15-9a50-4ffe-aec1-02a79001c0d1&bank=${this.state.flankId}&language=en`);
    }

  }
  // Customizable Area End
}
