import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup"
import toast from "react-hot-toast"
import { getDurationWithUnit } from "../../../components/src/commonFn";
export interface ICustomPlans {
  id: string
  type: string
  attributes:  {
    id: number
    name: string
    duration: number
    currency: string
    amount: number
    enabled: boolean
    benefits: string[],
    tier:number
  }
}

 interface IEditPlan {
  id: string,
  name: string,
  duration: number,
  durationUnit: string,
  price: number,
  currency:string,
  benefits: string[]
}
interface ICurrency{
  "currency_name": string,
  "currency_type": string,
  "symbol": string,
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  plannerInfo: {
    name:string,
    email:string,
    image:string
  },
  customPlans: ICustomPlans[],
  openPlanModal: boolean,
  initialValues: IEditPlan,
  openSuccessModal: boolean,
  openDisablePlanModel: boolean,
  currentStatus: boolean,
  currentPlanId: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class EditPlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getUserProfile();
    this.getCustomPlan();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    
    // Customizable Area End


    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      plannerInfo:{
        name:"",
        image:"",
        email:""
      },
      customPlans:[],
      openPlanModal: false,
      initialValues: {
        id: "1",
        name:"Free Plan",
        benefits: ["12","34"],
        duration:15,
        durationUnit:"minutes",
        price: 22,
        currency:"AED"
      },
      openSuccessModal: false,
      openDisablePlanModel: false,
      currentStatus: false,
      currentPlanId: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleUserProfileResponse(apiRequestCallId,responseJson);
    this.handleCustomPlansResponse(apiRequestCallId,responseJson);
    this.handleSubmitEditPlanResponse(apiRequestCallId,responseJson);

    // Customizable Area End
  }

  // Customizable Area Start
  getUserProfileAPICallId:string = "";
  getUserPlansAPICallId:string = "";
  updateUserPlanApiCallId: string = ""


  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const { contentType, method, endPoint, body } = data;
    const proposalRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token: localStorage.getItem("auhtToken")
      })
    );

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(proposalRequestMessage.id, proposalRequestMessage);
    return proposalRequestMessage.messageId;
  };

  goToBackPage() {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Schedule");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  currencyList = [
    {
      "currency_name": "United States dollar",
      "currency_type": "USD",
      "symbol": "$",
    },
    {
      "currency_name": "Arab Emirates Dirham",
      "currency_type": "AED",
      "symbol": "د.إ",
    },
    {
      "currency_name": "British pound sterling",
      "currency_type": "GBP",
      "symbol": "£",
    },
    {
      "currency_name": "European Euro",
      "currency_type": "EUR",
      "symbol": "€",
    },
    {
      "currency_name": "Japanese yen",
      "currency_type": "JPYN",
      "symbol": "¥",
    },
    {
      "currency_name": "Australian Dollar",
      "currency_type": "AUD",
      "symbol": "A$",
    },
    {
      "currency_name": "Chinese Yuan",
      "currency_type": "CNH",
      "symbol": "¥",
    }
  ];

  handleUserProfileResponse(apiCallId:string,responseJson: {
    data: {
      attributes:{
        name: string,
        email: string,
        image: string
      }
    }
  }){
    if(apiCallId === this.getUserProfileAPICallId){
      this.setState({
        plannerInfo: responseJson.data.attributes
      })
    }
  }

  handleCustomPlansResponse(apiCallId:string,responseJson: {
    custom_plans: {
      data: ICustomPlans[]
    }
  }){
    if(apiCallId === this.getUserPlansAPICallId){
      const response = responseJson.custom_plans.data;
      const customPlans = response.sort((item1, item2) => {
        return item1.attributes.tier - item2.attributes.tier;
      });
      this.setState({
        customPlans
      })
    }
  }

  getUserProfile = async () => {
    this.getUserProfileAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/show_profile",
      body: null,
      contentType:"application/json"
    });
  }

  getCustomPlan = async () => {
    this.getUserPlansAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: "bx_block_appointment_management/edit_appointment_plans",
      body: null,
      contentType:"application/json"
    });
  }

  getCurrencySymbol(currencyType:string) {
    for (let currency of this.currencyList) {
      if (currency.currency_type === currencyType) {
        return currency.symbol;
      }
    }
    return ''; 
  }

  validationSchema = Yup.object({
    name: Yup.string().required('Name is required').max(25, 'Name must be less than 25 characters'),
    duration: Yup.number()
    .required('Duration is required')
    .integer('Duration must be an integer')
    .positive('Duration must be a positive number')
    .when('durationUnit', {
      is: 'minutes',
      then: Yup.number().test(
        'is-multiple-of-15', 
        'Duration must be a multiple of 15 minutes', 
        value => value % 15 === 0
      )
    }),
    durationUnit: Yup.string().required('Duration Unit is required'),
    price: Yup.number().required('Price is required').min(0,'Price must be a positive number'),
    currency: Yup.string().required('Currency is required'),
    benefits: Yup.array().of(Yup.string().required('Benefit is required'))
  })

  

  durationOptions = [{
    value: "minutes",
    label: "minutes"
  }, {
    value: "hours",
    label: "hours"
  }];


  currencyOptions  =  this.currencyList.map(currency => ({
    value:currency.currency_type,
    label:currency.currency_type
  }))

  handleOpenEditDialog = (plan:ICustomPlans) => {
    let {duration,durationUnit} = getDurationWithUnit(plan.attributes.duration)
    this.setState({
      initialValues:{
        id: plan.id,
        duration,
        durationUnit,
        price: plan.attributes.amount,
        benefits: plan.attributes.benefits,
        currency: plan.attributes.currency,
        name: plan.attributes.name
      },
      openPlanModal: true
    })
  }

  cancelSubmit = () =>{
    this.setState({
      openPlanModal: false
    })
  }

  handleSubmit = async (values:IEditPlan) =>{
    let body = {
      ...values,
      duration: values.durationUnit === "minutes"?  values.duration : values.duration*60,
      amount: values.price,currency:"AED"
    }
    this.updateUserPlanApiCallId = await this.apiCall({
      method: "PATCH" + '',
      endPoint:`bx_block_appointment_management/custom_appointment_plans/${values.id}`,
      body: JSON.stringify(body),
      contentType:"application/json"
    });
  }

  handleSubmitEditPlanResponse(apiCallId:string,responseJson: {
    message:string,errors: string[]
  },){
    if(apiCallId === this.updateUserPlanApiCallId ){
     
      if(!responseJson.errors){
        this.setState({
          openPlanModal: false,
          openSuccessModal: true,
          openDisablePlanModel: false,
        });
      }else{
        this.setState({
          openPlanModal: false,
          openDisablePlanModel: false,
        });
        toast.error("Please save the plan with the correct details before making it available.")
      }
      this.getCustomPlan()

    }
  }


  dialogSuccessPaperStyle = {
    width: 'max-content',
    backgroundColor: '#395D6B',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };

  closeModal = () => {
    this.setState({
      openSuccessModal: false
    });
  }

  closeDisablePlanModal = () => {
    this.setState({
      openDisablePlanModel: false
    });
  }

  dialogPaperStyle = {
    width: 'max-content',
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #57575d',
    padding: '0rem 0rem',
  };

  handleConfirmChangePlan = (currentStatus: boolean,currentPlanId: number) => {
    this.setState({
      openDisablePlanModel: true,
      currentStatus: currentStatus,
      currentPlanId
    })
  }

  handleChangePlanStatus = async () => {
    this.updateUserPlanApiCallId = await this.apiCall({
      method: "GET" + '',
      endPoint:`/bx_block_appointment_management/custom_appointment_plans/${this.state.currentPlanId}/toggle_plan`,
      body: null,
      contentType:"application/json"
    });
  }


  arrowComponent = { DropdownIndicator: () => null, IndicatorSeparator: () => null }

  getBackground = (index: number) => {
    switch (index) {
      case 0:
        return 'linear-gradient(205deg, #2ce2d5, #187c75)'
      case 1:
        return 'linear-gradient(205deg, #814ff9, #cd22ff)'
      case 2:
      default:
        return 'linear-gradient(205deg, #177ace, #00a0fb)'
    }
  }

  // Customizable Area End 
}