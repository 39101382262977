export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logoimg = require("../assets/logo.png")
export const backIcon = require("../assets/back-icon.png");
export const plus = require("../assets/plus.png");
export const apple = require("../assets/apple.png");
export const card = require("../assets/card.png");
export const close = require("../assets/close.png");
export const info = require("../assets/info.png");
export const SuccessCard = require("../assets/cr.png");
export const imgMobileVisible = require("../assets/6741124f38bda16191f3031463c957494328d004.jpg");
export const bankimg = require("../assets/eaeb5616577395764dc6bfedca019c4f6ac5089b.png");
export const imgAsset =require("../assets/d6665267d82bc39d4a833a3ae521e00ae9b2f7a4.png");
export const imgAccess =require("../assets/ec278d3e6701fbe4e4e89b4f3019af5279b490d0.png");
export const imgInvestment =require("../assets/84eda39f80ec9d3e247fa6816269ef580d395a55.png");
export const imgMyneLogo=require("../assets/a58a05325ef0b3122dd20288b8a6b0abd36a5374.png");
export const imgBackIcon=require("../assets/back-icon.png");
export const group_Exit = require("../assets/group_Exit.png");
export const deleteIcon = require("../assets/delete.png");
export const checckedIcon = require("../assets/checkedIcon.svg");
export const freetrialimg = require("../assets/freetrial.png");



