import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { formatText } from "../../../components/src/ChartUtils";
type CurrencyPieChartData = {
  currency_symbol: string;
  total_networth: number;
  data: {
    [info: string]: number; 
  };
};
type AssetName = "Cash" | "Bank" | "Stocks" | "Crypto" | "Shares & Funds" | "Property" | "Luxury" | "Pension" | "Liabilities" | "Commodities" | "Mortgage";
type AssetName2 = "Cash" | "Bank" | "Stocks" | "Crypto" | "Shares & Funds" | "Property" | "Luxury" | "Pension" | "Liabilities" | "Commodities" ;

type BarChartData = {
  name: AssetName;
  data: number[];
  currency:string;
  direction:string;
} & Record<AssetName, number> & {
  [key: string]: string | number
};
type BarChartData2 = {
  name: AssetName;
  data: number[];
  currency:string;
  direction:string;
} & Record<AssetName2, number> & {
  [key: string]: string | number
};
export interface ICurrency{
  "id": number,
  "currency_name": string,
  "value_respect_to_usd": number,
  "currency_type": string,
  "symbol": string,
}
interface IFinancialSummary {
  total_gain: number;
  total_gain_percentage: number ;
  total_gain2?: number;
  total_gain_percentage2?: number ;
  monthly_gain: number;
  monthly_gain_percentage: number;
  yearly_gain: number;
  yearly_gain_percentage: number;
  total_investment: number;
  total_asset_count:number;
}
interface IFinancialSummary2 {
  total_gain: number;
  total_gain_percentage: number ;

}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currencyPieChartData: {
    name: string|null;
    value: number;
  }[],
  selectedCurrency: { value: string, label: string },
  currencyList :ICurrency[],
  currencyListDropDown: { value: string, label: string }[],
  currencyPieChartInfo:{
    currency_symbol: string;
    total_networth: number;
  },
  assetsType:string;
  allocationPercentage: number,
  allocationPieChart: {
    name: string;
    value: number;
  }[],
  activeIndex: undefined |number,
  currencyPieChartPositions: {
    index: number,
    position:number
  }[],
  allocationPieChartPositions: {
    index: number,
    position:number
  }[],
  performanceFilter: string,
  performanceData: BarChartData2[],
  direction: string,
  language:string,
  activeCurrencyIndex: undefined |number,
  currentLevel:{
    level: number,
    name: string,
    previousName: string,
    realName?: string,
    nextLavel:boolean
  },
  financialSummary: IFinancialSummary | null,
  financialSummary2: IFinancialSummary | null,
  assetName:string,
  BarchartLevel2assetNames: { name: string }[];
  barChartName:string;
  barChartType:string;
  current_asset_id: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class NewDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    
    // Customizable Area End


    this.state = {
      // Customizable Area Start
      currencyPieChartData:[],
      selectedCurrency: { value: '', label: '' },
      currencyList:[],
      currencyListDropDown:[],
      currencyPieChartInfo:{
        currency_symbol: "",
        total_networth: 0
      },
      assetsType:"Currency",
      allocationPercentage:0,
      allocationPieChart:[],
      activeIndex:undefined,
      currencyPieChartPositions:[],
      allocationPieChartPositions:[],
      performanceFilter:"6M",
      performanceData:[],
      direction:'ltr',
      language:"English",
      activeCurrencyIndex:undefined,
      currentLevel:{
        level: 1,
        name: 'Dashboard',
        previousName:"",
        realName:"personal_loans",
        nextLavel:true
      },
      financialSummary: null,
      financialSummary2: null,
      assetName:"",
      BarchartLevel2assetNames:[],
      barChartName:"",
      barChartType:"",
      current_asset_id: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleGetCurrencyPieChartResponse(apiRequestCallId,responseJson)
    this.handleGetCurrencyListResponse(apiRequestCallId,responseJson);
    this.handleManageCurrencyResponse(apiRequestCallId);
    this.handleGetCountrypieChartResponse(apiRequestCallId,responseJson);
    this.handleGetDivercificationResponse(apiRequestCallId,responseJson);
    this.handleGetAssetAllocationResponse(apiRequestCallId,responseJson)
    this.handleGetPeformanceResponse(apiRequestCallId,responseJson);
    this.handleGetSubGraphAssetAllocationResponse(apiRequestCallId,responseJson);
    this.handleGetSubGraphIndividualAssetAllocationResponse(apiRequestCallId,responseJson);
    this.handleGetAllAssetMatrixResponse(apiRequestCallId,responseJson);
    this.handleGetSubGraphCurrencyAllocationResponse(apiRequestCallId,responseJson);
    this.handleGetIndividualCurrencyAllocationResponse(apiRequestCallId,responseJson);
   this.handleGetBarChartForLevel2Response(apiRequestCallId,responseJson);
    this.handleGetCountrypieChartLevel2Response(apiRequestCallId,responseJson);
    this.handleGetCountrypieChartLevel3Response(apiRequestCallId,responseJson);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getCurrencyList();
    this.getDivercificationScore();
    let language = await getStorageData('language');
    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ direction: currentLanguage ,language: language});
  }


  getCurrencyPieChartAPICallId:string = ""; 
  getCurrencyListAPICallId: string = "";
  putCurrencyAPICallId:string = "";
  getCountryPieChartAPICallId: string = "";
  getDivercificationScoreAPICallId:string ="";
  getAllocationAPICallId:string = "";
  getPerformanceAPICallId:string ="";
  getSubAssetForLevel2APICallId:string = "";
  getIndividualAllocationAPICallId: string = "";
  getAllAssetMatrixAPICallId:string = "";
  getSubAssetForCurrencyLevel2APICallId: string = "";
  getIndividualCurrencyAllocationAPICallId:string = "";
  getBarChartForLevel2APICallId:string="";
  getCountryPieChartlevel2APICallId:string="";
  getCountryPieChartlevel3APICallId:string="";
  getBarChartForLevel3APICallId:string="";
  
  getCurrencyPieChart = async () => {
     this.getCurrencyPieChartAPICallId = await this.apiCall({
      body: null,
      endPoint: "bx_block_dashboard/dashboards/country_exposure_pie_chart", method: "GET",contentType: "application/json"
    });
  }

  handleGetCurrencyPieChartResponse = (apiCallId: string, response: CurrencyPieChartData) => {
    if (apiCallId === this.getCurrencyPieChartAPICallId) {
      const dataArray = Object.keys(response.data)
        .map
        (dataInfo =>
        (
          {
            name: dataInfo,
            value: response.data[dataInfo]
          }
        )
        )
        .filter(entry => entry.value !== 0.0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState(
        {
          allocationPieChartPositions: [],
          currencyPieChartData: dataArray,
          currencyPieChartPositions: [],
          currencyPieChartInfo: {
            currency_symbol: response.currency_symbol,
            total_networth: response.total_networth
          }
        }
      )

    }
  }

  PIE_CURRENCY_COLORS 
  = 
  [
      "#6A32F1", "#B79BF8", "#8D60F8", "#5D29D9", "#A17BF9", "#D2C2F8", '#E6DDFC', "#490ED7", "#763DFF", "#AE9ED4", "#814FF9",
  ];

  handleManageCurrencyResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.putCurrencyAPICallId){
      this.getCurrencyList()
    }
  }

  handleGetCurrencyListResponse(apiRequestCallId:string,responseJson: {
    list:ICurrency[],
    selection: number
  }){

    if (apiRequestCallId === this.getCurrencyListAPICallId) {
      const userSelectedCurrncy = responseJson.list.find((value) => {
        return value?.id == responseJson.selection
      })
      this.setState({
        currencyList: responseJson.list,
        selectedCurrency: userSelectedCurrncy ? { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` } : {
          value: "", label: ""
        },
        currencyListDropDown: responseJson.list.map((value) => (
          { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
        ))
      })
     this.handleRefreshDashboard()
    }
  }

  goToAccountDetail=() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "MyneSettingsProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message) 
  }

  handleAllocationChartCurrencyChange = () => {
    let assetName = this.state.currentLevel.previousName.toLowerCase();
    if(assetName === "luxury"){
      assetName = 'portable'
    }
    this.getIndividualAssetsAllocations((this.state.currentLevel.realName as string),assetName, this.state.current_asset_id);
  }

  getCurrencyList = async() => {
    this.getCurrencyListAPICallId = await this.apiCall({
      body: null,
      endPoint: "bx_block_profile/profiles/currency_list",
      method: "GET",
      contentType: "application/json"
    });
  }

  setCurrency = async (value: { value: string, label: string }) => {
    const currency = this.state.currencyList.find((newValue) => {
      return newValue.currency_type === value.value
    })
    if (currency) {
       this.putCurrencyAPICallId = await this.apiCall({
        body: JSON.stringify({
          currency_id: currency.id
        }),
        endPoint: "bx_block_profile/profiles/add_currency_to_account",
        method: "PUT",
        contentType: "application/json"
      });
    }
  }

  apiCall = async (data: {contentType: string, method: string, endPoint: string, body: string | null }) => {
    const token = await storage.get("token")
    const { contentType, method, endPoint, body } = data;
    const dashboardMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

      dashboardMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(
          {
            "Content-Type": contentType,
            token
          }
        )
      );

    dashboardMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);

    dashboardMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);

    body && dashboardMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body
    );
    runEngine.sendMessage(dashboardMessage.id, dashboardMessage);
    return dashboardMessage.messageId;
  };

  changeType = (type:string) =>{
    this.setState({
      assetsType: type
    },()=>{
      this.handleRefreshDashboard()
    });
  }

  getCountryPieChart = async () => {
    this.getCountryPieChartAPICallId = await this.apiCall({
     body: null,
     endPoint: "bx_block_dashboard/dashboards/all_assets_pie_chart?pie_chart_type=country",
     method: "GET",
     contentType: "application/json"
   });
 }
 getCountryPieChartlevel2=async(assetName:string)=>{
  this.getCountryPieChartlevel2APICallId = await this.apiCall({
    body: null,
    endPoint: `bx_block_dashboard/new_dashboard_pie_charts/country_exposure_on_asset_type?asset=${assetName}`,
    method: "GET",
    contentType: "application/json"
  });
 }
 getCountryPieChartLevel3=async(name:string,type:string)=>{
  let newType = type;
  if(type === 'bank accounts'){
    newType = 'bank'
  } 
  if (type === 'crypto currency') {
    newType = 'crypto'
  }
  if (type === 'shares & funds') {
    newType = 'share'
  }
  if (type === 'stocks') {
    newType = 'stock'
  }
  
  this.getCountryPieChartlevel3APICallId = await this.apiCall({
    body: null,
    endPoint: `bx_block_dashboard/new_dashboard_pie_charts/country_exposure_individual_asset_category?type=${newType.toLowerCase()}&name=${encodeURIComponent(name)}`,
    method: "GET",
    contentType: "application/json"
  });
 }
  handleGetCountrypieChartResponse = (apiCallId: string, response: {
    total_networth: number,
    symbol: string,
    piechart: {
      "name": string|null,
      "code": string,
      "balance": number,
      "percentage": string
    }[]
  }) => {
    if (apiCallId === this.getCountryPieChartAPICallId) {
      
      const dataArray = response.piechart.map(country => ({
        name:  country.name === null ? "" : country.name,
        value: this.roundOff(parseFloat(country.percentage))
      })).filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState({
       
        currencyPieChartData: dataArray,
        allocationPieChartPositions: [],
        currencyPieChartPositions: [],
        currencyPieChartInfo: {
          total_networth: response.total_networth,
          currency_symbol: response.symbol
        },
      })
    }
   

  }

  handleGetCountrypieChartLevel2Response=(apiCallId: string, response: {
    total: number,
    symbol: string,
    data: {
      "country": string|null,
      "percentage": any
    }[]})=>{
    if (apiCallId === this.getCountryPieChartlevel2APICallId) {
      const dataArray = response.data.map(country => ({
        name: country.country === null ? "" : country.country,
        value: this.roundOff(country.percentage)
      })).filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);;;
      this.setState({
       
        currencyPieChartData: dataArray,
        allocationPieChartPositions: [],
        currencyPieChartPositions: [],
        currencyPieChartInfo: {
          total_networth: response.total,
          currency_symbol: response.symbol
        },
      })

    }
  }

  getDivercificationScore = async () => {
    this.getDivercificationScoreAPICallId = await this.apiCall({
     body: null,
     endPoint: "/bx_block_cfdiversificationscore1/diversifications/score",
     method: "GET",
     contentType: "application/json"
   });
  }
  
  handleGetDivercificationResponse = (apiCallId: string, response: {
    "score": number
  }) => {
    if (apiCallId === this.getDivercificationScoreAPICallId) {
      this.setState({
        allocationPercentage: response.score,
      })
    }
  }

  getAssetsAllocations = async () => {
    this.getAllocationAPICallId = await this.apiCall({
     body: null,
     endPoint: "bx_block_dashboard/dashboards/all_assets_pie_chart?pie_chart_type=assets",
     method: "GET",
     contentType: "application/json"
   });
  }
  
  handleGetAssetAllocationResponse = (apiCallId: string, response: {
    total_networth: number,
    symbol: string,
    piechart:
    {
      "code": string,
      "name": string,
      "balance": number,
      "id": string;
      "percentage": string
    }[]
  }) => {
    if (apiCallId === this.getAllocationAPICallId) {
      const dataArray = response.piechart.map(assets => {
        let name = assets.name;
        if (name === "Shares") {
          name = "Shares & Funds"
        }
        if (name === "Portable") {
          name = "Luxury"
        }
        
        return (
          {
            name: name,
            asset_id: assets.id,
            value: parseFloat(assets.percentage)
          }
        )
      }).filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState({
        allocationPieChart: dataArray,
        allocationPieChartPositions: [],
        currencyPieChartInfo: 
        {
          total_networth: response.total_networth,
          currency_symbol: response.symbol
        },
      })
    }
  }


  onPieEnter= async (_data:{},index:number) =>{
    this.setState({
      activeIndex: index,
      allocationPieChartPositions: [],
      currencyPieChartPositions: [],
    })
  }

  onCurrencyPieEnter= async (_data:{},index:number) =>{
    this.setState(
      {
        activeCurrencyIndex: index,
        allocationPieChartPositions: [],
        currencyPieChartPositions: [],
      }
    )
  }

  getColor = (name: string) => {
    switch (name.toLowerCase()) {
      case 'liability':
        return '#d44bc7';
      case 'pension':
        return '#068d84';
      case 'luxury':
        return '#e82c81';
      case 'bank accounts':
        return '#4db7fe';
      case 'shares & funds':
        return '#8d8998';
      case 'cash':
        return '#c8c822';
      case 'stocks':
        return '#2f92fc';
      case 'crypto currency':
        return '#7849e8';
      case 'commodity':
        return '#48970e';
      case 'property':
        return '#df6767';
      default:
        return '#2ce2d5';
    }
  }



  onCurrencyPieOut= async (_data:{},index:number) =>{
    this.setState({
      activeCurrencyIndex: undefined,
      allocationPieChartPositions: [],
      currencyPieChartPositions: [],
    })
  }

  onPieLeave= async() =>{
    this.setState({
      activeIndex: undefined,
      allocationPieChartPositions: [],
      currencyPieChartPositions: [],
    })
  }

  getPeformance = async (performanceFilter:string) => {
    this.getPerformanceAPICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_cfdashboard6/dashboards/performance_graph?duration=${performanceFilter}`,
     method: "GET",
     contentType: "application/json"
   });
  }
  
  handleGetPeformanceResponse = (apiCallId: string, response: {
    data:{
      name: AssetName;
      data: number[];
    }[],    
    month: string[];
    total_gain_amount:number
    total_profit_percentage:number
  }) => {
    if (apiCallId === this.getPerformanceAPICallId) {
      const {data, month } = response;
  const formattedMonth = month.map((monthString) => {
    return monthString.replace(/\s\d{4}$/, ''); 
  });

      const dataArray:BarChartData[] =  formattedMonth.map((monthName, index) => {
        const result:  BarChartData = { name: monthName } as BarChartData;
        result.currency = this.state.selectedCurrency.value;
        result.direction = this.state.direction
        data.forEach(asset => {
          result[asset.name] = Number(asset.data[index]);
          
        });
        return result;
      });
      const filteredDataArray = this.filteredDataArray(dataArray);
      this.setState({
        performanceData:filteredDataArray
      })
      // this.setState({financialSummary:{...this.state.financialSummary,total_gain:response.total_gain_amount,}});
      this.setState((prevState:any) => {
      
          return {
            financialSummary2: {
              ...prevState.financialSummary2,
              total_gain: response.total_gain_amount,
              total_gain_percentage:  response.total_profit_percentage,
             
            }
          };
        
      });
    }
    
  }
  filteredDataArray=(dataArray:any)=>{
    return(dataArray.map(({ Mortgage, ...rest }:any) => rest))
  }
  handleSetPerformanceFilter = (performanceFilter:string) => {
    this.setState({
      performanceFilter
    })
    if(this.state.currentLevel.level==1){
      this.getPeformance(performanceFilter)
    }
    if(this.state.currentLevel.level==2){
      this.getBarChartForLevel2(this.state.assetName,performanceFilter)
    }
    if(this.state.currentLevel.level==3){

      this.getBarChartForLevel3(this.state.barChartName,this.state.barChartType,performanceFilter)
    }
  }

  getStringNewDashboard = (keyvalue: string) => {
    return configJSON.languageListNewDashboard[this.state.language][keyvalue]
  }
  
  performanceBarData = [
    { name: "Bank", fill: this.getColor("bank accounts") },
    { name: "Cash", fill: this.getColor("cash") },
    { name: "Stocks", fill: this.getColor("stocks") },
    { name: "Shares & Funds", fill: this.getColor("shares & funds") },
    { name: "Crypto", fill: this.getColor("crypto currency") },
    { name: "Property", fill: this.getColor("property") },
    { name: "Luxury", fill: this.getColor("luxury") },
    { name: "Pension", fill: this.getColor("pension") },
    { name: "Liabilities", fill: this.getColor("liability") },
    { name: "Commodities", fill: this.getColor("commodity") },
  ]
  performanceBarData2 = [
    {
      name: "villa",
      fill: this.getColor("shares & funds")
    },
    {
      name: "land",
      fill: this.getColor("stocks")
    },
    {
      name: "building",
      fill: this.getColor("cash")
    },
    {
      name: "apartment",
      fill: this.getColor("bank accounts")
    },
  ]
  


  handleBack = () => {
    if (this.state.currentLevel.level === 2) {
      this.setState(
        {
          currentLevel: {
            level: 1, name: "Dashboard",
            previousName: "",
            nextLavel:true
          }
        }, () => {
          this.handleRefreshDashboard()
        }
      );
    } else {
      let assetName = this.state.currentLevel.previousName
      this.setState(
        {
          currentLevel: {
            level: 2, name: assetName,
            previousName: "Dashboard",
            nextLavel:true
          }
        }, () => {
          this.handleRefreshDashboard()
        }
      );
    }
  }
  onPieChartClick = (asset: string, asset_id?: string) => {
    if (asset_id) {
      this.setState({ current_asset_id: asset_id })
    }
    // istanbul ignore next
    if (this.state.currentLevel.nextLavel) {
      if (this.state.currentLevel.level === 1) {
        this.setState({
          currentLevel: {
            previousName: "Dashboard",
            name: asset,
            realName: asset,
            level: 1,
            nextLavel: true
          }
        })
        this.getAssetDistribution(asset.toLowerCase())
      } else {
        let assetCategory = this.state.currentLevel.name.toLowerCase()
        if (this.hasLevel3Distribution(assetCategory)) {
          let assetName = assetCategory.toLowerCase();
          if (assetName === "luxury") { assetName = 'portable' }
          this.getIndividualAssetsAllocations(asset, assetName, asset_id);
          this.setState({
            currentLevel: {
              ...this.state.currentLevel, realName: asset,
              name: formatText(asset), previousName: this.state.currentLevel.name,
            }
          })
        }
      }
    }
  }
    
  hasLevel3Distribution = (asset: string) => {
    const validAssets = ["commodity", "luxury", "property", "liability", "pension", 'bank accounts', 
      'shares & funds', 'stocks', 'cash', 
      'crypto currency'];
    if (validAssets.includes(asset)) {
        return true;
    }
    return false;
  }


  getAssetDistribution = async (asset:string) => {
   
    const assetName=this.getAssetName1(asset);
    const assetName2=this.getAssetName2(asset);

  this.setState({assetName:assetName})
    this.getSubAssetForLevel2APICallId = await this.apiCall({
      body: null,
      endPoint: `bx_block_dashboard/dashboards/assets_distribution_pie_chart?asset=${assetName}`,
      method: "GET",
      contentType: "application/json"
    });
      if(this.state.assetsType === "Currency"){
        this.getAssetCurrencyDistribution(assetName)
      }else{
        this.getCountryPieChartlevel2(assetName)
      }
    
     this.getBarChartForLevel2(assetName2,this.state.performanceFilter);

  }
  getAssetName1=(asset:string)=>{
    let assetName = '';
    switch(asset) {
      case "bank accounts": 
        assetName = "bank";
        break;  
      case "shares & funds": 
        assetName = "share";
        break;  
      case "stocks": 
        assetName = "stock";
        break;  
      case "crypto currency": 
        assetName = "crypto";
        break;  
      case "luxury": 
        assetName = "portable";
        break;  
      default:
        assetName = asset;
    }
    return assetName
  }
  getAssetName2=(asset:string)=>{
    let assetName = '';
    switch(asset) {
      case "bank accounts": 
        assetName = "bank";
        break;  
      case "shares & funds": 
        assetName = "share";
        break;  
      case "stocks": 
        assetName = "stocks";
        break;  
      case "crypto currency": 
        assetName = "crypto";
        break;  
     
      default:
        assetName = asset;
    }
    return assetName
  }
  getAssetName3=(asset:string)=>{
    let assetName='';
    switch(asset) {
      case "bank accounts": 
        assetName = "bank";
        break;  
      case "share": 
        assetName = "shares%20%26%20Funds";
        break;  
      case "stock": 
        assetName = "stocks";
        break;  
      case "crypto currency": 
        assetName = "crypto";
        break;  
        case "liability": 
        assetName = "liabilities";
        break; 
        case "commodity": 
        assetName = "commodities";
        break;
        case "portable":
        assetName = "luxury";
        break;
      default:
        assetName = asset;
    }
    return assetName
  }
  getBarChartForLevel2=async(assetNamee:string,duration:string)=>{ 
   const assetName= this.getAssetName3(assetNamee)
    const assetName2=this.capitalizeFirstLetter(assetName)

    this.getBarChartForLevel2APICallId = await this.apiCall({
      body: null,
      endPoint: `bx_block_cfdashboard6/dashboards/asset_performance_sub_graph?duration=${duration}&asset_type=${assetName2}`,
      method: "GET",
      contentType: "application/json"
    });
  }
  getAssetName4=(asset:string)=>{
    let assetName='';
    switch(asset) {
      case "bank accounts": 
        assetName = "bank";
        break;  
      case "shares & funds": 
        assetName = encodeURIComponent("Shares & Funds");
        break;  
      case "stock": 
        assetName = "stocks";
        break;  
      case "crypto currency": 
        assetName = "crypto";
        break;  
      case "portable": 
        assetName = "Luxury";
        break;
        case "liability": 
        assetName = "liabilities";
        break;
        case "commodity": 
        assetName = "commodities";
        break;
      default:
        assetName = asset;
    }
    return assetName
  }

  getBarChartForLevel3=async(name:string,type:string,performanceFilter:string)=>{ 

    let assetName= this.getAssetName4(type);
    let assetName2 = assetName;
    if(!assetName.includes('funds')){
      assetName2 = this.capitalizeFirstLetter(assetName); 
    }
    

    this.getBarChartForLevel3APICallId = await this.apiCall({
      body: null,
      endPoint: `bx_block_cfdashboard6/dashboards/asset_performance_induced_sub_graph?asset_type=${assetName2}&name=${encodeURIComponent(name)}&duration=${performanceFilter}`,
      method: "GET",
      contentType: "application/json"
    });
  }
  
  getAssetCurrencyDistribution = async (asset:string) => {
    this.getSubAssetForCurrencyLevel2APICallId = await this.apiCall({
      body: null,
      endPoint: `bx_block_dashboard/new_dashboard_pie_charts/currency_exposure_on_asset_type?asset=${asset}`,
      method: "GET",
      contentType: "application/json"
    });
  }
  capitalizeFirstLetter(str:string) {
    return str.replace(/\b\w/g, function(char:string) {
      return char.toUpperCase();
    });
  }
  roundOff = (num:number) => {
    return parseFloat(parseFloat(num.toString()).toFixed(2));
  };
  handleGetSubGraphAssetAllocationResponse = (apiCallId: string, response: {
    next_level: boolean;
    total: number,   symbol: string,
    data: {
      "name": string, "percentage": number, "id": string
    }[]}) => {if (apiCallId === this.getSubAssetForLevel2APICallId) {
      const dataArray = response.data.map(assets => ({
        name: assets.name,value: assets.percentage, asset_id: assets.id
      })).filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);
      const roundedDataArray = dataArray.map((item) => ({
        ...item,value: this.roundOff(item.value)
      }));
      this.setState({
        allocationPieChart: roundedDataArray,
        currentLevel:{
          ...this.state.currentLevel,level: 2, nextLavel:response.next_level
        },
        allocationPieChartPositions: [],
        currencyPieChartInfo: {
          total_networth: response.total,currency_symbol: response.symbol
        },
      })
    }
  }
  handleGetBarChartForLevel2Response =(apiCallId: string, response: {
    data:{
      name: AssetName;
      data: number[];
    }[],    
    month: string[];
    asset_count: number;
    total_gain_amount: number;
  total_profit_percentage: number ;
  monthly_gain: number;
  monthly_gain_percentage: number;
  monthly_profit_percentage: number;
  yearly_gain: number;
  yearly_gain_percentage: number;
  total_investment: number;
  total_asset_count:number;
  performance_gain_amount: number;
  performance_profit_percentage: number;
  })=>{
    if (apiCallId === this.getBarChartForLevel2APICallId) {
  const {data, month } = response;
  const formattedMonth = month.map((monthString) => {
    return monthString.replace(/\s\d{4}$/, ''); 
  });
  const dataWithUniqueKeys = data.map((item, index) => {
    return {
      ...item,
      uniqueKey: `${item.name} asset ${index}`
    };
  });
      const dataArray2:BarChartData[] =  formattedMonth.map((monthName2, index2) => {
        const result2:  BarChartData = { name: monthName2 } as BarChartData;
        result2.currency = this.state.selectedCurrency.value;
        result2.direction = this.state.direction
        dataWithUniqueKeys.forEach(asset => {
          result2[asset.uniqueKey] = Number(asset.data[index2]);
          
        });
        return result2;
      });
      this.setState({
        performanceData:dataArray2
      })
      this.setState((prevState:any) => {
        return {
          financialSummary2: {
            ...prevState.financialSummary2,
            total_gain: response.performance_gain_amount,
            total_gain_percentage: response.performance_profit_percentage,
            monthly_gain: response.monthly_gain,
            monthly_gain_percentage: response.monthly_gain_percentage,
            total_gain2: response.total_gain_amount,
            total_gain_percentage2: response.total_profit_percentage,
            total_asset_count: response.asset_count
          }
        };
      });
      this.setState({
        financialSummary: {
          total_gain: response.total_gain_amount,
  total_gain_percentage: response.total_profit_percentage ,
  monthly_gain: response.monthly_gain,
  monthly_gain_percentage: response.monthly_gain_percentage,
 
  total_investment: 0,
  total_asset_count:0,
  yearly_gain: 0,
    yearly_gain_percentage: 0,
        },
      })
      const names = dataWithUniqueKeys.map(item => ({ name: item.uniqueKey }));
      const performanceBarData2 = names.map((item) => ({
        name: item.name,
      }));
      this.setState({BarchartLevel2assetNames:performanceBarData2});
    }
    if (apiCallId === this.getBarChartForLevel3APICallId) {

      this.setState({
        financialSummary: {
          total_gain: response.total_gain_amount,
  total_gain_percentage: response.total_profit_percentage ,
  monthly_gain: response.monthly_gain,
  monthly_gain_percentage: response.monthly_profit_percentage,
  total_investment: 0,
  total_asset_count:0,
  yearly_gain: 0,
    yearly_gain_percentage: 0,
        },
      })
      const { data, month } = response;
      const formattedMonth = month.map((monthString) => {
        return monthString.replace(/\s\d{4}$/, ''); 
      });


      const dataWithUniqueKeys = data.map((item, index) => {
        return {
          ...item,
          uniqueKey: `${item.name} asset ${index}`
        };
      });

      const dataArray3:BarChartData[] =  formattedMonth.map((monthName3, index3) => {
        const result3:  BarChartData = { name: monthName3 } as BarChartData;
        result3.currency = this.state.selectedCurrency.value;
        result3.direction = this.state.direction
        dataWithUniqueKeys.forEach(asset => {
          result3[asset.uniqueKey] = Number(asset.data[index3]);
          
        });
        return result3;
      });
      this.setState((prevState:any) => {
        return {
          financialSummary2: {
            ...prevState.financialSummary2,
            total_gain: response.performance_gain_amount,
            total_gain_percentage: response.performance_profit_percentage,
            monthly_gain: response.monthly_gain,
            monthly_gain_percentage: response.monthly_profit_percentage,
            total_gain2: response.total_gain_amount,
            total_gain_percentage2: response.total_profit_percentage,
            total_asset_count: response.asset_count
          }
        };
      });
      this.setState({
        performanceData:dataArray3
      })
      const names = dataWithUniqueKeys.map(item => ({ name: item.uniqueKey }));
      const performanceBarData2 = names.map((item) => ({
        name: item.name,
      }));
      this.setState({BarchartLevel2assetNames:performanceBarData2});
    }
  }
   getFundTypeText = () => {
    return this.state.currentLevel.level === 1 ? `${this.getStringNewDashboard("fundType")} ${this.state.allocationPieChart.length}` : '';
  };
   
  getIndividualAssetsAllocations = async (name:string,type:string, asset_id?:string) => {
    
    let newType = type;
    if (type == 'bank accounts') {
      newType = 'Bank'
    }
    if (type == 'shares & funds') {
      newType = encodeURIComponent(type)
    }
    if (type == 'crypto currency') {
      newType = 'crypto'
    }

    let asset_and_name = (this.state.currentLevel.level == 2 || this.state.currentLevel.level == 3)  ? `&asset_id=${asset_id}` : `&name=${encodeURIComponent(name)} `;


    this.getIndividualAllocationAPICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_dashboard/dashboards/get_individual_asset?type=${newType+asset_and_name}`,
     method: "GET",
     contentType: "application/json"
   });

    if (this.state.assetsType === "Currency") {
      this.getIndividualAssetCurrencyDistribution(name, type)
    } else {

      this.getCountryPieChartLevel3(name,type)
    }
this.setState({barChartName:name,barChartType:type})

    this.getBarChartForLevel3(name,type,this.state.performanceFilter);
  }

  handleGetSubGraphIndividualAssetAllocationResponse = (apiCallId: string, response: {
    next_level: boolean;
    total: number,
    symbol: string,
    data: {
      "number": string,
      "percentage": number,
      "id":string,
      "name": string
    }[]
  }) => {
    if (apiCallId === this.getIndividualAllocationAPICallId) {
      const dataArray = response.data.map(assets => ({
        name:  assets?.name || this.state.currentLevel.realName as string,
        asset_id: assets.id,
        value: assets.percentage
      })).filter(asset=>asset.value>0);
      this.setState({
        currencyPieChartPositions: [],allocationPieChart: dataArray,
        allocationPieChartPositions: [],
        currencyPieChartInfo: {
          total_networth: response.total,  currency_symbol: response.symbol
        },
        currentLevel:{
          ...this.state.currentLevel,level: 3,
          nextLavel:response.next_level
        }
      })
    }
  }

  getAllAssetMatrix = async () => {
    this.getAllAssetMatrixAPICallId = await this.apiCall({
     body: null,
     endPoint: "bx_block_cfdashboard6/dashboards/gain_calculation",
     method: "GET",
     contentType: "application/json"
   });
  }

  handleGetAllAssetMatrixResponse = (apiCallId: string, response: IFinancialSummary) => {
    if (apiCallId === this.getAllAssetMatrixAPICallId) {
      this.setState({
        financialSummary: {
          ...response,
          total_gain_percentage: response.total_gain_percentage ? response.total_gain_percentage: 0,
        },
      })
    }
  }

  handleGetSubGraphCurrencyAllocationResponse = (apiCallId: string, response: {
    symbol: string,total: number,
    data: {
      "percentage": number,"currency": string,
    }[]
  }) => {
    if (apiCallId === this.getSubAssetForCurrencyLevel2APICallId) {
      const dataArray = response.data.map(assets => ({
        name: assets.currency,
        value: parseFloat(assets.percentage.toFixed(2))
      }))
      .filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState({
        currencyPieChartPositions: [],
        currencyPieChartData: dataArray,
        allocationPieChartPositions: [],currencyPieChartInfo:{currency_symbol: response.symbol,total_networth: response.total 
        }
      })
    }
  }  

  handleGetIndividualCurrencyAllocationResponse = (apiCallId: string, response: {
    total: number,
    symbol: string,
    data: {
      "currency": string,
      "percentage": number
    }[]
  }) => {
    if (apiCallId === this.getIndividualCurrencyAllocationAPICallId) {
      const dataArray = response.data.map(assets => ({
        value: parseFloat(assets.percentage.toFixed(2)),
        name: assets.currency,
      }))
        .filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState({
        currencyPieChartInfo: {
          currency_symbol: response.symbol, total_networth: response.total,
        },
        allocationPieChartPositions: [],
        currencyPieChartData: dataArray,
        currencyPieChartPositions: [],

      })
    }
  }
  handleGetCountrypieChartLevel3Response = (apiCallId: string, response: {
    total: number,
    symbol: string,
    data: {
      "country": string|null,
      "percentage": number
    }[]
  }) => {
    if (apiCallId === this.getCountryPieChartlevel3APICallId) {
      const dataArray = response.data.map(assets => ({
        value: parseFloat(assets.percentage.toFixed(2)),
        name: assets.country === null ? "" : assets.country,
      }))
      .filter(entry => entry.value >0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState({
        currencyPieChartInfo:{
          total_networth: response.total,
          currency_symbol: response.symbol,
        },
        currencyPieChartPositions: [],
        currencyPieChartData: dataArray,
        allocationPieChartPositions: [],
        
      })
    }
  }
  getIndividualAssetCurrencyDistribution = async (name:string,type:string) => {
    let newType = type;
    if (type === 'bank accounts') {
      newType = 'bank'
    }
    if (type === 'crypto currency') {
      newType = 'crypto'
    }
    if (type === 'shares & funds') {
      newType = 'share'
    }
    this.getIndividualCurrencyAllocationAPICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_dashboard/new_dashboard_pie_charts/currency_exposure_individual_asset_category?type=${newType}&name=${encodeURIComponent(name)}`,
     method: "GET",
     contentType: "application/json"
   });
  }
   

  handleRefreshDashboard = () => {
    
    if(this.state.currentLevel.level === 1){
      this.getPeformance(this.state.performanceFilter);
      this.getAssetsAllocations();
      if(this.state.assetsType === "Currency"){
        this.getCurrencyPieChart()
      }else{
        this.getCountryPieChart()
      }
    }else if(this.state.currentLevel.level === 2){      
      this.getAssetDistribution( this.state.currentLevel.name.toLowerCase());
      this.getBarChartForLevel2(this.state.barChartType,this.state.performanceFilter);

    }else{
     this.handleAllocationChartCurrencyChange()
    }
    this.getAllAssetMatrix()
  }
  // Customizable Area End 
}