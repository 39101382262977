// Customizable Area Start
import React from "react";

import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, OutlinedInput, ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import { image_Imagenav_backicon, startSelect, starUnSelect, SuccessCard } from "./assets"
import FormApprovalWorkflowController, {
  Props
} from "./FormApprovalWorkflowController.web";
import Rating from "@material-ui/lab/Rating";



const theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: 'Poppins !important'
  },
  overrides:{
    MuiOutlinedInput: {
      root: {
        background:"#fff",
        fontFamily: 'Poppins !important',
        lineHeight: "1.35rem",
        "& ::placeholder": { 
          color:"#3c3c51 !important",
          opacity:1
       },
        "& $notchedOutline": {
          borderColor: "#fff !important",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#fff !important",
        },
        "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff !important",
        },
        "& .MuiOutlinedInput-input": {
          color: "#3c3c51",
          width: '100%',
          borderBottom: '0px',
          "& ::placeholder": { 
            color:"#3c3c51"
         },
        },
      },

    },
  }
});

export default class EstatePlannerReview extends FormApprovalWorkflowController {
  constructor(props: Props) {
    super(props);
  }




  render() {
    return (
    <ThemeProvider theme={theme}>
      <div className="pagedashboard-wrapper">
        <div style={{
          paddingLeft:"2rem"
        }}>
          <Box style={{ display: "flex" }}>
            <Box data-test-id="backButton" onClick={()=>this.goBack()}><img style={{
              paddingTop: "12px"
            }} src={image_Imagenav_backicon} alt="back" /></Box>
            <Typography style={styles.headerName}> {this.state.currentPlannerName}</Typography>
          </Box>
          <Grid container spacing={4} style={{
            marginTop:"24px"
          }}>
            <Grid item md={6} sm={12}>
              <Grid container spacing={4}>
                  <Grid item md={5}>
                    {this.state.currentPlannerImage ? (
                      <img style={styles.image} src={this.state.currentPlannerImage} alt="Planner" />
                    ) : (
                      <div style={styles.image}>
                        {this.getInitials(this.state.currentPlannerName)}
                      </div>
                    )}
                  </Grid>
                <Grid item md={6} style={styles.textHeader}>
                  <Typography component="p" style={styles.description}>
                    {this.getStringTransactions('YourExp')}
                     
                    <Typography style={styles.name} component={"span"}>   {this.state.currentPlannerName}</Typography>
                  </Typography>
                  <div style={{marginTop:"16px"}}>
                  </div>
                  <StyledRating
                    name="simple-controlled"
                    value={this.state.reviewRating}
                    onChange={(event,value)=> this.handleReviewRating(value)}
                    icon={<div><img src={startSelect} /></div>}
                    emptyIcon={<div><img src={starUnSelect} /></div>}
                  />
                </Grid>
                  <Grid item md={11} style={styles.lableContainer}>
                    <Typography style={styles.writeAreviewText}>
                      {this.getStringTransactions('WriteReview')}
                    </Typography>
                    <Typography style={styles.maxWordTxt}>
                      {this.getStringTransactions('MaxWords')}
                    </Typography>
                  </Grid>
                  <Grid item md={11} style={{ paddingTop: "6px" }}>
                    <OutlinedInput
                      data-test-id="review-field"
                      className="inputBox"
                      fullWidth={true}
                      style={styles.inputRoot1}
                      maxRows={4}
                      rows={4}
                      placeholder={this.getStringTransactions('ExpPlace')}
                      multiline
                      inputProps={{
                        maxLength: 250 
                      }}
                      value={this.state.reviewText}
                      onChange={(event)=>{
                        this.handleReviewText(event)
                      }}
                    />
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box style={styles.btnContainer}><Button style={styles.saveBtn} onClick={()=>{
            this.handleSubmitReview()
          }} data-test-id="submit-review">
            {this.getStringTransactions('SubmitReview')}
          </Button>
          </Box>
        </div>
      </div>
      <Dialog 
          open={this.state.openReviewSuccessModal}
          onClose={this.handleReviewModalClose}
          data-test-id="dialogModel"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: styles.dialogStyle }}
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
          <DialogContent style={{ padding: '8px 20px' }}>
            <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
              textAlign: "center"
            }}>
              <Typography style={styles.successMessage}>
                {this.getStringTransactions('reviewSuccess')}
              </Typography >
              <Typography style={styles.successMessage}>
               {this.getStringTransactions('submitBtn')}
              </Typography >
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  }
}
type IStyle = { [key: string]: React.CSSProperties }

const StyledRating = styled(Rating)({
  '& .MuiRating-label': {
    paddingRight:"12px",
    paddingLeft:"12px"
  },
});

const styles: IStyle = {
  dialogStyle: {width: 'max-content',backgroundColor: '#395D6B',borderRadius: '8px',boxShadow: 'none',paddingTop:"16px",paddingBottom:"16px"},
  successMessage: {fontSize: "20px",color: "#fff",fontFamily: "Poppins"},
  writeAreviewText:{fontSize:"20px",color:"#fff"},
  maxWordTxt:{fontSize:"18px",color:"#c4c4ca"},
  lableContainer:{display:"flex",justifyContent:"space-between",paddingBottom:0,marginTop:"18px"},
  textHeader:{ alignSelf: "center",padding: "12px",fontFamily: "Poppins",textAlign:"center"},
  name:{fontWeight: 600,fontSize: "24px",color: "#fff",fontFamily: "Poppins"},
  description:{fontSize: "24px",color: "#fff",fontFamily: "Poppins"},
  image: {
    height: '200px',
    width: '200px',
    borderRadius: '50%',
    backgroundColor: '#ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '3em', 
    color: 'black', 
    objectFit: 'cover',
    textAlign: 'center'
  },
  headerName:{fontWeight: 600,marginLeft: "12px",fontSize: "28px",color: "#fff",fontFamily: "Poppins"},
  btnContainer:{display:"flex",justifyContent:"center",marginTop:"2rem"},
  saveBtn: { position: "fixed",bottom: "20px", left: "60%",transform: "translateX(-60%)", color: "#3c3c50", background: "#c9f9f6", width: "20%", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px" },
};

// Customizable Area End
