// Customizable Area Start
import {getAuth, GoogleAuthProvider} from "@firebase/auth";
import {initializeApp} from "@firebase/app";

// Customizable Area End

// Customizable Area Start
const firebaseConfig = {
  apiKey: "AIzaSyDCJxSk-cbYzmY7FiX15lY3TsBUO5Z1QzA",
  authDomain: "myne-app-432508.firebaseapp.com",
  databaseURL: "https://myne-app-432508-default-rtdb.firebaseio.com/",
  projectId: "myne-app-432508",
  storageBucket: "myne-app-432508.appspot.com",
  messagingSenderId: "1004632711977",
  appId: "1:1004632711977:web:bff05282c136deff424d38",
  measurementId: "G-XNX4WM4921"
};
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  export{auth,provider}
  // Customizable Area End