import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ValueType } from "react-select";
import storage from "framework/src/StorageProvider";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area End

export interface ICurrency{
  "id": number,
  "currency_name": string,
  "value_respect_to_usd": number,
  "currency_type": string,
  "symbol": string,
}

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    selectedCurrency: { value: string, label: string },
    currencyList :ICurrency[],
    language:string,
    direction: string,
    selectedLiabilityType: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddLiabilityController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  getCurrencyListAPICallId: string = "";
  putCurrencyAPICallId:string = "";
  postUserSelectDataCallId: string = "";
 

  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      
      selectedCurrency: { value: '', label: '' },
      currencyList:[],
      language: "English",
      direction: "ltr",
      selectedLiabilityType: this.LiabilityType.Mortgages
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCurrencyList();
    this.functionTokenCheck()

    let language = await getStorageData('language');
    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ direction: currentLanguage ,language: language});
    // Customizable Area End
  }
  
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleGetCurrencyListResponse(apiRequestCallId,responseJson);
    this.handleManageCurrencyResponse(apiRequestCallId);

    // Customizable Area End
  }

  // Customizable Area Start

  handleManageCurrencyResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.putCurrencyAPICallId){
      this.getCurrencyList()
    }
  }

  getString = (keyvalue: string) => {
    return configJSON.languageListLiability[this.state.language][keyvalue]
  }


  handleGetCurrencyListResponse(apiRequestCallId:string,responseJson: {
    list:ICurrency[],
    selection: number
  }){

    if(apiRequestCallId === this.getCurrencyListAPICallId){
      const userSelectedCurrncy = responseJson.list.find((value) => {
        return value?.id == responseJson.selection
    })
      this.setState({
        currencyList: responseJson.list,
        selectedCurrency: userSelectedCurrncy? { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` }:{
          value:"",label:""
        },
       
      })
    }
  }
  navigateRoute = (route: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(msg)
}

  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const token = await storage.get("authToken")
    const { contentType, method, endPoint, body } = data;
    const liabilityMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    liabilityMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token
      })
    );

    liabilityMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    liabilityMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && liabilityMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(liabilityMessage.id, liabilityMessage);
    return liabilityMessage.messageId;
  };

  getCurrencyList = async() => {
    this.getCurrencyListAPICallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: "bx_block_profile/profiles/currency_list" ,
      body: null
    });
  }

    LiabilityType = {
        Mortgages: 1,
        CreditCard: 2,
        PersonalLoan: 3,
        Other: 4
    }

    handleLiabilityTypeChange = (liabilityType: number) => {
        this.setState({ selectedLiabilityType: liabilityType })
    }

    handleSelectLiabilityNext = () => {
        switch (this.state.selectedLiabilityType) {
            case this.LiabilityType.Mortgages:
                this.navigateRoute("MortgageForm")
                break;
            case this.LiabilityType.PersonalLoan:
                this.navigateRoute("PersonalLoanForm")
                break;
            case this.LiabilityType.Other:
                this.navigateRoute("OtherLoanForm")
                break;
            case this.LiabilityType.CreditCard:
                this.navigateRoute("AddBankAccounts")
                break;
            default:
                break;
        }
    }

  setCurrency = async (value: { value: string, label: string }) => {
    const currency = this.state.currencyList.find((newValue) => {
      return newValue.currency_type === value.value
    })
    if (currency) {
      this.putCurrencyAPICallId = await this.apiCall({
        contentType: "application/json" + '',
        method: "PUT" + '',
        endPoint: "bx_block_profile/profiles/add_currency_to_account",
        body: JSON.stringify({
          currency_id: currency.id
        })
      });
    }
  }
  functionTokenCheck=async ()=>{
    const authToken = localStorage.getItem("token")
    if (!authToken ) {
      this.NavigateToLogout() 
    }
  }
  NavigateToLogout=()=>{
    window.localStorage.removeItem('token');
}

    // Customizable Area End
}
