import React from "react";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import { subarrowicon, settingicon, down, up } from "../../dashboard/src/assets";
import "../../dashboard/src/style.css";
import "./style.css";
import { LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer, CartesianGrid, ReferenceDot } from 'recharts';
import { OptionProps, components } from "react-select";
import ParentChildDropdown from "../../../components/src/SeleteBox/ParentChildDropdown";

import PendingBudgetPage from "../../../components/src/PendingBudgetPage.web";
// Customizable Area End

import VisualAnalyticsController, {
  Props,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getTransactionMonth()
    // Customizable Area End
  }

  // Customizable Area Start
  CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p style={webStyle.tooltip}>{dataPoint.month}</p>
          <p style={webStyle.tooltip}>{this.state.currency_symbol} {dataPoint.formattedValue}</p>
        </div>
      );
    }
    return null;
  };
  styleCss = () =>{
    return this.state.logoImg1AddCash === "rtl" ? this.state.dataValue : this.state.dataValueNew
  }
  iconOption = (props: OptionProps<any, boolean>) => {
    const { Option } = components;
    return (
      <Option {...props}  >
        <div style={{
          display:"flex",
          alignContent:"center",
          alignItems:"center"
        }}>
        <img src={props.data.icon} alt="" width="30" height="30" /> 
        <div style={{textAlign: "left"}}>
          <div style={{lineHeight: "20px"}}>
            {props.data.label}
          </div>
          <div style={{lineHeight: "10px", paddingBottom: "3px", fontSize: "10px"}}>
            {props.data.type}
          </div>
        </div>
        </div>
      </Option>
    )
  };

  renderNoCategoryData = () => {
    return <>
      {
        !this.state.spendingCategory && <p style={webStyle.notAvailable}>
          {this.getStringVisualAnalytics('NoDataAvailable')}
        </p>
      }</>
  }

  renderNoTransactionData = () => {
    return <>
      {
        !this.state.recentTransactions && <p style={webStyle.notAvailable}>
          {this.getStringVisualAnalytics('NoDataAvailable')}
        </p>
      }</>
  }
  renderLogo = (imgOne: string, imgTwo: string) => imgOne ?? imgTwo;
  renderTransaction = () => {
    return (
      <div className="recent-wrapper">
        {this.state.recentTransactions && this.state.recentTransactions.map((value:any, key: string) => {
          const {
            sub_category: { image, name },
            description,
            transaction_date,
            amount_operand,
            user_currency,
            brand_logo,
            converted_amount
          } = value.attributes;
  
          const formattedAmount = parseFloat(converted_amount || 0).toLocaleString();
  
          return (
            <div className="recent-block-content" key={key}>
              <div className="recent-item">
                <img 
                  src={this.renderLogo(brand_logo, image)} 
                  className="spending-image" 
                  alt="SpendingImage" 
                />
                <div className="recent-inner-item">
                  <h4>{description == null ? this.getStringVisualAnalytics('Unknown') : description}</h4>
                  <p>{transaction_date}</p>
                </div>
              </div>
              <div className="recent-price">
                <span dir="ltr" style={this.styleCss()} className={amount_operand == '+' ? "plus" : ""}>
                  { (formattedAmount != '0' ?   amount_operand:"") + user_currency}{formattedAmount !== "" ? formattedAmount : "0"} 
                  <img className={amount_operand == '+' ? "up" : "down"} src={amount_operand == '+' ? up : down} alt="downarrow" />
                </span>
                <p>{name == 'Other' ? this.getStringVisualAnalytics('Unknown') : name}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  
  showReferenceDot() {
    return this.state.activeDot && this.state.activeDot.x && this.state.activeDot.y !== null &&
      <ReferenceDot
        x={this.state.activeDot.x}
        y={this.state.activeDot.y}
        r={6}
        stroke="#fff"
        strokeWidth={2}
        fill="#2ce2d5"
      />
  }
  checkDirection = () => this.state.logoImg1AddCash === "rtl" ? "right" : "left";
  showDropdown = () => this.state.isBankListLoading ? <></> : <ParentChildDropdown
    bankData={this.state.bankOptions}
    handleApiCall={this.handleSelectBankApiCall}
  />
  checkOperand = () => this.state.oprand != "-" ? "up" : "down"
  checkImgUpDown=()=> this.state.oprand != "-" ? up : down
  showPlusMinus = (value: string) => value != '0' ? '-' : ''
  // Customizable Area End

  render() {
    
    return (
      // Customizable Area Start
      <>
            { this.state.transactionStatus == 'completed' ?
      <div className="pagedashboard-wrapper">
      <div className="header-wrapper" onClick={this.handleGlobaldropdown} style={{marginTop:'30px'}}>
        <div className="page-title">
        </div>
        <div className="page-info">
          <ul className="drop-menu">
            <li>
              <div
                data-test-id="currencyDropdoen"
                onClick={() => this.handleDropdown()}
                className={this.state.dropdownVisiblity} >
                <p data-test-id="fakedatatestid">
                 {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                </p>
                <ul id="fakeid" >
                  {this.state.currencyList && this.state.currencyList.list.map((value:any, index:number)=>{
                    return <li style={{textAlign:this.checkDirection()}} data-test-id={"currency-"+index} onClick={()=>this.selectCurrency(value)} key={index}>{value.symbol+value.currency_type}</li>
                  })}
                  
                </ul>
              </div>
            </li>
            <li>
              <NavLink className="fake-class" to="/Account-Detail">
                <img src={settingicon} alt="setting" id="setting-img" />
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="content-wrapper"  dir={this.state.logoImg1AddCash}>
      <div className="screen-wrapper">
            <div className="screen-flex">
              
              <div className="layout-grid">
                <p className="current-month-expense">{this.state.currentMonthExpanse}</p>
                {
                  this.showDropdown()
                }
                
              </div>
              {
                this.state.graph_data &&

                <div className="graph-block" style={{ height: 'unset' }}>
                  <div>
                    <span className="last-month-comparision-new">
                      <img className={this.checkOperand()} src={this.checkImgUpDown()} alt="downarrow" />
                      <div style={{ padding: '8px' }}>

                        <div style={this.styleCss()}>
                          <span className="bold-value pr-2">{this.state.currency_symbol + this.state.lmec}</span> {this.getStringVisualAnalytics("Thanlastmonth")}
                        </div>
                      </div>
                    </span></div>
                  <div className="graph-text">
                    <div dir="ltr" style={this.state.dataValueNew}>
                      <span className="graph-month">
                        <div>
                          {
                            this.state.selectedTransactionMonth !== this.state.transactionMonth.length - 1 &&
                            <span className="left-btn calendar-arrow" data-test-id="prevMonthBtn" onClick={() => this.updateMonth('minus')}>{`<`}&nbsp;&nbsp;</span>

                          }
                          {this.state.transactionMonth[this.state.selectedTransactionMonth]}
                          {this.state.selectedTransactionMonth !== 0
                            && <span className="right-btn calendar-arrow" data-test-id="nextMonthBtn" onClick={() => this.updateMonth('plus')}>&nbsp;&nbsp;{`>`}</span>
                          }
                        </div>
                      </span>
                      <span className="graph-legend">
                        <span className="graph-legend-icon"></span>
                        &nbsp;{this.getStringVisualAnalytics("spending")}
                      </span>
                    </div>
                  </div>
                  <div dir="ltr" style={this.state.dataValueNew} >
                    <ResponsiveContainer width="100%" height={279} data-test-id="line_chart">
                      <LineChart                      
                        layout="horizontal"
                        data={this.getFilteredData()}
                        margin={{
                          top: 50,
                          right: 0,
                          left: 0,
                          bottom: 25
                        }}
                          onMouseMove={this.handleMouseMove}
                          onMouseLeave={this.handleMouseLeave}
                      >
                        <CartesianGrid horizontal vertical={false} />
                        <XAxis dataKey="month" axisLine={false} dy={15} interval={3} allowDataOverflow={false} tick={{ fontSize: '12px' }} padding={{ left: 15, right: 15 }} />
                        <YAxis
                          tick={(tickObject) => {
                            const formattedValue =
                              tickObject.payload.value > 999
                                ? `${tickObject.payload.value / 1000}k`
                                : `${tickObject.payload.value}`;
                            let textAnchor = "end";
                            return (
                              <text
                                {...tickObject}
                                fontFamily="Poppins, sans-serif"
                                fontSize={"16px"}
                                textAnchor={textAnchor}
                              >
                                {this.state.currency_symbol}
                                {formattedValue}
                              </text>
                            );
                          }}
                          axisLine={false}
                          data-test-id="YaxisLineChart"
                        />;
                        <Line
                          type="monotone"
                          dataKey="value"
                          stroke="#2CE2D5"
                          strokeWidth={3}
                          dot={false}
                        />


                        <Tooltip content={this.CustomTooltip} />
                          {
                            this.showReferenceDot()
                          }

                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              }
                    <div className="spending-block">
                        <div className="spending-flex">
                            <h5>{this.getStringVisualAnalytics("SpendingCategory")}</h5>
                            <NavLink to="/spendingCategory">
                              {
                                this.state.spendingCategory && this.state.spendingCategory.length> 0 &&  
                                <span>{this.getStringVisualAnalytics('SeeAll')}</span>
                              }
                         
                            </NavLink>
                        </div>
                        {this.renderNoCategoryData()}
                
                        <div className="spending-wrapper">
                            {this.state.spendingCategory && this.state.spendingCategory.map((value:any,key:any)=>{
                               return(
                                      <div className="spending-block-content" key={key}>
                                            <div className="spending-item">
                                                <img src={value[3].data.attributes.image} className="spending-image" alt="SpendingImage" />
                                                <h4 className="cursor-pointer" data-test-id="spendingCategory" onClick={()=>this.navigateSpendings(value[3].data.attributes.id)} >{value[3].data.attributes.name}</h4>
                                            </div>
                                            <div className="spending-price">
                                                <span dir="ltr">{ this.showPlusMinus(value[1]) }{this.state.currency_symbol}{parseFloat(value[1]).toLocaleString()}</span>
                                            </div>
                                      </div>
                                )
                            })}
                           
                        </div>
                    </div>
                </div>
                <div className="screen-flex">
                    <div className="recent-flex">
                        <h5>{this.getStringVisualAnalytics("RecentTransactions")}</h5>
                        <NavLink to="/Transactions">
                          {
                            this.state.recentTransactions && this.state.recentTransactions.length> 0 && 
                            <span data-test-id="recentTransactions" onClick={()=>this.seeRecentTransactions()}>{this.getStringVisualAnalytics('SeeAll')}</span>
                          }
                     
                        </NavLink>
                    </div>
              {this.renderNoTransactionData()}

              { this.renderTransaction()}
              
                </div>
            </div>
      </div>
     
    </div>
:
        <><PendingBudgetPage direction={this.state.logoImg1AddCash} /></>
      }
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle = {
    notAvailable: {
        color : '#fff',
        fontFamily: 'Poppins',
    },
    tooltip:{
      paddingLeft:4,
      paddingRight: 4
  }
}
// Customizable Area End
