import React from "react";
// Customizable Area Start
import "./style.css"
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { back_icon, logo, myne_logo } from "./assets";
import StepProgressBar from "../../../components/src/StepProgressbar.web";

interface Props {
    id: string;
    navigation: any;
    instance: any;
}
interface S { }
interface SS { }
// Customizable Area End



export default class InProgressBank extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
    const { instance } = this.props;
        return (
            // Customizable Area Start
            <div className="mainBank-wrapper">
               {!this.props.instance.state.isArabic ?
                <div className="bank-integraion-wrapper">
                    <div className="bank-integration-box hgt-res-500">
                    <img
                    className="back-icon display-none"
                    data-test-id='bank-icon-click'
                    style={{
                        position: 'absolute',
                        left: '5',
                        top: '0',
                        padding: '40px',
                        fontSize: '44px',
                        cursor: 'pointer',
                    }}
                    onClick={() =>{ instance.setState({ componentPage: "AddBankAccounts" }) }}
                    src={back_icon}
                    alt="back-icon"
                            />
                            <div className="logo-right">
                                <a href="#"><img src={logo} alt="logo" /></a>
                            </div>
                            <div className="title-content-bank">
                                <p>We are establishing a secure connection to your bank<br />
                                    account, please wait as this process might take<br />
                                    a couple of minutes
                                </p>
                            </div>
                            <div className="progress-img-content">
                                <div className="logo-circular-progress">
                                    <img className="myne-logo-img" src={myne_logo} alt="" height="40px" width="40px" />
                                    <div className="myne-progress-circle">
                                        <svg className="svgImg" viewBox="25 25 50 50">
                                            <circle className="svgCircle" r="20" cy="50" cx="50"></circle>
                                            <circle className="svgCircle2" r="20" cy="50" cx="50"></circle>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <StepProgressBar
                            data-test-id="step-progressbar"
                            apiResponseReceived = {instance.state.hasResponse}
                            steps={
                                [
                                    "Establishing a secure connection",
                                    "Fetching account balances",
                                    "Fetching transactions",
                                    "Categorizing transactions",
                                    "Complete!"
                                ]
                            }
                            />
                        </div>
                </div>
                :
                <div className="bank-integraion-wrapper" dir="rtl">
                <div className="bank-integration-box hgt-res-500">
                <div className="logo-left">
                        <a href="#"><img src={logo} alt="logo" /></a>
                    </div>
                    <div className="title-content-bank">

                            <p>نحن نقوم بإنشاء اتصال آمن بالبنك الذي تتعامل معه<br />
                            الحساب، يرجى الانتظار حيث قد تستغرق هذه العملية<br />
                            بضع دقائق</p>
                            </div>
                            <div className="progress-img-content">
                                <div className="logo-circular-progress">
                                    <img
                                        className="myne-logo-img"
                                        src={myne_logo}
                                        alt=""
                                        height="40px"
                                        width="40px"
                                    />
                                    <div className="myne-progress-circle">
                                        <svg
                                            className="svgImg"
                                            viewBox="25 25 50 50"
                                        >
                                            <circle
                                                className="svgCircle"
                                                r="20"
                                                cy="50"
                                                cx="50"
                                            ></circle>
                                            <circle className="svgCircle2" r="20" cy="50" cx="50"></circle>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <StepProgressBar
                                direction="rtl"
                                apiResponseReceived={instance.state.hasResponse}
                                steps={
                                    [
                                        "إنشاء اتصال آمن",
                                        "جلب أرصدة الحسابات",
                                        "جلب المعاملات",
                                        "تصنيف المعاملات",
                                        "مكتمل!"
                                    ]
                                }
                            />
                        </div>
                    </div>
                }
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
