Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "flanksapi1";
exports.labelBodyText = "flanksapi1 Body";
exports.exampleAPiPOSTMethod = "POST";

exports.btnExampleTitle = "CLICK ME";

exports.languagePortfolio = {
  English: {
    AddStocksPortfolio: 'Add Stocks Portfolio',
    LinkWallet:'Link Wallet',
  },
  Arabic: {
    LinkWallet:'ربط المحفظة',
    AddStocksPortfolio: 'إضافة محفظة الأسهم',

  }
}
// Customizable Area End