Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodtype = "GET";
exports.validationApiPostMethodtype = "POST";
exports.passcodelockAPIEndpoint = "";
exports.passcodelockPlaceholder = "Passcode Lock";
exports.labelTitleText = "Passcode Lock";
exports.lebelBodyText = "Passcode Lock Body";
exports.btnExampleTitle = "Passcode Lock";
exports.languageListCrypto = {
  English: {
    EstateTitle:'Estate Planning',
    forgotPin:'Forgot PIN',

    setUpPin:'Set up a PIN to secure your access',
    yourVault:'to your document vault',
    pinBtn:'Set up a PIN',
    desc:'account and we will send an OTP for verification',

    cancelBtn:'Cancel',
    confirmBtn:'Confirm',
    notValid:'Email is not valid',

    confirmPin:'Confirm PIN',
    pinNotMatch:'PIN does not match',
    confirmNewPin:'Confirm new PIN',
    Continue:'Continue',
    one:'1',
    enterMail:'Enter the email address associated with your',
    pinSuccess:'PIN set up successfully!',
    enterPin:'Enter PIN',
    emailAdd:'Email Address',
    emailReq:'Email is required',
    didnotReceive:'Didnt receive the OTP?',

    pinInvalid:'PIN invalid',
    otpInvalid:'OTP invalid!',
    verifyCode:'Please enter the 4 digit verification code sent to you at',

    otpVerify:'OTP Verification',
    otpSent:'OTP is send to your email successfully',
    verify:'Verify',
    validateSuccess:'Validation success',
    resend:'Resend again',
    pinChanged:'PIN changed successfully!',
  },
  Arabic: {
  forgotPin:'نسيت رقم التعريف الشخصي',

  EstateTitle:'التخطيط العقاري',
  otpInvalid:'كلمة المرور لمرة واحدة غير صالحة!',
  resend:'إعادة إرسال مرة أخرى',
  validateSuccess:'نجاح التحقق من الصحة',
  confirmNewPin:'تأكيد رقم التعريف الشخصي الجديد',
  pinChanged:'تم تغيير رقم التعريف الشخصي بنجاح!',
  enterPin:'أدخل رقم التعريف الشخصي',

  otpSent:'تم إرسال OTP إلى بريدك الإلكتروني بنجاح',
  verify:'يؤكد',
  didnotReceive:'لم تتلق كلمة المرور لمرة واحدة؟',
  setUpPin:'قم بإعداد رقم PIN لتأمين وصولك',
  pinNotMatch:'رقم التعريف الشخصي غير متطابق',

  otpVerify:'التحقق من OTP',
  enterMail:'أدخل عنوان البريد الإلكتروني المرتبط بحسابك',
  yourVault:'إلى خزانة المستندات الخاصة بك',
  pinBtn:'قم بإعداد رقم التعريف الشخصي',
  cancelBtn:'يلغي',
  desc:'الحساب وسوف نرسل OTP للتحقق',
  verifyCode:'الرجاء إدخال رمز التحقق المكون من 4 أرقام المرسل إليك على',

  emailReq:'البريد الإلكتروني مطلوب',
  notValid:'البريد الإلكتروني غير صالح',
  confirmBtn:'تاكيد',
  pinSuccess:'تم إعداد رقم التعريف الشخصي بنجاح!',

  emailAdd:'عنوان البريد الإلكتروني',
  Continue:'يكمل',
  one:'واحد',
  confirmPin:'تأكيد رقم التعريف الشخصي',
  pinInvalid:'رقم التعريف الشخصي غير صالح',

  }
}
// Customizable Area End
