import React from "react";
// Customizable Area Start
import "./style.css";
import {
  SuccessCard,
    logoNewDefault,
    settingicon,
    subarrowicon,
} from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import {
    Box,
    Typography,
    OutlinedInput,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { backIcon } from "../../forgot-password/src/assets";
import Grid from '@material-ui/core/Grid';

const theme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-input": {
                    borderBottom: '0px',
                    color: "white",
                    width: '100%',
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
            },
        },
    },
});
import HeaderWithNavigationIcon from "../../../components/src/Header/HeaderWithNavigationIcon";
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import InfiniteScroll from "react-infinite-scroll-component";
export default class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    renderDir =()=>{
      return this.state.direction === 'rtl' ? 'rtl' : 'ltr'
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
          <><div id="scrollableDiv" style={webStyle.scrollBarStyle} dir={this.renderDir()}>

            <div className="header-wrapper" style={{ paddingRight: "50px" }}>
              <div className="page-title">
                <h4>
                  <NavLink to="/StockSelection"><img src={backIcon} alt="back-icon" style={{transform: this.state.direction ==='rtl'? 'scaleX(-1)':'' }} /></NavLink>
                  &nbsp;{this.getStringDashboard("AddStockTicker")}
                </h4>
              </div>
              <div className="add-stock page-info">
                <ul id="addStockFile" className="drop-menu dropDoenMenu">
                  <li id="addStockCurrencyDropdown">
                    <div
                      id="stockAddListing"
                      data-test-id="currencyDropdoen"
                      onClick={() => this.handleDropdown()}
                      className={this.state.dropdownVisiblity}
                    >
                      <p className="selectedCurrencyLabel">
                        {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                      </p>
                      <ul className="listDropdownAddStock">
                        {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                          return <li data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)}>{value.symbol + value.currency_type}</li>;
                        })}
                      </ul>
                    </div>
                  </li>
                  <li id="settingRedirectionFromAddStock">
                    <NavLink data-test-id="linkForPath" to="/Account-Detail">
                      <img src={settingicon} alt="setting" />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            {/* input boxes */}
           
        <ThemeProvider theme={theme}>

<Box>

  <Grid container spacing={2} style={{ margin: '15px 10px 0px 10px' }}>

    <Grid item xs={12} sm={12}>

      <div></div>

      <Typography style={webStyle.formLabel}> {this.getStringDashboard("EnterQuantity")}</Typography>

      <OutlinedInput

        onChange={(e) => this.tickerQuantityUpdate(e.target.value)}

        style={{ ...webStyle.inputRootForPayments, marginTop: '12px', width: '15%' }}

        data-test-id="quantityId"

        className="inputBox"

        fullWidth={true}

      />

    </Grid>

    <Grid item xs={12} sm={12}>

      <Typography style={webStyle.formLabel}> {this.getStringDashboard("ChooseStock")}</Typography>

      <OutlinedInput

        style={{ ...webStyle.inputRootForPayments, marginTop: '12px', width: '30%' }}

        className="inputBox"

        data-test-id="searchTicker"

        fullWidth={true}

        placeholder={this.getStringDashboard('searchStock')}

        onChange={(e) => this.searchTicker(e.target.value)}

      />

    </Grid>

  </Grid>

                {/* tickerList infinite scroller container */}
                <InfiniteScroll
                  dataLength={this.state.tickerList}
                  next={this.tickerList}
                  hasMore={true}
                  loader={<p></p>}
                  scrollableTarget="scrollableDiv"
                >
                  <Grid container spacing={0} style={{ margin: '15px 10px 0px 10px', width: '60%' }}>
                    {this.state.tickerList && this.state.tickerList.map((values: {
                      ticker: string;
                      icon_url: string;
                      id: string;
                      name: string;
                    }) => (
                      <Grid item xs={12} sm={4} md={4} lg={3} style={{ padding: '10px' }} key={values.id}>
                        <Box
                          style={this.state.ticker_id != values.id ? webStyle.stockCard : webStyle.stockCardActive} data-test-id="stock-ticker"
                          onClick={() => { this.setStockTickerId(values.id); } }
                        >
                          <Box style={{ ...webStyle.stockCard2, flexDirection: 'column' }}>
                            <Box>
                              <img src={values.icon_url ? values.icon_url : logoNewDefault} style={{ width: '50px', height: "50px", margin: 'auto' }} />
                            </Box>
                            <Typography style={this.state.ticker_id != values.id ? webStyle.tickerLabels : webStyle.tickerLabelsActive}>
                              {values.ticker}
                            </Typography>
                            <Typography style={this.state.ticker_id != values.id ? webStyle.tickerSubLabels : webStyle.tickerSubLabelsActive}>
                              {values.name.length > 10 ? `${values.name.slice(0, 10)}...` : values.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </InfiniteScroll>
              </Box>
            

            <Box style={{ ...webStyle.btnBox, position: 'absolute', bottom: '20px', left: this.state.direction === "rtl"?'30px':'', right: this.state.direction === "rtl"?'': '30px', borderRadius: '8px', }} data-test-id="tickerSubmit" onClick={() => this.addTicker()}>
              <Typography style={webStyle.btnLabel}>
                 {this.getStringDashboard("Confirm")}
              </Typography>
            </Box>

         
              <Dialog

                open={this.state.openSuccessModal}

                keepMounted

                data-test-id="dialogModel"

                aria-labelledby="alert-dialog-slide-title"

                aria-describedby="alert-dialog-slide-description"

                PaperProps={{ style: this.dialogSuccessPaperStyle }}

                onClose={() => this.goToStockBackPage()}

              >

                <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>

                <DialogContent style={{ padding: '8px 20px', textAlign: "center" }}>

                  <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
                    textAlign: "center"
                  }}>

                    {this.getStringDashboard("PositionSuccess")}

                  </DialogContentText>



                </DialogContent>

              </Dialog>
            </ThemeProvider>

            </div>
            </>
      
          );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    formLabel: { color: '#FFF', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500 },
    stockPortfolioLabel: { color: '#FFF', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px' },
    stockTickerLabel: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '24px' },
    tickerLabels: { color: '#fff', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px' },
    tickerSubLabels: { color: '#fff', fontFamily: 'Poppins', fontSize: '11px', fontWeight: 500 },
    tickerLabelsActive: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px' },
    tickerSubLabelsActive: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '11px', fontWeight: 500 },
    btnLabel: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 700, padding:'0.50rem', borderRadius:'15%' },
    btnBox: { cursor: 'pointer', backgroundColor: '#C9F9F6', width: '135px', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' },
    stockCard2: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px' },
    stockCard: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px', background: '#2A2A39', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)' },
    stockCardActive: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px', background: '#2CE2D5', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)' },


    inputBoxStyle: {
        height: '48px',
        borderRadius: '8px',
    },

    inputRootForPayments: {
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        height: '56px',
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        fontFamily: 'Poppins',
        outline: 'none',
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        '& input::placeholder': {
            fontWeight: 500,
            fontSize: '14px',
            color: '#f44336 ',
            opacity: 0.5,
        },
        '& .MuiInputBase-input': {
            borderColor: 'white !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#94989c !important',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        borderRadius: '8px',
    },
    scrollBarStyle: { height: "100vh", overflow: "auto", width: "100%", padding: "50px 0px 50px 50px", background: "rgba(0, 0, 0, 0.3)" }

}
// Customizable Area End
