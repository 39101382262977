import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast"
import { getStorageData } from "framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  heard: string;
  first_name: string;
  last_name: string;
  componentPage: string;
  goals: any;
  token:any;
  allCountries:any;
  selectedCountry:any;
  selectedCountryCode:any;
  countryDropdownVisible:boolean;
  countryPage:any;
  apiCallingStage:boolean;
  searchLocation: string;
  allCountriesLocation: any;
  loadData:boolean;
  isArabic:boolean;
  questionnnaireData: any;
  currentQuestionIndex:number;
  selectedAnswer:any;
  data:any;
  answers:any;
  isFromEditPage: boolean;
  language: string,
  direction: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionbankController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  QuestionBankApiCallId: any;
  getCountriesCallId: any;
  questionnaireListCallId: any;
  userQuestionCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      heard: "",
      first_name: "",
      data: {
        question_id: '',
        selected_options: {},
        previous_data: [],
      },
      last_name: "",
      componentPage: "questions",
      goals: [],
      token: localStorage.getItem('token'),
      allCountries:"",
      selectedCountry:"",
      selectedCountryCode:"",
      countryDropdownVisible:false,
      countryPage:0,
      apiCallingStage:true,
      searchLocation: '',
      allCountriesLocation: [],
      loadData:false,
      isArabic:false,
      questionnnaireData:[],
      currentQuestionIndex: 0,
      answers:{},
      selectedAnswer:"",
      direction:'ltr',
      language:'English',
      isFromEditPage: false
      // Customizable Area End
    };
    this.checkboxHandle = this.checkboxHandle.bind(this)
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleNavigationMessage(message);

    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.getCountriesCallId === dataMessage) {
      let responseJson2 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
       
      if (responseJson2.length > 1) {
        this.setState((prevState) => ({
          allCountries: [...prevState.allCountries, ...responseJson2],
          allCountriesLocation: [...prevState.allCountriesLocation, ...responseJson2]
        }), () => {
          this.setState({ apiCallingStage: true })
        });
        this.callCountry()
      }else{
        if (this.state.countryDropdownVisible) {
          this.setState({  countryDropdownVisible:  false,loadData:false})
          this.inputLocation(this.state.searchLocation,this.state.allCountriesLocation)
        }
      }
    }

    if (this.QuestionBankApiCallId === dataMessage) {
      
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     if(responseJson.data){
      toast.success(responseJson.meta.message)
      this.props.navigation.navigate("Freetrial");
     }
     else if(responseJson.error){
      toast.error(responseJson.error)
     }
    }
    if(this.userQuestionCallId===dataMessage){
      let responseJson3 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage))
this.handleReceivefn(responseJson3)

    }

    if(this.questionnaireListCallId===dataMessage){
      let responseJson3 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage))
      const dataArray = this.handleQuestionListResponse(responseJson3);
      this.setState({questionnnaireData:responseJson3, data: {...this.state.data, previous_data: dataArray}})
    }
    
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
handleReceivefn=(responseJson3:any)=>{
  if(responseJson3?.message=="Saved"){
    this.props.navigation.navigate("Progress")
    if (this.state.questionnnaireData[0]?.answer[0]?.length || this.state.isFromEditPage) {
      setTimeout(() => {
        this.props.navigation.navigate("EstatePlanningPreferences");
      }, 5000);
    } else {
      setTimeout(() => {
        this.props.navigation.navigate("FinancialPlanner");
      }, 5000);
    }
  }
}
  checkActiveClass = (value:any)=>{
    if(this.state.goals.includes(value)){
      return "active";
    }
    else{
      return "";
    }
  }
  checkLanguage = () =>{
    let language = localStorage.getItem('language');
    if(language == 'Arabic'){
      this.setState({isArabic:true});
    }
    else{
      this.setState({isArabic:false});
    }
  }

  handleQuestionListResponse = (response: {id: number, answer: string[]}[]) => {
    return response.map((value: {id: number, answer: string[]}) => {
      return {
        question_id: value.id,
        id: value.id,
        selected_options: value.answer[0] || [],
        description: value.answer[1] || null
      };
    });
  }

  callCountry = async () => {
    if(this.state.apiCallingStage){
      this.setState({apiCallingStage:false},()=>{
        this.getCountries();
      })
    }
  }

  inputLocation = (value: string, allCountriesLocation: any) => {
    if(this.state.loadData){
      this.setState({  countryDropdownVisible:false})
    }
    const searchData = allCountriesLocation.filter((val: any) =>
      val.attributes.name.toLowerCase().includes(value.toLowerCase())
    );
    setTimeout(() => {
      this.setState({ countryDropdownVisible:true});
    }, 100);
    this.setState({ searchLocation: value, allCountries: searchData, selectedCountry: "", selectedCountryCode: ""});
  };
  

  async checkboxHandle(value: string) {
    if (this.state.heard != value) {
      this.setState({ heard: value });
    }
  }

  handleChange = (name: string, value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: value.trim()
    })) as unknown as Pick<S, keyof S>
  }

  goalsHandle = async (value: string) =>{
    this.setState((prevState) => {
      const goals = prevState.goals;
      const index = goals.indexOf(value);
      if (index !== -1) {
        goals.splice(index, 1);
      } else {
        goals.push(value);
      }
      return {
        goals: [...goals]
      };
    });
  }

  onSubmit = async () => {
    if (this.state.first_name.length < 1 ||  this.state.selectedCountry < 1) {
      if(!this.state.isArabic){
        toast.error("Please check again,you missed some of the fields !")
      }else{
        toast.error("جميع الحقول مطلوبة")
      }
      
    }
    else if (!/[A-Za-z]/.test(this.state.first_name)) {
      if(!this.state.isArabic){
        toast.error("Numbers are not allowed in first name")
      }else{
        toast.error("غير مسموح بالأرقام في الاسم الأول")
      }

    }
    else {
      this.setState({ componentPage: "heard" })
    }
  }

    sendQuestionBank = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.QuestionBankApiCallId = requestMessage.messageId;
      console.log(this.QuestionBankApiCallId)
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    

    const heard_from = this.state.heard ? this.state.heard : ""; 
    const goals = this.state.goals ? this.state.goals : "";
    const country = this.state.selectedCountryCode;
    const httpBody = {
      first_name:this.state.first_name,
      heard_from,
      goals,
      country,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/question_banks"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountries = async () => {
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_countries?page=${this.state.countryPage+1}`
    );
    this.setState({countryPage:this.state.countryPage+1})


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  //////////////////
  questionnaireList = () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.questionnaireListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/estate_planning_questionnaire`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  userQuestion = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userQuestionCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const Method = !this.state.questionnnaireData[0].answer[0]?.length ? "POST" : "PUT";
    const endPoint = !this.state.questionnnaireData[0].answer[0]?.length ? "bx_block_questionbank/estate_planning_questionnaire" : "bx_block_questionbank/estate_planning_questionnaire/change_questionnaire"
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let httpBody: any ={
      "data": {
        "attributes": 
          this.state.data.previous_data.map((value: {selected_options: string[], id: string}) => {
            const { selected_options, id, ...restValue } = value;
            return ({...restValue, user_answers: selected_options })
          })
        
    }
    };
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigationMessage(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data1 = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage) 
      );
      if (data1) {
        this.setState({isFromEditPage: true})
      }
    }
}

  //////////////////
  async componentDidMount() {
    super.componentDidMount();
    this.getCountries();
    this.checkLanguage();
    this.questionnaireList();

    let language = await getStorageData('language');
    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ direction: currentLanguage ,language: language});

  }


  getStringQuestion = (key: string) => {
    let languageLogin = localStorage.getItem("language") || "English"
    return configJSON.languageListQuestion[languageLogin][key]
  }

  // Customizable Area End
}
