import React from 'react';
import { bankIcon, CopyIcon, EsyncWebIcon, pest_sync, red_delete_icon } from '../../../blocks/settings2/src/assets';


const BankWalletCard = ({
	bankAccountData,
	onRemove,
	onSync,
	getString,
}: {
	bankAccountData: {
		id: number,
		bank_logo_url: string | null,
		bank_name: string,
		bank_account_number?: string,
		sort_code?: string,
		connected_on: string,
		last_synced: string,
		type: string,
		isSynced?: boolean,
		is_deleted?: boolean,
		publicAddress?: string,
	},
	onRemove: (id: number, type: string) => void,
	onSync: (type: string, force: boolean, id: number) => void,
	getString: (key: string) => string,
}) => {
	const {
		id,
		bank_logo_url,
		bank_name,
		bank_account_number,
		sort_code,
		connected_on,
		last_synced,
		type,
		isSynced,
		is_deleted,
		publicAddress,
	} = bankAccountData;
	return (
		<div
			className='bank-card'
			style={{
				opacity:is_deleted ? 0.5 : 1
			}}
			key={id}>
			{/* Row 1: Bank Name and Logo */}
			<div className='card-row-1'>
				<div className='title-container'>
					<span className={`title-style`}>{getString('BankName')}</span>
					<span className={`title-value`}>{bank_name}</span>
				</div>
				<div className='banks-logo-container'>
					<img
					 width={32}
					 height={32}
						src={bank_logo_url ||bankIcon}
						alt='bank logo'
					/>
				</div>
			</div>

			{/* Row 2: Public Address */}
			{publicAddress && (
				<div className='card-row-2'>
					<div className='title-container'>
						<span className={`title-style`}>
							{getString('PublicAddress')}
						</span>
						<span className={`title-value`}>{publicAddress}</span>
					</div>
					<div className='copy-logo'>
						<img
							src={CopyIcon}
							alt='copy img'
						/>
					</div>
				</div>
			)}

			{/* Row 3: Account Number and Sort Code */}
			{bank_account_number && (
				<div className='card-row-3 bank-account-row'>
					<div className='title-container'>
						<span className={`title-style`}>
							{getString('AccountNumber')}
						</span>
						<span className={`title-value acc-number-break`}>
							{bank_account_number}
						</span>
					</div>
					<div
						className='title-container'
						dir='ltr'>
						<span className={`title-style short-code-style`}>
							{getString('SortCode')}
						</span>
						<span className={`title-value`}>{sort_code}</span>
					</div>
				</div>
			)}

			{/* Row 4: Connected On and Last Synced */}
			<div className='card-row-4'>
				<div className='title-container'>
					<span className={`title-style`}>
						{getString('Connectedon')}
					</span>
					<span className={`title-value`}>{connected_on}</span>
				</div>
				<div className='title-container'>
					<span className={`title-style`}>
						{getString('LastSynced')}
					</span>
					<span className={`title-value`}>{last_synced}</span>
				</div>
				<div
				style={{
					cursor:'pointer'
				}}
					onClick={() => {
						if (isSynced && !is_deleted) onSync(type, isSynced, id);
					}}>
					<img
						src={
							isSynced
								? pest_sync
								: EsyncWebIcon
						}
						alt='sync img'
					/>
				</div>
			</div>

			{/* Row 5: Resync and Remove */}
			<div className='card-row-5'>
				<button
					disabled={is_deleted}
					className={`resync-btn remove-btn`}
					onClick={() => !is_deleted && onRemove(id, type)}>
					<img
						src={red_delete_icon}
						className='btn-bank-icon'
					/>
					{getString('Remove')}
				</button>
			</div>
		</div>
	);
};

export default BankWalletCard;
