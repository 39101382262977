import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// Styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 80,
            maxWidth: 150,
        },
        select: {
            width: '100% !important',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        icon: {
            color: '#2CE2D5 !important',
        },
        menuPaper: {
            color: '#FFFFFF',
            backgroundColor: '#395D6B',
        },
    })
);

// Types for the category structure
interface Category {
    id: string;
    type: string;
    attributes: {
        id: number;
        parent_id: number;
        name: string;
        image: string | null;
    };
}

interface MySelectComponentProps {
    categoriesList: Category[]; // Use Category[] instead of repeating the type
    selectedCategoryId: number | string;
    handleCategoryChange: (event: React.ChangeEvent<{ value: unknown }>) => void; // Typed correctly for `onChange`
}

const SelectInputField: React.FC<MySelectComponentProps> = ({
    categoriesList,
    selectedCategoryId,
    handleCategoryChange,
}) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <Select
                labelId="category-select-label"
                id="category-select"
                value={selectedCategoryId}
                onChange={handleCategoryChange}
                displayEmpty
                classes={{ select: classes.select, icon: classes.icon }}
                MenuProps={{ PaperProps: { className: classes.menuPaper } }}
            >
                {/* Sort and map through the categories */}
                {categoriesList
                    .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
                    .map((category) => (
                        <MenuItem key={category.attributes.id} value={category.attributes.id}>
                            {category.attributes.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default SelectInputField;
