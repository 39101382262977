import React from "react";

//Customizable Area Start

import PasscodeLockController, {
  Props,
} from "./PasscodeLockController";
import { logo, backIcon } from "../../forgot-password/src/assets";
import "../../forgot-password/src/style.css";
//Customizable Area End

export default class ForgotPin extends PasscodeLockController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {

    return (
      <>
        <style>
          {`
            .verification-code input {
              width: 65px !important;
            }
            input::placeholder' {
              marginLeft: '0.5rem'
            }
          `}
        </style>
        <div className="main-wrapper" dir={this.state.direction}>
          <div className="forgotPassword-wrapper">
            <div className="forgotPassword-box">
              <div className="logo-right">
                <a href="#"><img src={logo} alt="logo" /></a>
              </div>
              <div className="forgotPassword-block">
                <span data-test-id="go-back" onClick={() => this.handleGoBack()}><h4><img src={backIcon} alt="back-icon" style={{transform: this.state.direction === "rtl" ? "rotate(180deg)" : "0deg"}} />{this.getStringCrypto('forgotPin')}</h4></span>
                <p>{this.getStringCrypto('enterMail')}<br />{this.getStringCrypto('desc')}</p>
                <div className="forgotPassword-code">
                 {this.getStringCrypto('emailAdd')}
                  <br />
                  <input  type="email" className="forgotPassword-input" data-test-id="emailField" onChange={(event) => this.setState({ email: event.target.value })} />
                  <button className="verifyForgotPassword" onClick={this.onEmailSubmit} data-test-id="verifyBtn">{this.getStringCrypto('Continue')}</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </>

    );
  }
}

// Customizable Area Start

