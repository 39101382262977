import React from "react";
// Customizable Area Start
import "./style.css"
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { image_Blob } from "./assets";


interface Props {
  id: string;
  navigation: any;
  instance: any;
}
interface S { }
interface SS { }
// Customizable Area End



export default class ApiIntegrationBankAccountsHome extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { instance } = this.props
    return (
      // Customizable Area Start
      <div className="mainBank-wrapper">
        {!instance.state.isArabic ?
                    <div className="bank-integraion-wrapper connect-block">
                    <div className="bank-integration-box d-flex">
                      <div className="text-content">
                        <h4>Connect your bank account</h4>
                        <p style={{fontWeight:400, fontSize: '15px', color: '#acacbf'}}>Add your primary account and Myne will<br />
                            need this to help you to track<br />
                            expenses, set budget and save money.
                        </p>
                      <p data-test-id="skipBtn" onClick={()=>instance.skipBankConnection()} style={{fontSize:"16px", marginBottom:0, fontWeight:400,color:"#fff", cursor:"pointer"}}>Skip </p>
              <button className="bankHomeBtn" onClick={()=>instance.setBankState()} data-test-id="continueBtn">Continue</button>

                      </div>
                      <div className="img-content">
                        <img src={image_Blob} />
                      </div>
                    </div>
                    <ul className="list-dot-main">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className="current-main"></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            :

         <div className="bank-integraion-wrapper connect-block" dir="rtl">
            <div className="bank-integration-box d-flex">
              <div className="text-content" style={{ paddingRight:30}}>
                <h4>قم بربط حسابك المصرفي</h4>
                <p>أضف حسابك الأساسي وسيقوم Myne بذلك<br />
                بحاجة إلى هذا لمساعدتك على تتبع<br />
                النفقات، تحديد الميزانية وتوفير المال.
                </p>
                <p data-test-id="skipBtn" onClick={()=>instance.skipBankConnection()} style={{fontSize:"16px", marginBottom:0, fontWeight:600,color:"#fff", cursor:"pointer"}}>تخطي</p>

                <button className="bankHomeBtn" onClick={() =>  instance.setBankState() } data-test-id="continueBtn1">متابعة</button>
              </div>
              <div className="img-content" style={{ paddingLeft:30}}>
                <img src={image_Blob} />
              </div>
            </div>
            <div style={{position: 'relative'}}>
              <ul className="list-dot-main1">
                <li></li>
                <li></li>
                <li className="current-main"></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
            }
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
