import React from 'react';

interface TimelineItemProps {
  children: React.ReactNode;
}

const TimelineItem: React.FC<TimelineItemProps> = ({ children }) => {
  return (
    <div className="timeline-item">
      <div className="timeline-dot"></div>
      <div className="timeline-details">
        {children}
      </div>
    </div>
  );
};

export default TimelineItem;
