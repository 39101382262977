import { BlockComponent } from "../../framework/src/BlockComponent";

import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import moment from "moment";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Appointment {
  client_id: number,
  client: string,
  slot: {
    data: {
      id: string,
      type: string,
      attributes: {
        booking_date: string,
        plan_name: string,
        plan_duration: number,
        plan_benefits: Array<string>,
        video_meeting_id: string,
        starts_at: string,
        ends_at: string
      }
    }
  },
  wealth_planner_name: string
}

interface S {
  // Customizable Area Start
  openModel: boolean;
  activeSubMenu: string;
  activeMenu: string;
  showQuestionArray: boolean;
  hasSelectedPlanner: boolean;
  serviceSelected: boolean;
  isPinPresent: boolean;
  isDrawerOpen: boolean;
  toastOpenn: boolean;
  currentUniqueId: string[];
  appointments: Appointment[],
  currentPath: string;
  currentAppointment: Appointment | null,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PlannerController extends BlockComponent<Props,
  S,
  SS> {



  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock((this) as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ]);
    this.state = {
      openModel: false,
      activeMenu: "home",
      activeSubMenu: "networth",
      currentPath: window.location.pathname,
      showQuestionArray: true,
      hasSelectedPlanner: false,
      serviceSelected: false,
      isPinPresent: false,
      isDrawerOpen: false,
      toastOpenn: false,
      currentUniqueId: [],
      appointments: [],
      currentAppointment: null
    };
  }


  interval2Id: ReturnType<typeof setInterval> | number = 0;

  VideoCallApiCallid: string = "";



  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.handleVideoResponse( responseJson, apiRequestCallId)



    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    this.interval2Id = window.setInterval(() => this.checkAppointments(), 6000);
    this.GetCustomerDetails();

  }

  GetCustomerDetails = async () => {
    const headers = {
      token: localStorage.getItem("auhtToken"),
      "Content-Type": "application/json",
    };
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.VideoCallApiCallid = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_dashboard/planner_dashboard_home/scheduled_calls`
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);

  }


  

  handleVideoResponse(response:{index: Appointment[]}, apiCallId: string) {

    if (apiCallId === this.VideoCallApiCallid) {
      
      this.setState({ appointments: response.index })
      this.checkAppointments();
    }

  }

  checkAppointments = () => {
    const now = new Date();
    const currentUTCTime = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

    const currentAppointment = this.state.appointments?.find(appointment => {
      const newDate = moment(`${appointment?.slot?.data.attributes.booking_date} ${now.getUTCHours()}:${now.getUTCMinutes()}`, "DD/MM/YYYY HH:mm")
      const appointmentDate = new Date(newDate.utc().format());

      const isToday = now.getUTCFullYear() === appointmentDate.getUTCFullYear() &&
        now.getUTCMonth() === appointmentDate.getUTCMonth() &&
        now.getUTCDate() === appointmentDate.getUTCDate();

      if (!isToday) {
        return false;
      }

      const [startHourString, startMinute] = appointment?.slot?.data.attributes.starts_at.split(':');
      const startHour = parseInt(startHourString, 10);
      const appointmentStart = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), startHour, parseInt(startMinute, 10), 0, 0));

      const [endHourString, endMinute] = appointment?.slot?.data.attributes.ends_at.split(':');
      const endHour = parseInt(endHourString, 10);
      const appointmentEnd = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), endHour, parseInt(endMinute, 10), 0, 0));

      const appointmentStartTime = appointmentStart.getTime();
      const appointmentEndTime = appointmentEnd.getTime();

      const withinAppointmentTimeRange = currentUTCTime >= (appointmentStartTime - 5 * 60 * 1000) && currentUTCTime <= appointmentEndTime;
      return withinAppointmentTimeRange;
    });

    this.setState({
      currentAppointment: currentAppointment || null,
    }, ()=> this.checkLocalStorageData());
  };

  checkLocalStorageData(){
    if (this.state.currentAppointment) {
      localStorage.setItem("wpSlotData", JSON.stringify(this.state.currentAppointment.client_id))
      this.handleToast()
    }else{
      localStorage.removeItem("wpSlotData")
    }
  }

  handleToast() {

    const slotdata = localStorage.getItem("wpSlotData")
    const closeData = localStorage.getItem("wpCloseData")

    if (slotdata) {
      if (closeData) {
        if (slotdata === closeData) {
          this.setState({ toastOpenn: false })
        } else {
          this.setState({ toastOpenn: true })
        }
      } else {
        this.setState({ toastOpenn: true })

      }
}

  }
  handleSidebarToastClose = () => {
    if (this.state.toastOpenn) {
      this.setState({ toastOpenn: false })
      localStorage.setItem("wpCloseData", JSON.stringify(this.state.currentAppointment?.client_id))
    }

  }

}
