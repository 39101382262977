import DateFnsUtils from "@date-io/date-fns";
import React, { useEffect, useState } from "react";
import { format, parse } from "date-fns";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { calendarImg } from "../../blocks/dashboard/src/assets";

const CustomDatePicker: React.FC<{
  currentDate: string;
  onDateChange: (date: string, serviceIndex: number, planId: number, type: string) => void;
  planId: number;
  isReadonly?: boolean;
  type:string;
  serviceIndex:number;
}> = ({ onDateChange, planId, currentDate,isReadonly=false, type,serviceIndex}) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const handleDateChanges = (date: any) => {
    setSelectedDate(date);
    onDateChange(format(date, "do MMMM''yy"),serviceIndex, planId,type);
    
  };

  useEffect(() => {
    if (currentDate) {
      let date = parse(currentDate, "do MMMM''yy", new Date());
      setSelectedDate(date);
    }
  
  }, [currentDate]);

  const toggleDatePicker = () => {
    setIsDatePickerOpen((prevOpen) => !prevOpen);
  };

  let theme = createTheme({
    palette: {
      primary: {
        main: "#2CE2D5",
        contrastText: "#fff",
      },
    },
  });
  theme = createTheme(theme, {
    palette: {
      primary: {
        main: "#2CE2D5",
        contrastText: "#fff",
      },
    },
    overrides: {
      MuiFormControl: {
        root: {
          width: 0,
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#3C3C51",
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          backgroundColor: "rgb(57, 93, 107)",
         color:"rgb(181, 176, 176)"

        },
      }, 
      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: "rgb(57, 93, 107)",
          color:"rgb(181, 176, 176)",
          "& button":{
            backgroundColor: "rgb(57, 93, 107)",
            color:"rgb(181, 176, 176)",
          }
        },
        dayheader:{
          backgroundColor: "rgb(57, 93, 107)",
          color:"rgb(181, 176, 176)"
        },
        dayLabel:{
          color:"rgb(181, 176, 176)"
        }
    },
      MuiPickersDay: {
        day: {
          color: "rgb(181, 176, 176)",
        },
        daySelected: {
          backgroundColor: "#2CE2D5",
        },
        dayDisabled: {
          color: "#ccc",
        },
        current: {
          color: "rgb(181, 176, 176)"
        },
        dayLabel:{
          color:"rgb(181, 176, 176)"
        }
      },
      MuiPickersModal: {
        dialogAction: {
          color: "#33abb6",
          backgroundColor: "#3C3C51",
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          variant="inline"
          margin="normal"
          id="date-picker-inline"
          open={isDatePickerOpen}
          onOpen={toggleDatePicker}
          onClose={toggleDatePicker}
          value={selectedDate}
          onChange={handleDateChanges}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          style={{
            width: 0,
          }}
          className="customDatePicker"
          autoOk={true}
          keyboardIcon={<img src={calendarImg} style={{
            borderRadius:"0",
            height:"18px" ,
            width:"18px",
            marginTop:"-4px"
          }} />}
          helperText=""
          readOnly={isReadonly}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
export default CustomDatePicker;
