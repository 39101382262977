Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "freetrial";
exports.labelBodyText = "freetrial Body";

exports.btnExampleTitle = "CLICK ME";

exports.languageListLogin = {
  English: {
    premium: 'Premium',
    manageSubscription: 'Manage Subscription',
    editDetails:'Edit Details',
    paymentDetails: 'Payment Details',
    method: 'Method',
    deleteWarning: 'Deleting the Primary Card will make the next card as primary.',
    creditDebitCard: 'Credit/Debit Card',
    cannotDelete: 'Cannot delete, need atleast one card as primary',
    billingAddress: 'Billing Address',
    addNewAddress: 'Add new address',
    differentModeOfPayment: 'Choose a different mode of payment for your future subscription.',
    youCanClick: 'You can click',
    manage: 'Manage',
    addRemovePaymentMethods: 'to add or remove payment methods.',
    changeAddress: 'to change your billing address',
    cardDeleted: 'Your card has been deleted.',
    billingAddressUpdated: 'Billing Address updated successfully',
    paymentFailed: 'Payment Failed, try a different payment method or try again later',
    saveAsPrimary: 'Save this as your primary payment card',
    verify:'Verify',
    ZipCodeMandatory:'The zip code for the United States is required. Please enter the zip code first',
    verified: 'Verified',
    enterCVVToVerify: 'Enter CVV to verify',
    primary: 'Primary',
    confirm: 'Confirm',
    cardNumber: 'Card number',
    expiryDate: 'Expiry date',
    cardName: 'Card holder Name',
    cardNamePlaceholder: 'Enter Card holder name',
    enterName:'Enter Name',
    confirmAddress: 'Confirm Address',
    selectCountry: 'Select Country:',
    atleast4Digits: 'Please input atleast 4 digits',
    zipCode:'Zip Code: (Optional)',
    city:'City:',
    address:'Address:',
    name:'Name:',
    editAddress: 'Edit Address',
    addAddress: 'Add Address',
    enterAddress:'Enter Address',
    currentPlan: 'Current Plan',
    planType: 'Plan Type',
    nextBilling:'Next Billing',
    trial30Day: '30 Day trial',
    freeTrial: 'Free trial',
    enterCity:'Enter City',
    max9Digits:'Max 9 digits',
    enterCardNumber: 'Enter card number',
    addAddressValidation: 'Some of your fields are not properly filled',
    nameValidation:'This is not right name',
    zipValidation:'This is not right zipcode',
    cvvValidation:'Enter valid cvv',
    primaryCardUpdated: 'Primary card is updated',
    cardAddSuccess:'Card added successfully',
    billingAddressValidation:'Billing address is required',
    fillDetailsProperly:'Please fill all the details properly',
    congratulations: 'Congratulations',
    subscriptionActive:'Your Subscription is active.',
    trialActive:"Your Trial Subscription is active.",
    continue:'Continue',
    createAccountMyne:'Create an account with Myne to access',
    extraFeature:'extra features with:',
    subscription:'Subscription',
    unlimitedBank:'Unlimited Bank',
    walletSync:'& wallet Sync',
    accessToAll:'Access to all',
    typesOfAssets:'types of assets',
    fullAccessTo:'Full access to',
    estatePlanning:'Estate Planning',
    fullAccess:'Full access',
    toInvestment:'to Investment',
    choose:'Choose',
    plan:'Plan',
    start:'Start',
    for30Days:'for 30 days',
    recommended:'Recommended',
    or:'or',
    after30DaysTrialEnds:'After 30 days free trial, this subscription automatically renews with your monthly payment, unless it is cancelled at least 24 hours before the end of the trial period.',
    proceedingToPurchase:'By proceeding to purchase a plan, you agree to our',
    termsAndConditions:'Terms & Conditions',
    changePlan:'Change plan',
    afterCurrentPlanEnds:'Will be applicable after your currrent plan ends',
    summary:'Summary',
    yourPlan:'Your Plan',
    payment:'Payment',
    totalCharge:'Total Charge',
    paymentMethod:'Payment Method',
    completePurchase:'complete purchase',
    allSet:"You're all set",
    purchaseSuccess:'Your purchase was successful',
    notBeChargedFor: 'You will not be charged for the first trial month, payment will debit from the next month.',
    authenticateCVV:'Authenticate With CVV',
    confirmDetails:'Confirm details',
    expiresOn:'expires on',
   
  },
  Arabic: {
    premium: 'متميز',
    allSet:"أنت جاهز تمامًا",
    purchaseSuccess:'عملية الشراء كانت ناجحة',
    notBeChargedFor: 'لن يتم تحصيل رسوم منك مقابل الشهر التجريبي الأول، وسيتم خصم الدفع اعتبارًا من الشهر التالي.',
    authenticateCVV:'المصادقة مع CVV',
    confirmDetails:'تأكيد التفاصيل',
    expiresOn:'تنتهي في',
    completePurchase:'شراء كامل',
    yourPlan:'خطتك',
    payment:'قسط',
    totalCharge:'إجمالي الرسوم',
    paymentMethod:'طريقة الدفع',
    afterCurrentPlanEnds: 'سيتم تطبيقه بعد انتهاء خطتك الحالية',
    summary:'ملخص',
    after30DaysTrialEnds:'بعد تجربة مجانية مدتها 30 يومًا، يتم تجديد هذا الاشتراك تلقائيًا مع دفعتك الشهرية، ما لم يتم إلغاؤه قبل 24 ساعة على الأقل من نهاية الفترة التجريبية.',
    proceedingToPurchase:'من خلال الشروع في شراء الخطة، فإنك توافق على الشروط والأحكام الخاصة بنا',
    termsAndConditions:'الشروط والأحكام',
    changePlan:'تغيير الخطة',
    or:'أو',
    for30Days:'لمدة 30 يوما',
    recommended:'مُستَحسَن',
    start:'للبدء',
    plan:'الخطة',
    ZipCodeMandatory:'الرمز البريدي للولايات المتحدة مطلوب. الرجاء إدخال الرمز البريدي أولا',
    toInvestment:'للاستثمار',
    choose:'للاختيار',
    fullAccess:'الوصول الكامل',
    estatePlanning:'التخطيط العقاري',
    accessToAll:'الوصول إلى الجميع',
    typesOfAssets:'أنواع الأصول',
    fullAccessTo:'الوصول الكامل إلى',
    subscription:'الاشتراك',
    unlimitedBank:'بنك غير محدود',
    walletSync:'ومزامنة المحفظة',
    createAccountMyne:'قم بإنشاء حساب مع Myne للوصول',
    extraFeature:'ميزات إضافية مع:',
    subscriptionActive:'اشتراكك نشط.',
    trialActive:"اشتراكك التجريبي نشط.",
    continue:'يكمل',
    congratulations:'تهانينا',
    trial30Day: 'تجربة لمدة 30 يومًا',
    primaryCardUpdated:'يتم تحديث البطاقة الأساسية',
    fillDetailsProperly:'يرجى ملء كافة التفاصيل بشكل صحيح',
    cardAddSuccess:'تمت إضافة البطاقة بنجاح',
    billingAddressValidation:'عنوان إرسال الفواتير مطلوب',
    freeTrial: 'تجربة مجانية',
    enterCity:'أدخل المدينة',
    max9Digits:'الحد الأقصى 9 أرقام',
    enterCardNumber: 'أدخل رقم البطاقة',
    addAddressValidation: 'بعض الحقول الخاصة بك لم يتم ملؤها بشكل صحيح',
    nameValidation:'هذا ليس الاسم الصحيح',
    zipValidation:'هذا ليس الرمز البريدي الصحيح',
    cvvValidation:'أدخل رمز CVV صالحًا',
    currentPlan:'الخطة الحالية',
    planType: 'نوع الخطة',
    nextBilling:'الفواتير القادمة',
    manageSubscription: 'إدارة الاشتراك',
    cardNumber: 'رقم البطاقة',
    expiryDate: 'تاريخ انتهاء الصلاحية',
    atleast4Digits: 'الرجاء إدخال 4 أرقام على الأقل',
    zipCode:'الرمز البريدي: (اختياري)',
    city:'مدينة:',
    address:'عنوان:',
    name:'اسم:',
    editAddress: 'تحرير العنوان',
    addAddress: 'إضافة عنوان',
    enterAddress:'أدخل العنوان',
    cardName: 'اسم حامل البطاقة',
    cardNamePlaceholder: 'أدخل اسم حامل البطاقة',
    enterName:'أدخل الاسم',
    confirmAddress: 'تأكيد العنوان',
    selectCountry: 'اختر الدولة:',
    editDetails: 'تحرير التفاصيل',
    paymentDetails: 'تفاصيل الدفع',
    method: 'طريقة',
    deleteWarning: 'سيؤدي حذف البطاقة الأساسية إلى جعل البطاقة التالية أساسية.',
    creditDebitCard: 'بطاقة الائتمان/الخصم',
    cannotDelete: 'لا يمكن الحذف، تحتاج إلى بطاقة واحدة على الأقل كبطاقة أساسية',
    billingAddress: 'عنوان الفواتير',
    addNewAddress: 'أضف عنوانًا جديدًا',
    differentModeOfPayment: 'اختر طريقة دفع مختلفة لاشتراكك المستقبلي.',
    youCanClick: 'يمكنك النقر',
    manage: 'يدير',
    addRemovePaymentMethods: 'لإضافة أو إزالة طرق الدفع.',
    changeAddress: 'لتغيير عنوان إرسال الفواتير الخاص بك',
    cardDeleted: 'لقد تم حذف بطاقتك.',
    billingAddressUpdated: 'تم تحديث عنوان إرسال الفواتير بنجاح',
    paymentFailed: 'فشل الدفع، حاول طريقة دفع مختلفة أو حاول مرة أخرى لاحقًا',
    saveAsPrimary: 'احفظ هذه كبطاقة الدفع الأساسية الخاصة بك',
    verify:'يؤكد',
    verified: 'تم التحقق منه',
    enterCVVToVerify: 'أدخل CVV للتحقق',
    primary: 'أساسي',
    confirm: 'تاكيد',

  }
}
// Customizable Area End