import React from "react";
// Customizable Area Start
import "./style.css";
import {
    settingicon,
    subarrowicon,
    cryptoPortfolio,
    cryptoTicker
} from "./assets";
import {
    Box,
    Typography,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { backIcon } from "../../forgot-password/src/assets";
import Grid from '@material-ui/core/Grid';

// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
export default class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        
        // Customizable Area End
    }
    // Customizable Area Start

   
    renderDir =()=>{
        return this.state.direction === 'rtl' ? 'rtl' : 'ltr'
      }
      renderBtnPositionRight =()=> this.state.direction === 'rtl' ? '':'30px';
      renderBtnPositionLeft =()=> this.state.direction === 'rtl' ? '30px':''

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div className="pagedashboard-wrapper" dir={this.renderDir()}>
                <div className="header-wrapper">
                    <div className="page-title">
                        <h4>
                            <NavLink to="/CategoriessubcategoriesWeb"><img src={backIcon} alt="back-icon" style={{transform: this.state.direction ==='rtl'? 'scaleX(-1)':'' }} /></NavLink>
                            &nbsp; {this.getStringDashboard("AddCrypto")}
                        </h4>
                    </div>
                    <div id="pageInfoStocKTicker" className="page-info">
                        <ul data-test-id="StockCurrencyDropDown" className="drop-menu">
                            <li >
                                <div
                                    data-test-id="currencyDropdoen"
                                    id="currencyStockDropdown"
                                    onClick={() => this.handleDropdown()}
                                    className={this.state.dropdownVisiblity}
                                >
                                    <p >
                                        {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                                    </p>
                                    <ul id="labelForStock">
                                        {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                            return <li data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)}>{value.symbol + value.currency_type}</li>
                                        })}

                                    </ul>
                                </div>
                            </li>
                            <li >
                                
                                <NavLink to="/Account-Detail">
                                    <img date-test-id="settingIcon" src={settingicon} alt="setting" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <Box>
                    <Grid container spacing={6} style={{ margin: '20px' }} data-test-id="cryptoBoxId">
                        <Grid item>
                            {this.state.cryptoBtn == 'wallet' ?
                            <>
                            <Box
                                style={{...webStyle.stockCardActive,flexDirection: 'column',}}
                            >
                                <Box>
                                    <img src={cryptoPortfolio} style={{ width: '50px', margin: 'auto' }} />
                                </Box>
                                <Typography style={webStyle.stockPortfolioLabel}>
                                     {this.getStringDashboard("CryptoWalletExchange")}
                                </Typography>
                            </Box>
                            </> :
                            <Box
                            onClick={()=>this.setState({cryptoBtn:'wallet'})}
                            data-test-id="portfolioCard"
                                style={{...webStyle.stockCard,flexDirection: 'column',}}
                            >
                                <Box>
                                    <img src={cryptoPortfolio} style={{ width: '50px', margin: 'auto' }} />
                                </Box>
                                <Typography style={{textAlign:'center',...webStyle.stockPortfolioLabel}}>
                                {this.getStringDashboard("CryptoWalletExchange")}
                                </Typography>
                            </Box>
       }
                        </Grid>
                        <Grid item>
                            {this.state.cryptoBtn == 'ticker' ?  
                            <Box
                                style={{...webStyle.stockCardActive, flexDirection:'column'}}
                            >
                                <Box>
                                    <img src={cryptoTicker} style={{ width: '50px', margin: 'auto' }} />
                                </Box>
                                <Typography style={webStyle.stockTickerLabel}>
                                 {this.getStringDashboard("CryptoPositions")}
                                </Typography>
                            </Box> :
                            <Box
                            onClick={()=>this.setState({cryptoBtn:'ticker'})}
                            data-test-id="tickerCard"
                                style={{...webStyle.stockCard, flexDirection:'column'}}
                            >
                                <Box>
                                    <img src={cryptoTicker} style={{ width: '50px', margin: 'auto' }} />
                                </Box>
                                <Typography style={webStyle.stockTickerLabel}>
                                {this.getStringDashboard("CryptoPositions")}
                                </Typography>
                            </Box>}
                        </Grid>
                        <Grid item xs={12} sm={3}></Grid>
                        <Grid item xs={12} sm={3}></Grid>

                    </Grid>
                </Box>
                <Box>
                    <Box style={{...webStyle.btnBox, position:'absolute', bottom:'20px',right:this.renderBtnPositionRight(), left: this.renderBtnPositionLeft() , borderRadius:'8px', padding:'10px'}} data-test-id="nextBtn" onClick={()=>this.redirectToNext1()}>
                        <Typography style={webStyle.btnLabel}>
                            {this.getStringDashboard("Next")}
                        </Typography>
                    </Box>
                </Box>
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

type IStyle = { [key: string]: React.CSSProperties }

const webStyle :IStyle= {
    btnLabel: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 700 },
    stockTickerLabel: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '24px' },
    stockCardActive: {cursor:'pointer',  display: 'flex', justifyContent: 'center', alignItems: 'center',  height: '200px', width: '200px', borderRadius: '12px', background: '#2CE2D5', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)'},
    stockCard: {cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',  height: '200px', width: '200px', borderRadius: '12px', background: '#2A2A39', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)'},
    btnBox: { cursor:'pointer',backgroundColor: '#C9F9F6', width:'135px', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' },
    stockPortfolioLabel: { color: '#FFF', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '24px',textAlign:'center' },
}
// Customizable Area End
