import React from "react";

import {
  Container,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Grid,
  Card,
  Divider,
  CardContent,
  Box,
  Dialog,
  DialogContent,
  OutlinedInput,
  createTheme,
  ThemeProvider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DialogTitle,
  DialogContentText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Popover,
  Snackbar,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { arrowImage, deleteIcon, arrowDown, SuccessCard, CheckboxUnselect, Checkbox, CheckboxRight, AlertImage } from "./assets";
import CustomPagination from "../../../components/src/CustomPagination"
import { styled } from '@material-ui/styles';


let theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    }
  }
});
theme = createTheme(theme, {
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        lineHeight: "1.35rem",
        "& $notchedOutline": {
          borderColor: "#808d9e !important",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#808d9e !important",
        },
        "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
          borderColor: "#808d9e !important",
        },
        "& .MuiOutlinedInput-input": {
          color: "white",
          width: '100%',
          borderBottom: '0px',
        },

        "&.price-box": {
          "& .MuiOutlinedInput-input": {
            width: '140px',
            color: "#808d9e !important",
            fontWeight: 600,
            textAlign: "right"

          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#808d9e !important",
          },
        },
        "&.template-field-edit": {
          "& .MuiOutlinedInput-input": {
            width: '220px',
            color: "#808d9e !important",
            fontWeight: 600,

          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#808d9e !important",
          },
        },

        "&.price-box-1": {
          "& .MuiOutlinedInput-input": {
            width: '140px',
            color: "#fff !important",
            fontWeight: 600,

          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#808d9e !important",
          },
        },


      },

    },
    MuiAccordion: {
      root: {
        "&.primary-accordion": {
          borderBottom: "1px solid #808d9e",
          transition: "all 0.2s ease",

          "& .MuiAccordionSummary-root": {
            padding: 0,

            "& .MuiAccordionSummary-content": {
              fontWeight: 500,
              margin: "12px 0",
              fontSize: 16,
              lineHeight: "24px",
              transition: "all 0.1s ease",
            },
            "& .MuiAccordionSummary-expandIcon": {
              padding: 0
            },

            "& .MuiAccordionSummary-expandIconWrapper": {
              minWidth: 24,
              height: 24,
              marginLeft: 10,
              background: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            "&.Mui-expanded": {
              margin: 0,
              minHeight: 44,
            },
            "&.Mui-focusVisible": {
              backgroundColor: "transparent",
              "& .MuiAccordionSummary-content": {
                fontWeight: 600,
              },
            },
          },
          "& .MuiCollapse-root": {
            "& .MuiAccordionDetails-root": {
              padding: "0 16px 20px",

            },
          },
          "&.Mui-expanded": {
            marginBottom: 0,
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
          },
          "&:before": {
            content: "none",
          },
          "&:hover": {
            backgroundColor: "rgba(10, 73, 74, 0.07)",
          },
        },
      },
    },
    MuiTableContainer: {
      root: {
        boxShadow: 'none',
        borderRadius: 0,
        '&.normal-grid': {
          '& .MuiTable-root': {
            '& .MuiTableHead-root': {
              '& .MuiTableCell-root': {
                padding: '22px 22px',
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '20px',
                letterSpacing: '0.25px',
                color: '#8f99a3',
                borderColor: '#808d9e',
                whiteSpace: 'nowrap',
              },
            },
            '& .MuiTableBody-root': {
              '& .MuiTableRow-root.MuiTableRow-hover:hover': {
                backgroundColor: 'rgba(9,72,73,0.05)',
                cursor: 'pointer',
                paddingTop: 6,
                paddingBottom: 6
              },
              '& .MuiTableCell-root': {
                padding: '22px 22px',
                fontFamily: "'MaderaRegular', sans-serif",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: '20px',
                letterSpacing: '0.25px',
                color: '#fff',
                borderColor: '#808d9e',

              },
            },
          },
        },
      },

    },
    MuiAccordionDetails: {
      root: {
        display: "unset"
      }
    },
    MuiPopover: {
      root: {
        zIndex: 999,
        borderRadius: "14px",
        '&.simple-tooltip .MuiPaper-root': {
          padding: 14,
          boxShadow: 'none',
          fontSize: 12,
          lineHeight: 16,
          letterSpacing: '0.4px',
          backgroundColor: "#395d6b !important",
          borderRadius: "14px",
        },
        "& .MuiPaper-rounded": {
          borderRadius: "0px",
          boxShadow: 'none',
        }

      },
    },
  },
});


// Customizable Area End

import Proposalgeneration2Controller, {
  Props,
} from "./Proposalgeneration2Controller";
import UserProfile from "../../../components/src/UserProfile.web";
import { Form, Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { subarrowicon } from "../../dashboard/src/assets";
import { formatNumberWithCommas, getFirstCharacterFromEachWord, sanitizeAndRemoveCommas } from "../../../components/src/commonFn";


export default class Proposalgeneration2 extends Proposalgeneration2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderProfile = () => {
    return (
      <div data-test-id="profileLink" style={styles.container} onClick={() => this.goToProfilePage()}>
        <div>
          {this.state.plannerInfo.image ? (
            <img
              src={this.state.plannerInfo.image}
              style={styles.imageProfile}
              alt="profile"
            />
          ) : (
            <div style={styles.noImg}>
              {getFirstCharacterFromEachWord(this.state.plannerInfo.name)}
            </div>
          )}
        </div>
        <div style={styles.textContainers}>
          <span style={styles.nameStyle}>{this.state.plannerInfo.name}</span>
          <span style={styles.email}>{this.state.plannerInfo.email}</span>
        </div>
      </div>
    )
  }


  renderSnackBar = () => {
    return (
      <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} anchorOrigin={{
        vertical: 'top', horizontal: 'center'
      }} onClose={this.handleCloseSnackbar}>
        <Box style={styles.snakbar}>
          <Grid container justifyContent="center" alignContent="center">
            <Grid item>
              <img src={AlertImage} alt="" style={{
                paddingTop: "4px",
                paddingRight: "12px"
              }} />
            </Grid>
            <Grid item>
              {this.state.errorMsg}
            </Grid>

          </Grid>
        </Box>
      </Snackbar>
    )
  }

  renderTemplate = () => {
    return (
      <>
        {
          this.state.activeTab === "templates" && this.state.templates.length > 0 &&
          <Grid container spacing={4} style={styles.gridContainer} >
            {this.state.templates.map((template, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} >
                <Card style={styles.templateCard}>
                  <div style={styles.deleteIconContainer} onClick={() => this.openDeleteTemplateModal(template.id)} data-test-id="deleteTemplate">  <img src={deleteIcon} alt="delete" style={styles.arrowImage} /> </div>
                  <Typography style={styles.templateText}>{template.name}</Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        }
        {
          this.state.activeTab === "templates" && this.state.templates.length < 1 &&
          <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
            <Box>
              <Typography align="center" style={styles.noTemplateText} data-test-id="noTemplate">
                No templates saved yet.
              </Typography>
            </Box>
          </Box>
        }
      </>
    )
  }

  renderClients = () => {
    return (
      <>
        {
          this.state.activeTab === "clients" && this.state.clients.length < 1 &&
          <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
            <Box>
              <Typography align="center" style={styles.noTemplateText} data-test-id="noTemplate">
                No clients available.
              </Typography>
            </Box>
          </Box>
        }
        {
          this.state.activeTab === "clients" && this.state.clients.length > 0 &&
          <Box>
            <Box style={{
              display: "flex",
              justifyContent: "flex-end"
            }}>
              <Button style={styles.sendServiceBtn} onClick={() => this.handleSendServiceModal()} data-test-id="send-services-btn">
                Send Service
              </Button>
            </Box>

            <Box style={styles.mainContainer}>
              <Box style={{
                borderBottom: "1px solid #fff"
              }}>
                <Typography style={styles.activeStyle}>
                  Active Clients
                </Typography>
              </Box>
              <TableContainer className="normal-grid">
                <Table style={{ minWidth: 300 }}>
                  <TableHead>
                    <TableRow >
                      <TableCell><img style={{ borderRadius: 0 }} data-test-id="select-all-btn" onClick={() => this.handleSelectAll()} src={this.state.selectedClients.length !== this.state.rowPerPage ? CheckboxUnselect : CheckboxRight} /></TableCell>
                      <TableCell align="left">Client Name</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Services</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.clients.slice((this.state.currentPage - 1) * this.state.rowPerPage, ((this.state.currentPage - 1) * this.state.rowPerPage) + this.state.rowPerPage).map((client) => (
                      <TableRow key={client.id}>
                        <TableCell>
                          <img data-test-id="select-single-client" onClick={() => this.handleSelectClient(client.attributes.client_id)} style={{ borderRadius: 0 }} src={this.isSelected(client.attributes.client_id) ? CheckboxRight : Checkbox} />
                        </TableCell>
                        <TableCell align="left">
                          {client.attributes.name ? client.attributes.name : "Client Name"}
                        </TableCell >
                        <TableCell align="left">
                          {client.attributes.date}
                        </TableCell>
                        <TableCell align="left">
                          <Typography style={client.attributes.proposal_sent_status ? styles.sendServiceLabel : styles.notSendServiceLable}>
                            {client.attributes.proposal_sent_status ? "Sent" : "Not Sent"}
                          </Typography>
                        </TableCell>
                        <TableCell ><Typography data-test-id="send-service-single-client" component="span" style={styles.sendServiceText} onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => this.handleServiceClick(event, client.attributes.client_id)} >Send Service</Typography> </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box style={{
                marginTop: "2rem"
              }}>
                <CustomPagination page={this.state.currentPage} totalRecord={this.state.clients.length} rowPerPage={this.state.rowPerPage} handlePageChange={(page) => this.handlePageChange(page)} />
              </Box>
            </Box>
          </Box>
        }
      </>
    )
  }

  renderPrice = (price: string, displayPrice: string, canDisplay: boolean) => {
    return (
      <>
        {
          displayPrice && canDisplay && <Grid item style={styles.price}>
            <OutlinedInput data-test-id="disabled-price" className="price-box" readOnly value={`${
              formatNumberWithCommas(displayPrice)}`} />
          </Grid>
        }
      </>
    )
  }

  renderSaveButton = (values: {
    services: {
      service_id: number;
      price: string;
      currency: string;
      plan_inclusion: {
        service_detail_id: number;
        description: string;
      }[];
      is_selected: boolean;
    }[];
    save_as_template: boolean;
    name: string;
  }, handleChange: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    return (
      <>
        <Box>
          {
            this.state.isServiceSendDialogOpen &&
            <Grid container spacing={0} style={{
              marginTop: "18px",
              alignItems: "center"
            }}>
              <Grid item xs={1} style={{ marginLeft: "12px" }}>
                <div>
                  <div onClick={() => setFieldValue('save_as_template', !values.save_as_template, true)}>
                    <img style={{ borderRadius: 0 }} src={values.save_as_template ? CheckboxRight : Checkbox} />
                  </div></div>
              </Grid>
              <Grid item xs={4}>
                <Typography component="span" style={styles.nameText1}> Save as a template</Typography>
              </Grid>
              <Grid item xs={12} md={6} style={{ alignSelf: 'flex-start', textAlign: "right" }}>
                <OutlinedInput data-test-id="template-field-edit" className="template-field-edit" value={`${values.name}`}
                  onChange={handleChange(`name`)} placeholder="Enter template name"
                />
              </Grid>
            </Grid>
          }
        </Box>
        <Box   sx={{
    display: "flex",
    justifyContent: "space-between",
    marginTop: "4rem",
    marginBottom: "1rem",
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gridRowGap:{
      xs: '1rem',
      md: '0',
    }
  }}
  >
          <Button type="button" data-test-id="cancelService" onClick={() => {
            this.handleOpenCancelServiceModal()
          }} style={styles.cancelBtn}>Cancel</Button>

          <Button style={styles.saveBtn} data-test-id="submitForm" type="submit">{this.state.isServiceSendDialogOpen ? "Send" : "Save"}</Button>
        </Box>
      </>
    )
  }

  renderServiceModal = () => {
    return (
      <Dialog
        open={this.state.openEditServiceModal}
        keepMounted
        data-test-id="edit-service-id"
        aria-labelledby="proposal-edit"
        aria-describedby="proposal-edit"
        PaperProps={{ style: styles.dialogStyle }}
      > <Toaster containerStyle={{ zIndex: 99999 }} />
        <Formik
          initialValues={{ "services": this.getInitialValue(), "save_as_template": false, "name": "" }}
          onSubmit={(values, actions) => {
            this.serviceProposalSchema
              .validate(values, { abortEarly: false })
              .then(() => {
                this.handleSubmit(values);
              })
              .catch((errors) => {
                errors.inner.forEach((error: { message: string; }) => {
                  toast.error(error.message);
                });
                actions.setSubmitting(false);
              });
          }} data-test-id="service-edit-form"
        >
          {({ values, handleSubmit, handleChange, setFieldValue }) => (
            <Form translate={"eng"} onSubmit={handleSubmit}>
              <DialogContent>
                {this.state.services.map((service, index) => (
                  <Accordion className="primary-accordion" data-test-id="primary-accordion"
                    elevation={0} style={styles.accordion} expanded={this.state.expandedServiceId === service.id} >
                    <AccordionSummary expandIcon={<div style={{ padding: "12px" }} data-test-id="expand-accordion" onClick={() => this.handleAccordionChange(service.id)}>
                      <img src={arrowDown} alt="ExpandIcon" />
                    </div>
                    }>
                      <Grid container style={styles.headerRoot} alignItems="center">
                        <Grid item style={styles.imageTitle}>
                          {
                            this.state.isServiceSendDialogOpen && this.state.expandedServiceId !== service.id &&
                            <img style={{ borderRadius: 0, paddingRight: "18px", height: "18px" }} onClick={() => { setFieldValue(`services.${index}.is_selected`, !values.services[index].is_selected, true) }} src={values.services[index].is_selected ? CheckboxRight : Checkbox} />
                          }
                          {
                            service.attributes.image && <img src={service.attributes.image} style={styles.image} alt="" />
                          }
                          <Typography style={styles.editServiceNameText}> {service.attributes.name} </Typography>
                        </Grid>
                        <Grid item style={styles.price}>
                          {
                            this.state.expandedServiceId === service.id &&
                            <OutlinedInput data-test-id="price-box-edit" className="price-box-1" value={formatNumberWithCommas(`${values.services[index].price}`)} inputMode="text"
                              onChange={handleChange(`services.${index}.price`)}
                              onKeyPress={(event) => this.handleKeyPress(event)}
                            />
                          }
                        </Grid>
                        {
                          this.renderPrice(service.attributes.price, values.services[index].price, this.state.expandedServiceId !== service.id)
                        }
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        {
                          this.state.expandedServiceId === service.id &&
                          <div style={{ marginTop: "-40px", position: "relative" }}>
                            <div style={{ display: "flex", justifyContent: "end" }}>
                              <Typography style={styles.commisionFeeText}>   Myne commision fee: {this.state.commisionFee}%   </Typography>
                            </div>
                            <div style={{ display: "flex", justifyContent: "end" }}>
                              {
                                values.services[index].price &&
                                <Typography style={styles.totalText}>
                                  Total : {formatNumberWithCommas(parseFloat((parseFloat(sanitizeAndRemoveCommas(values.services[index].price)) + (this.state.commisionFee / 100 * parseFloat(sanitizeAndRemoveCommas(values.services[index].price)))).toString()).toFixed(2))} { } AED
                                </Typography>
                              }
                            </div>
                          </div>
                        }
                        <div style={{ marginTop: "2rem" }}>
                          <div style={styles.planInclusionTitleContainer}>
                            <Divider style={styles.divider1} />
                            <Typography style={styles.planInclusionText1} >  Plan Inclusions </Typography>
                            <Divider style={styles.divider1} />
                          </div>

                          {service.attributes.plan_inclusions.map((planInclusion, secondryIndex) => {
                            return (
                              <Grid container spacing={0} style={styles.planInclusionContainer}>
                                {
                                  this.state.isServiceSendDialogOpen &&
                                  <Grid item md={1}>
                                    {
                                      secondryIndex === 0 &&
                                      <img style={{ borderRadius: 0 }} onClick={() => { setFieldValue(`services.${index}.is_selected`, !values.services[index].is_selected, true) }} src={values.services[index].is_selected ? CheckboxRight : Checkbox} />
                                    }
                                  </Grid>
                                }
                                <Grid item md={this.getWidthOfDialog()}>
                                  <div>
                                    <div style={styles.imageTitle}>
                                      <img src={arrowImage} alt="arrow" style={styles.arrowImage} />
                                      <Typography style={styles.nameText1}>  {planInclusion.name}   </Typography>
                                    </div>
                                    <OutlinedInput
                                      data-test-id="txtInputEmail"
                                      className="inputBox"
                                      fullWidth={true}
                                      value={`${values.services[index].plan_inclusion[secondryIndex]?.description}`}
                                      onChange={handleChange(`services.${index}.plan_inclusion.${secondryIndex}.description`)}
                                      style={styles.inputRoot1}
                                      maxRows={4}
                                      rows={4}
                                      multiline
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            )
                          })}
                        </div>
                      </>
                    </AccordionDetails>
                  </Accordion>
                ))}
                {this.renderSaveButton(values, handleChange, setFieldValue)}

              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }
  renderTemplateSelectDialog = () => {
    return (<Dialog
      open={this.state.selectTemplateDialog}
      keepMounted
      data-test-id="select-template-dialog"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          ...this.dialogTemplateSelectionStyle,
          width: '72%',
          textAlign:'center',
        },
      }}
      maxWidth={false}
    >
      <DialogContent style={{ padding: '8px 20px' }}>
        <DialogContentText id="alert-dialog-slide-description" >
          <Grid container spacing={5} style={{ marginTop: "20px" }} >
            {this.state.templates.map((template, index) => (
              <Grid item key={index} xs={6} md={6} data-test-id="template-name" onClick={() => this.handleSelectTemplate(template.id)}>
                <Card style={this.state.selectedTemplateId === template.id ? styles.templateCard2 : styles.templateCard1}>
                  <Typography style={this.state.selectedTemplateId === template.id ? styles.templateText1 : styles.templateText}>{template.name}</Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
          <CustomBox>
            <Button type="button" style={styles.cancelBtn} data-test-id="close-template" onClick={() => this.closeSelectTemplateDialog()}>Cancel</Button>
            <Button style={styles.saveBtn} data-test-id="save-template" onClick={() => this.sendTemplate()} >Send</Button>
          </CustomBox>
        </DialogContentText>
      </DialogContent>
    </Dialog>)

  }


  // Customizable Area End

  render() {
    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.renderSnackBar()}

        <div style={{
          width: "100%",
          background: "#2a2a39"
        }} className="pagedashboard-wrapper2">
          <div
            className="header-wrapper"
            style={{ justifyContent: "end", }}
          >
            <div className="page-info">
              <ul className="drop-menu" style={{
                gridGap: "20px"
              }}>
                <li>
                  {this.renderProfile()}
                </li>
              </ul>
            </div>
          </div>

          <Box style={{
            margin: '24px 0',
            background: "#2a2a39"
          }}>
            <Box style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '16px',
              overflowX: 'auto',
              padding: '16px 0'
            }}>
              <Box style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                // overflowX:'auto'
              }}>
                <Typography style={this.getTabDesign("services")} data-test-id="service-tab" onClick={() => this.handleTabChange("services")}> Services </Typography>
                <Typography style={this.getTabDesign("templates")} data-test-id="templates" onClick={() => this.handleTabChange("templates")}> Templates </Typography>
                <Typography style={this.getTabDesign("clients")} data-test-id="clients" onClick={() => this.handleTabChange("clients")}> Clients </Typography>
              </Box>
              {
                this.state.activeTab === "services" &&
                <Button style={styles.editButton} onClick={() => this.handleEditServiceModal()} data-test-id="edit-services-btn"> Edit </Button>
              }
            </Box>

            <Box>
              {
                this.state.activeTab === "services" &&
                <Grid container spacing={4}>
                  {this.state.services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} >
                      <ServiceCard>
                        <CardContent style={styles.cardContainer}>
                          <Grid container style={styles.headerRoot} alignItems="center">
                            <Grid item style={styles.imageTitle}>
                              {
                                service.attributes.image && <img src={service.attributes.image} style={styles.image} alt="" />
                              }
                              <Typography style={styles.nameText}> {service.attributes.name} </Typography>
                            </Grid>
                            {
                              parseInt(service.attributes.price) > 0 && <Grid item style={styles.price}>
                                <Typography variant="h6" style={styles.priceText}> {service.attributes.currency} {formatNumberWithCommas(parseFloat(service.attributes.price).toFixed(2).toString())}   </Typography>
                              </Grid>
                            }
                          </Grid>
                          <div style={styles.planInclusionTitleContainer}>
                            <Divider style={styles.divider} />
                            <Typography style={styles.planInclusionText} >  Plan Inclusions </Typography>
                            <Divider style={styles.divider} />
                          </div>
                          {service.attributes.plan_inclusions.map((planInclusion) => {
                            return (
                              <div style={styles.planInclusionContainer}>
                                <div style={styles.imageTitle}>
                                  <img src={arrowImage} alt="arrow" style={styles.arrowImage} />
                                  <Typography style={styles.nameText}>  {planInclusion.name}   </Typography>
                                </div>
                                {planInclusion.explanation ? (
                                  <Typography style={styles.explanationText}>  {planInclusion.explanation}  </Typography>
                                ) : (
                                  <div style={styles.noExplanationTextContainer}>
                                    <Typography style={styles.noExplanationText}> Enter description   </Typography>
                                  </div>
                                )}
                              </div>
                            )
                          })}
                        </CardContent>
                      </ServiceCard>
                    </Grid>
                  ))}

                </Grid>
              }
            </Box>


            <Box>
              {this.renderTemplate()}
            </Box>
            <Box>
              {this.renderClients()}
            </Box>

          </Box>






          <Box>
            {this.state.openEditServiceModal && this.renderServiceModal()}
          </Box>
          <Dialog
            open={this.state.openDeleteModel}
            keepMounted
            data-test-id="dialogModel"
            onClose={this.closeDeleteModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ style: this.dialogPaperStyle }}
          >
            <DialogTitle id="alert-dialog-slide-title" style={styles.dialogTitle}>
              <span style={styles.dialogTitleHeader}>Are you sure you want to delete?</span></DialogTitle>
            <DialogContent style={styles.dialogContent}>
              <DialogContentText id="alert-dialog-slide-description"
                style={styles.dialogContainer}>
                <div className="modal-button-container">
                  <div className="modal-button modal-discard" style={{
                    borderRadius: "7px",
                    height: "40px",
                    marginRight: "3rem"
                  }} data-test-id='cancelDelete' onClick={() => this.closeDeleteModal()}>
                    Cancel
                  </div>
                  <div style={{
                    borderRadius: "7px",
                    height: "40px",
                    color: "#c9f9f6",
                    border: "1px solid #c9f9f6"
                  }} className="modal-button modal-save" data-test-id='yesDelete' onClick={() => this.deleteTemplate()}>
                    Yes
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.cancelServiceModal}
            keepMounted
            data-test-id="dialogModel"
            style={{
              zIndex: 1400
            }}
            onClose={this.handleCloseCancelServiceModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ style: this.dialogPaperStyle }}
          >
            <DialogTitle id="alert-dialog-slide-title" style={styles.dialogTitle}>
              <span style={styles.dialogTitleHeader}>Are you sure you want to cancel?</span></DialogTitle>
            <DialogContent style={styles.dialogContent}>
              <DialogContentText id="alert-dialog-slide-description"
                style={styles.dialogContainer}>
                <div className="modal-button-container" style={{
                  marginBottom: "1rem"
                }}>
                  <div className="modal-button modal-discard" style={{
                    borderRadius: "7px",
                    height: "40px",
                    marginRight: "3rem"
                  }} data-test-id='cancel-service' onClick={() => this.handleCloseCancelServiceModal()}>
                    Cancel
                  </div>
                  <div style={{
                    borderRadius: "7px",
                    height: "40px",
                    color: "#c9f9f6",
                    border: "1px solid #c9f9f6"
                  }} className="modal-button modal-save" data-test-id='confirm-cancel-service' onClick={() => this.handleCloseServiceModal()}>
                    Yes
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.openSuccessDialog}
            keepMounted
            data-test-id="dialogModel"
            onClose={this.dialogSuccessModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ style: this.dialogSuccessPaperStyle }}
          >
            <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center", marginTop: "2rem" }}><img src={SuccessCard} /></DialogTitle>
            <DialogContent style={{ padding: '8px 20px' }}>
              <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
                textAlign: "center",
                marginBottom: "3rem"
              }}>
                {this.state.successMsg}
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Popover
            open={Boolean(this.state.anchorToolTipEl)}
            elevation={0}
            anchorEl={this.state.anchorToolTipEl}
            onClose={() => this.closePopover()}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            className="simple-tooltip"
          >
            <Box style={styles.actionContainer}>
              <Typography style={styles.defaultServiceTxt} data-test-id="send-default-service" onClick={() => this.handleServiceSelectDialog()}>
                Send default service
              </Typography>
              <Typography data-test-id="send-from-template" onClick={() => this.handleTemplateSelectDialog()} style={styles.sendFromTemplateTxt}>
                Send from templates
              </Typography>
            </Box>
          </Popover>
          {this.renderTemplateSelectDialog()}
          <Dialog
            open={this.state.openSendServiceModal}
            keepMounted
            data-test-id=""
            onClose={this.closeSendServiceModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ style: this.dialogStyle }}
          >

            <DialogContent style={styles.dialogContent}>
              <DialogContentText id="alert-dialog-slide-description"
                style={styles.dialogContainer}>
                <Grid container style={{ justifyContent: "center", textAlign: "center" }}>
                  <Grid item md={12} >
                    <Button style={{ ...styles.saveBtn, width: "80%", textAlign: "center" }} data-test-id="sendFromServicesMultiple" onClick={() => this.handleServiceSelectDialog(true)}>
                      Send from default service
                    </Button>
                  </Grid>
                  <Grid item md={12} style={{ marginTop: "3rem" }}>
                    <Button onClick={() => this.handleTemplateSelectDialog(true)} data-test-id="sendFromTemplatesMultiple" style={{ ...styles.cancelBtn, width: "80%", textAlign: "center" }}>
                      Send from templates
                    </Button>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

type IStyle = { [key: string]: React.CSSProperties };
const GridCotainer = styled(Box)({
  display: "flex", width: "1680px", overflowX: "auto", paddingBottom: "2rem",
  '@media (min-width: 1200px)':
  {
    width: '890px',
  },
  '@media (min-width: 1100px)':
  {
    width: '800px',
  },
  '@media (min-width: 1000px)':
  {
    width: '780px',
  },
  '@media (min-width: 1300px)':
  {
    width: '1000px',
  },
  '@media (min-width: 1400px)':
  {
    width: '990px',
  },
  '@media (min-width: 1500px)':
  {
    width: '1150px',
  }, '@media (min-width: 1600px)':
  {
    width: '1110px',
  }, '@media (min-width: 1700px)':
  {
    width: '1360px',
  }, '@media (min-width: 1800px)':
  {
    width: '1500px',
  }, '@media (min-width: 2400px)':
  {
    width: '1980px',
  },
})

const CustomBox=styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '2rem',
  marginBottom: '2rem',
  gap: '20px',

  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px'
  }
})
const ServiceCard = styled(Grid)(
  {
    display: "inline-block", width: "300px", height: "100%", marginRight: "3rem", verticalAlign: "top", background: "unset", boxShadow: "none", border: "1px solid #d8d8d8", borderRadius: "10px",
    '@media (min-width: 1000px)':
    {
      width: '324px',
    }, '@media (min-width: 1700px)':
    {
      width: '360px',
    }, '@media (min-width: 1800px)':
    {
      width: '433px',
    },
    '@media (min-width: 2000px)':
    {
      width: '490px',
    },
    '@media (min-width: 2400px)':
    {
      width: '572px',
    }
  })

const styles: IStyle = {
  root: { overflowX: "auto", whiteSpace: "nowrap", flexGrow: 1 },
  card: { display: "inline-block", margin: "2rem", width: "435px", height: "90%", verticalAlign: "top", background: "unset", boxShadow: "none", border: "1px solid #d8d8d8", borderRadius: "10px" },
  headerRoot: { flexGrow: 1, padding: "12px" },
  imageTitle: { display: "flex", alignContent: "center" },
  price: { marginLeft: "auto" },
  image: { height: "15px", width: "15px", marginTop: "5px", marginRight: "12px", borderRadius: "0px" },
  planInclusionTitleContainer: { display: "flex", alignItems: "center", marginBottom: "2px" },
  divider: { flexGrow: 1, height: "1px", backgroundColor: "#f2f2f2", margin: "0px 10px" },
  nameText: { fontSize: "16px", color: "#fff", fontFamily: 'Poppins', whiteSpace: "break-spaces" },
  priceText: { fontSize: "20px", color: "#2de2d5", fontFamily: 'Poppins' },
  planInclusionText: { fontSize: "16px", fontFamily: 'Poppins', color: "#fff", marginRight: "20px", marginLeft: "20px" },
  planInclusionContainer: { marginTop: "18px" },
  explanationText: { fontSize: "16px", color: "#c4c4ca", fontFamily: 'Poppins', whiteSpace: "break-spaces", marginTop: "16px", marginBottom: "12px", wordWrap: "break-word" },
  cardContainer: { padding: "14px" },
  arrowImage: { height: "22px", width: "22px", marginTop: "2px", marginRight: "16px" },
  noExplanationText: { fontSize: "16px", fontFamily: 'Poppins', color: "#808d9e" },
  noExplanationTextContainer: {
    border: "1px solid", borderColor: "#808d9e", borderRadius: "4px", marginTop: "16px", paddingTop: "18px", paddingLeft: "12px", minHeight: "120px", marginBottom: "12px"
  },
  tabRoot: { padding: "12px", paddingBottom: "0px", marginLeft: "20px" },
  editButton: { background: "#c9f9f6", color: "#3c3c50", minWidth: "130px", fontSize: "16px", fontWeight: 600, textTransform: "none", borderRadius: "8px" },
  inputRoot1: {
    borderRadius: '10px',
    minHeight: '120px',
    marginTop: "1rem",
    fontFamily: 'Poppins',

  } as React.CSSProperties,
  accordion: { background: "unset", boxShadow: "none" },
  templateCard: { minHeight: "120px", background: "#3c3c50", justifyContent: "center", alignItems: "center", display: "flex", position: "relative", borderRadius: "8px"},
  templateText: { fontSize: "16px", fontFamily: 'Poppins', color: "#c4c4c4", },
  deleteIconContainer: { position: "absolute", right: "22px", top: "22px", cursor: "pointer" },
  gridContainer: { paddingTop: "2rem" },
  gridContainer1: { paddingTop: "2rem" },
  serviceContainer: { display: "flex", width: "1680px", overflowX: "auto", },
  deleteIcon: { height: "22px", width: "22px", cursor: "pointer" },
  dialogTitle: { padding: '0px 65px', marginTop: "3rem" },
  dialogTitleHeader: { color: '#fff', textAlign: 'center', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 600 },
  dialogContent: { padding: '0px!important', marginBottom: '0px', paddingTop: '15px' },
  dialogContainer: { color: 'rgb(255, 255, 255) !important', fontSize: '20px !important', fontFamily: 'Poppins !important', fontWeight: 500, marginTop: '18px', marginBottom: "2rem" },
  editServiceNameText: { fontSize: "16px", color: "#808d9e", fontFamily: 'Poppins', whiteSpace: "break-spaces" },
  divider1: { flexGrow: 1, height: "1px", backgroundColor: "#808d9e", margin: "0px 10px" },
  planInclusionText1: { fontSize: "16px", fontFamily: 'Poppins', color: "#808d9e", marginRight: "20px", marginLeft: "20px" },
  nameText1: { fontSize: "16px", color: "#808d9e", fontFamily: 'Poppins', fontWeight: 600 },
  commisionFeeText: { fontSize: "16px", color: "#808d9e", fontFamily: 'Poppins', whiteSpace: "break-spaces", fontWeight: 500, borderBottom: "1px solid #808d9e", paddingBottom: "12px" },
  totalText: { marginTop: "18px", marginBottom: "18px", fontSize: "20px", color: "#fff", fontFamily: 'Poppins', whiteSpace: "break-spaces", fontWeight: 600, },
  dialogStyle: { width: "80%", backgroundColor: '#3b3c50', borderRadius: '8px' },
  saveBtn: { background: "#c9f9f6", color: "#3c3c50", minWidth: "150px", fontSize: "16px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px" },
  cancelBtn: { color: "#c9f9f6", minWidth: "150px", fontSize: "16px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px" },
  noTemplateText: { fontSize: "14px", color: "#c4c4c4", fontFamily: 'Poppins', whiteSpace: "break-spaces" },
  sendServiceLabel: { background: "#DAF8DA", color: "#0ABE64", borderRadius: "10px", textAlign: "center", fontSize: "12px", padding: "6px 28px 6px 28px", fontFamily: 'Poppins', width: "110px" },
  notSendServiceLable: { background: "#F7C1BA", color: "#EE5353", borderRadius: "10px", textAlign: "center", fontSize: "12px", padding: "6px 28px 6px 28px", fontFamily: 'Poppins', width: "110px" },
  sendServiceText: { fontSize: "14px", width: "fit-content", color: "#2f92fc", borderBottom: "1px solid #2f92fc", cursor: "pointer" },
  actionContainer: { backgroundColor: "#395d6b", padding: "1rem", borderRadius: "14px" },
  defaultServiceTxt: { fontFamily: "Poppins", fontSize: "14px", color: "#fff", cursor: "pointer" },
  sendFromTemplateTxt: { fontFamily: "Poppins", fontSize: "14px", color: "#fff", marginTop: "1rem", cursor: "pointer" },
  sendServiceBtn: { background: "#c9f9f6", color: "#3c3c50", minWidth: "150px", fontSize: "16px", fontWeight: 600, textTransform: "none" },
  activeStyle: { fontFamily: "Poppins", fontSize: "18px", fontWeight: 500, color: "#fff", marginBottom: "2rem" },
  templateCard1: { minHeight: "80px",background: "#395D6D",justifyContent: "center", alignItems: "center", display: "flex", position: "relative", boxShadow: "none", border: "none" },
  templateCard2: { minHeight: "80px", background: "#2de2d5", justifyContent: "center", alignItems: "center", display: "flex", position: "relative", boxShadow: "none", border: "none" },
  templateText1: { fontSize: "16px", fontFamily: 'Poppins', color: "#0a0412", },
  mainContainer: { backgroundColor: "#3c3c51", width: "100%", marginTop: "1rem", borderRadius: "14px", padding: "2rem", marginBottom: "2rem" },
  snakbar: { display: "flex", background: "#f7c1ba", color: "#ee5353", fontSize: "14px", fontFamily: "Poppins", paddingRight: "4rem", paddingLeft: "4rem", minWidth: "440px", textAlign: "center", borderRadius: "8px", height: "45px" },
  noImg: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    color: "#fff",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    backgroundColor: "2CE2D5",
  } as React.CSSProperties,
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  } as React.CSSProperties,
  imageProfile: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
  },
  textContainers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "12px",
  } as React.CSSProperties,
  nameStyle: {
    fontSize: "14px",
    color: "white",
  },
  email: {
    fontSize: "12px",
    marginTop: "-8px",
    color: "#ffffff78",
  },
};
// Customizable Area End
