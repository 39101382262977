import React from 'react';
import Select, { StylesConfig } from 'react-select';

interface SelectMenuProps {
    countryName: { value: string; label: string }[];
    paymentMethod: string;
    addAddressFormDetails: { country: string };
    address?: { attributes?: { country: string } };
    customStyles?: any;
    updateAddAddressDetails: any;
}

const SelectMenu: React.FC<SelectMenuProps> = ({
    countryName,
    paymentMethod,
    addAddressFormDetails,
    customStyles = {},
    updateAddAddressDetails,
}) => {
    let value = { value: "", label: "" };
        value = {
            value: addAddressFormDetails?.country || "",
            label: countryName? countryName?.filter((item: { value: string, label: string }) => item?.value == addAddressFormDetails?.country)[0]?.label : "",
        };

    const extendedStyles: any = {
        ...customStyles,
        input: (base: any) => ({
            ...base,
            color: 'white',
        }),
    };

    return (
        <Select
            options={countryName}
            value={value}
            onChange={(event) => updateAddAddressDetails('country', event)}
            classNamePrefix="an-simple-select"
            className="selectMenu"
            data-test-id="selectMenu"
            styles={extendedStyles}
        />
    );
};

export default SelectMenu;
