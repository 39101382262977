import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

type DeleteDialogModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dialogTitle: string;
  cancelText: string;
  confirmText: string;
};

const DeleteDialogModal: React.FC<DeleteDialogModalProps> = ({
  open,
  onClose,
  onConfirm,
  dialogTitle,
  cancelText,
  confirmText,
}) => {
  // Styles that are consistent across all instances, with type React.CSSProperties
  const dialogStyles: { [key: string]: React.CSSProperties } = {
    paperStyle: {
      boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
      backgroundColor: '#395D6B',
      borderRadius: '8px',
      width: 'max-content',
    },
    dialogTitle: {
      padding: '0px 65px',
      marginTop: '3rem',
    },
    dialogTitleHeader: {
      color: '#fff',
      textAlign: 'center' as 'center',
      fontSize: '16px',
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    dialogContent: {
      padding: '0px!important',
      marginBottom: '0px',
      paddingTop: '15px',
    },
    dialogContainer: {
      color: 'rgb(255, 255, 255)',
      fontSize: '20px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      marginTop: '18px',
      marginBottom: '2rem',
    },
    cancelBtn: {
      borderRadius: '7px',
      height: '40px',
      color: '#c9f9f6',
      border: '1px solid #c9f9f6',
      marginRight: '3rem',
    },
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ style: dialogStyles.paperStyle }}
    >
      <DialogTitle id="alert-dialog-slide-title" style={dialogStyles.dialogTitle}>
        <span style={dialogStyles.dialogTitleHeader}>{dialogTitle}</span>
      </DialogTitle>
      <DialogContent style={dialogStyles.dialogContent}>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={dialogStyles.dialogContainer}
        >
          <div className="modal-button-container">
            <div
              style={dialogStyles.cancelBtn}
              className="modal-button modal-save"
              onClick={onClose}
              data-test-id="cancelDelete"
            >
              {cancelText}
            </div>
            <div
              className="modal-button modal-discard"
              style={{
                borderRadius: '7px',
                height: '40px',
              }}
              onClick={onConfirm}
              data-test-id="yesDelete"
            >
              {confirmText}
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDialogModal;
