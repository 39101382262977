import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import storage from "framework/src/StorageProvider"
import toast from "react-hot-toast";
import { getStorageData } from "framework/src/Utilities";

interface PortfolioObject {
  id: number;
  name: string;
  country: string;
  cash_accounts: string | null;
  investment_accounts: string | null;
  cash_transactions: string | null;
  investment_transactions: string | null;
  liabilities: null;
  cards: string | null;
  crypto: string | null;
  login_url: string;
  sca_types: string | null;
  allways_2fa: string | null;
  date_created: string;
  connected_status:	boolean;
  transaction_limit_cash: string | null;
  transaction_limit_investments: string | null;
  entity_type: string;
  api_identifier: string;
  investments_covered: string;
  status: string | null;
  trasaction_token_flow: null;
  image_url: string | null
}

interface CurrencyObject {
  id: number;
  currency_name: string;
  created_at: string;
  updated_at: string;
  value_respect_to_usd: number;
  currency_type: string;
  symbol: string;
  usd_to_currency: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  portfolioList: PortfolioObject[];
  selectedPortfolioId: number | null;
  currencyList: CurrencyObject[];
  selectedCurrency: {label: string, value: string};
  flanksUrl: string;
  importingData: boolean;
  direction: string,
  language: string,
  isCodePresent: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Flanksapi1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  portfolioListCallId: string = "";
  getCurrencyListCallId: string = "";
  setNewCurrencyCallId: string = "";
  getFlanksUrlCallId: string = "";
  sendCodeDataAPICallId: string = "";
  getInvestmentAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      portfolioList: [],
      selectedPortfolioId: null,
      currencyList: [],
      selectedCurrency: {label: "", value: ""},
      flanksUrl: "",
      importingData: false,
      direction: 'ltr',
      language: 'English',
      isCodePresent: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.managePortfolioListResponse(responseJson2, apiRequestCallId)
    this.manageCurrencyResponse(responseJson2, apiRequestCallId, message)
    this.manageFlanksUrlResponse(responseJson2, apiRequestCallId)
    this.manageSendCodeResponse(responseJson2, apiRequestCallId)
    this.manageInvestmentResponse(responseJson2, apiRequestCallId)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const currentUrl = window.location.search;
    let language = await getStorageData('language');
    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ direction: currentLanguage ,language: language});
    this.portfolioList();
    this.getCurrencyList();
    this.getFlanksUrl();
    if (currentUrl) {
      this.setState({
        importingData: true,
        isCodePresent: true,
      })
      this.sendCode(currentUrl.substring(6))
    }
  }

  manageSendCodeResponse = (responseJson2: { flank_portfolio_credentials_token: string }, apiRequestCallId: string) => {
    if (responseJson2 && apiRequestCallId === this.sendCodeDataAPICallId) {
      this.getInvestments()
    }
  }

  sendCode = async (codeData: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendCodeDataAPICallId = requestMessage.messageId;
    const formData = new FormData();
    formData.append("link_code", codeData);
    const header = {
      token: await storage.get("authToken")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_apiintegrationstocksshares/generate_credentials_token"
    );
    requestMessage.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  manageInvestmentResponse = (responseJson2: {investments: {data:string}}, apiRequestCallId: string) => {
    if (apiRequestCallId === this.getInvestmentAPICallId) {
      if (responseJson2?.investments?.data) {
        this.setState({
          importingData: false
        })
      } else {
        this.setState({
          isCodePresent: false
        })
        toast.error("Fetching data, please wait... \n Data will reflect after some time")
      }
    }
  }

  getInvestments = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvestmentAPICallId = requestMessage.messageId;
    const header = {
      token: await storage.get("authToken")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_apiintegrationstocksshares/flanks_investments"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  portfolioList = async () => {
    const header = {
      token: await storage.get("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.portfolioListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_apiintegrationstocksshares/flanks_port_folios`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  managePortfolioListResponse = async (responseJson2: PortfolioObject[], apiRequestCallId: string) => {
    if (apiRequestCallId === this.portfolioListCallId) {
      if (responseJson2) {
        this.setState({ portfolioList: responseJson2 })
      }
      else {
        this.setState({ portfolioList: [] })
      }
    }

  }

  getCurrencyList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrencyListCallId = requestMessage.messageId;
    const header = {
      token: await storage.get("authToken")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/currency_list"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  manageFlanksUrlResponse = async (responseJson2: { link_token : string }, apiRequestCallId: string) => {
    if (apiRequestCallId === this.getFlanksUrlCallId) {
      this.setState({
        flanksUrl: responseJson2.link_token
      })
    }
  }

  getFlanksUrl = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFlanksUrlCallId = requestMessage.messageId;
    const header = {
      token: await storage.get("authToken")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_apiintegrationstocksshares/get_urls"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  manageCurrencyResponse = async (responseJson2: { list: CurrencyObject[] }, apiRequestCallId: string, message: Message) => {
    if (responseJson2 &&
      apiRequestCallId === this.getCurrencyListCallId
    ) {
      const responseJson6 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      responseJson6?.list.map((currency: CurrencyObject) => {
        if (currency.id === responseJson6.selection) {
          this.setState({ selectedCurrency: {label: currency.symbol+currency.currency_type, value: currency.currency_type} })
        }
      })
      this.setState({ currencyList: responseJson6.list })
    }
  }

  handlePortfolioSelect = (id: number) => {
    this.setState({ selectedPortfolioId: id })
  }

  handlePortfolioConfirm = () => {
    const seletedPortfolio = this.state.portfolioList.find(portfolio => {
      return this.state.selectedPortfolioId === portfolio.id
    })
    if (seletedPortfolio && !seletedPortfolio.connected_status) {
      const portfolioUrl = `https://platform.flanks.io/link?token=${this.state.flanksUrl}&language=en&bank=${seletedPortfolio.api_identifier}`
      window.location.href = portfolioUrl
    } else {
      toast.error('Account already linked!\nPlease select another portfolio before moving further');
    }
  }

  setCurrency = async (val: {label: string, value: string}) => {
    const selectedCurrencyObj = this.state.currencyList.find(currency => {
      return val.value === currency.currency_type
    })
    this.setState({selectedCurrency: {label: selectedCurrencyObj?.symbol + val.label, value: val.value}})
    if(selectedCurrencyObj?.id) {
      const formData = new FormData();
      formData.append("currency_id", selectedCurrencyObj.id.toString());
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setNewCurrencyCallId = requestMessage.messageId;
      const header = {
        token: await storage.get("authToken")
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_profile/profiles/add_currency_to_account"
      );
      requestMessage.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getStringPortfolio = (keyvalue: string) => {
    return configJSON.languagePortfolio[this.state.language][keyvalue]
  }
  // Customizable Area End
}
