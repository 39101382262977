import React from "react";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import { subarrowicon, settingicon, down, up } from "../../dashboard/src/assets";
import "../../dashboard/src/style.css";
import "./style.css";
import { backIcon } from "../../forgot-password/src/assets";
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { ResponsivePie } from '@nivo/pie';
import SelectInputField from "../../../components/src/SeleteBox/SelectInputField";
import { formatNumberWithCommasAndDecimals } from "../../../components/src/commonFn";
import { deleteIcon, image_BackIcon } from "./assets";

 
// Customizable Area End

import VisualAnalyticsController, {
  Props,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getTransactionMonth();
    addEventListener('beforeunload', function () {
      localStorage.removeItem('type');
      localStorage.removeItem('category');
      localStorage.removeItem('date');
    });

    
    // Customizable Area End
  }

  // Customizable Area Start
  convertToK = (amount:any)=>{
    let convertedAmount = parseFloat(amount)
    let newAmount;
    if (convertedAmount >= 1000) {
      newAmount = (convertedAmount / 1000).toFixed(1) + 'k';
    }
    else{
      newAmount = convertedAmount;
    }
    return newAmount;
  }
  
  getArrowImage(amountOperand: string) {
    return amountOperand === '+' ? up : down;
}
 altImageArrow(){
  return(this.state.singleTransactionDetails.operand === '+' ? 'Up Arrow' : 'Down Arrow')
 }
  brandLogo() {
    return (
      this.state.singleTransactionDetails?.brand_logo ?? this.state.singleTransactionDetails?.sub_category?.image
    )
  }
 
  imgBrandLogo(value:any){
    return(
      value.attributes.brand_logo ?? value.attributes?.sub_category.image
    )
  }

amountSelection(){
return (
  this.state.singleTransactionDetails.operand === "+" ? "amount-positive" : "amount-negative"
)
}
  
wrapperBox=()=>{
  return(
  <Box className="details-container">
  <Box className="table-format">
      <img src={this.brandLogo()} className="transactionDetailImg" width={48} />
      <img src={deleteIcon} data-test-id="delete-transaction" width={36} style={{ cursor: 'pointer' }} onClick={this.onCancel} />
  </Box>
  <Box className="detail-info-container">
      <Box className="table-format">
          <span className="detail-title">{this.getStringVisualAnalytics('Status')}</span>
          <span className="detail-title-value">{this.state.singleTransactionDetails.status}</span>
      </Box>
      
      <Box className="table-format">
          <span className="detail-title">{this.getStringVisualAnalytics('Category')}</span>
          <div className="select-container">
              <SelectInputField categoriesList={this.state.categoriesList}
              selectedCategoryId={this.state.updatedSubCategoryForSingleTransacionId}
              handleCategoryChange={this.handleCategoryChange} 
              />
          </div>
      </Box>
      <Box className="table-format">
          <span className="detail-title"> {this.getStringVisualAnalytics('Source')}</span>
          <span className="detail-title-value">{this.state.singleTransactionDetails.source}</span>
      </Box>
  </Box>
  <Box className="detail-info-container">
      <Box className="table-format">
          <span className="detail-title">{this.getStringVisualAnalytics('CurrentAccount')}</span>
          <span className="detail-title-value">{this.state.singleTransactionDetails.current_account}</span>
      </Box>
      <Box className="table-format">
          <span className="detail-title">{this.getStringVisualAnalytics('Amount')}</span>
          <p className={this.amountSelection()}>
              {`${this.state.singleTransactionDetails.operand} ${formatNumberWithCommasAndDecimals(this.state.singleTransactionDetails.amount)}`}
              
          </p>
      </Box>
  </Box>
  <Box className="detail-info-container">
      <Box className="table-format">
          <span className="detail-title">{this.getStringVisualAnalytics('Date')}</span>
          <span className="detail-title-value">{this.state.singleTransactionDetails.date}</span>
      </Box>
      <Box className="table-format">
          <span className="detail-title">{this.getStringVisualAnalytics('Time')}</span>
          <span className="detail-title-value">{this.state.singleTransactionDetails.time}</span>
      </Box>
  </Box>
  <Box className="table-format">
      <div></div>
      <button className="save-btn-transDetails" data-test-id="save-btn" onClick={this.updateSubCategoryOfTransaction}>{this.getStringVisualAnalytics('Save')}</button>
  </Box>
</Box>
  )
}
  commonPieChart = () => {
    const generateColor = (index: number) => {
      const baseHue = 258;
      const saturation = 90 + (index % 10);
      const lightness = 50 + (index % 20);
      return `hsl(${baseHue}, ${saturation}%, ${lightness}%)`;
    };

    const pieChartData = this.state.spendingGraphData.spending_category &&
      this.state.spendingGraphData.spending_category.map((dataValue: any, index: number) => {
        return {
          id: dataValue[3].data.id,
          label: dataValue[3].data.attributes.name,
          value: dataValue[1],
          percentage: dataValue[0],
          symbol: this.state.spendingGraphData.currency_symbol,
          color: this.state.pieChartColor[index] || generateColor(index),
        };
      });
    const marginData = { top: 40, right: 80, bottom: 40, left: 80 }

    if (pieChartData){
    return (
      <Box>
        <div style={{ height: "400px", position: "relative", textAlign: "center" }}>
          <div className="circle_title">
            <div className={'circle_two'}>{this.state.spendingGraphData.currency_symbol + this.convertToK(this.state.spendingGraphData.total_spendings) || 0}</div>
          </div>
          <ResponsivePie
            data={pieChartData}
            margin={marginData}
            innerRadius={0.65}
            padAngle={0.1}
            cornerRadius={0}
            colors={pieChartData.map((ind: any) => ind.color)}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.0]] }}
            radialLabelsSkipAngle={0}
            radialLabelsLinkOffset={20}
            radialLabelsTextXOffset={0}
            radialLabel='label'
            radialLabelsLinkDiagonalLength={0}
            radialLabelsLinkHorizontalLength={0}
            radialLabelsLinkStrokeWidth={15}
            slicesLabelsSkipAngle={10}
            radialLabelsTextColor="white"
            slicesLabelsTextColor="#ffffff00"
            enableRadialLabels={true}
            isInteractive={true}
            theme={{
              labels:{
                text:{
                  fontSize: 16,
                  fontWeight: 600,
                  fontFamily:"Poppins"
                }
              }
            }}
            tooltip={(data: any) => {
              return (<>
                <span data-test-id="pieChartSpan" className="tooltip-content">
                  <div
                  className="percentage-class"
                    style={{
                      color: '#323031',
                      padding: '5px 20px',
                      textAlign: 'center',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '10px',
                    }}
                  > {parseFloat(data.percentage).toFixed(2)}%
                  </div>
                  <div
                    style={{
                      color: '#000',
                      padding: '5px 20px',
                      textAlign: 'center',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '10px',
                    }}
                  >{`-${data.symbol+parseFloat(data.value).toLocaleString()}`}
                  </div>                 
                  <div className="tooltip-arrow" />
                </span>
              </>)
            }}
          />
        </div>
      </Box>
    )
          }
  }
  conditionalFunction=()=>{
    return(
<div className="screen-wrapper-transaction">
          <div className="screen-flex-transaction">
            <div className="recent-flex margin-top-css">
              <h5></h5>
             
            </div>
            <div className="recent-wrapper">
             <div style={{display: "flex", justifyContent: "space-between", alignItems: "center" }}> <h2 style={{color:"white"}}>{this.state.subCategoryData?.date}</h2>
              <h2 style={{color:"#EE5353"}}>{this.state.subCategoryData?.symbol + this.state.subCategoryData?.total}</h2></div>
              {this.state.subCategoryData.transactions&&this.state.subCategoryData.transactions.data && this.state.subCategoryData.transactions.data.map((value: any, index:number) => {
                return (
                  <>
                    <div className="recent-block-content" data-test-id="transaction-api-details" onClick={()=>{
                     this.setState({openDetailsInfo : true});
                      this.transactionDetailAPI(value.attributes.id)
                    }
                    }>
                      <div className="recent-item">
                        <img src={this.imgBrandLogo(value)} className="spending-image" alt="SpendingImage" />
                        <div className="recent-inner-item">
                          <h4 className="pointer-show">{value.attributes.description}</h4>
                          <p>{value.attributes.transaction_date}</p>
                        </div>
                      </div>
                      <div className="recent-price">
                      <span dir="ltr">{(value.attributes.converted_amount != '0' ? value.attributes.amount_operand:'') +value.attributes.user_currency+ parseFloat(value.attributes.converted_amount).toLocaleString()}</span>
                      <p>&nbsp;</p>
                      </div>
                    </div>
                  </>
                )
              })}


            </div>
          </div>
        </div>
    )
  }
  handleDetailsInfo() {
    return this.state.openDetailsInfo ? this.wrapperBox() : this.conditionalFunction();
  }
  addImg = () => {
    return this.state.logoImg1AddCash === "rtl" ? image_BackIcon : backIcon
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <div className="pagedashboard-wrapper" dir={this.state.logoImg1AddCash}>
        <div className="header-wrapper" onClick={this.handleGlobaldropdown}>
          <div className="page-title">
            <h4>{this.state.spendingCategoryDetail ? <img src={this.addImg()} className="pointer-show" data-test-id="backButtonSpending" alt="back-icon" onClick={() => {
                                if (this.state.openDetailsInfo) {
                                    this.setState({ openDetailsInfo: false });
                                } else {
                                    this.setState({
                                        spendingCategoryDetail: false,
                                        sub_category_id: "",
                                    });
                                }
                            }} /> 
            
            :
              <NavLink to="/VisualAnalytics"><img src={this.addImg()} alt="back-icon" /></NavLink> }
              &nbsp; {this.getStringVisualAnalytics("SpendingCategory")}</h4>
          </div >
          <div className="page-info" data-test-id="page-info-sub-category">
            <ul className="drop-menu" id="subCategory-drop-menu">
              <li className="currency-dropdown">
                <div
                  data-test-id="currencyDropdoen"
                  onClick={() => this.handleDropdown()}
                  className={this.state.dropdownVisiblity} >
                  <p className="subcategoryfakeid" dir="ltr">
                    {this.state.selectedCurrency}
                  </p>
                  <img src={subarrowicon} alt="subarrow" />
                  <ul id="unique-ul-id">
                    {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                      return <li style={{textAlign:this.state.logoImg1AddCash ==="rtl" ? "right": "left"}} data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)} key={index}>{value.symbol + value.currency_type}</li>
                    })}

                  </ul>
                </div>
              </li>
              <li className="nav-link-setting" id="navLinkRandomId">
                <NavLink data-test-id="navLinkUniqueId" to="/Account-Detail">
                  <img src={settingicon} alt="setting" className="setting-img" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="content-wrapper">
         {this.state.spendingCategoryDetail ? 
          this.handleDetailsInfo()
         :
            <div className="screen-wrapper-transaction">
              <div className="screen-flex-transaction">
                <div className="dashboard-graph temp-height">
                  <div className="pieChartHeader">
                    <span className="pointer-show" data-test-id="prevMonthBtn" onClick={()=>this.updateMonth('minus')}>{`<`}</span>
                    {this.state.transactionMonth[this.state.selectedTransactionMonth]}
                    <span className="pointer-show" data-test-id="nextMonthBtn" onClick={()=>this.updateMonth('plus')}>{`>`}</span>
                    </div>
              {this.commonPieChart()} 
                </div>
                <br />
                <div className="recent-flex margin-top-css">
                  <h5>{this.getStringVisualAnalytics("TotalExpenses")}</h5>
                 
                </div>
                <div className="recent-wrapper">
                  {this.state.allSpendingCategory && this.state.allSpendingCategory.map((value: any, index:number) => {
                    return (
                      <>
                        <div className="recent-block-content" key={index}>
                          <div className="recent-item">
                            <img src={value[3].data.attributes.brand_logo ?? value[3].data.attributes.image} className="spending-image" alt="SpendingImage" />
                            <div className="recent-inner-item">
                              <h4 className="pointer-show" data-test-id={`subCategory-${index}`} onClick={()=>this.subCategoryGet(value[3].data.attributes.id)}>{value[3].data.attributes.name}</h4>
                              <p dir="ltr">{value[2]}</p>
                            </div>
                          </div>
                          <div className="recent-price">
                          <span dir="ltr">{"-"+this.state.currency_symbol+ parseFloat(value[1]).toLocaleString()}</span>
                          <p>&nbsp;</p>
                          </div>
                        </div>
                      </>
                    )
                  })}


                </div>
              </div>
            </div>
         }
        </div>
        <Dialog
                    open={this.state.deleteModel}
                    keepMounted
                    data-test-id="dialogModel"
                    onClose={this.dialogModel}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{ style: this.state.dialogCss }}
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0px 65px', }}>
                        <span style={{
                            color: '#fff',
                            textAlign: 'center',
                            fontSize: '13px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                        }}>{this.getStringVisualAnalytics('arabicTargetDate')}</span><br /><span style={{
                            color: '#fff',
                            textAlign: 'center',
                            fontSize: '13px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            display: "flex",
                            justifyContent: "center"
                        }}></span></DialogTitle>
                    <DialogContent style={{
                        padding: '0px!important',
                        marginBottom: '0px',
                        paddingTop: '15px'
                    }}>
                        <DialogContentText id="alert-dialog-slide-description"
                            style={{
                                color: 'rgb(255, 255, 255) !important',
                                fontSize: '20px !important',
                                fontFamily: 'Poppins !important',
                                fontWeight: 500,
                                marginBottom: '0px',
                            }}>
                            <div className="modal-button-container">
                                <div className="modal-button modal-save" data-test-id='popup-cancle-click' onClick={this.onCancel}>
                                   {this.getStringVisualAnalytics('Cancel')}
                                </div>
                                <div className="modal-button modal-discard" data-test-id='popup-for-delete' onClick={this.deleteTransaction} >
                                   {this.getStringVisualAnalytics('DeleteNew')}
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
