import React from "react";
// Customizable Area Start
import "./style.css"
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { image_Shape } from "./assets";
import { NavLink } from "react-router-dom";


interface Props {
    id: string;
    navigation: any;
    instance: any;
}
interface S { }
interface SS { }
// Customizable Area End



export default class SuccessfullBank extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div className="mainBank-wrapper">
             {!this.props.instance.state.isArabic ?
             <div className="bank-integraion-wrapper">
                    <div className="bank-integration-box d-flex align-center">

                      <div className="successful-img-content">
                        <img src={image_Shape} />
                        <p>Your account linking is successful!</p>
                        <NavLink to="/Dashboard"> <button className="successBtn bankBtns" style={{marginLeft: "15px", marginRight: "15px", width: "calc(100% - 30px)"}} data-test-id="continueBtn">Continue to dashboard</button></NavLink>

                      </div>
                    </div>
                </div>
            :
            <div className="bank-integraion-wrapper">
            <div className="bank-integration-box d-flex align-center">

              <div className="successful-img-content">
                <img src={image_Shape} />
                <p>تم ربط حسابك بنجاح</p>
                <NavLink to="/Dashboard"> <button className="successBtn bankBtns" data-test-id="continueBtn">المتابعة إلى لوحة القيادة</button></NavLink>

              </div>
            </div>
        </div>
            }
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
