import React, { Component } from 'react';
import "../../blocks/apiintegrationbankaccounts/src/style.css"
const markImg = require("./mark.png");


interface StepProgressBarProps {
  steps: string[];
  direction?: "rtl" | "ltr";
  apiResponseReceived?: boolean;
}

class StepProgressBar extends Component<StepProgressBarProps> {
  render() {
    const { steps, direction = "ltr", apiResponseReceived = false } = this.props;

    return (
      <div className="step-progress-bar">
        {steps.map((step, index) => (
          <div key={index} className="step">
            <div className="circle"
              style={{
                animationDelay: `${1 + index * ( apiResponseReceived ? 0: 30)}s`,
              }}
            >
              <img src={markImg} alt="" />
            </div>
            <div
              className={`label ${direction}`}
              style={{
                animationDelay: `${1 + index * ( apiResponseReceived ? 0: 30)}s`,
              }}
            >
              {step}
            </div>
            {index < steps.length - 1 && (
              <div
                className={`line ${direction}`}
                style={{
                  animationDelay: `${1 + (index + 1) * ( apiResponseReceived ? 0: 30)}s`,
                }}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default StepProgressBar;
