import React, { ChangeEvent } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Box, Typography, OutlinedInput, TextField, Grid } from "@material-ui/core";
import Select from 'react-select';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import { format } from 'date-fns';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import FormControl from '@material-ui/core/FormControl';
import StepLabel from "@material-ui/core/StepLabel";
import { imagenav_BackIcon, image_Vuesax, image_Calendar, image_Upload,imagenav_Bitmap, image_Group4, view_Rectangle, modelSuccess } from "./assets";
import * as Yup from "yup";
import { getStorageData } from 'framework/src/Utilities';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { formatNumberWithCommas, processInputValue, sanitizeAndRemoveCommas } from "../../../components/src/commonFn";

export const portableConfigJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    category: string;
    subCategory: string;
    selectedCategoryID: any;
    selectCurrency: { value: string, label: string },
    selectCashCurrency: any,
    image_Vuesax: string;
    image_Calendar: string;
    activeStep: number;
    skipped: any;
    isVisible: boolean;
    direction: string,
    currencyList: any,
    model: string;
    id: string;
    dropdownCategoryStatus: boolean;
    activeModalType: string;
    language:string,
    currency_name: any;
    PortableAssettype: string;
    selectAssets: number;
    imagenav_BackIcon: string;
    image_Upload: string;
    image_Group4: string;
    view_Rectangle: string;
    modelSuccess: string;
    selectedFile: any;
    selectedPdf: any;
    selectedPdf2: any;
    openPdfUploadError: boolean,
    openPdfUploadError2: boolean,
    Assetname: string;
    Price: number;
    Currency: string;
    Loan: string;
    Currencys: string;
    imgtitel: string;
    Date: any;
    hader_dropdown: any;
    openPictureUploadError: boolean,
    open: false;
    setOpens: false;
    openModel: boolean;
    selectedFileUplodeError: string;
    categoriesArray: any;
    assetNameError: string;
    portableAssetsTypes: any;
    currentScreen: string;
    combineImg: any;
    imgBoxOne: any;
    imgBoxTwo: any;
    editPortableData: any;
    focusedInput: string;
    assetname: any;
    price: any;
    loan: any;
    Model: any;
    countryList: any;
    allCountryListData: any;
    apiCallingStage: boolean;
    assetNameErrorLone:string;
    logoAddPortableAsset:string;
    initialCountryValue: string | null;
    countryPage: number;
    loanPaymentCalculation: string;
    loanAmountCal: number | null,
    loanInterestsCal: number | null, 
    loanMonthsCal: number | null, 
    loanTermCal: number | null,
    // editPortableAssetsName:string;
    // editPortableAssetsPrice:string;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class PortableAssetController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postUserPDFDataCallId: any;
    getCategoriesApiCallId: any;
    deleteCategoriesApiCallId: any;
    deleteSubCategoriesApiCallId: any;
    postUserSelectDataCallId: any;
    addCategoryApiCallId: any;
    addSubCategoryApiCallId: any;
    validationApiCallId: string = "";
    getPropertyCountriesCallId: string = "";
    portableTypesApiCallId: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            assetNameErrorLone:"",
            logoAddPortableAsset: "",
            Model: React.createRef(),
            loan: React.createRef(),
            price: React.createRef(),
            assetname: React.createRef(),
            focusedInput: "Assetname",
            categoriesArray: [],
            category: "",
            subCategory: "",
            image_Vuesax: image_Vuesax,
            imagenav_BackIcon: "",
            image_Calendar: image_Calendar,
            isVisible: false,
            dropdownCategoryStatus: false,
            activeModalType: "",
            selectedCategoryID: [],
            activeStep: 0, skipped: new Set(),
            selectCurrency: { value: '', label: '' },
            selectCashCurrency: '',
            selectAssets: 0,
            image_Upload: image_Upload,
            image_Group4: image_Group4,
            view_Rectangle: view_Rectangle,
            modelSuccess: modelSuccess,
            selectedFile: [],
            countryList: [],
            allCountryListData: [],
            apiCallingStage: true,
            initialCountryValue: null,
            selectedPdf: "",
            language:"English",
            selectedPdf2: "",
            Assetname: "",
            Price: 1263,
            currency_name: [],
            imgtitel: "",
            PortableAssettype: "",
            openModel: false,
            selectedFileUplodeError: '',
            Currency: "",
            Loan: "",
            Currencys: "",
            model: "",
            id: '',
            Date: new Date(),
            open: false,
            setOpens: false,
            hader_dropdown: [],
            openPictureUploadError: false,
            openPdfUploadError: false,
            openPdfUploadError2: false,
            token: "",
            assetNameError: '',
            portableAssetsTypes: [],
            currentScreen: '',
            combineImg: [],
            imgBoxOne: '',
            imgBoxTwo: '',
            currencyList: [],
            countryPage: 0,
            loanPaymentCalculation: "",
            loanAmountCal: this.props.location.state?.portableEditData ? this.props.location.state?.portableEditData?.attributes.loan_detail?.amount : null, 
            loanInterestsCal: this.props.location.state?.portableEditData ? this.props.location.state?.portableEditData?.attributes.loan_detail?.annual_interest_rate : null, 
            loanMonthsCal: this.props.location.state?.portableEditData ? this.props.location.state?.portableEditData?.attributes.loan_detail?.term_months : null, 
            loanTermCal: this.props.location.state?.portableEditData ? this.props.location.state?.portableEditData?.attributes.loan_detail?.term_years : null,
            editPortableData: this.props.location.state?.portableEditData?.attributes,
            direction: 'ltr'
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    isStepSkipped(step: any) {
        return this.state.skipped.has(step);
    }


    portableAsset = () => {
        if (this.state.activeStep == 0) {
            !this.state.editPortableData ? this.props.navigation.navigate("CategoriessubcategoriesWeb") : this.props.navigation.navigate("PortableAssetsOverview")
        } else if (this.state.activeStep == 1) {
            this.setState((prevState) => ({
                activeStep: 0
            }));
        } else if (this.state.activeStep == 2) {
            this.setState(() => ({
                activeStep: 1
            }));
        }
    }


    errorMessageOne = (errors: any) => {
        return this.finalErrorMessage((errors?.Assetname) || this.state.assetNameError)
    }

    settingRoute = () => {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationSettingPage)
          );
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(msg);    }

    handleNext = () => {
        let newSkipped = this.state.skipped;
        if (this.isStepSkipped(this.state.activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(this.state.activeStep);
        }
        if (this.state.activeStep == 0) {
            this.setState({ currentScreen: 'one' })
        } else if (this.state.activeStep == 1) {
            this.setState({ currentScreen: 'two' })
        } else {
            this.setState({ currentScreen: 'last' })
        }
        if (this.state.activeStep < 2) {
            this.setState((prevState) => ({
                activeStep: this.state.activeStep + 1, skipped: newSkipped
            }));
        }
    };

    async componentWillMount() {
        let language = await getStorageData('language');
        console.log('lang+++', language);
        
        const logoImg1AddPortableAsset = language === 'Arabic' ? 'rtl' : 'ltr';
        this.setState({ logoAddPortableAsset: logoImg1AddPortableAsset });
        const limagenav_BackIcon = language === 'Arabic' ? imagenav_Bitmap : imagenav_BackIcon;
        this.setState({ imagenav_BackIcon: limagenav_BackIcon  });

        const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
        if(!language){
         language = "English"
        }
        this.setState({ direction: currentLanguage ,language: language});
    }

    convertBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    openPictureUpload = async () => {
        const inputPicherPortable = document.createElement('input');
        inputPicherPortable.type = 'file';
        inputPicherPortable.accept = "image/png, image/jpeg, iamge/jpg"
        inputPicherPortable.multiple = true
        inputPicherPortable.click();
        inputPicherPortable.onchange = (event: any) => {
            this.openPictureUploadInSide(event);
        }
    }
    openPictureUploadInSide = async (event: any) => {
        const uplodeFilesArray = Array.from(event.target.files);
        uplodeFilesArray.forEach(async (file: any) => {
            if (file.size < 5000000) {
                const base64 = await this.convertBase64(file)
                this.setState({
                    selectedFile: [...this.state.selectedFile, file],
                    combineImg: [...this.state.combineImg, base64]
                })
            } else {
                this.setState({ openPictureUploadError: true })
            }
        })
    }

    handleRemoveMain = (ind: number) => {
        const newDoument = [...this.state.selectedFile]
        const newBlob = [...this.state.combineImg]
        newDoument.splice(ind, 1)
        newBlob.splice(ind, 1)
        this.setState({ selectedFile: newDoument, combineImg: newBlob })
    };

    handleRemove = () => {
        this.setState({ selectedFile: '' });
    };
    openPdfUpload = () => {
        const protableInput = document.createElement('input');
        protableInput.type = 'file';
        protableInput.accept = "image/png,image/jpeg,iamge/jpg,application/pdf"
        protableInput.click();
        protableInput.onchange = (event: any) => {
          this.openPictureUploadInSideOne(event);
        }
    }
    openPictureUploadInSideOne = async (event: any) => {
        const Portablefile = event.target.files[0] || null;
        if (Portablefile.size < 5000000) {
            const base64 = await this.convertBase64(Portablefile)
            this.setState({ selectedPdf: Portablefile, openPdfUploadError: false, imgBoxOne: base64 })
        } else {
            this.setState({ openPdfUploadError: true })
        }
    }

    handleRemovePdf = () => {
        this.setState({ selectedPdf: '', imgBoxOne: '' });
    }
    openPdf2Upload = () => {
        const input2Portable = document.createElement('input');
        input2Portable.type = 'file';
        input2Portable.accept = "image/png,image/jpeg,iamge/jpg,application/pdf"
        input2Portable.click();
        input2Portable.onchange = (event: any) => {
            this.openPictureUploadInSideTwo(event);
        }
    }
    openPictureUploadInSideTwo = async (event: any) => {
        const file2Portavle = event.target.files[0] || null;
        if (file2Portavle.size < 2000000) {
            const base64 = await this.convertBase64(file2Portavle)
            this.setState({ selectedPdf2: file2Portavle, openPdfUploadError2: false, imgBoxTwo: base64 })
        } else {
            this.setState({ openPdfUploadError2: true })
        }
    }
    handleRemovePdf2 = () => {
        this.setState({ selectedPdf2: '',  imgBoxTwo: '' });
    }
    handleDateChange = (date: any) => {
        this.setState({ Date: date });
    };
    dialogModel = () => {
        this.setState({ openModel:  !this.state.openModel })
        this.props.navigation.navigate("CategoriessubcategoriesWeb")
    }
    responseErrorMessage = (addPensionResponseJson: any) => {
        if (addPensionResponseJson.errors.name) {
            this.setState({ assetNameError: addPensionResponseJson.errors.name[0] })
        } else {
            this.setState({ assetNameError: '' })
        }
        if (addPensionResponseJson.errors.loan) {
            this.setState({ assetNameErrorLone: addPensionResponseJson.errors.loan [0].replace(/_/g, ' ') })
        } else {
            this.setState({ assetNameErrorLone: '' })
        }
    }
    firstPortableApiResponse = async (from: string, message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) ===  message.id &&
            this.postUserPDFDataCallId !== null &&
            this.postUserPDFDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonPortable = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJsonPortable.list) {
                const userSelectedCurrncy = responseJsonPortable.list?.find((value: any) => {
                    return value?.id == responseJsonPortable?.selection
                })
                const currency = responseJsonPortable.list?.map((value: any) => (
                    { value: value.currency_type, label: value.currency_type }
                ));
                this.setState({
                    currency_name: currency, currencyList: responseJsonPortable,
                    selectCurrency: { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` },
                    selectCashCurrency: { value: userSelectedCurrncy.currency_type, label: userSelectedCurrncy.currency_type }
                });
                this.setState({
                    hader_dropdown: responseJsonPortable?.list?.map((value: any) => (
                        { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
                    ))
                });
            }
            if (responseJsonPortable?.meta?.message.includes("successfully")) {
                this.setState({ openModel: true })
            }
            if (responseJsonPortable?.meta?.message.includes("updated")) {
                this.props.navigation.navigate("PortableAssetsOverview")
            }
            if (responseJsonPortable?.errors) {
                this.responseErrorMessage(responseJsonPortable)
            }
        }
    }

    secondPortableApiResponse = (from: string, message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.portableTypesApiCallId !== null &&
            this.portableTypesApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    portableAssetsTypes: responseJson?.data,
                    id: !this.state.editPortableData ? responseJson?.data[0].attributes.id : this.state.editPortableData?.sub_category?.id,
                    imgtitel: !this.state.editPortableData ? responseJson?.data[0].attributes.name : this.state.editPortableData?.sub_category?.name
                })
            }
        }
    }

    thirdPortableApiResponse = (from: string, message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserSelectDataCallId !== null &&
            this.postUserSelectDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let cashResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (cashResponseJson.meta.currency) {
                this.getCurrencyList()
            }
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.firstPortableApiResponse(from, message)
        this.secondPortableApiResponse(from, message)
        this.thirdPortableApiResponse(from, message)

        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getPropertyCountriesCallId !== null &&
            this.getPropertyCountriesCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let cashResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.handleCashResponse(cashResponseJson)
        }
        // Customizable Area End
    }


    setOpen = (setOpen: any) => {
        this.setState({ setOpens: false })
    }
    handleCashResponse = (cashResponseJson: any) => {
        if (cashResponseJson.length > 1) {
            this.setState((prevState) => ({
                countryList: [...prevState.countryList, ...cashResponseJson.map((value: any) => {
                    this.state.allCountryListData.push(value)
                    return (value.attributes.emoji_flag+ " "+ (this.state.direction == 'rtl' ?  value.attributes.arabic_name : value.attributes.name))
                })],
            }), () => {
                this.setState({ apiCallingStage: true })
            });
        } else {
            this.setCountryData();
        }
    }
    setCountryData = () => {
        if (this.state.editPortableData) {
            const getData = this.state.allCountryListData.find((valueData: any) => valueData.id === this.state.editPortableData?.country);
            this.setState({
                initialCountryValue: getData?.attributes?.emoji_flag + " " +( this.state.direction == 'rtl' ?   getData?.attributes?.arabic_name : getData?.attributes?.name)
            })
        }
    }
    dialogPaperStyle = {
        width: 'max-content',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    };

    getPortableTypes() {
        const data = localStorage.getItem("token")
        const header = { "token": data };
        let userDetailurl = portableConfigJSON.PortableTypes;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: portableConfigJSON.exampleAPiNewMethodtoken,
        });
        this.portableTypesApiCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    getCurrencyList = () => {
        const data = localStorage.getItem("token")
        const header = { "token": data }; 
        let userDetailurl = portableConfigJSON.Loginsubmittoken;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: portableConfigJSON.exampleAPiMethodtoken,
        });
        this.postUserPDFDataCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
        this.getPortableTypes();
    }

    recrpitsEditImage = async (value: any) => {
        try {
            const response = await fetch(value.url);
            const blob = await response.blob();
            const newFile = new File([blob], value.filename, { type: value.content_type });
            this.setState({ selectedPdf: newFile })
        } catch (error) {
            return null;
        }
    }


    warrentsEditImage = async (value: any) => {
        try {
            const response = await fetch(value.url);
            const blob = await response.blob();
            const newFile = new File([blob], value.filename, { type: value.content_type });
            this.setState({ selectedPdf2: newFile })
        } catch (error) {
            return null;
        }
    }

    setEditImages = () => {
        if (this.state.editPortableData?.images?.length > 0) {
            const imageUploadOne = this.state.editPortableData?.images?.map((value: any) => value.url)

            this.setState({ combineImg: imageUploadOne })
            this.state.editPortableData?.images?.map(async (value: any) => {
                try {
                    const response = await fetch(value.url);
                    const blob = await response.blob();
                    const newFile = new File([blob], value.filename, { type: value.content_type });
                    this.setState({ selectedFile: [...this.state.selectedFile, newFile] })
                } catch (error) {
                    return null;
                }
            })
        }
        if (this.state.editPortableData?.receipts?.length > 0) {
            this.setState({
                imgBoxOne: this.state.editPortableData?.receipts[0].url
            })
            this.recrpitsEditImage(this.state.editPortableData?.receipts[0])
        }
        if (this.state.editPortableData?.warrants?.length > 0) {
            this.setState({
                imgBoxTwo: this.state.editPortableData?.warrants[0].url
            })
            this.warrentsEditImage(this.state.editPortableData?.warrants[0])
        }
    }

    async componentDidMount() {
        let language = await getStorageData('language');
        const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
        this.setState({ direction: currentLanguage });
        this.getCurrencyList();
        this.setEditImages()
        if (this.state.editPortableData?.purchase_date) {
            this.setState({ Date: this.state.editPortableData?.purchase_date })
        }
        if (this.state.editPortableData) {
            this.calculateLoanInstallments();   
        }
    }

    potableSelect = () => {
        return (
            <FormControl>
                <Select options={this.state.hader_dropdown}
                    value={this.state?.selectCurrency}
                    onChange={(val: any) => { this.setCurrency(val)}}
                    classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="selectMenu" />
            </FormControl>
        )
    }

    setCurrencyPutapi = (currencyId: string) => {
        const dataCash = localStorage.getItem("token")
        const header = { "token": dataCash };
        let  userDetailurl = portableConfigJSON.SetCurrencyPutapi;
        const portableFormData = new FormData();
        portableFormData.append("currency_id",currencyId);
        const portableRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postUserSelectDataCallId = portableRequestMessage.messageId;
        portableRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            userDetailurl
        );
        portableRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        portableRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            portableFormData
        );
        portableRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            'PUT' 
        );
        runEngine.sendMessage(portableRequestMessage.id, portableRequestMessage);
    }

    setCurrency = (value: { value: string, label: string }) => {
        const currencyId = this.state.currencyList.list?.find((newValue: any) => {
            return newValue.currency_type == value.value 
        })
        this.setCurrencyPutapi(currencyId?.id)
    }

    imageFormData = (formData: any) => {
        if (this.state.selectedFile?.length > 0) {
            this.state.selectedFile.forEach((it: any, index: number) => {
                it && formData.append("data[attributes][images][]", it);
            })
        } else {
            formData.append("data[attributes][delete_all_images]", "true");
        }
        if ([this.state.selectedPdf]?.length > 0) {
            [this.state.selectedPdf].forEach((it: any, index: number) => {
                if (it) formData.append("data[attributes][receipts][]", it);
                else formData.append("data[attributes][delete_all_receipts]", "true");
            })
        }
        if ([this.state.selectedPdf2]?.length > 0) {
            [this.state.selectedPdf2].forEach((it: any, index: number) => {
                if (it) formData.append("data[attributes][warrants][]", it);
                else formData.append("data[attributes][delete_all_warrants]", "true");
            })
        }
    }

    submitApiCallDetails = (portableRequestMessage: any) => {
        if (this.state.editPortableData) {
            portableRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
                'PUT'
            );
        } else {
            portableRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
                'POST'
            );
        }
    }

    formateDate = (dateString: string) => {
        const newDate = new Date(dateString);
        return `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`;
    }

    getCountryData = (country: string) => {
        if(country){

            const getData = this.state.allCountryListData.filter((value: {
                id: string;
                type: string;
                attributes: {
                    name: string;
                    emoji_flag: string;
                    country_code: string;
                    arabic_name: string;
                }
            }) => { return value.attributes?.emoji_flag+" "+( this.state.direction == 'rtl' ? value.attributes.arabic_name :value.attributes.name) == country })[0]
            return getData?.id
        }else {
            return null
        }
    }

    getCurrencyValue = (currency: string) => {
        if (currency) {
            return currency
        } else {
            return this.state.selectCashCurrency.value
        }
    }
    checkLoanSatus = (status: string) => {
        if (status == 'yes') {
            return Boolean(status)
        }else{
            return false
        }
    }


    HandleSubmit = async (values: any) => {
        this.handleNext()
        const dateType = typeof this.state.Date;
        if (this.state.currentScreen == 'last') {
            if (this.state.activeStep === 2) {
                const data = localStorage.getItem("token")
                const header = { "token": data };
                const formData = new FormData();
                formData.append("data[attributes][portable_type]", this.state.imgtitel);
                formData.append("data[attributes][name]", values.Assetname);
                formData.append("data[attributes][model]", values.model);
                formData.append("data[attributes][country]",  this.getCountryData(values.country));
                
                formData.append("data[attributes][price_currency]",  this.getCurrencyValue(values.Currencys?.value));
                formData.append("data[attributes][price]", values.Price);
                formData.append("data[attributes][purchase_date]", this.formateDate(values.PriceDate));
                if (values.MarketPrice) {
                    formData.append("data[attributes][market_value]", values.MarketPrice);
                    formData.append("data[attributes][market_date]", this.formateDate(values.MarketDate));
                  }
                  let userDetailurl = !this.state.editPortableData ? portableConfigJSON.ApiContentType : `account_block/portable_assets/${this.props.location.state.portableEditData.id}`
                formData.append("data[attributes][loan_status]",  this.checkLoanSatus(values.loanSelect.value).toString());

                if (Boolean(values.loanSelect.value === 'yes')) {
                    formData.append("data[attributes][loan_detail_attributes][amount]", values.loanAmount);
                    formData.append("data[attributes][loan_detail_attributes][term_years]", values.loanTerm);
                    formData.append("data[attributes][loan_detail_attributes][term_months]", values.loanMonths);
                    formData.append("data[attributes][loan_detail_attributes][annual_interest_rate]", values.loanInterest);
                    formData.append("data[attributes][loan_detail_attributes][loan_monthly_payments]", sanitizeAndRemoveCommas(this.state.loanPaymentCalculation));
                    formData.append("data[attributes][loan_detail_attributes][start_date]", values.loanStartDate);
                    formData.append("data[attributes][loan_detail_attributes][first_loan_payment_date]", values.loanFirstPayment);
                }
                formData.append("data[attributes][sub_category_id]", this.state.id);

                this.imageFormData(formData)
                const portableRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                this.postUserPDFDataCallId = portableRequestMessage.messageId;
                portableRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
                    userDetailurl
                );
                portableRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );
                portableRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                    formData
                );
                this.submitApiCallDetails(portableRequestMessage)
                runEngine.sendMessage(portableRequestMessage.id, portableRequestMessage);
            }
        }
    }
    loneInput = (values:any) => {
        return values.Loan ? values.Loan : ""
    }
    getStringAddPortable = (key: string) => {
        let languagePortable = localStorage.getItem("language") || "English"
        return portableConfigJSON.languageListLogin[languagePortable][key]
    }

    validDecimals = (value: string) => {
        if (!value) return true;
        return /^\d+(\.\d{1,2})?$/.test(value);
    }

     codeSchema = [
        Yup.object().shape({
            Assetname: Yup.string()
                .required(this.getStringAddPortable('AssetnameError')),
                country: Yup.string().nullable()
                .required(this.getStringAddPortable('locationCountryRequired'))
        }),
        Yup.object().shape({
            Price: Yup.number()
            .transform(function (value, originalValue) {
                if (this.isType(value) && value !== null) return value;
                const cleanValue = parseFloat(sanitizeAndRemoveCommas(originalValue));
                return isNaN(cleanValue) ? undefined : cleanValue;
            })
            .nullable()
            .typeError(this.getStringAddPortable('priceTypeErrors'))
            .test('is-decimal', this.getStringAddPortable('priceDecimalErrors'), this.validDecimals)
            .max(999999999999999, this.getStringAddPortable('priceMaxError')) 
            .required(this.getStringAddPortable('portablePriceError')),

            MarketPrice: Yup.number()
            .transform(function (value, originalValue) {
                if (this.isType(value) && value !== null) return value;
                const cleanValue = parseFloat(sanitizeAndRemoveCommas(originalValue));
                return isNaN(cleanValue) ? undefined : cleanValue;
            })
            .nullable()
            .typeError(this.getStringAddPortable('marketPriceTypeErrors'))
            .test('is-decimal', this.getStringAddPortable('marketPriceDecimalErrors'), this.validDecimals)
            .max(999999999999999, this.getStringAddPortable('marketPriceMaxError')),
              
            PriceDate: Yup.date().nullable().required(this.getStringAddPortable("propertyDateReqError")),
            MarketDate: Yup.date().min(
                Yup.ref('PriceDate'),
                this.getStringAddPortable("MarketPriceError")
            ).nullable(),
            loanSelect: Yup.object().shape({
                label: Yup.string(),
                value: Yup.string()
            }).required(),
            loanAmount: Yup.number()
            .transform(function (value, originalValue) {
                if (this.isType(value) && value !== null) return value;
                const cleanValue = parseFloat(sanitizeAndRemoveCommas(originalValue));
                return isNaN(cleanValue) ? undefined : cleanValue;
            })
            .when('loanSelect.value', {
                is: "yes",
                then: Yup.number()
                    .nullable()
                    .typeError(this.getStringAddPortable('loanAmountTypeError'))
                    .test('is-decimal', this.getStringAddPortable('loanAmountDecimalError'), this.validDecimals)
                    .max(999999999999999, this.getStringAddPortable('loanAmountMaxError')) // Custom error message for max digits
                    .required(this.getStringAddPortable('loanAmountError'))
            }).nullable(),  
            loanTerm: Yup.number().when('loanSelect.value', {
                is: "yes",
                then: Yup.number().nullable().typeError(this.getStringAddPortable('loanAmountTypeError'))
                    .integer(this.getStringAddPortable("loanTermDecimalError"))
                    .required(this.getStringAddPortable('loanTermError'))
            }).nullable(),
            loanMonths: Yup.number().when('loanSelect.value', {
                is: "yes",
                then: Yup.number().nullable().typeError(this.getStringAddPortable('loanAmountTypeError'))
                    .integer(this.getStringAddPortable("loanMonthsDecimalError"))
                    .min(0, this.getStringAddPortable("loanMonthsMinError"))
                    .max(12, this.getStringAddPortable("loanMonthsMaxError"))
                    .required(this.getStringAddPortable('loanMonthsError'))
            }).nullable(),
            loanInterest: Yup.number().when('loanSelect.value', {
                is: "yes",
                then: Yup.number().nullable().typeError(this.getStringAddPortable('loanAmountTypeError'))
                    .test('is-decimal', this.getStringAddPortable('loanAmountDecimalError'), this.validDecimals)
                    .required(this.getStringAddPortable('loanInterestError'))
            }).nullable(),
            loanStartDate: Yup.string().when('loanSelect.value', {
                is: "yes",
                then: Yup.string().required(this.getStringAddPortable('loanStartDateError'))
            }).nullable(),
            loanFirstPayment: Yup.string().when('loanSelect.value', {
                is: "yes",
                then: Yup.string().required(this.getStringAddPortable('loanFirstPaymentError'))
            }).nullable(), 
        }),
    ];

    finalErrorMessage(error: any) {
        if (error) {
            return error
        } else {
            return;
        }
    }

    staperButton = () => {
        if (this.state.activeStep === 2) {
            return this.getStringAddPortable('SaveButton')
        } else {
            return this.getStringAddPortable('NextButton')
        }
    }

    stepperArea = () => {
        return (<Stepper nonLinear style={{ padding: '24px 0px' }} className={"dottedLine"} activeStep={this.state.activeStep}>
            <Step className={this.state.activeStep >= 0 ? 'dottedLine2' : 'dottedLine'}>
                <StepLabel></StepLabel>
            </Step>
            <Step className={this.state.activeStep >= 1 ? 'dottedLine2' : 'dottedLine'}>
                <StepLabel></StepLabel>
            </Step>
            <Step className={this.state.activeStep >= 2 ? 'dottedLine2' : 'dottedLine'}>
                <StepLabel></StepLabel>
            </Step>
        </Stepper>)
    }
    ProgressBar = (totalStepsPortable:any, currentStepPortable:any) => {
        return (
          <div style={{ 
            display: 'flex', 
            gap: '5px'
             }}>
            {
            Array.from({ 
                length: totalStepsPortable 
            }).map((_, index) => (
              <div
                key={index}
                style={{
                  width: '100%',  height: '5px', backgroundColor: index === currentStepPortable  ? '#2ce2d5' : 'grey',
                  borderRadius: '50px',
                  transition:'background-color 0.3s ease'
                }}
              >
                
              </div>
            ))}
          </div>
        );
      };


    uploadPicture = () => {
        return (<Box style={{ paddingTop: '23px' }}>
            <Typography className="fieldTitle"> {this.getStringAddPortable('portableAssetUploadpicture')}</Typography>
            <Box className="fieldArea" data-test-id="openPictureUpload" onClick={() => { this.openPictureUpload() }} style={{ borderColor: this.state.selectedFile?.name ? '#2CE2D5' : '#FFF' }}>
                <Typography className="fieldAreaLabel portableNewOne"> {this.getStringAddPortable('portableAssetUploadPortableAsset')}</Typography>
                <img className="fieldImg" src={this.state.image_Upload} />
            </Box>
            <Typography className="fieldValidate" style={{ color: 'rgba(255, 255, 255, 0.6)' }}> {this.getStringAddPortable('portableAssetUploadError')}</Typography>
            {this.state.selectedFile?.map((value: any, index: number) => {
                return (
                    <Box className="fieldAreaShow" key={index} style={{ display: value?.name ? 'flex' : 'none' }} data-test-id="portableNewOneiamghe">
                        <Typography className="fieldAreaFileName portableNewOneiamghe" data-test-id="handleRemove" >
                            <img className="uploadBoximg" src={this.state.combineImg[index]} />{value?.name}</Typography>
                        <img className="fieldImg" data-test-id="handleRemoves" src={this.state.image_Group4} onClick={() => this.handleRemoveMain(index)} />
                    </Box>
                )
            })
            }
        </Box>)
    }

    uploadOriginalReceipt = () => {
        return (<Box style={{ paddingTop: '23px' }}>
            <Typography className="fieldTitle"> {this.getStringAddPortable('UploadOriginalReceipt')}</Typography>
            <Box className="fieldArea" data-test-id="openPictureUploadTwo" onClick={() => {  this.state.selectedPdf == ''  &&  this.openPdfUpload() }} style={{ borderColor: this.state.selectedPdf?.name ? '#2CE2D5' : "#fff" }}>
                <Typography className="fieldAreaLabel"> {this.getStringAddPortable('UploadReceiptDocuments')}</Typography>
                <img className="fieldImg" src={this.state.image_Upload} />
            </Box>
            <Typography className="fieldValidate" style={{ color: this.state.openPdfUploadError ? 'rgb(238, 83, 83)' : 'rgba(255, 255, 255, 0.6)' }}> {this.getStringAddPortable('portableAssetUploadError')}</Typography>
            <Box className="fieldAreaShow" style={{ display: this.state.selectedPdf?.name ? 'flex' : 'none' }} >
                <Typography className="fieldAreaFileName" data-test-id="handleRemove" >
                    <img src={this.state.imgBoxOne} className="uploadBoximg" />{this.state.selectedPdf?.name} </Typography>
                <img className="fieldImg" src={this.state.image_Group4} onClick={this.handleRemovePdf} />
            </Box>
        </Box>)
    }

    uploadWarrantyOrCertificate = () => {
        return (<Box style={{ paddingTop: '23px' }}>
            <Typography className="fieldTitle"> {this.getStringAddPortable('UploadWarrantyCertificate')}</Typography>
            <Box className="fieldArea" data-test-id="openPictureUploadThree" onClick={() => {  this.state.selectedPdf2 == ''  &&  this.openPdf2Upload() }} style={{ borderColor: this.state.selectedPdf2?.name ? '#2CE2D5' : '#FFF' }}>
                <Typography className="fieldAreaLabel"> {this.getStringAddPortable('UploadWarrantyCertificate')}</Typography>
                <img className="fieldImg" src={this.state.image_Upload} />
            </Box>
            <Typography className="fieldValidate" style={{ color: this.state.openPdfUploadError2 ? 'rgb(238, 83, 83)' : 'rgba(255, 255, 255, 0.6)' }}> {this.getStringAddPortable('portableAssetUploadError')}</Typography>
            <Box className="fieldAreaShow" style={{ display: this.state.selectedPdf2?.name ? 'flex' : 'none' }} >
                <Typography className="fieldAreaFileName" data-test-id="handleRemove" >
                    <img src={this.state.imgBoxTwo} className="uploadBoximg" />{this.state.selectedPdf2?.name} </Typography>
                <img className="fieldImg" src={this.state.image_Group4} onClick={this.handleRemovePdf2} />
            </Box>
        </Box>)
    }

    multiBox() {
        return (<Grid container spacing={3}>
            {this.state.portableAssetsTypes?.map((value: any, ind: number) => (
                <Grid item xs={6} sm={4} key={ind}>
                    <Box className='setBoxs'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            backgroundColor: this.state.id == value.attributes.id ? '#2CE2D5' : "#36364D",
                            boxShadow: this.state.id !== value.attributes.id ? '0px 0px 4px 0px rgba(6, 141, 132, 0.45)' : 'rgb(255 255 255 / 0%) 0px 0px 4px 0px',
                        }}
                        data-test-id="setBox0"
                        onClick={() => {
                            this.setState({ id: value.attributes.id, imgtitel: value.attributes.name })
                        }}>
                        <img width='96px' height="96px" src={value.attributes.image} />
                        <Typography style={{
                            paddingTop: '9px',
                            fontWeight: 500,
                            fontSize: '16px',
                            textAlign: 'center',
                            fontFamily: 'Poppins',
                            color: this.state.id == value.attributes.id ? '#3C3C50' : '#FFF',
                        }}> {value.attributes.name == "more" ? 'Others' : value.attributes.name.charAt(0).toUpperCase() + value.attributes.name.slice(1)}</Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>)
    }

    selectMenu(name: string, setFieldValue: any, customStyles: any, values: any, fieldValues: any) {
        return (
            <Select
                name={name}
                defaultValue={fieldValues}
                classNamePrefix="an-simple-select"
                value={name === "Currencys" ? this.CurrencysFild(fieldValues) : this.fieldValuesDataFild(fieldValues)}
                onChange={(val: any) => { setFieldValue(name, val) }}
                options={this.state.currency_name}
                className="selectMenu"
                styles={customStyles}
                data-test-id="selectMenu"
            />
        )
    }

    propertyLocation = (webStyle: any, values: any, setFieldValue: any) => {
        return (<Autocomplete
            id="combo-box-demo"
            data-test-id="country-dropdown"
            options={this.state.countryList}
            getOptionLabel={(option: any) => option}
            style={webStyle.selectCountry}
            classes={{ popper: 'selectMenu' }}
            value={values.country}
            onChange={(event: ChangeEvent<{}>, newValue: any) => setFieldValue("country", newValue)}
            ListboxProps={{ onScroll: this.handleScroll() }}
            renderInput={(params: any) => this.inputRender(params)}
        />)
    }

    handleScroll = () => {
        if (this.state.apiCallingStage) {
            this.setState({ apiCallingStage: false }, () => {
                this.getPropertyCountriesValue();
            })
        }
    };

    inputRender = (params: any) => {
        return (
            <TextField
                {...params} placeholder={this.getStringAddPortable('assetCountry')} variant="outlined" />
        )
    }

    loanSelectMenu(name: string, setFieldValue: any, customStyles: any, values: any, fieldValues: any) {
        return (
            <Select
                name={name}
                defaultValue={fieldValues}
                classNamePrefix="an-simple-select"
                value={fieldValues}
                onChange={(val: any) => { setFieldValue(name, val) }}
                options={[{label: "Yes", value: "yes"}, {label: "No", value: "no"}]}
                className="selectMenu selectedMenuDropdown"
                styles={customStyles}
                data-test-id="loanSelectMenu"
            />
        )
    }

    getPropertyCountriesValue = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPropertyCountriesCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/get_countries?page=${this.state.countryPage + 1}`
        );
        this.setState({ countryPage: this.state.countryPage + 1 })
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    CurrencysFild = (fieldValues:any) => {
        return fieldValues.value ? fieldValues : ""
    }
    fieldValuesDataFild = (fieldValues:any) => {
        return fieldValues.value ? fieldValues : this.state.selectCashCurrency
    }
    handleMonthlyPaymentCalculate = (name: string, value: string) => {
        switch (name) {
            case "loanAmount":
                this.setState({ loanAmountCal: parseInt(sanitizeAndRemoveCommas(value)) }, () => this.calculateLoanInstallments())
                break;
            case "loanTerm":
                this.setState({ loanTermCal: parseInt(value) }, () => this.calculateLoanInstallments())
                break;
            case "loanMonths":
                this.setState({ loanMonthsCal: parseInt(value) }, () => this.calculateLoanInstallments())
                break;
            case "loanInterest":
                this.setState({ loanInterestsCal: parseInt(value) }, () => this.calculateLoanInstallments())
                break;
            default:
                break;
        }
    }
    OutlinedInputAddAsset = (otherProps:{readOnly:boolean , name: string, placeholder: string}, webStyle: any, values: any, handleChange: any, type: string, nameInputField: { current: null },setFieldValue?: any) => {
        const {name, placeholder,readOnly} = otherProps;
        
        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let inputValue = e.target.value;
        
            if ((name === "Price" || name === 'MarketPrice' || name === 'loanAmount') && setFieldValue) {
                const result = processInputValue(inputValue, 15);
        
                if (result) {
                    const { actualValue, formattedValue } = result;
                    setFieldValue(name, actualValue);
                    handleChange(name)(formattedValue);
                }
            } else {
                handleChange(name)(inputValue || '');
            }
            if (["loanAmount", "loanTerm", "loanMonths", "loanInterest"].includes(name)) {
                this.handleMonthlyPaymentCalculate(name, inputValue);
            }
        };
        return (
            <>
                <OutlinedInput 
                    style={webStyle.inputRoot} 
                    data-test-id="txtInputAssetname" 
                    placeholder={this.getStringAddPortable(placeholder)} 
                    fullWidth={true} 
                    name={name} 
                    type={type} 
                    readOnly={readOnly}
                    value={values} 
                    onChange={handleInputChange} 
                    inputRef={nameInputField} 
                    onKeyDown={this.handleEnterKeyAddAsset} 
                    onClick={() => this.handleNameInputClickAddAsset(name)}
                />
            </>
        )
    }
    handleEnterKeyAddAsset = (e: any) => {
        if (e.key === "Enter") {
            if (this.state.focusedInput === "Assetname") {
                this.state.price.current?.focus();
                this.setState({ focusedInput: "price" })
            } else if (this.state.focusedInput === "price") {
                this.state.loan.current?.focus();
                this.setState({ focusedInput: "Loan" })
            } else if (this.state.focusedInput === "Loan") {
                this.state.Model.current?.focus();
                this.setState({ focusedInput: "model" })
            }
        }
    };

    onKeyDownAddAsset = (keyEvent: any) => { if ((keyEvent.charCode || keyEvent.keyCode) === 13) { keyEvent.preventDefault() } }

    handleNameInputClickAddAsset = (name: string) => { this.setState({ focusedInput: name }) };

    editDataName = () => {
        return this.state.logoAddPortableAsset === "rtl" ? "يحرر" : "Edit"
    }
    addDataName = () => {
        return this.state.logoAddPortableAsset === "rtl" ? "يضيف" : "Add"
    }

    editPortableAssetTitle = () => {
        return !this.state.editPortableData ? this.addDataName() : this.editDataName()
    }

    calculateLoanInstallments = () => {
        const { loanAmountCal, loanInterestsCal, loanMonthsCal, loanTermCal } = this.state
        if (loanAmountCal?.toString() && loanTermCal?.toString() && loanMonthsCal?.toString() && loanInterestsCal?.toString()) {
            const monthlyInterest = loanInterestsCal / (12 * 100);
            const totalPayment = Number(loanTermCal) * 12 + Number(loanMonthsCal);
            const monthlyPayment = Number(loanAmountCal) * (monthlyInterest * Math.pow(1 + monthlyInterest, totalPayment)) / (Math.pow(1 + monthlyInterest, totalPayment) - 1);
            this.setState({ loanPaymentCalculation: monthlyPayment ? formatNumberWithCommas((Math.round(monthlyPayment * 100) / 100).toString()): ""  })
        } else {
            this.setState({ loanPaymentCalculation: "" })
        }
    }
    // Customizable Area End
}
