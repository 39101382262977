// Customizable Area Start
import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    OutlinedInput,
    Button,
    styled
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormApprovalWorkflowController, {
    Props,
} from "./FormApprovalWorkflowController.web";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SelectMenu from '../../../components/src/SelectMenu.web'
import DialogTitle from '@material-ui/core/DialogTitle';
import { image_Imagenav_backicon, plus1, card, apple, SuccessCard, close, groupArrow } from "./assets";
import { convertISOTo12HourFormat, convertMinutesToHoursAndMinutes } from "../../../components/src/commonFn";
import CircularProgress from '@material-ui/core/CircularProgress';
import StripePayment from "../../../components/src/StripeForm/StripePaymentForm";


// Customizable Area End

// Customizable Area Start
const cashCustomStyles = {
    control: (defaultStyles: any) => (
        {
            ...defaultStyles,
            textAlign: "left",
            fontSize: '16px',
            color: 'rgba(255, 255, 255, 0.60)',
            backgroundColor: "transparent !important",
            border: '1px solid #2DF1E3 !important',
            fontWeight: '500',
            padding: "8px",
            fontFamily: 'Poppins',
            borderRadius: '8px',
        }),
};
const theme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor: "#2DF1E3",
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#2DF1E3",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2DF1E3 !important",
                },
                "& .MuiOutlinedInput-input": {
                    color: "white",
                    width: '99.9%',
                    borderBottom: '0.1px',
                }
            },
        },
    },
});

// Customizable Area End

export default class AppointmentPayment extends FormApprovalWorkflowController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


   
    cog = (message: any) => {
        if (this.state.paymentMethod == message) {
            return webStyle.billingAddressFontActive
        } else {
            return webStyle.billingAddressFont
        }
    }
    cog1=(message: any)=>{
        if(this.state.paymentMethod== message){
return webStyle.billingAddressFontActive
        } else{
            return webStyle.billingAddressFont
        }
    }
    cog2=(message: any)=>{
        if(this.state.paymentMethod== message){
return webStyle.billingAddressFontActive
        } else{
            return webStyle.billingAddressFont
        }
    }

    formRender() {
        return (
            <Box>
                {
                    this.state.paymentMethod == 'credit_debit_card' &&
                    <>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={card} style={{ width: '47px' }} />
                                <Typography style={webStyle.formHeader}>{this.getStringTransactions('CreditDebitCard')}</Typography>
                            </Box>
                            <Box style={webStyle.credit_card_form}>
                            <StripePayment handleToken={this.handleCardToken} />
                            </Box>

                        </Box>
                    </>
                }
                {
                    this.state.paymentMethod.includes('dynamic_card') &&
                    <>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={card} style={{ width: '47px' }} />
                                <Typography style={webStyle.formHeader}>{this.state.selectedPaymentMethod.attributes.card_name + " " + this.state.selectedPaymentMethod.attributes.card_number}</Typography>
                                <Typography style={webStyle.formHeader2}>{this.getStringTransactions('ExpiresOn')}{` ${this.state.selectedPaymentMethod.attributes.expires_on}`}</Typography>

                            </Box>
                            <Box style={webStyle.credit_card_form}>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography style={webStyle.card_input_message}>{this.getStringTransactions('AuthWithCVV')}</Typography>
                                        <OutlinedInput
                                            style={{ ...webStyle.inputRootForPayments, marginTop: '12px', marginBottom: '16px', width: '120px' }}
                                            className="inputBox"
                                            fullWidth={true}
                                            placeholder="000"
                                            value={this.state.saveCvvInfo}
                                            onChange={this.handleStoreCvvInfo}
                                            data-test-id="storeCvv"
                                            inputProps={{ maxLength: 4 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                    </Grid>
                                </Grid>

                            </Box>

                        </Box>
                    </>
                }
                {
                    this.state.paymentMethod == 'add_address' &&
                    <>
                        <Box className="add_address_form" >
                            <Box id="add_address_label" style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={webStyle.formHeader}>{this.getStringTransactions('addAddress')}</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyle.address_form} className="form_body">
                            <Grid container spacing={2} className="add_Address_grid">
                                <Grid item xs={12} id="name_label">
                                    <Typography style={webStyle.card_input_message}>{this.getStringTransactions('name')}</Typography>
                                    <OutlinedInput
                                        className="inputBox"
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="addNameInput"
                                        placeholder={this.getStringTransactions('enterName')}
                                        onChange={(event) => this.updateAddAddressDetails('name', event.target.value)}
                                        fullWidth={true} required
                                    />
                                </Grid>
                                <Grid item xs={12} id="addressFieldLabel">
                                    <Typography style={webStyle.card_input_message}>{this.getStringTransactions('address')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        fullWidth={true}
                                        data-test-id="addAddressInput"
                                        onChange={(event) => this.updateAddAddressDetails('address', event.target.value)}
                                        placeholder={this.getStringTransactions('enterAddress')} required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="cityLabel">
                                    <Typography style={webStyle.card_input_message}>{this.getStringTransactions('city')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        data-test-id="addCityInput"
                                        onChange={(event) => this.updateAddAddressDetails('city', event.target.value)}
                                        fullWidth={true}
                                        placeholder={this.getStringTransactions('enterCity')} required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="zipcodeBlock">
                                    <Typography style={webStyle.card_input_message}>{this.getStringTransactions('zipCode')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="addZipCodeInput"
                                        className="inputBox"
                                        onChange={(event) => this.updateAddAddressDetails('zip_code', event.target.value)}
                                        fullWidth={true}
                                        placeholder={this.getStringTransactions('EnterZipCode')}
                                        inputProps={{ maxLength: 9 }}
                                    />
                                </Grid>
                                <Grid item xs={12} id="countryLabel">
                                    <Typography style={webStyle.card_input_message}>{this.getStringTransactions('selectCountry')}</Typography>
                                    <Box style={{ marginTop: '12px' }}>
                                        <SelectMenu countryName={this.state.countryName}
                                            paymentMethod={this.state.paymentMethod}
                                            address={this.state.address}
                                            addAddressFormDetails={this.state.addAddressFormDetails}
                                            updateAddAddressDetails={this.updateAddAddressDetails}
                                           
                                           customStyles={cashCustomStyles}/>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography data-test-id="addNewAddress" onClick={() => this.setNewAddress()} style={{ ...webStyle.confirmBtnMessage, cursor: 'pointer', float: 'right' }}>{this.getStringTransactions('confirmDetails')}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
                {
                    this.state.paymentMethod == 'edit_address' &&
                    <>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={webStyle.formHeader}>{this.getStringTransactions('editAddress')}</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyle.address_form}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message}>{this.getStringTransactions('name')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="editAddressName"
                                        className="inputBox"
                                        fullWidth={true}
                                        onChange={(event) => this.updateAddAddressDetails('name', event.target.value)}
                                        defaultValue={this.state.addAddressFormDetails.name}
                                        placeholder={this.getStringTransactions('enterName')}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message}>{this.getStringTransactions('address')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        data-test-id="editAddressAddress"
                                        fullWidth={true}
                                        onChange={(event) => this.updateAddAddressDetails('address', event.target.value)}
                                        defaultValue={this.state.addAddressFormDetails.address}
                                        placeholder={this.getStringTransactions('enterAddress')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Typography style={webStyle.card_input_message}>{this.getStringTransactions('city')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        fullWidth={true}
                                        data-test-id="editAddressCity"
                                        onChange={(event) => this.updateAddAddressDetails('city', event.target.value)}
                                        defaultValue={this.state.addAddressFormDetails.city}
                                        placeholder={this.getStringTransactions('enterCity')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Typography style={webStyle.card_input_message}>{this.getStringTransactions('zipCode')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        data-test-id="editAddressZipCode"
                                        fullWidth={true}
                                        onChange={(event) => this.updateAddAddressDetails('zip_code', event.target.value)}
                                        defaultValue={this.state.addAddressFormDetails?.zip_code}
                                        placeholder={this.getStringTransactions('EnterZipCode')}
                                        inputProps={{ maxLength: 9 }}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message}>{this.getStringTransactions('selectCountry')}</Typography>
                                    <Box style={{ marginTop: '12px' }}>
                                        <SelectMenu
                                            countryName={this.state.countryName}
                                            paymentMethod={this.state.paymentMethod}
                                            addAddressFormDetails={this.state.addAddressFormDetails}
                                            address={this.state.address}
                                            customStyles={cashCustomStyles}
                                            updateAddAddressDetails={this.updateAddAddressDetails}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={{ ...webStyle.confirmBtnMessage, cursor: 'pointer', float: 'right' }} data-test-id="editConfirmDetails" onClick={() => this.editAddress()}>{this.getStringTransactions('confirmDetails')}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
            </Box>
        )
    }
    renderBoxPadding = () => this.state.languagesLogin === 'rtl' ? '0px 0px 0px 23px' : '0px 23px 0px 0px';
    renderBoxMargin = () => this.state.languagesLogin === 'rtl' ? '0px 75px 0px 0px' : '0px 0px 0px 27px'; 
    renderImgTrnasform = () => this.state.languagesLogin === 'rtl' ? 'scaleX(-1)':'' ;
    renderBillingPadding = () => this.state.languagesLogin === 'rtl' ? '0px 0px 0px 50px':'0px 50px 0px 0px';
    renderPositionRight =()=> this.state.languagesLogin === 'rtl'?'':'23px';
    renderPositionLeft =()=> this.state.languagesLogin === 'rtl'?'23px':'';


    render() {     


        return (
            // Customizable Area Start
            // Required for all blocks
            <Box style={{ width: '100%', scrollbarWidth: 'thin', scrollbarColor: '#00ebd9 transparent', height: '99.9vh', overflowY: 'scroll', overflowX: 'hidden',padding: this.renderBoxPadding()  }} dir={this.state.languagesLogin}>
                <Box >
                    <Box >
                        <Box >
                            <ThemeProvider theme={theme}>
                                <CssBaseline />


                                <Box dir={this.state.languagesLogin} style={{ display: 'flex', alignItems: 'center', marginTop: '30px', margin: this.renderBoxMargin()}}>
                                    <NavLink to="/Calendar"><img src={image_Imagenav_backicon} alt="back-icon" style={{transform: this.renderImgTrnasform()}} /></NavLink>
                                    <Typography style={webStyle.customLabel}>{this.getStringTransactions('ProceedPayment')}</Typography>

                                </Box>

                                <Grid container spacing={2} style={{ margin: '20px' }} dir={this.state.languagesLogin}>
                                    <Grid item xs={12} sm={6}>
                                        <Box style={{ paddingRight: '50px', width: '90%' }}>
                                            <Typography style={webStyle.customLabel2}>{this.getStringTransactions('Details')}</Typography>
                                            <Box style={{ marginTop: '22px' }}>
                                                <Box style={{ display: "flex", gap: '30px', alignItems: "center" }}>
                                                    <Typography style={webStyle.customLabel43}>{this.getStringTransactions('ConsultationDate')}</Typography>
                                                    <Typography style={webStyle.customLabel434}>{this.state.timeSlotDate.date}</Typography>

                                                </Box>

                                                <Box style={{ display: "flex", gap: '30px', alignItems: "center", marginTop: '12px' }}>
                                                    <Typography style={webStyle.customLabel43}> {this.getStringTransactions('ConsultationTime')}</Typography>
                                                    {
                                                        this.state.timeSlotDate.time && <Typography style={webStyle.customLabel434}>{convertISOTo12HourFormat(this.state.timeSlotDate.time)} </Typography>
                                                    }

                                                </Box>
                                            </Box>



                                            <Box style={{ marginTop: '22px' }}>
                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography style={webStyle.customLabel2}>{this.getStringTransactions('SelectedPlan')}</Typography>
                                                </Box>
                                                <Box style={webStyle.normalCardBox as React.CSSProperties} >
                                                    <Button variant="contained" style={{
                                                        position: 'absolute',
                                                        top: '-20px',
                                                        left: '50%',
                                                        transform: 'translate(-50%)',
                                                        borderRadius: 30,
                                                        background: this.getBackgroundColor(this.state.currentPlan?.attributes.tier).bgColor,

                                                        color: 'white',
                                                        backgroundBlendMode: 'normal'
                                                    }}>{this.state.currentPlan?.attributes?.name}</Button>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                       
                                                        <Typography style={{ fontSize: '18px', fontFamily: 'Poppins', fontWeight: 500 }}>
                                                        {
                                                            this.state.currentPlan && convertMinutesToHoursAndMinutes(this.state.currentPlan.attributes.duration)
                                                        }</Typography>
                                                        <div style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}></div>
                                                    </div>
                                                    <Typography style={{ fontSize: '22px', fontFamily: 'Poppins', fontWeight: 500 }}>
                                                    {
                                                            this.state.currentPlan && this.state.currentPlan?.attributes.currency_symbol
                                                    }    
                                                    {`${this.state.currentPlan?.attributes.amount?.toFixed(2)}`}</Typography>
                                                    <Box style={{ position: 'relative' }}>
                                                        <hr style={{ position: 'absolute', width: '100%', height: '1px', color: '#E1E6EC' }} />
                                                        <p style={{ fontSize: '20px', position: 'absolute', left: '50%', transform: 'translateX(-50%)', textAlign: 'center', top: '-25px', padding: '0px 10px', backgroundColor: 'white', fontFamily: 'Poppins', fontWeight: 500 }}> {this.getStringTransactions('BenifitsOfPlan')}</p>
                                                    </Box>
                                                    <Box style={{ marginTop: '25px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                        {this.state.currentPlan?.attributes.benefits.map((benefit: string, index: number) => (
                                                            <Box key={index} style={webStyle.profileNameNew}>
                                                                <Box data-test-id="backButton">
                                                                    <img src={this.getBackgroundColor(this.state.currentPlan?.attributes.tier).imgSrc} style={{...webStyle.marginImg1, maxWidth:'31px'}} alt="arrow" />
                                                                </Box>
                                                                <Box style={{...webStyle.planning1, wordBreak:"break-all"}}>{benefit}</Box>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography style={webStyle.customLabel22}> {this.getStringTransactions('Summary')}</Typography>
                                            <Box style={{ marginTop: '22px' }}>
                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography style={webStyle.customLabel3}> {this.getStringTransactions('YourPlan')}</Typography>
                                                    <Typography style={{textTransform:'uppercase',...webStyle.customLabel4}}>{this.state.currentPlan?.attributes.name}</Typography>
                                                </Box>
                                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography style={webStyle.customLabel3}> {this.getStringTransactions('Payment')}</Typography>
                                                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                                        <>  <Typography style={webStyle.customLabel3}>{this.state.currentPlan?.attributes.currency}  {this.state.currentPlan?.attributes.amount?.toFixed(2)}</Typography>&nbsp;&nbsp;</>
                                                        <Typography style={webStyle.customLabel4}></Typography>
                                                    </Box>
                                                </Box>
                                                <hr color="#928F98" />
                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography style={webStyle.customLabel3}> {this.getStringTransactions('TotalCharge')}</Typography>
                                                    <Typography style={webStyle.customLabel5}> {this.state.currentPlan?.attributes.currency} {this.state.currentPlan?.attributes.amount?.toFixed(2)}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>




                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box style={{ marginBottom: '100px' }}>
                                            {this.state.address ?
                                                <Box style={{ marginTop: '24px', padding:   this.renderBillingPadding() , width: '90%' }}>
                                                    <Box style={webStyle.editAddressBox}>
                                                        <Typography style={webStyle.customLabel2}> {this.getStringTransactions('BillingAddress')}</Typography>
                                                        {this.state.paymentMethod != 'edit_address' &&
                                                            <Typography style={webStyle.editDetailsFont} data-test-id="editDetailsBtn" onClick={() => this.editDetails()}> {this.getStringTransactions('EditDetails')}</Typography>
                                                        }
                                                    </Box>

                                                    <Box>
                                                       
                                                        <Typography
                                                            style={this.cog('edit_address')}
                                                        >{this.state.address?.attributes?.name}</Typography>
                                                            <Typography
                                                            style={this.cog1('edit_address')}
                                                        >{this.state.address?.attributes?.address}</Typography>
                                                            <Typography
                                                            style={this.cog2('edit_address')}
                                                        >{this.state.address?.attributes?.city + ", " + this.state.address?.attributes?.zipcode + ", " + this.state.address?.attributes?.country}</Typography>

                                                       

                                                    </Box>

                                                </Box>
                                                : <>
                                                    <Box style={{ marginTop: '24px', paddingRight: '0px', width: '77%' }}>
                                                        <Typography style={webStyle.customLabel2}>{this.getStringTransactions('BillingAddress')}</Typography>
                                                        <Box data-test-id="addAddressBtn" onClick={() => { this.checkboxHandle('add_address') }} style={{ ...webStyle.addressBtn, display: 'flex', alignItems: 'center', width:'100%' }}>
                                                            <img src={plus1} style={{ width: '16px', height: '16px', marginLeft: '10px' }} />
                                                            <Typography style={webStyle.customLabel6}> {this.getStringTransactions('BillingAddress')}</Typography>

                                                        </Box>

                                                    </Box>

                                                </>}
                                            <Box style={{ marginTop: '24px', paddingRight: '0px', width: '77%' }}>
                                                <Typography style={webStyle.customLabel2}>{this.getStringTransactions('PaymentMethod')}</Typography>
                                                {this.state.paymentMethods &&
                                                    this.state.paymentMethods.map((values: any) => {
                                                        return (
                                                            <Box style={{ ...webStyle.addressBtn2, display: 'flex', position: 'relative', alignItems: 'center', width:'100%' }} data-test-id={`dynamic_card_${values.id}`} className={this.state.paymentMethod == `dynamic_card_${values.id}` ? 'activeBorder' : ""} onClick={() => this.paymentMethodForDynamicCard(values)}>
                                                                {values.attributes.primary_card &&
                                                                    <div style={{ position: 'absolute', top: '-11px', right: this.renderPositionRight(), left: this.renderPositionLeft()  , background: '#814FF9', borderRadius: '5px', padding: '1px 10px', fontSize: '12px', color: '#fff', }}> {this.getStringTransactions('Primary')}</div>
                                                                }
                                                                <div className="main-radio-box">
                                                                    <div className="radio-box2" >
                                                                        <input type="radio" className="radio-button" value="search_engine" checked={this.state.paymentMethod === `dynamic_card_${values.id}`} />
                                                                        <label>
                                                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <img src={card} style={{ width: '47px' }} />
                                                                                <Box>
                                                                                    <Typography style={webStyle.cardNameLabel}>{values.attributes.card_name + ' ' + values.attributes.card_number}</Typography>
                                                                                    <Typography style={webStyle.cardExpireLabel}>{this.getStringTransactions('ExpiresOn')}{` ${values.attributes.expires_on}`}</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </label></div>
                                                                </div>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                          
                                            <Box style={{ ...webStyle.addressBtn2, cursor: "pointer", display: 'flex', alignItems: 'center' }} data-test-id="credit-debit-card-btn" className={this.state.paymentMethod == 'credit_debit_card' ? 'activeBorder' : ""} onClick={() => this.checkboxHandle('credit_debit_card')}>
                                                <div className="main-radio-box">
                                                    <div className="radio-box2" data-test-id="radioBox1"><input type="radio" className="radio-button" value="search_engine" checked={this.state.paymentMethod === "credit_debit_card"} /><label>
                                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={card} style={{ width: '47px' }} />
                                                            <Typography style={{ ...webStyle.customLabel7, cursor: "pointer" }}>{this.getStringTransactions('CreditDebitCard')}</Typography>
                                                        </Box>
                                                    </label></div>
                                                </div>
                                            </Box>

                                            {this.formRender()}
                                        </Box>

                                    </Grid>

                                </Grid>

                            </ThemeProvider>
                            {this.handleIsProcessPaymentVisible() &&
                                <Box data-test-id="completePurchaseBtn" onClick={() => this.completePurchase()} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Box>
                                        <Typography data-test-id="purchaseBtn" style={webStyle.buttonContinue as React.CSSProperties} >{this.getStringTransactions('CompletePurchase')}</Typography>
                                    </Box>
                                </Box>
                            }

                        </Box>

                    </Box>
                </Box>
                <Box >
                    <Dialog
                        open={this.state.openSuccessAddress}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogCashModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogCashPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" , minWidth:'40%'}}><img src={SuccessCard} /></DialogTitle>
                        <DialogContent style={{ padding: '7.5px 20px', textAlign: 'center' }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent'>
                                {this.getStringTransactions('AddressUpdateSuccess')}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.openRejectedPopup}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogRejectModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogRejectedCashPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={close} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{ textAlign: 'center' }}>
                                {this.getStringTransactions('PaymentfailedMsg')}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    {this.state.isLoader && <LoaderContainer style={{color:"#fff"}}>
        <CircularProgress  size={20} color="inherit"  />
        </LoaderContainer>}
                </Box>
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const LoaderContainer = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    height: "100vh",
    position: "absolute",
    top: 0,  // Add top 0 to position correctly
    right: 0,
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.5)",  // Dark background for better visibility
    color: "#fff",
});
const webStyle = {
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    normalCardBox: {
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '12px',
        border: '1px solid #2675ff',
        padding: '24px 27px',
        position: 'relative',
        marginTop: '38px',
    },
    profileNameNew: { display: 'flex', alignItems: 'center' },
    marginImg1: { marginRight: '10px', cursor: "pointer" },
    planning1: { fontSize: "18px", color: "black", fontFamily: 'Poppins', fontWeight: 400 },
    buttonContinue: {
        width: '100%',
        maxWidth: '456px',
        background: '#C9F9F6',
        border: '1px solid #707070',
        padding: '15px',
        borderRadius: '10px',
        color: '#3C3C50',
        fontSize: '18px',
        fontWeight: '700',
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginTop: '30px',
        marginBottom: '30px'
    },
    save_card_msg: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Poppins',
    },
    confirmPurchaseBtn: {
        backgroundColor: '#C9F9F6',
        display: 'flex',
        width: '456px',
        height: '56px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
    },
    card_input_message: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 300,
        fontFamily: 'Poppins',
    },
    credit_card_form: {
        borderRadius: '12px',
        border: '1px solid #2DE2D5',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.66)',
        width: '77%',
        marginTop: '22px',
        padding: '20px',
    },
    address_form: {
        width: '340px',
    },
    customLabel: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '24px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        marginLeft: "10px",
        marginRight: "10px",
    },
    billingAddressFontActive: {
        color: '#2CE2D5',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    billingAddressFont: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    confirmBtnMessage: {
        color: '#C9F9F6',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    card_message: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '10px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '0px 7px',

    },
    customLabel2: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    customLabel22: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        marginTop: '24px',
    },
    editAddressBox: {
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: "center",
    },
    editDetailsFont: {
        color: '#814FF9',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        cursor: 'pointer',
    },
    customLabel3: {
        color: '#928F98',
        padding: "5px",
        fontStyle: 'normal',
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    customLabel43: {
        color: '#2CE2D5',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    customLabel434: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    customLabel4: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    customLabel5: {
        color: '#2CE2D5',
        fontStyle: 'normal',
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    customLabel6: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 12px',
    },
    customLabel7: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },
    cardNameLabel: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '0px 7px',

    },
    cardExpireLabel: {
        color: 'rgba(255, 255, 255, 0.50)',
        fontStyle: 'normal',
        fontSize: '10px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '0px 7px',
    },
    MuiPaperRoot :{
minWidth: "50%"
    },
    formHeader: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },
    formHeader2: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },


    inputRootForPayments: {
        borderRadius: '10px',
        height: '56px',
        fontFamily: 'Poppins',
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '& input::placeholder': {
            fontWeight: 500,
            fontSize: '12px',
            color: 'rgba(255, 255, 255, 0.60)',

        }, '& .MuiInputBase-input': {
            borderColor: '#2DF1E3 !important'
        },
    },
    cekbox: {
        '& .MuiCheckbox-root': {
            color: 'aqua',
        },
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },

    buttons: {
        backgroundColor: "#c7f9f6",
        borderRadius: '10px',
        margin: '0px',
        Color: '#3C3C50',
        height: "50px",
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'none',
    },
    addressBtn: {
        borderRadius: "12px",
        border: '1px solid #928F98',
        width: '65%',
        height: '60px',
        marginTop: '22px',
        cursor: 'pointer',
    },
    addressBtn2: {
        borderRadius: "12px",
        border: '1px solid #928F98',
        width: '77%',
        height: '60px',
        marginTop: '15px',
        cursor: 'pointer',
    },
    logoandimg: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        paddingTop: '46px',
        paddingRight: '49px',
        paddingLeft: '49px'
    },
    fieldError: {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    }
};
// Customizable Area End
// Customizable Area End
