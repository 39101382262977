import React from "react";
// Customizable Area Start
import "./style.css";
import {
  Box,
  Typography,
  Grid,
  Button,
  OutlinedInput,
} from "@material-ui/core";
import {
  subarrowicon,
  picture,
  globe,
  plus1
} from "./assets";
import Select from 'react-select';
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";
import DashboardController, { Props } from "./DashboardController";
import { styled } from '@material-ui/core/styles';
// Customizable Area End

const cashCustomStyles = {
  control: (defaultStyles: any) => (
      {
          ...defaultStyles,
          fontSize: '16px',
          textAlign: "left",
          backgroundColor: "transparent !important",
          color: 'rgba(255, 255, 255, 0.60)',
          border: '1px solid #2DF1E3 !important',
          fontWeight: '500',
          borderRadius: '8px',
          padding: "3px 8px",
          fontFamily: 'Poppins',
      }),
    input: (defaultStyles: object) => ({ ...defaultStyles, color: '#fff' })

};

export default class EditAccountInfo extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start


  dialogPaperStyle1 = {
    width: 'max-content',
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #2DE2D5',
    padding: '19px 23px',
  }; 

  selectMenu(customStyles: any) {
    let value = { value: "", label: "" };
        value = { value: this.state.addAddressFormDetails?.country, label: this.state.addAddressFormDetails?.country }

    return (
        <Select
            options={this.state.countryName} value={value}
            onChange={(event) => this.updateAddAddressDetails('country', event)}
            classNamePrefix="an-simple-select" className="selectMenu" data-test-id="selectMenu" styles={customStyles} />
    )
}
  AddForm = () => (
    <Box style={{ marginTop: '24px', paddingRight: '50px', width: '100%', display: "flex", gap: "50px" }}>
      <Box style={{ width: '50%' }}>
        <Typography style={webStyle.customLabel2}>Billing Address</Typography>
        <Box data-test-id="addAddressBtn" onClick={() => this.setState({ editAddressStep: "add" })} style={{ ...webStyle.addressBtn, display: 'flex', alignItems: 'center' }}>
          <img src={plus1} style={{ width: '16px', height: '16px', marginLeft: '10px' }} />
          <Typography style={webStyle.customLabel6}>Add new address</Typography>

        </Box>
      </Box>
      <Box style={{ width: '50%' }}>
        <Typography style={webStyle.customLabel2}>Account Details</Typography>
        <Box data-test-id="addAddressBtn" style={{ ...webStyle.addressBtn, display: 'flex', alignItems: 'center' }}>
          <img src={plus1} style={{ width: '16px', height: '16px', marginLeft: '10px' }} />
          <Typography style={webStyle.customLabel6}>Add account details</Typography>

        </Box>
      </Box>

    </Box>
  )
  renderProfile = () =>{
    return (
      <div  data-test-id="profileLinkTab" onClick={()=> this.goToProfilePage()} style={styles.containerProfile} >
      <div>
        {this.state.plannerInfo.image ? 
        (
          <img
            style={styles.imageAvatar}
            src={this.state.plannerInfo.image}
            alt="profile"
          />
         ) : 
         (
          <div style={styles.noProfileImg}>
            {getFirstCharacterFromEachWord(this.state.plannerInfo.name)}
          </div>
        )}
      </div>
      <div style={styles.textContainers1}>
        <span style={styles.nameStyleProfile}>{this.state.plannerInfo.name}</span>
        <span style={styles.emailInfo}>{this.state.plannerInfo.email}</span>
      </div>
    </div>
    )}

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <StyleWrapper className="pagedashboard-wrapper">
        <div className="header-wrapper" style={{ justifyContent: 'end' }}>
          <div className="page-info">
            <ul className="drop-menu">
              <li>
              {this.renderProfile()}
              </li>
            </ul>
          </div>
        </div>

        <div style={{ display: 'flex', gap: 30 }} >

          <div className="faq-wrapper" style={{ width: '100%', height: 300 }}>
            {this.state.editAddressStep !== "" && <div
              className={this.state.expandBank}
              style={{ border: '2px solid rgba(192, 182, 182, 0.1)' }}
              data-test-id="bankaccounttab"
            >

              <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                <h4 style={{ color: 'white', margin: 0 }}>Payments Details</h4>
              </div>
              {this.state.editAddressStep === "initial" ?
                this.AddForm() : <div className="viewPaymentDetails">
                <div className="viewBillingDetails">
                  <BorderBottomBox style={webStyle.address_form} className="form_body">
                    <h5 style={{ color: 'white', marginTop: 10, marginBottom: 10 }}>Billing Address</h5>
                    <Grid container spacing={1} className="add_Address_grid">
                      <Grid item xs={12} id="name_label">
                        <Typography style={webStyle.card_input_message}>Full Name:</Typography>
                        <OutlinedInput
                          className="inputBox"
                          style={{ ...webStyle.inputRootForPayments, marginTop: '6px' }}
                          data-test-id="addNameInput"
                          placeholder="Enter Name"
                          value={this.state.addAddressFormDetails.name}
                          onChange={(event) => this.updateAddAddressDetails('name', event.target.value)}
                          fullWidth={true} required
                        />
                      </Grid>
                      <Grid item xs={12} id="addressFieldLabel">
                        <Typography style={webStyle.card_input_message}>Address:</Typography>
                        <OutlinedInput
                          style={{ ...webStyle.inputRootForPayments, marginTop: '6px',wordBreak: 'break-word',overflow: 'hidden', textOverflow: 'ellipsis',whiteSpace: 'normal' }}
                          className="inputBox"
                          fullWidth={true}
                          data-test-id="addAddressInput"
                          value={this.state.addAddressFormDetails.address}
                          onChange={(event) => this.updateAddAddressDetails('address', event.target.value)}
                          placeholder="Enter Address" required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} className="cityLabel">
                                    <Typography style={webStyle.card_input_message}>City:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '6px' }}
                                        className="inputBox"
                                        data-test-id="addCityInput"
                                        value={this.state.addAddressFormDetails.city}

                                        onChange={(event) => this.updateAddAddressDetails('city', event.target.value)}
                                        fullWidth={true}
                                        placeholder="Enter City" required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="zipcodeBlock">
                                    <Typography style={webStyle.card_input_message}>Zip Code:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '6px' }}
                                        data-test-id="addZipCodeInput"
                                        className="inputBox"
                                        value={this.state.addAddressFormDetails.zip_code}
                                        onChange={(event) => this.updateAddAddressDetails('zip_code', event.target.value)}
                                        fullWidth={true}
                                        placeholder="Enter Zip Code" required
                                    />
                                </Grid>
                                <Grid item xs={12} id="countryLabel">
                                    <Typography style={webStyle.card_input_message}>Select Country:</Typography>
                                    <Box style={{ marginTop: '6px' }}>
                                        {(this.selectMenu(cashCustomStyles))}
                                    </Box>
                                </Grid>
                      <Grid item xs={12} style={{display:"flex",gap:"10px",width:"100%", marginTop: 30,flexWrap: "wrap"}} >
                        <Button
                          style={{ ...webStyle.editButton2 }} data-test-id="edit-services-btn" onClick={()=>this.goToPaymentPage()}>   
                          <Typography style={{ textTransform: 'none',fontFamily: "'Poppins'", }}>Cancel</Typography>
                        </Button>
                        <Button
                          style={{ ...webStyle.editButton }} data-test-id="edit-services-btn1" onClick={this.handleSaveClick}>
                          <Typography style={{ textTransform: 'none', fontFamily: "'Poppins'", }}>Save</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </BorderBottomBox>
                </div>
                <div className="viewPaymentDetailsData">
                  <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                    <h5 style={{ color: 'white' }}>Account Details</h5>
                    <p style={{ fontSize: '13px', color: '#9400ff',cursor:"pointer"}} data-test-id="NavigateToAccount" onClick={() => this.goToEditAccountPage()}>Edit Details</p>
                  </div>
                  <div>
                    <p style={{ fontSize: '15px', color: '#ffffff85',lineHeight:1 }}>{this.state.addAccountDetails.beneficiary_name}</p>
                    <p style={{ fontSize: '15px', color: '#ffffff85',lineHeight:1 }}>Address: {this.state.addAccountDetails.address}</p>
                    <p style={{ fontSize: '15px', color: '#ffffff85' ,lineHeight:1}}>Account Number: {this.state.addAccountDetails.bank_number}</p>
                    <p style={{ fontSize: '15px', color: '#ffffff85',lineHeight:1 }}>IBAN number:{this.state.addAccountDetails.iban_number}</p>
                  </div>
                </div>
              </div>}

            </div>}
          </div>
        </div>
       
      </StyleWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyleWrapper = styled(Box)({
  "& .viewPaymentDetails": {
    display: "flex",
    width: "100%",
  },
  "& .viewBillingDetails": {
    color: 'white', 
    paddingInline: "15px", 
    borderRight: '2px solid #2CE2D5',
    width: "50%"
  },
  "& .viewPaymentDetailsData": {
    color: 'white', 
    paddingInline: "100px",
    width: "50%",
  },
  "@media(max-width: 960px)": {
    "& .viewPaymentDetails": {
      flexWrap: "wrap",
    },
    "& .viewBillingDetails": {
      width: "100%",
      borderRight: 'none',
      borderBottom: '2px solid #2CE2D5',
      marginBottom: "20px",
      paddingBottom: "20px",
    },
    "& .viewPaymentDetailsData": {
      width: "100%",
      paddingInline: "10px",
    },
  }
})

const BorderBottomBox = styled(Box)({
  '& input': {
    borderBottom: 'none !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#2CE2D5 !important',
  },
})


const ButtonStyle1 = styled(Button)({
  borderRadius: "8px", background: "#C8F9F6", color: "#000", textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, padding: "5px 30px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {
    backgroundColor: "#395D6B",
  },
})
const ButtonStyleDecline = styled(Button)({
  borderRadius: "8px", textAlign: "center", fontFamily: "Poppins", border: '1px solid #c44d4d', color: "#c44d4d", fontSize: "16px", fontStyle: "normal", fontWeight: 600, padding: "5px 30px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {

  },
})
const ButtonStyle2 = styled(Button)({
  borderRadius: "8px", border: '1px solid #2DE2D5', color: "#fff", textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, padding: "5px 30px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {

  },
})
const IncomeOverviewSelect = styled(Box)({
  backgroundColor: 'transparent',
  appearance: 'none',
  color: 'white',
  width: '113px',
  borderRadius: '5px',
  border: '1px solid #f3ecec6e',
  paddingTop: '1px',
  paddingBottom: '1px',
  paddingLeft: '9px',
  paddingRight: '9px',
  display: 'flex',
  justifyContent: "space-between",
  alignItems: 'center',
  gap: '10%',
  cursor: "pointer"
})

const styles = {
  faqWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  emailInfo: {
    fontSize: "12px",
    marginTop:"-8px",
    color: "#ffffff78",
  },
  containerProfile: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  }as React.CSSProperties,
  nameStyleProfile: {
    fontSize: "14px",
    color: "white",
  },
  expandBank: {
    border: '2px solid rgba(192, 182, 182, 0.1)',
  },
  tableWrapper: {
    flex: 1,
    marginLeft: '3%',
  },
  heading: {
    color: 'white',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '3%',
    borderBottom: '1px solid #ffffff78',
    padding: '2%',
    fontSize: '13px',
    boxShadow: '0px 3px 0px 0px rgba(10, 150, 150, .19)',
    color: 'white',
  },
  columnHeader: {
    color: '#ffffff80',
  },
  column: {
    flex: 1,
  },
  actionColumn: {
    textAlign: 'center',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    gap: '3%',
    color: '#8C8C90',
    fontSize: '10px',
    alignItems: 'center',
  },
  paginationItem: {
    border: '2px solid rgb(44, 226, 213)',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '2px',
    paddingBottom: '2px',
    borderRadius: '8px',
  },
  dateSelected: {
    backgroundColor: "#1A6882",
  },
  ActiveCallJoinBtn: {
    backgroundColor: "#066DE61A",
    color: "#066DE6"
  },
  actionContainer: {
    backgroundColor: "#395d6b",
    padding: "1rem",
    borderRadius: "14px",
    zIndex: 999
  },
  monthlyIncomeTxt: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
    cursor: "pointer"
  },
  yearlyIncomeTxt: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
    marginTop: "1rem",
    cursor: "pointer"
  },
  noProfileImg: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    color: "#fff",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    backgroundColor: "2CE2D5",
  }as React.CSSProperties,
  imageAvatar: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
  },
  textContainers1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "12px",
  }as React.CSSProperties,
  clientTabContainer: {
    color: '#ffffff80', display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '2% 0px 0px',
    gap: '10%',
    borderBottom: '1px solid #ffffff78',
    fontSize: '13px',
    cursor: "pointer"
  } as React.CSSProperties,
  activeTabStyle: { color: 'white', marginBottom: -1, borderBottom: '3px solid rgb(44, 226, 213)' } as React.CSSProperties,
};

const webStyle = {
  formHeader: {
    color: 'white',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    margin: '12px 7px',
  },
  address_form: {
    maxWidth: '340px',
    width: '100%',
    margin: 'auto'
  },
  card_input_message: {
    color: 'white',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300,
    fontFamily: 'Poppins',
  },
  inputRootForPayments: {
    borderRadius: '10px',
    height: '44px',
    fontFamily: 'Poppins',
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336 !important',
    },
    '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2DF1E3 !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2DF1E3 !important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2DF1E3 !important',
    },
    '& input::placeholder': {
      fontWeight: 500,
      fontSize: '12px',
      color: 'rgba(255, 255, 255, 0.60)',

    }, '& .MuiInputBase-input': {
      borderColor: '#2DF1E3 !important'
    },
  },
  confirmBtnMessage: {
    color: '#C9F9F6',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Poppins',
  },
  editButton: {
    background: "#c9f9f6",
    color: "#3c3c50",
    minWidth: "130px",
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: "8px",
    padding: '10px 0',
    flex:1,
    cursor:"pointer",
    fontFamily: "'Poppins', sans-serif",
  },
  editButton2: {
    border: "1px solid #c9f9f6",
    color: "#c9f9f6",
    minWidth: "130px",
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: "8px",
    padding: '10px 0',
    flex:1,
    cursor:"pointer",
    fontFamily: "'Poppins', sans-serif",
  },
  customLabel2: {
    color: 'white',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Poppins',
  },
  customLabel6: {
    color: 'white',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    margin: '12px 12px',
  },
  addressBtn: {
    borderRadius: "12px",
    border: '1px solid #928F98',
    width: '100%',
    height: '60px',
    marginTop: '22px',
    cursor: 'pointer',
  },
}
// Customizable Area End