import React from "react";
// Customizable Area Start
import "./style.css"
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { back_icon, country, view_image, noBanks } from "./assets";
import storage from "../../../framework/src/StorageProvider.web";

interface Props {
  id: string;
  navigation: any;
  instance: any;
}
interface S { }
interface SS { }
// Customizable Area End



export default class BankLists extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleCountry = () => {
    const { instance } = this.props;
    return instance.state.selectedCountry ? instance.state.selectedCountry : instance.state.searchLocation;
  };
  renderBankContent(instance: any) {
    return (
      <div className="bank-integraion-wrapper">
        <div className="bank-integration-box d-flex align-center">

          <div className="bank-account-wrapper banklist-wrapper-no-bank">
            <img className="back-icon" data-test-id='bank-icon-click' onClick={() => { instance.setState({ componentPage: "home" }) }} src={back_icon} alt="back-icon" />
            <h4>Add Bank Account</h4>
            <div className="state-dropdown">
              <div data-test-id="countriesDropdown" onClick={() => { instance.handleDropdownVisible() }}>
                <img src={country} alt="" />
                <p>{instance.handleBankParams()}<img src={view_image} alt="" /></p></div>
              {instance.state.dropdownVisible &&
                <div className="country-dropdown" style={{width: '200px',background: '#33505f', color: '#FFFFFF'}}>
                  <ul data-test-id="test123" onScroll={() => { instance.callCountry() }}>
                    <input
                      data-test-id="bankField3"
                      type="text"
                      style={{ borderBottom: 'none', backgroundColor: "#aaaaaa", borderRadius: '5px', color: "black" }}
                      className="searchBankCountry"
                      value={this.handleCountry()}

                      onChange={(e) => { instance.inputLocation(e.target.value) }}
                      placeholder="Select Location" />
                    <li className="dropdown-li-content" data-test-id="dropdown" onClick={() => { instance.onCountryReset() }}>Select Country</li>
                    {instance.state.countries && instance.state.countries.map((value: any, index: number) => {
                      return <li data-test-id={'countries-data1-' + index} onClick={() => { instance.onCountryChange(value.id, value.attributes.name) }} className="dropdown-li-content">{value.attributes.name}</li>;
                    })}
                  </ul>
                </div>
              }
            </div>
            <div className="bank-account-content-wrapper">
              <div className="row d-flex" style={{gap:'10px'}}>
                {instance.state.banks && instance.state.banks[0] ? instance.state.banks[0].map((value: any, index: number) => {
                  return (<>
                    <div className="col-6" key={index}>
                      <div data-test-id={"bank-change-" + index} onClick={() => {
                        storage.set("bank_id",value.id)
                        instance.setState({
                          flankId: value.flanks_id,
                          bankIdentifier: value.identifier,
                          selectedBank: value.id,
                          selectedBankName: value.name,
                          selectedBankLogo: value.logo_url,
                          storage_provider: value.saltedge_provider_code
                        });
                      }} className={`${instance.state.selectedBank == value.id ? "active" : ""} bank-account-content d-flex`}>
                        <div className="bank-image">
                          <img
                            className="active-img"
                            src={value.logo_url}
                            alt="bank-account"
                          />
                          <img
                            className="default-img"
                            src={value.logo_url}
                            alt="bankimage"

                          />
                        </div>
                        <p>{value.name}</p>
                      </div>
                    </div>
                  </>)
                }) : <div className="no-bank-main">
                  <div className="no-bank-image">
                    <img src={noBanks} />
                  </div>
                  <div className="no-bank-text">No banks found. Please select a different country.</div>
                </div>}

              </div>
            </div>
            <div className="buttons-heard-1" style={{width: "calc(100% - 60px)"}}>
              {instance.state.banks &&
                <div className="button bank-button">
                  <button className="bankBtns" data-test-id="onAddAccountBtn" onClick={() => instance.onAddAccount()}>Add account</button>
                </div>
              }
              <ul className="list-dot bank-list">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li className="current"></li>
                <li></li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    );
  }
  renderArabicBankContent(instance: any) {
    return <div className="bank-integraion-wrapper1">
      <div className="bank-integration-box1 d-flex align-center">

        <div className="bank-account-wrapper1 banklist-wrapper-no-bank1">
          <div className="back-arrow-div">
            <img className="back-icon" data-test-id='bank-icon-click' onClick={() => { instance.setState({ componentPage: "home" }) }} src={back_icon} alt="back-icon" />
            <h4 className="heading-text">إضافة حساب بنكي</h4>
          </div>
          <div className="state-dropdown1">
            <div data-test-id="countriesDropdown" className="state-div" onClick={() => { instance.handleDropdownVisible() }}>
              <img src={country} alt="" />
              <p>{instance.handlbanksParamsName()}<img src={view_image} alt="" /></p></div>
            {instance.state.dropdownVisible &&
              <div className="country-dropdown1">
                <ul data-test-id="test12" onScroll={() => { instance.callCountry() }}>
                  <input
                    className="searchBankCountry"
                    style={{ borderBottom: 'none', backgroundColor: "#aaaaaa", borderRadius: '5px', color: "black" }}
                    data-test-id="bankField3"
                    type="text"
                    value={this.handleCountry()}

                    onChange={(e) => { instance.inputLocation(e.target.value) }}
                    placeholder="حدد الموقع" />
                  <li className="dropdown-li-content" data-test-id="dropdown" onClick={() => { instance.onCountryReset() }}>اختر البلد</li>
                  {instance.state.countries && instance.state.countries.map((value: any, index: number) => {
                    return <li data-test-id={'countries-data1-' + index} onClick={() => { instance.onCountryChange(value.id, value.attributes.arabic_name) }} className="dropdown-li-content">{value.attributes.arabic_name}</li>;
                  })}
                </ul>
              </div>
            }
          </div>
          <div className="bank-account-content-wrapper1">
            <div className="row1 d-flex">
              {instance.state.banks && instance.state.banks[0] ? instance.state.banks[0].map((value: any, index: number) => {
                return (<>
                  <div className="col-6" key={index}>
                    <div data-test-id={"bank-change-" + index} onClick={() => { instance.setState({ flankId: value.flanks_id, bankIdentifier: value.identifier, selectedBank: value.id, selectedBankName: value.name, selectedBankLogo: value.logo_url }) }} className={`${instance.state.selectedBank == value.id ? "active" : ""} bank-account-content d-flex`}>
                      <div className="bank-image">
                        <img
                          src={value.logo_url}
                          alt="bank-account"
                          width="25"
                          height="25"
                          className="active-img"
                        />
                        <img
                          src={value.logo_url}
                          alt="bankimage"
                          className="default-img"
                        />
                      </div>
                      <p>{value.name}</p>
                    </div>
                  </div>
                </>)
              }) : <div className="no-bank-main1">
                <div className="no-bank-image1">
                  <img src={noBanks} />
                </div>
                <div className="no-bank-text">لم يتم العثور على البنوك. الرجاء تحديد بلد مختلف.</div>
              </div>}

            </div>
          </div>
          <div className="buttons-heard-4" style={{width: "calc(100% - 60px)"}}>
            {instance.state.banks &&
              <div className="button bank-button">
                <button className="bankBtns1" data-test-id="onAddAccountBtn" onClick={() => instance.onAddAccount()}>إضافة حساب</button>
              </div>
            }
            <ul className="list-dot bank-list">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li className="current"></li>
              <li></li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  }
  // Customizable Area End

  render() {
    const { instance } = this.props
    return (
      <div className="mainBank-wrapper" dir={instance.handleArabic()}>
        {!instance.state.isArabic ?
          this.renderBankContent(instance)

          :
          this.renderArabicBankContent(instance)
        }
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
