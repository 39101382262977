import React,{ChangeEvent} from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import Select from 'react-select';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imagenav_BackIcon, image_Vuesax, image_Calendar, modelSuccess, imagenav_Bitmap } from "./assets";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import * as Yup from "yup";
import { OutlinedInput } from "@material-ui/core";
import { getStorageData } from 'framework/src/Utilities';
import moment from "moment";
import { processInputValue } from "../../../components/src/commonFn";

export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    image_Vuesax: string;
    imagenav_BackIcon: string;
    hader_dropdown: any;
    currency_name: any;
    selectedFileError: string;
    companyNameError: string;
    newPriceError: string,
    priceError: string,
    companyName: string,
    numberOfShares: string,
    Date: any;
    image_Calendar: string;
    selectCurrency: { value: string, label: string },
    selectCashCurrency: any,
    currencyList: any,
    editPensionData: any;
    openPensionModel: boolean;
    modelSuccess: string;
    countryList: any;
    countryPage: number,
    apiCallingStage: boolean,
    allCountryListData: any,
    nameInput: any;
    pensionName: any;
    amount: any;
    focusedInput: string;
    location: any;
    logoImg1AddPension: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AddPensionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    validationApiCallId: string = "";
    getCategoriesApiCallId: any;
    addSubCategoryApiCallId: any;
    postUserSelectDataCallId: any;
    addCategoryApiCallId: any;
    deleteCategoriesApiCallId: any;
    deleteSubCategoriesApiCallId: any;
    postUserPDFDataCallId: any;
    getCountriesCallId: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            logoImg1AddPension: "ltr",
            priceError: '',
            image_Vuesax: image_Vuesax,
            imagenav_BackIcon: "",
            selectCurrency: { value: '', label: '' },
            hader_dropdown: [],
            companyNameError: '',
            companyName: '',
            currency_name: [],
            selectedFileError: '',
            currencyList: [],
            newPriceError: '',
            numberOfShares: '',
            Date: null,
            image_Calendar: image_Calendar,
            token: "",
            selectCashCurrency: '',
            editPensionData: this.props.location?.state?.pensionEditData?.attributes,
            openPensionModel: false,
            modelSuccess: modelSuccess,
            countryList: [],
            countryPage: 0,
            apiCallingStage: true,
            allCountryListData:[],
            focusedInput: "pensionName",
            nameInput: React.createRef(),
            pensionName: React.createRef(),
            amount: React.createRef(),
            location: React.createRef()
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentWillMount() {
        const language = await getStorageData('language');
        const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
        this.setState({ logoImg1AddPension: logoImg1AddCash });
        const limagenav_BackIcon = language === 'Arabic' ? imagenav_Bitmap : imagenav_BackIcon;
        this.setState({ imagenav_BackIcon: limagenav_BackIcon  });
    }

    handleDateChange = (date: any) => {
        this.setState({ Date: new Date(date) });
    };

    portableAsset = () => {
        !this.state.editPensionData ? this.props.navigation.navigate("CategoriessubcategoriesWeb") : this.props.navigation.navigate("PensionAssetsOverview")
    }

    handleScroll = () => {
        if (this.state.apiCallingStage) {
            this.setState({ apiCallingStage: false }, () => {
                this.getCountriesValue();
            })
        }
    };

    selectPensionCurrency = () => {
        return (

            <FormControl>
                <Select options={this.state.hader_dropdown}
                    value={this.state?.selectCurrency}
                    onChange={(val: any) => { this.setCurrency(val) }}
                    classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="selectMenu" />
            </FormControl>)
    }

    settingRoute = () => {
        this.props.navigation.navigate('Settings2')
    }
    selectMenu(name: string, setFieldValue: any, customStyles: any, values: any, fieldValues: any) {
        return (
            <Select
                className="selectMenu addPensionselectMenuNewMenu"
                styles={customStyles}
                name={name}
                defaultValue={fieldValues}
                options={this.state.currency_name}
                value={fieldValues.value ? fieldValues : this.state.selectCashCurrency}
                onChange={(val: any) => { setFieldValue(name, val) }}
                classNamePrefix="an-simple-select"
                data-test-id="selectMenu"
            />
        )
    }
    errorMessage = (addPensionResponseJson: any) => {
        if (addPensionResponseJson.errors.name) {
            this.setState({ companyNameError: addPensionResponseJson.errors.name[0] })
        } else if (addPensionResponseJson.errors.amount) {
            this.setState({ newPriceError: addPensionResponseJson.errors.amount[0] })
        }
    }

    inputRender = (params: any) => {
        return (
                <TextField
                    {...params} placeholder={this.getStringAddPension('placeholderPensionCurrencyNew')} variant="outlined"
                />
        )
    }

    pensionLocation = (webStyle: any, values: any, setFieldValue: any) => {
        return ( <Autocomplete
            id="combo-box-demo"
            data-test-id="pensionLocationElastic"
            options={this.state.countryList}
            getOptionLabel={(option: any) => option}
            style={webStyle.selectCountry}
            classes={{ popper: 'selectMenu' }}
            value={values.country}
            onChange={(event: ChangeEvent<{}>, newValue:any) => setFieldValue("country", newValue)}
            ListboxProps={{ onScroll: this.handleScroll() }}
            renderInput={(params:any)  =>this.inputRender(params)}
        />)
    }

    dialogPensionPaperStyle = {
        width: 'max-content',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    };

    getStringAddPension = (key: string) => {
        let languageAddPension = localStorage.getItem("language") || "English"
        return configJSON.languageListLogin[languageAddPension][key]
    }
    codeSchema = Yup.object().shape({
        amount: Yup.number().transform((value, originalValue) => {
            if (typeof originalValue === 'string') {
                return parseFloat(originalValue.replace(/,/g, ''));
            }
            return value;
        }).typeError(this.getStringAddPension('amountMustNumber'))
        .required(this.getStringAddPension('amountRequired')),
        pensionName: Yup.string()
            .required(this.getStringAddPension('pensionNameError')),
            date: Yup.date()
            .nullable()
            .notRequired()
            .typeError(this.getStringAddPension('enterValidDate')),
        start_date: Yup.string()
            .required(this.getStringAddPension('startDateError')).nullable(),
        
    });

    dialogPensionModel = () => {
        this.setState({ openPensionModel: !this.state.openPensionModel })
        this.props.navigation.navigate('CategoriessubcategoriesWeb')
    }

    setPensionCurrencyPutapi = (currencyId: string) => {
        const pensionDataCash = localStorage.getItem("token")
        const pensionheader = { "token": pensionDataCash };
        let PensionUserDetailurl = configJSON.SetCurrencyPutapi;
        const formData = new FormData();
        formData.append("currency_id", currencyId);
        const newCashRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postUserSelectDataCallId = newCashRequestMessage.messageId;
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            PensionUserDetailurl
        );
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(pensionheader)
        );
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            'PUT'
        );
        runEngine.sendMessage(newCashRequestMessage.id, newCashRequestMessage);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserPDFDataCallId !== null &&
            this.postUserPDFDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let addPensionResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (addPensionResponseJson.list) {
                const userSelectedCurrncy = addPensionResponseJson.list?.find((value: any) => {
                    return value?.id == addPensionResponseJson?.selection
                })
                const currency = addPensionResponseJson.list?.map((value: any) => (
                    { value: value.currency_type, label: value.currency_type }
                ));
                this.setState({
                    currency_name: currency, currencyList: addPensionResponseJson,
                    selectCurrency: { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` },
                    selectCashCurrency: { value: userSelectedCurrncy.currency_type, label: userSelectedCurrncy.currency_type }
                });
                this.setState({
                    hader_dropdown: addPensionResponseJson?.list?.map((value: any) => (
                        { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
                    ))
                });
            }

            if (addPensionResponseJson?.meta?.message.includes("successfully")) {
                this.setState({ openPensionModel: !this.state.openPensionModel })
            } else if (addPensionResponseJson?.meta?.message.includes("updated")) {
                this.props.navigation.navigate('PensionAssetsOverview')
            } else {
                this.errorMessage(addPensionResponseJson)
            }
        }

        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserSelectDataCallId !== null &&
            this.postUserSelectDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let cashResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (cashResponseJson.meta.currency) {
                this.getPensionList()
            }
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getCountriesCallId !== null &&
            this.getCountriesCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let cashResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.handleCashResponseJson(cashResponseJson)
        }
        // Customizable Area End
    }
    handleCashResponseJson = (cashResponseJson: any) => {
        if (cashResponseJson.length > 1) {
            this.setState((prevState) => ({
                countryList: [...prevState.countryList, ...cashResponseJson.map((value: any) => { 
                    this.state.allCountryListData.push(value)
                    return value.attributes.name })],
            }), () => {
                this.setState({ apiCallingStage: true })
            });
        }
    }



    finalErrorMessage(error: any) {
        if (error) {
            return error
        } else {
            return;
        }
    }

    setCurrency = (value: { value: string, label: string }) => {
        const pensionCurrencyId = this.state.currencyList?.list?.find((newValue: any) => {
            return newValue?.currency_type == value?.value
        })
        this.setPensionCurrencyPutapi(pensionCurrencyId?.id)
    }

    getPensionList = () => {
        const addPensionData = localStorage.getItem("token")
        const header = { "token": addPensionData };
        let userDetailurlPension = configJSON.Loginsubmittoken;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurlPension,
            httpMethod: configJSON.exampleAPiMethodtoken,
        });
        this.postUserPDFDataCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    getCountriesValue = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCountriesCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/get_countries?page=${this.state.countryPage + 1}`
        );
        this.setState({ countryPage: this.state.countryPage + 1 })
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async componentDidMount() {
        this.getPensionList();
        this.getCountriesValue();
        if (this.state.editPensionData.start_date) { 
            const parsedDate = new Date(Date.parse(this.state.editPensionData.start_date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')));
            this.setState({ Date: parsedDate })
        }
        this.state.nameInput.current.focus()
    }

    submitPensionApi = (addPensionOnerequestMessage: any) => {
        if (this.state.editPensionData) {
            addPensionOnerequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
                'PUT'
            );
        } else {
            addPensionOnerequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
                'POST'
            );
        }
    }

    getCountryNewData=(values:any)=>{
        const getData=this.state.allCountryListData.filter((value:any)=>{return value.attributes.name==values.country})[0]
return getData?.id
    }

    formateDate = (dateString: string) => {
        if(!dateString){
            return moment().format('DD/MM/YYYY');
        }
        const newDate = new Date(dateString);
        return `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`;
    }

    HandleSubmit = async (values: any) => {
        const data = localStorage.getItem("token")
        const header = { "token": data };
        let userDetailurlPensionNew = !this.state.editPensionData ? configJSON.PensionApi : `account_block/pensions/${this.props.location?.state?.pensionEditData?.id}`;
        const formData = new FormData();
        formData.append("data[attributes][name]", values.pensionName);
        formData.append("data[attributes][location]", values.location);
        formData.append("data[attributes][start_date]", this.formateDate(values.start_date));
        formData.append("data[attributes][amount]", values.amount);
        formData.append("data[attributes][amount_currency]", values.currency.value ? values.currency.value : this.state.selectCashCurrency.value);
        const addPensionOnerequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postUserPDFDataCallId = addPensionOnerequestMessage.messageId;
        addPensionOnerequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            userDetailurlPensionNew
        );
        addPensionOnerequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        addPensionOnerequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        this.submitPensionApi(addPensionOnerequestMessage)
        runEngine.sendMessage(addPensionOnerequestMessage.id, addPensionOnerequestMessage);
    }

    handleEnterKey = (e: any) => {
        if (e.key === "Enter") {
            if (this.state.focusedInput === "pensionName") {
                this.state.amount.current?.focus();
                this.setState({ focusedInput: "amount" });
            } else if (this.state.focusedInput === "amount") {
                this.state.location.current?.focus();
                this.setState({ focusedInput: "location" });
            }
        }
    };
    onKeyDown = (keyEvent: any) => { if ((keyEvent.charCode || keyEvent.keyCode) === 13) { keyEvent.preventDefault() } }

    handleNameInputClickAddPension = (name: string) => { this.setState({ focusedInput: name }) };

    OutlinedInputNewAddPension = (webStyle: any, placeholderData: string, values: string, handleChange: any, name: string, type: string, nameInputField: { current: null },setFieldValue?: any) => {
        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let inputValue = e.target.value;

            if (name === "amount" && setFieldValue) { 
                    const result = processInputValue(inputValue, 11);
                    if (result) {
                        const { actualValue, formattedValue } = result;
                        setFieldValue(name, actualValue);
                        handleChange(name)(formattedValue);
                      }
                } else {
            handleChange(name)(inputValue);
            }
        };
        
        return (
            <OutlinedInput 
            style={webStyle?.pensionInputRoot} 
            data-test-id="txtInputAssetname" 
            placeholder={this.getStringAddPension(placeholderData)} 
            fullWidth={true} name={name} 
            type={type} value={values} 
            onChange={handleInputChange}
            inputRef={nameInputField} 
            onKeyDown={this.handleEnterKey} 
            onClick={() => this.handleNameInputClickAddPension(name)} />
        )
    }
    // Customizable Area End
}
