import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, FormLabel, makeStyles } from '@material-ui/core';

const RecentTransactionsFilterCheckbox: React.FC<CheckboxFilterProps> = ({
    categories,
    selectedFilterTypes,
    selectedFilterDates,
    selectedFilterCategories,
    handleFilterChange,
    handleApiCallForFilter,
    resetFilters,
    toggleFilter
}) => {
    const classes = useStyles();


    const [language, setLanguage] = useState('English');
    const [translation, setTranslation] = useState(translations.English);


    useEffect(() => {
        const storedLanguage = localStorage.getItem('language') || 'English';
        setLanguage(storedLanguage);
        setTranslation(storedLanguage === 'Arabic' ? translations.Arabic : translations.English);
    }, []);


    const typeOptionsWithValues = [
        { label: translation.typeOptions[0], value: 'Income' },
        { label: translation.typeOptions[1], value: 'Expense' }
    ];

    const dateOptionsWithValues = [
        { label: translation.dateOptions[0], value: '1 month' },
        { label: translation.dateOptions[1], value: 'Last Week' },
        { label: translation.dateOptions[2], value: 'Today' }
    ];

    return (
        <div>
            {/* Clear Filter Link */}
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <p style={{ color: "#C9F9F6", cursor: "pointer" }} onClick={resetFilters}>{translation.clearFilter}</p>

            </div>
            {/* Type Section */}
            <div className={classes.section}>
                <FormLabel className={classes.title}>{translation.type}</FormLabel>
                <FormGroup>
                    {typeOptionsWithValues.map(({ label, value }) => (
                        <FormControlLabel
                            key={value}
                            control={
                                <Checkbox
                                    className={classes.checkbox}
                                    checked={selectedFilterTypes.includes(value.toLowerCase())}
                                    onChange={(e) => handleFilterChange('type', value.toLowerCase(), e.target.checked)}
                                    name={value}
                                />
                            }
                            label={label}
                            classes={{ root: classes.formControlLabel }}
                        />
                    ))}
                </FormGroup>
            </div>

            {/* Date Section */}
            <div className={classes.section}>
                <FormLabel className={classes.title}>{translation.date}</FormLabel>
                <FormGroup>
                    {dateOptionsWithValues.map(({ label, value }) => (
                        <FormControlLabel
                            key={value}
                            control={
                                <Checkbox
                                    className={classes.checkbox}
                                    checked={selectedFilterDates.includes(value.toLowerCase())}
                                    onChange={(e) => handleFilterChange('date', value.toLowerCase(), e.target.checked)}
                                    name={value}
                                />
                            }
                            label={label}
                            classes={{ root: classes.formControlLabel }}
                        />
                    ))}
                </FormGroup>
            </div>

            {/* Category Section */}
            <div className={classes.section}>
                <FormLabel className={classes.title}>{translation.category}</FormLabel>
                <FormGroup>
                    {categories.map((category) => (
                        <FormControlLabel
                            key={category.id}
                            control={
                                <Checkbox
                                    className={classes.checkbox}
                                    checked={selectedFilterCategories.includes(parseInt(category.id, 10))}
                                    onChange={(e) => handleFilterChange('category', parseInt(category.id, 10), e.target.checked)}
                                    name={category.id}
                                />
                            }
                            label={category.attributes.name}
                            classes={{ root: classes.formControlLabel }}
                        />
                    ))}
                </FormGroup>
            </div>

            {/* Button Section */}
            <div className={classes.buttonContainer}>
                <button
                    className={classes.cancelButton}
                    onClick={toggleFilter}
                >
                    {translation.cancel}
                </button>

                <button
                    className={classes.filterButton}
                    onClick={handleApiCallForFilter}
                >
                    {translation.filter}
                </button>
            </div>
        </div>
    );
};

export default RecentTransactionsFilterCheckbox;

type Category = {
    id: string;
    attributes: {
        name: string;
    };
};

interface CheckboxFilterProps {
    categories: Category[];
    selectedFilterTypes: string[];
    selectedFilterDates: string[];
    selectedFilterCategories: number[];
    handleFilterChange: (filterType: 'type' | 'date' | 'category', value: string | number, checked: boolean) => void;
    handleApiCallForFilter: () => void;
    resetFilters: () => void;
    toggleFilter: () => void;
}

const useStyles = makeStyles({
    checkbox: {
        color: '#2de2d5',
        '&$checked': {
            color: '#2de2d5',
        },
    },
    checked: {},
    formControlLabel: {
        marginLeft: '0px',
        marginRight: '0px',
        color: '#fff',
    },
    section: {
        marginBottom: '20px',
    },
    title: {
        color: '#fff',
        fontWeight: 'bold',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px',
    },
    cancelButton: {
        padding: '5px 35px',
        cursor: 'pointer',
        fontWeight: 700,
        borderRadius: '5px',
        borderWidth: '1px',
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        borderColor: '#C9F9F6',
        color: '#C9F9F6',
    },
    filterButton: {
        padding: '5px 35px',
        cursor: 'pointer',
        fontWeight: 700,
        borderRadius: '5px',
        borderWidth: '1px',
        borderStyle: 'solid',
        backgroundColor: '#C9F9F6',
        borderColor: '#C9F9F6',
        color: '#3C3C50',
    }
});

const translations = {
    English: {
        type: 'Type',
        date: 'Date',
        category: 'Category',
        clearFilter: 'Clear Filter',
        cancel: 'Cancel',
        filter: 'Filter',
        typeOptions: ["Income", "Expense"],
        dateOptions: ["1 month", "Last Week", "Today"],
    },
    Arabic: {
        type: 'نوع',
        date: 'تاريخ',
        category: 'فئة',
        clearFilter: 'مسح الفلتر',
        cancel: 'إلغاء',
        filter: 'فلتر',
        typeOptions: ["دخل", "مصروف"],
        dateOptions: ["شهر واحد", "الأسبوع الماضي", "اليوم"],
    }
};